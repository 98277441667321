import React, { useEffect, useState } from "react";
import { Link,  useLocation } from "react-router-dom";
import SidebarToggler from "../../components/SidebarToggler";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Button,
} from "reactstrap";

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import classnames from 'classnames';

import api from '../../utils/api';

const INITIAL_QUERY = {}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const AnalyticDashboard = () => {
  
  const [activeTab, setActiveTab] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [data, setData] = useState({});
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [isLoading, setLoading] = useState(true);
  let month = 0;
  let year = 0;
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);
      year = selectedYear  ?  selectedYear : new Date().getFullYear();
      month = selectedMonth  ?  selectedMonth : 0;
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('/dashboard?month=' + month +'&year=' + year , {
        params: query,
      })
        .then(function (response) {
        setData(response.data);
          console.log(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
    }

    getData();
  }, []);

  const options = [];
  let start_year = new Date().getFullYear();
  for (let i=start_year;i>start_year-5;i--) options.push(i);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Operation Dashboard
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">Operation Dashboard</li>
              {/* <li className="breadcrumb-item active">Departments List</li> */}
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-right">
          <Input 
            type="select" 
            className="custom-select" 
            name="month" 
            id="Month"
            style={{ width: "3cm" }}
           // onChange={handleChange}
            onChange={(e) => {
             // setQuery(INITIAL_QUERY);

              setSelectedMonth(e.target.value);

              let year = 0;
              let { value } = e.currentTarget;

                  let token = localStorage.getItem('auth-token');

                  token = JSON.parse(token);
                  
                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                  year = selectedYear  ?  selectedYear : new Date().getFullYear();
                  api.get('/dashboard?year='+year+'&month='+value)
                  
                  .then((response) => {

                    setData(response.data);
                    setLoading(false);

                  })
                  .catch((error) => {
                    setLoading(false);
                  }); /* */
                }}
          >
            <option>Month</option>
            {[
              {
                month: 0,
                name: 'All Months'
              },
              {
                month: 1,
                name: 'January'
              },
              {
                month: 2,
                name: 'February'
              },
              {
                month: 3,
                name: 'March'
              },
              {
                month: 4,
                name: 'April'
              },
              {
                month: 5,
                name: 'May'
              },
              {
                month: 6,
                name: 'June'
              },
              {
                month: 7,
                name: 'July'
              },
              {
                month: 8,
                name: 'August'
              },
              {
                month: 9,
                name: 'September'
              },
              {
                month: 10,
                name: 'October'
              },
              {
                month: 11,
                name: 'November'
              },
              {
                month: 12,
                name: 'December'
              },
            ].map((month) => 
              <option value={month.month} /* selected={new Date().getMonth()+1 == month.month ?  true :  false} */>{month.name}</option>
            )}
          </Input>

          <Input 
            outline
            color="primary" 
            onChange={(e) => {
              // setQuery(INITIAL_QUERY);
              let { value } = e.currentTarget;
              setSelectedYear(e.target.value);
              let month = selectedMonth ? selectedMonth : 0;
                  let token = localStorage.getItem('auth-token');
                  token = JSON.parse(token);
                  
                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                  api.get('/dashboard?year='+value+'&month='+month)
                  .then((response) => {
                    setData(response.data);
                    setLoading(false);

                  })
                  .catch((error) => {
                    setLoading(false);
                  });
                }}
            type="select" 
            className="custom-select" 
            name="year"
            id="year"
            style={{ width: "3cm" }}
          >
            <option>Year</option>
            {options.map(option => (
            <option key={option} selected={ option==new Date().getFullYear() ?  true :  false}  value={option}>
            {option}
            </option>
            ))}
          </Input>
              &nbsp;
              <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-1`}>
                      Reset
                    </Tooltip>
                  }
                >

                <Button outline
                color="primary"
                type="reset"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href='/analytics';
                  }}
                >
                  <i className="icon-refresh"></i>
                </Button>
                </OverlayTrigger>
          </div>
        </div>
      </div>

      {/* <ul className="nav nav-tabs nav-tabs-new">
        <li class="nav-item">
          <button class="nav-link active show">Contract Review</button>
        </li>
        <li class="nav-item">
          <button class="nav-link">Certification Approval Process & Issuance Of Certificate</button>
        </li>
        <li class="nav-item">
          <button class="nav-link">Auditor Development & Performance Monitoring</button>
        </li>
      </ul> */}

      <Nav tabs>
        <NavItem key={1}>
          <NavLink
            className={classnames({ active: activeTab === 1 })}
            onClick={() => { 
              toggle(1);
            }}
          >
            Pre-Audit
          </NavLink>
        </NavItem>

        <NavItem key={2}>
          <NavLink
            className={classnames({ active: activeTab === 2 })}
            onClick={() => { 
              toggle(2);
            }}
          >
            Post-Audit
          </NavLink>
        </NavItem>

        <NavItem key={3}>
          <NavLink
            className={classnames({ active: activeTab === 3 })}
            onClick={() => { 
              toggle(3);
            }}
          >
            Invoicing
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Receive acknowledgment of proposal from Sales<br />24hrs to response to client</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                        <tr className="text-info font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                        <tr className="text-success font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>a. For new client - based on client readiness (3 working days)</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                        <tr className="text-info font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                        <tr className="text-success font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>b. For surveillance and recertification (3 months ahead)</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan - Surveillance & Re-Certification (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                        <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].all.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan - Surveillance & Re-Certification - 3 Months preparation (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].all.submitted : '~'}</td>
                        </OverlayTrigger>
                        <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].all.percentage : '~'}%</td>
                        </tr>
                        <tr className="text-info font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan - Surveillance & Re-Certification (ISO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].iso.total : '~'}</td>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan - Surveillance & Re-Certification - 3 Months preparation (ISO)
                              </Tooltip>
                            }
                        >    
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].iso.submitted : '~'}</td>
                        </OverlayTrigger>
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].iso.percentage : '~'}%</td>
                        </tr>
                        <tr className="text-success font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan - Surveillance & Re-Certification (MSPO)
                              </Tooltip>
                            }
                        >
                        <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].mspo.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan - Surveillance & Re-Certification - 3 Months preparation (MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].mspo.submitted : '~'}</td>
                        </OverlayTrigger>
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[2].mspo.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Auditor appointment<br />1 month ahead (submission proposed name of auditor to the management)</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Appointment (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                        <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].all.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Appointment 1 Month Ahead (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].all.submitted : '~'}</td>
                        </OverlayTrigger>                          
                        
                        <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].all.percentage : '~'}%</td>
                        </tr>
                        <tr className="text-info font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Appointment (ISO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].iso.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Appointment 1 Month Ahead (ISO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].iso.submitted : '~'}</td>
                        </OverlayTrigger>
                        <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].iso.percentage : '~'}%</td>
                        </tr>
                        <tr className="text-success font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Appointment (MSPO)
                              </Tooltip>
                            }
                        >
                           <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].mspo.total : '~'}</td>
                        </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Appointment 1 Month Ahead (MSPO)
                              </Tooltip>
                            }
                        >
                           <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].mspo.submitted : '~'}</td>
                        </OverlayTrigger> 
                        <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[3].mspo.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Submission of audit plan to client<br />14 working days before the audit date<br /><br /></div>
                  </div>
                  <table className="table">
                    <tbody>
                      <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].all.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan Submitted in 14 working days (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].all.submitted : '~'}</td>
                        </OverlayTrigger>
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].all.percentage : '~'}%</td>
                        </tr>
                      <tr className="text-info font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                                <Tooltip id={`tooltip-1`}>
                                  Total Audit Plan (ISO)
                                </Tooltip>
                              }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].iso.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan Submitted in 14 working days (ISO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].iso.submitted : '~'}</td>
                        </OverlayTrigger>    
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].iso.percentage : '~'}%</td>
                        </tr>
                      <tr className="text-success font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                                <Tooltip id={`tooltip-1`}>
                                  Total Audit Plan (MSPO)
                                </Tooltip>
                              }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].mspo.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan Submitted in 14 working days (MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].mspo.submitted : '~'}</td>
                        </OverlayTrigger>
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[4].mspo.percentage : '~'}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Arrangement of the auditor transportation and/or accommodation<br />7 working days before the audit date</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Audit Plan (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].all.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Arrangement in  7 working days (ISO & MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].all.submitted : '~'}</td>
                        </OverlayTrigger>
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].all.percentage : '~'}%</td>
                        </tr>
                      <tr className="text-info font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                                <Tooltip id={`tooltip-1`}>
                                  Total Audit Plan (ISO)
                                </Tooltip>
                              }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].iso.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Arrangement in  7 working days (ISO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].iso.submitted : '~'}</td>
                        </OverlayTrigger>    
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].iso.percentage : '~'}%</td>
                        </tr>
                      <tr className="text-success font-weight-bold">
                        <OverlayTrigger placement="top" overlay={
                                <Tooltip id={`tooltip-1`}>
                                  Total Audit Plan (MSPO)
                                </Tooltip>
                              }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].mspo.total : '~'}</td>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={
                              <Tooltip id={`tooltip-1`}>
                                Total Auditor Arrangement in  7 working days (MSPO)
                              </Tooltip>
                            }
                        >
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].mspo.submitted : '~'}</td>
                        </OverlayTrigger>
                          <td>{data.operation && data.operation.pre_audit ? data.operation.pre_audit[5].mspo.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane tabId={2}>
        <div className="row clearfix">
            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Lead Auditor to submit report to MSC<br />Within 7 working days</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-info font-weight-bold">
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[0].iso.total : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[0].iso.submitted : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[0].iso.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Report submission to client<br />Within 14 working days (For ISO - completed)</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-info font-weight-bold">
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[1].iso.total : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[1].iso.submitted : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[1].iso.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Report MSPO - draft report<br /><br /></div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-success font-weight-bold">
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[2].mspo.total : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[2].mspo.submitted : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[2].mspo.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Report MSPO - LA Submit Finalize Report<br /><br /><br /></div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-success font-weight-bold">
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[3].mspo.total : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[3].mspo.submitted : '~'}</td>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[3].mspo.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table><br /><br /><br /><br />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>NCR: NCR Respond, MINOR (30d), MAJOR (60d) Closed NCR (3d)<br /><br /></div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-1`}>
                                    Total Minor NCR (ISO & MSPO)
                                  </Tooltip>
                                }
                              >
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].minor.all.total : '~'}</td></OverlayTrigger>
                          <OverlayTrigger placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-1`}>
                                    NCR Reply from Client in 30 days (ISO & MSPO)
                                  </Tooltip>
                                }
                              >
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].minor.all.submitted : '~'}</td></OverlayTrigger>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].minor.all.percentage : '~'}%</td>
                        </tr>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-1`}>
                                    Total Major NCR (ISO & MSPO)
                                  </Tooltip>
                                }
                              >
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].major.all.total : '~'}</td></OverlayTrigger>
                          <OverlayTrigger placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-1`}>
                                    NCR Reply from Client in 60 days (ISO)
                                    & NCR Reply from Client in 90 days (MSPO)
                                  </Tooltip>
                                }
                              >
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].major.all.submitted : '~'}</td></OverlayTrigger>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].major.all.percentage : '~'}%</td>
                        </tr>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-1`}>
                                    Total Closed NCR (ISO & MSPO)
                                  </Tooltip>
                                }
                              >
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].closed.all.total : '~'}</td></OverlayTrigger>
                          <OverlayTrigger placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-1`}>
                                    Auditor Closed NCR in 3 days (ISO & MSPO)
                                  </Tooltip>
                                }
                              >
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].closed.all.submitted : '~'}</td></OverlayTrigger>
                          <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[4].closed.all.percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Technical:<br />
                      1. Submission CAP (3d)<br />
                      2. Courier Certificate (3d)</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top"
                          overlay={
                            <Tooltip id={`tooltip-1`}>
                              Total certificate that has been delivered (ISO & MSPO)
                            </Tooltip>
                          }
                        >
                        <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[5].courier_certificate.total : '~'}</td></OverlayTrigger>
                        <OverlayTrigger placement="top"
                          overlay={
                            <Tooltip id={`tooltip-1`}>
                              Total certificate that has been delivered in 3 days (ISO & MSPO)
                            </Tooltip>
                          }
                        >
                        <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[5].courier_certificate.submitted : '~'}</td></OverlayTrigger>
                        <td>{data.operation && data.operation.post_audit ? data.operation.post_audit[5].courier_certificate.percentage : '~'}%</td>
                        </tr>
                       {/*  <tr className="text-danger font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane tabId={3}>
        <div className="row clearfix">
            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="content ms-3">
                    <div>Invoicing to client<br />Within 14 days after audit completed</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                        <OverlayTrigger placement="top"
                          overlay={
                            <Tooltip id={`tooltip-1`}>
                              Total invoicing to client (ISO & MSPO)
                            </Tooltip>
                          }
                        >
                        <td>{data.operation && data.operation.invoicing ? data.operation.invoicing[0].total : '~'}</td></OverlayTrigger>
                        <OverlayTrigger placement="top"
                          overlay={
                            <Tooltip id={`tooltip-1`}>
                              Total invoicing to client in 14 days (ISO & MSPO)
                            </Tooltip>
                          }
                        >
                        <td>{data.operation && data.operation.invoicing ? data.operation.invoicing[0].submitted : '~'}</td></OverlayTrigger>
                        <td>{data.operation && data.operation.invoicing ? data.operation.invoicing[0].percentage : '~'}%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="content ms-3">
                    <div>Auditor claim submission to Corporate Division<br />Within 3 working days after 7th of the month</div>
                  </div>
                  <table className="table">
                    <tbody>
                        <tr className="text-danger font-weight-bold">
                          <td>0</td>
                          <td>0</td>
                          <td>0%</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default AnalyticDashboard;