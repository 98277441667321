import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  // useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button
} from "reactstrap";

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

const INITIAL_VALUES = {
  name: ""
};

const View = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);

  useEffect(() => {
    const element = document.querySelector('#main-content');
    
    element.classList.add('profilepage_2');

    element.classList.add('blog-page');

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/users/' + id)
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          if (response.data && response.data && response.data.roles && response.data.roles[0]) {
            response.data.role_id = response.data.roles[0].id;
          }

          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card profile-header">
            <div className="body">
              <div className="profile-image">
                <img className="rounded-circle" src={`https://ui-avatars.com/api/?name=${data.name.replace(/ /g, "+")}`} alt="" />
              </div>
              <div>
                <h4 className="m-b-0">
                  <strong>
                    {data.name}
                  </strong>
                </h4>
                <span>{data.roles && data.roles[0].name ? data.roles[0].name : ""}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-md-12">
          <div className="card">
            <div className="header">
              <h2>Info</h2>
            </div>
            <div className="body">
              <small className="text-muted">Group:</small>
              <p>{data.roles && data.roles[0].name ? data.roles[0].name.replace('NCSB', 'NioshCert') : ""}</p>
              <hr />
              <small className="text-muted">Name:</small>
              <p>{data.name}</p>
              <hr />
              <small className="text-muted">Email address:</small>
              <p>{data.email}</p>

              <div className="row clearfix">
                <div className="col-sm-4">
                  <Button 
                    // className="btn btn-outline-secondary"
                    outline
                    color="secondary" 
                    tag={Link} 
                    to="/user"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default View;