import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

//import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

import moment from 'moment';

import { objectToFormData } from 'object-to-formdata';

// import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

const AUDITORS = [
  'Lead Auditor',
  'Lead Auditor In Training',
  'Auditor',
  'Auditor In Training'
];

const INITIAL_VALUES ={};


const Form = (props) => {

  let id=1;
  let history = useHistory();
  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client" } };

  const { addToast } = useToasts();
  const [data, setData] = useState(INITIAL_VALUES);
  const [reviewers, setReviewer] = useState([]);
  const [profile, setProfile] = useState({});

  
  let { readOnly }  = props;

  useEffect(() => {
    // console.log(path);

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

         setProfile(response.data);
        /* if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') === "undefined") {
          history.push(props.path.replace(/:type/, type));
        } */
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getReviewers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      
      api.get('/users?limit=-1&role[]=4&role[]=5')
      .then(function (response) {
        //console.log(response.data.data);
        let reviewers = [];
        response.data.data.map((user, i) => reviewers.push({
          value: user.name,
          id: user.id
        }));
        setReviewer(reviewers);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getReviewers();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditor-claim-settings/' + id)
        .then(function (response) {
          console.log(response.data.data);

          delete response.data.data.id;

          setData(response.data.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);
    
   
  
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2 style={{ textTransform: "capitalize" }}>
               Auditor Claim
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Auditor
              </li>
              <li className="breadcrumb-item">
              Auditor Claim
              </li>
              <li className="breadcrumb-item">
              Settings
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Berer ' + token.access_token;

        // // values.total_allowance = allowance;
        //  values.total_auditor_fee = auditFee;
        //  values.total_amount = totalAll;

          
         
            //data.append('_method', 'PUT');
            console.log(values);
            AUDITORS.map((AUDITOR, i) => {
              values.fee_per_day[i].role = AUDITOR;
              values.mspo_fee_per_day[i].role = AUDITOR;
              values.allowance_per_day[i].role = AUDITOR;
              values.mspo_allowance_per_day[i].role = AUDITOR;
              values.mileage_per_km[i].role = AUDITOR;
              values.mspo_mileage_per_km[i].role = AUDITOR;
            }
            );
          //  var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
          //  data.append('_method', 'PUT');
            var obj = values;
            await api.put('/auditor-claim-settings/'+id, JSON.stringify(obj), {
              headers: {
                'Content-Type': 'application/json',
              },
            })

            /* await api({
              method: 'POST',
              url: '/auditor-claims-settings/' + id,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            }) */

          .then(function (response) {
             console.log(response);

            addToast('Claim Setting successfully updated', { appearance: 'success' });

            // history.push('/audit/audits');

           //  window.location = '/auditor-claim-setting';

            /* if (response.data.data.id) {
              history.push(`${props.path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${response.data.data.id}?tab=introduction`);
            } */
          })
          .catch(function (error) {
            console.log(error);
            if (error && error.response && error.response.data) {
              addToast(error.response.data.error.message, { appearance: 'error' });
            } else {
              addToast(error.message , { appearance: 'error' });
            }
          });
            // api.put('/audits/' + id, JSON.stringify(values), {
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            // })
            // .then(function (response) {
            //   // console.log(response);

            //   addToast('Audit successfully updated', { appearance: 'success' });

            //   history.push('/audit/audits');

            //   // window.location = '/client-application/iso';
            // })
            // .catch(function (error) {
            //   addToast(error.message, { appearance: 'error' });
            // });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <Card>
                  <div className="header">
                    <h2>Auditor Claim Settings</h2>
                  </div>
                  <div className="body">
                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Reviewer">Active ? </Label>
                          <Input 
                            //disabled={id}
                            type="text" 
                            name="active" 
                            id="active"
                            required
                            value={values.active}
                          />
                          
                         
                        </FormGroup>
                      </div>
                    </div> */}
                      <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Reviewer">To Be Reviewed By </Label>
                          <Input 
                           // disabled={id}
                            type="select" 
                            className="custom-select" 
                            name="reviewer_id" 
                            id="reviewer_id"
                            required
                            onChange={handleChange}
                            value={values.reviewer_id}
                          >
                            <option></option>
                            {reviewers && reviewers.map((reviewer, i) => 
                              <option key={i} value={reviewer.id}>{reviewer.value}</option>
                            )}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                      <FormGroup>
                          <Label for="Approver">To Be Approved By </Label>
                          <Input 
                           // disabled={id}
                            type="select" 
                            className="custom-select" 
                            name="approver_id" 
                            id="approver_id"
                            required
                            onChange={handleChange}
                            value={values.approver_id}
                          >
                            <option></option>
                            {reviewers && reviewers.map((approver, i) => 
                              <option key={i} value={approver.id}>{approver.value}</option>
                            )}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>  
                    <React.Fragment>
                      <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th>ISO</th>
                          {AUDITORS.map((AUDITOR, i) => 
                          <th className="text-center">{AUDITOR}</th>)}
                        </tr>
                        </thead>                          
                        <tbody> 
                          <tr>
                            <td>Fee per day (RM) {/* Lead Auditor */}</td>
                            {AUDITORS.map((AUDITOR, i) => 
                            <td className="text-center" key={i}>
                              <Input 
                                  type="text" 
                                  onChange={handleChange}
                                  name={`fee_per_day.${i}.fee_per_day`}
                                  value={values.fee_per_day && values.fee_per_day[`${i}`]  ? values.fee_per_day[`${i}`][`fee_per_day`] : ''}
                                /></td>
                                )}
                          </tr>

                          <tr>
                            <td>Allowance per day (RM) {/* Lead Auditor In Training */}</td>
                            {AUDITORS.map((AUDITOR, i) => 
                            <td className="text-center" key={i}>
                              <Input 
                                  type="text" 
                                  onChange={handleChange}
                                  name={`allowance_per_day.${i}.allowance_per_day`}
                                  value={values.allowance_per_day && values.allowance_per_day[`${i}`]  ? values.allowance_per_day[`${i}`][`allowance_per_day`] : ''}
                                /></td>
                                )}
                          </tr>

                          <tr>
                            <td>Mileage per km (RM) {/* Auditor */}</td>
                            {AUDITORS.map((AUDITOR, i) => 
                            <td className="text-center" key={i}>
                              <Input 
                                  type="text" 
                                  onChange={handleChange}
                                  name={`mileage_per_km.${i}.mileage_per_km`}
                                  value={values.mileage_per_km && values.mileage_per_km[`${i}`] ? values.mileage_per_km[`${i}`][`mileage_per_km`] : ''}
                                /></td>
                                )}
                          </tr>
                        </tbody> 
                       
                      </table>
                    </React.Fragment>  

                    <React.Fragment>
                      <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th>MSPO</th>
                          {AUDITORS.map((AUDITOR, i) => 
                          <th className="text-center">{AUDITOR}</th>)}
                        </tr>
                        </thead>                          
                        <tbody> 
                          <tr>
                            <td>Fee per day (RM) {/* Lead Auditor */}</td>
                            {AUDITORS.map((AUDITOR, i) => 
                            <td className="text-center" key={i}>
                              <Input 
                                  type="text" 
                                  onChange={handleChange}
                                  name={`mspo_fee_per_day.${i}.fee_per_day`}
                                  value={values.mspo_fee_per_day && values.mspo_fee_per_day[`${i}`]  ? values.mspo_fee_per_day[`${i}`][`fee_per_day`] : ''}
                                /></td>
                                )}
                          </tr>

                          <tr>
                            <td>Allowance per day (RM) {/* Lead Auditor In Training */}</td>
                            {AUDITORS.map((AUDITOR, i) => 
                            <td className="text-center" key={i}>
                              <Input 
                                  type="text" 
                                  onChange={handleChange}
                                  name={`mspo_allowance_per_day.${i}.allowance_per_day`}
                                  value={values.mspo_allowance_per_day && values.mspo_allowance_per_day[`${i}`]  ? values.mspo_allowance_per_day[`${i}`][`allowance_per_day`] : ''}
                                /></td>
                                )}
                          </tr>

                          <tr>
                            <td>Mileage per km (RM) {/* Auditor */}</td>
                            {AUDITORS.map((AUDITOR, i) => 
                            <td className="text-center" key={i}>
                              <Input 
                                  type="text" 
                                  onChange={handleChange}
                                  name={`mspo_mileage_per_km.${i}.mileage_per_km`}
                                  value={values.mspo_mileage_per_km && values.mspo_mileage_per_km[`${i}`] ? values.mspo_mileage_per_km[`${i}`][`mileage_per_km`] : ''}
                                /></td>
                                )}
                          </tr>
                        </tbody> 
                       
                      </table>
                    </React.Fragment>                    
                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <button name="action" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Save'} {/* {id ? 'Update' : 'Create'} */}
                        </button>&nbsp;
                        <button type="button" disabled={isSubmitting} className="btn btn-outline-secondary">
                        {'Cancel'}
                        </button>
                        </div> 
                      </div>
                    </div>  
                    
                </Card>
              
              </div>
            </div>
  
          </FormikForm>
        )}
      </Formik>
            
    </div>
  )
}

export default Form;