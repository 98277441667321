import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import {
  InputGroup,
} from 'react-bootstrap';

import DatePicker from 'reactstrap-date-picker';

import { objectToFormData } from 'object-to-formdata';

import api from '../../utils/api';

import { 
  // useFormik, 
  Formik,
  Form as FormikForm,
  // FieldArray,
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';
import SignaturePad from 'react-signature-pad-wrapper';

import Recaptcha from "react-recaptcha";
//import SignatureCanvas from 'react-signature-canvas';

// const COMPETENCIES = [
//   'Occupational Safety & Health MS',
//   'Environmental MS',
//   'Quality MS',
//   'Information Security MS',
//   'Energy MS',
//   'Food Safety MS',
// ];

const STATES = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Wilayah Persekutuan Kuala Lumpur',
  'Wilayah Persekutuan Labuan',
  'Wilayah Persekutuan Putrajaya',
];

const FORMAL_EDUCATION = {
  year_completed: '',
  institution: '',
  qualification: '',
};

const RELEVANT_WORK_EXPERIENCE = {
  job_tenure: '',
  organization: '',
  position: '',
  total_duration: '',
}

const COURSE_ATTENDED = {
  year_completed: '',
  institution: '',
  qualification: '',
}

const AUDITOR_REGISTRATION_NO = {
  body: '',
  no: '',
  grade: '',
}

const AUDITING_TRAINING = {
  year_completed: '',
  institution: '',
  standards: '',
}

const EXPERIENCE = {
  industry: ''
}

const INITIAL_VALUES = {
  client: {
    num_of_employee_per_shift: {},
    industries: [],
    certifications: [],
  },
  branches: {},
  application: {
    branches: []
  }
};

const BRANCH = {};

const PRODUCTS = [{
  'label': 'Palm Oil Mill',
  'field': 'palm_oil_mill',
}, {
  'label': 'Refinery',
  'field': 'refinery',
}, {
  'label': 'Kernel Crusher Plant',
  'field': 'kernel_crusher_plant',
}, {
  'label': 'Biodiesel Plant',
  'field': 'biodiesel_plant',
}, {
  'label': 'Others',
  'field': 'others',
}];

let sigCanvas;
const recaptchaLoaded = async () => {
  /* if (!window.confirm('Send for draft review?')) {
    return;
  } */
  console.log('Captcha successfully loaded!');
}


const RegistrationForm = (props) => {
  let history = useHistory();
  let { path } = useRouteMatch();
  const [isVerified, setVerified] = useState(false);
  const [CERTIFICATIONS, setCertifications] = useState({});
  const [NACE_CODES, setNaceCodes] = useState([]);
  const { addToast } = useToasts();
  const [client, setClient] = useState({});
  const [displayEligibility, setDisplayEligibility] = useState('none');

  const verifyCallback = async(response)=>{
    if(response){
      setVerified(true);
    }
  }  
  useEffect(() => {
    
    // console.log(props);
    const getNaceCodes = async () => {
     // let token = await localStorage.getItem('auth-token');

     // token = JSON.parse(token);

      //api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('auditors/application/nace-codes')
      .then(function (response) {
        // console.log(response.data);

        setNaceCodes(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getNaceCodes();

    const getCertifications = async () => {
     // let token = await localStorage.getItem('auth-token');

    //  token = JSON.parse(token);

      //api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();
    
    
  }, [props]);

  return (
    <React.Fragment>
      <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
      <Card className="invoice1">
        <div className="body">
          <div className="invoice-top clearfix">
            <div className="logo">
              <img src={process.env.PUBLIC_URL + '/logo-niosh.png'} alt="logo" className="rounded-circle img-fluid" />
            </div>
            <div className="info">
            <h6>NIOSH CERTIFICATION SDN. BHD.</h6>
              <p>7th Floor, NIOSH Tower, Lot 1, Jalan 15/1, Seksyen 15, 43650 Bandar Baru Bangi, Selangor<br />
                Tel: +603 8769 2100 / +603 8911 3800   |   Fax: +603 8926 2900
              </p>
            </div>
            <div class="title">
              <h4>CLIENT APPLICATION FORM</h4>

            </div>
          </div>
          <hr />
          <div className="invoice-mid clearfix">&nbsp;</div>
          <div className="invoice-mid clearfix">

          
        <Formik 
          enableReinitialize={true}
          initialValues={client}
          onSubmit={async (values, { setSubmitting }) => {

          values.type = values.type.toUpperCase();
          values.application.iso_type = values.type.toUpperCase();
    
          values.application.declaration_signature = sigCanvas.toDataURL();

          if(isVerified){
              
          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
          await api({
            method: 'POST',
            url: '/client/application',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            addToast('Data successfully created', { appearance: 'success' });
        
            window.location.reload();
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }else
        {
          alert('Please verify that you are human!');
        }
       
          }}
          >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <FormGroup>
                <Label for="CompanyName">Application For  </Label>
              <Input 
              type="select" 
              className="custom-select" 
              name="type" 
              id="type"
              onChange={handleChange}
              /* onChange={(e) => {              
                let stage = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                setFieldValue('stage', stage);
                  if (AuditObjectives[subtype] && AuditObjectives[subtype][stage]) {
                    if (typeof values.audit_objective == 'undefined' || values.audit_objective == null) {
                      setFieldValue('audit_objective', AuditObjectives[subtype][stage]);
                    } else {
                      if (window.confirm('Replace audit objective?')) {
                        setFieldValue('audit_objective', AuditObjectives[subtype][stage]);
                      }
                    }
                  }
              }} */
              value={values.type}
            >
              <option></option>
              <option value="iso">ISO</option>
              <option value="mspo">MSPO</option>
              <option value="mspo-sccs">MSPO-SCCS</option>
            </Input>
            </FormGroup>
          </div>
          <div className="col-lg-4"></div>
        </div>
        <br/>
        {values.type ?
          <Card>
          <div className="header">
            <h2>COMPANY DETAILS</h2>
          </div>
          <div className="body">
        
          <div className="row clearfix">
            <div className="col-lg-12">
            <FormGroup>
              <Label for="CompanyName">Name of Company/Organization</Label>
              <Input 
                
                type="text" 
                name="name" 
                id="CompanyName"
                onChange={handleChange}
                value={values.name}
                required
              />
            </FormGroup>
            </div>           
            <div className="col-sm-12">
            <FormGroup>
              <Label for="CompanyAddress1">Full Address</Label>
              <Input 
                
                type="textarea" 
                name="address_1" 
                id="CompanyAddress1"
                onChange={handleChange}
                value={values.address_1}
              />
            </FormGroup>
            </div>
            <div className="col-sm-6">
            <FormGroup>
              <Label for="Postcode">Postcode</Label>
              <Input 
                
                type="number" 
                name="postcode" 
                id="Postcode"
                // onChange={handleChange}
                onChange={(e) => {
                  setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                }}
                value={values.postcode}
                maxLength={6}
                pattern="\d*"
              />
            </FormGroup>
            </div>
            <div className="col-sm-6">
            <FormGroup>
              <Label for="State">State</Label>
              <Input 
                type="select" 
                className="custom-select" 
                name="state" 
                id="State"
                onChange={handleChange}
                value={values.state}
              >
                <option></option>
                {STATES.map((STATE, key) => <option key={key} value={STATE}>{STATE}</option>)}
              </Input>
            </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label for="Tel">Tel</Label>
                <Input 
                  
                  type="text" 
                  name="tel" 
                  id="Tel"
                  onChange={handleChange}
                  value={values.tel}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label for="Fax">Fax</Label>
                <Input 
                  
                  type="text" 
                  name="fax" 
                  id="Fax"
                  onChange={handleChange}
                  value={values.fax}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label for="Email">Webmail</Label>
                <Input 
                  
                  type="email" 
                  name="email" 
                  id="Email"
                  onChange={handleChange}
                  value={values.email}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label for="Website">Website</Label>
                <Input 
                  
                  type="text" 
                  name="website" 
                  id="Website"
                  onChange={handleChange}
                  value={values.website}
                />
              </FormGroup>
            </div>     
            <div className="col-sm-6">
              <FormGroup>
                <Label for="LegalStatus">Legal Status (R.O.C or R.O.B No)</Label>
                <Input 
                  
                  type="text" 
                  name="legal_status" 
                  id="LegalStatus"
                  onChange={handleChange}
                  value={values.legal_status}
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Label for="ROCCrtificate">Copy of R.O.C Certificate</Label>
                <Input 
                  type="file" 
                  name="roc_certificate" 
                  //id="ROCCrtificate"
                  onChange={(event) => {
                    setFieldValue('roc_certificate', event.currentTarget.files[0])
                  }}
                />
                <div>
                    {values.roc_certificate && values.roc_certificate.path && values.roc_certificate.url ?

                    <a className="btn btn-link" href={values.roc_certificate.url} target="_blank" >{values.roc_certificate.originalName}</a>  
                  : values.roc_certificate && values.roc_certificate.path ?
                    <a className="btn btn-link" href={values.roc_certificate.path} target="_blank" >{values.roc_certificate.originalName}</a> : null }
                </div>
              </FormGroup>
            </div>  
            <div className="col-sm-6">
              <FormGroup>
                <Label for="ManagementRepresentativeName">Name of Management Representative</Label>
                <Input 
                  
                  type="text" 
                  name="management_representative_name" 
                  id="ManagementRepresentativeName"
                  onChange={handleChange}
                  value={values.management_representative_name ? values.management_representative_name : null}
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Label for="ManagementRepresentativeDesignation">Management Representative Designation</Label>
                <Input 
                  
                  type="text" 
                  name="management_representative_designation" 
                  id="ManagementRepresentativeDesignation"
                  onChange={handleChange}
                  value={values.management_representative_designation ? values.management_representative_designation : null}
                />
              </FormGroup>
            </div>     
            <div className="col-sm-12">
              <FormGroup>
                <Label for="ContactPersonName">Name of Contact Person</Label>
                <Input 
                  
                  type="text" 
                  name="contact_person_name" 
                  id="ContactPersonName"
                  onChange={handleChange}
                  value={values.contact_person_name ? values.contact_person_name : null}
                />
              </FormGroup>
            </div>

            <div className="col-sm-4">
              <FormGroup>
                <Label for="ContactPersonPosition">Position</Label>
                <Input 
                  
                  type="text" 
                  name="contact_person_position" 
                  id="ContactPersonPosition"
                  onChange={handleChange}
                  value={values.contact_person_position ? values.contact_person_position : null}
                />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup>
                <Label for="ContactPersonPhone">H/Phone No.</Label>
                <Input 
                  
                  type="text" 
                  name="contact_person_phone" 
                  id="ContactPersonPhone"
                  onChange={handleChange}
                  value={values.contact_person_phone ? values.contact_person_phone : null}
                />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup>
                <Label for="ContactPersonEmail">Email</Label>
                <Input 
                  
                  type="text" 
                  name="contact_person_email" 
                  id="ContactPersonEmail"
                  onChange={handleChange}
                  value={values.contact_person_email ? values.contact_person_email : null}
                />
              </FormGroup>
            </div>

          {values.type && values.type.toLowerCase() === 'mspo-sccs' ?
          
          <React.Fragment>
            <div className="col-sm-12">
            <FormGroup>
                <Label for="">Type of Operating Unit & Product Name(s)</Label>

                {PRODUCTS.map((PRODUCT, key) => 
                <InputGroup className="mb-2">
                <InputGroup.Prepend style={{ width: "190px" }}>
                    <InputGroup.Text style={{ backgroundColor: "#fff", fontSize: "0.8rem", maxHeight: "35px", paddingBottom: 0, width: "190px" }}>
                    <div className="fancy-checkbox">
                        <label>
                        <Input 
                            
                            type="checkbox" 
                            name={PRODUCT.field}
                            onClick={handleChange}
                            onChange={() => {
                            // if (!values[PRODUCT.field]) {
                            //   setFieldValue(`client.${PRODUCT.field}_products`, null);
                            // } else {
                            //   setFieldValue(`client.${PRODUCT.field}`, );
                            // }

                            // if (!values[PRODUCT.field]) {
                            //   setFieldValue(`client.${PRODUCT.field}`, 1);
                            // } else {
                            //   setFieldValue(`client.${PRODUCT.field}`, 1);
                            // }

                            // console.log(values[PRODUCT.field]);

                            if (values[PRODUCT.field]) {
                                setFieldValue(`${PRODUCT.field}`, !values[PRODUCT.field]);
                            } else {
                                setFieldValue(`${PRODUCT.field}`, 1);
                            }
                            }}
                            value="1"
                            checked={values[PRODUCT.field]} 
                        />
                        <span>
                            {PRODUCT.label}
                        </span>
                        </label>
                    </div>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Input 
                    // disabled={values[`${PRODUCT.field}`] !== '1'}
                    //readOnly={readOnly ? true : (values[PRODUCT.field] ? false : true)}
                    readOnly={values[PRODUCT.field] ? false : true}
                    type="text" 
                    name={`${PRODUCT.field}_products`} 
                    id={`${PRODUCT.field}_products`} 
                    onChange={handleChange}
                    value={values[`${PRODUCT.field}_products`]}
                />
                </InputGroup>
                )}
            </FormGroup>
            </div>

            <div className="col-sm-12">
            <FormGroup>
                <Label for="OutsourcedActivities">
                Please state should do you have an outsource activities (if any)
                </Label>
                <Input 
                
                type="text" 
                // className="custom-select" 
                name="application.outsource_activities" 
                id="OutsourcedActivities"
                onChange={handleChange}
                value={values.application && values.application.outsource_activities ? values.application.outsource_activities : ""}
                />
            </FormGroup>
            </div>

            <div className="col-sm-12">
            <FormGroup>
                <Label for="TravelHour">
                Travel hour from the operating unit (if any)
                </Label>
                <Input 
                
                type="text" 
                // className="custom-select" 
                name="application.travel_hour_from_operating_unit" 
                id="TravelHour"
                onChange={handleChange}
                value={values.application && values.application.travel_hour_from_operating_unit}
                />
            </FormGroup>
            </div>
          </React.Fragment>
          : null}

          {values.type && values.type.toLowerCase() === 'iso' ?
          <React.Fragment>
              <div className="col-sm-12">
                <Label>Number of  employees (For Head Office &amp; Site):</Label>
              </div>
                
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="NumOfEmployeeManagement">Management</Label>
                      <Input 
                        
                        type="number" 
                        name="num_of_employee_management" 
                        id="NumOfEmployeeManagement"
                        onChange={handleChange}
                        value={values.num_of_employee_management}
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="NumOfEmployeeContractWorkers">Contract Workers</Label>
                      <Input 
                        
                        type="number" 
                        name="num_of_employee_contract_workers" 
                        id="NumOfEmployeeContractWorkers"
                        onChange={handleChange}
                        value={values.num_of_employee_contract_workers}
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="NumOfEmployeeStaff">Staff</Label>
                      <Input 
                        
                        type="number" 
                        name="num_of_employee_staff" 
                        id="NumOfEmployeeStaff"
                        onChange={handleChange}
                        value={values.num_of_employee_staff}
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="NumOfEmployeeOthers">Others</Label>
                      <Input 
                        
                        type="number" 
                        name="num_of_employee_others" 
                        id="NumOfEmployeeOthers"
                        onChange={handleChange}
                        value={values.num_of_employee_others}
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>

              <div className="col-sm-12">
                <Label>Detail of Shift:</Label>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Size">Shift Operation</Label>
                      <Input type="select" 
                        className="custom-select" 
                        name="shift_operation" 
                        id="ShiftOperation"
                        onChange={handleChange}
                        value={values.shift_operation}
                      >
                        <option></option>
                        <option value="1">Yes (please indicate the number of employee per shift on the following)</option>
                        <option value="0">No</option>
                      </Input>
                    </FormGroup>
                  </div>
                </div>

                {parseInt(values.shift_operation) === 1 ?
                <div className="row clearfix">
                  <div className="col-sm-4">
                    <FormGroup>
                      <Label for="NumOfEmployeeFirstShift">1<sup>st</sup> Shift</Label>
                      <Input 
                        
                        type="number" 
                        name="num_of_employee_per_shift.0" 
                        id="NumOfEmployeeFirstShift"
                        onChange={handleChange}
                        value={values.num_of_employee_per_shift && values.num_of_employee_per_shift[0]}
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup>
                      <Label for="NumOfEmployeeSecondShift">2<sup>nd</sup> Shift</Label>
                      <Input 
                        
                        type="number" 
                        name="num_of_employee_per_shift.1" 
                        id="NumOfEmployeeSecondShift"
                        onChange={handleChange}
                        value={values.num_of_employee_per_shift && values.num_of_employee_per_shift[1]}
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                  <FormGroup>
                    <Label for="NumOfEmployeeThirdShift">3<sup>rd</sup> Shift</Label>
                    <Input 
                      
                      type="number" 
                      name="num_of_employee_per_shift.2" 
                      id="NumOfEmployeeThirdShift"
                      onChange={handleChange}
                      value={values.num_of_employee_per_shift && values.num_of_employee_per_shift[2]}
                      min={0}
                      step={1}
                    />
                  </FormGroup>
                  </div>
                </div>
                : null}
              </div>

            <div className="col-sm-12">
              <FormGroup>
                <Label for="CoverBranches">Do you want to include the certification in the same registration to cover your network of local offices or branches?</Label>
                <Input 
                  type="select" 
                  className="custom-select" 
                  name="application.cover_branches" 
                  id="CoverBranches"
                  onChange={(event) => {
                    setFieldValue('application.cover_branches', event.currentTarget.value);
                    
                    if (event.currentTarget.value == 1) {
                      setDisplayEligibility('block');
                    } else {
                      setDisplayEligibility('none');
                    }
                  }}
                  value={values.application && values.application.cover_branches ? values.application.cover_branches : ""}
                >
                  <option></option>
                  <option value="1">Yes (please indicate below)</option>
                  <option value="0">No</option>
                </Input>
              </FormGroup>
            </div>

            {values.application && values.application.cover_branches && parseInt(values.application.cover_branches) === 1 ?
            <React.Fragment>
                <div className="col-sm-12">
                  <FormGroup>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sites</th>
                          <th>Address</th>
                          <th>No. Of Employees</th>
                          <th>No. Of Shifts</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.application && values.application.branches && values.application.branches.length && values.application.branches.map((BRANCH, key) => 
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              <Input 
                                
                                type="text" 
                                name={`application.branches.${key}.address`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.address`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].address}
                              />
                            </td>
                            <td>
                              <Input 
                                
                                type="number" 
                                name={`application.branches.${key}.no_of_employees`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.no_of_employees`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].no_of_employees}
                                min={0}
                                step={1}
                              />
                            </td>
                            <td>
                              <Input 
                                
                                type="number" 
                                name={`application.branches.${key}.no_of_shifts`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.no_of_shifts`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].no_of_shifts}
                                min={0}
                                step={1}
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                outline
                                color="danger"
                                onClick={() => {
                                  if (window.confirm('Delete this row?')) {
                                    let { branches } = values.application;

                                    delete branches[key];

                                    setFieldValue('application.branches', branches);
                                  }
                                }}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="5">
                            <Button 
                              block 
                              outline
                              onClick={(e) => {
                                e.preventDefault();

                                let { branches } = values.application;
                                if (!branches) {
                                  branches = [];
                                }
                                branches.push(BRANCH);

                                // setData(data => {
                                //   return {...data, branches: branches}
                                // })

                                // console.log(branches);

                                setFieldValue('application.branches', branches);

                                // console.log(values);
                              }}
                            >
                              Add Row
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormGroup>
                </div>

                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="">No Of Site</Label>
                    <Input 
                      type="text" 
                      // name="management_representative_name" 
                      // id="ManagementRepresentativeName"
                      // onChange={handleChange}
                      value={values.application && values.application.branches ? values.application.branches.length : 0}
                      disabled
                    />
                  </FormGroup>
                </div>
            </React.Fragment>
            : null}
          </React.Fragment>
          : null}

          {values.type && values.type.toLowerCase() === 'mspo' ?
          <React.Fragment>
            <div className="col-sm-12">
              <Label>Please indicate the Oil Palm Plantation / Palm Oil Mills details below</Label>

                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="TypeOfMSPOCertification">Types Of MSPO Certification</Label>
                    <Input 
                      type="select" 
                      className="custom-select" 
                      name="application.type_of_mspo_certification" 
                      id="TypeOfMSPOCertification"
                      onChange={handleChange}
                      value={values.application && values.application.type_of_mspo_certification ? values.application.type_of_mspo_certification : ""}
                    >
                      <option></option>
                      <option value="I">
                        Individual Certification
                      </option>
                      <option value="G">
                        Group Certification
                      </option>
                    </Input>
                  </FormGroup>
                </div>

                <div className="col-sm-12">
                  <FormGroup>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sites</th>
                          <th>Full Address Of Estates / Mill</th>
                          <th>Area of Plantation (Ha) / Mill Capacity (Mt/Hr)</th>
                          <th>Travel Hours from Nearest Town</th>
                          <th>Travel Hours from Nearest Airport</th>
                          <th>Years of Planting</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.application && values.application.branches && values.application.branches.length && values.application.branches.map((BRANCH, key) => 
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              <Input 
                                
                                type="text" 
                                name={`application.branches.${key}.address`}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.address`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].address}
                              />
                            </td>
                            <td>
                              <Input 
                                
                                type="text" 
                                name={`application.branches.${key}.area_or_capacity`}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.area_or_capacity`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].area_or_capacity}
                              />
                            </td>
                            <td>
                              <Input 
                                
                                type="text" 
                                name={`application.branches.${key}.travel_hour_town`}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.travel_hour_town`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].travel_hour_town}
                              />
                            </td>
                            <td>
                              <Input 
                                
                                type="text" 
                                name={`application.branches.${key}.travel_hour_airport`}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.travel_hour_airport`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].travel_hour_airport}
                              />
                            </td>
                            <td>
                              <Input 
                                
                                type="text" 
                                name={`application.branches.${key}.years_planting`}
                                onChange={(event) => {
                                  setFieldValue(`application.branches.${key}.years_planting`, event.currentTarget.value);
                                }}
                                value={values.application.branches[key].years_planting}
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                outline
                                color="danger"
                                onClick={() => {
                                  if (window.confirm('Delete this row?')) {
                                    let { branches } = values.application;

                                    delete branches[key];

                                    setFieldValue('application.branches', branches);
                                  }
                                }}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="7">
                            <Button 
                              block 
                              outline
                              onClick={(e) => {
                                e.preventDefault();

                                let { branches } = values.application;
                                if (!branches) {
                                  branches = [];
                                }
                                branches.push(BRANCH);

                                // setData(data => {
                                //   return {...data, branches: branches}
                                // })

                                // console.log(branches);

                                setFieldValue('application.branches', branches);
                              }}
                            >
                              Add Row
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormGroup>
                </div>

                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="">No Of Site</Label>
                    <Input 
                      type="text" 
                      // name="management_representative_name" 
                      // id="ManagementRepresentativeName"
                      // onChange={handleChange}
                      value={values.application && values.application.branches ? values.application.branches.length : 0}
                      disabled
                    />
                  </FormGroup>
                </div>
            </div>
          </React.Fragment>
          : null}
            
          </div> 
          </div>
          </Card>
          : null}
        
        {values.type && values.type.toLowerCase() !== 'mspo-sccs' ?             
          <Card style={{ display: displayEligibility }}>
            <div className="header">
              <h2>ELIGIBILITY OF AN ORGANIZATION FOR MULTI-SITE SAMPLING AUDIT
                <small>
                  Please tick the following situations to determine the 
                  eligibility for certification of multiple-sites based 
                  on sampling
                </small>
              </h2>
            </div>
            <div className="body">
              <div className="row clearfix">
                <div className="col-sm-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>NO</th>
                        <th>SITUATIONS</th>
                        <th>YES</th>
                        <th>NO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1a</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          The processes at all sites have to be substantially of 
                          the same kind and have to be operated to similar 
                          methods and procedures. <b>AND/OR</b>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1a" 
                                value="Y" 
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1a === 'Y'}
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1a"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1a', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1a ? values.application.eligibility_for_multisite_sampling_audit_1a === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1a" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1a === 'N'}
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1a"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1a', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1a ? values.application.eligibility_for_multisite_sampling_audit_1a === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>1b</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          Processes in each location 
                          are not similar but are clearly linked (e.g. fabrication 
                          in one location, assembly in several other locations
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1b" 
                                value="Y"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1b === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1b"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1b', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1b ? values.application.eligibility_for_multisite_sampling_audit_1b === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1b" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1b === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1b"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1b', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1b ? values.application.eligibility_for_multisite_sampling_audit_1b === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          The organization’s management system shall be under a 
                          centrally controlled and administered plan and be subject 
                          to central management review.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_2" 
                                value="Y"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_2 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_2"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_2', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_2 ? values.application.eligibility_for_multisite_sampling_audit_2 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_2" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_2 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_2"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_2', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_2 ? values.application.eligibility_for_multisite_sampling_audit_2 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          All the relevant sites shall be subject to the organization’s internal 
                          audit program and all shall have been audited in accordance with that 
                          program prior to the certification body, NIOSH Certification starting 
                          its audit.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_3" 
                                value="Y" 
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_3 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_3"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_3', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_3 ? values.application.eligibility_for_multisite_sampling_audit_3 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_3" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_3 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_3"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_3', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_3 ? values.application.eligibility_for_multisite_sampling_audit_3 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          The organization’s is able to collect and analyze data from all sites and 
                          authorize to initiate organizational change if required.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_4" 
                                value="Y"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_4 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_4"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_4', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_4 ? values.application.eligibility_for_multisite_sampling_audit_4 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_4" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_4 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_4"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_4', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_4 ? values.application.eligibility_for_multisite_sampling_audit_4 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          Use of temporary sites which are to be included within the scope of 
                          certification.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_5" 
                                value="Y" 
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_5 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_5"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_5', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_5 ? values.application.eligibility_for_multisite_sampling_audit_5 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_5" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_5 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_5"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_5', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_5 ? values.application.eligibility_for_multisite_sampling_audit_5 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Card>
          : null}                        

        {values.type ?
        <Card>
        <div className="header">
          <h2>SCOPE/PROCESS</h2>
        </div>
        <div className="body">
          <div className="row clearfix">
            <div className="col-sm-12">
              <FormGroup>
                <Label for="ScopeOfCertification">Scope Of Certification</Label>
                <Input 
                  type="textarea" 
                  name="scope_of_certification" 
                  id="ScopeOfCertification" 
                  onChange={handleChange}
                  value={values.scope_of_certification} 
                />
              </FormGroup>
            </div>              
          
            {values.type && values.type.toLowerCase() === 'iso' ?
            <div className="col-sm-12">
              <FormGroup>
                <Label for="NaceCode">Nace Code</Label>
                
                <div className="row">
                  <div className="col-sm-6">
                    {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                      i < 20 ? (
                      <React.Fragment key={i}>
                        <div className="fancy-checkbox">
                          <label>
                            <Input 
                              //disabled={readOnly}
                              type="checkbox" 
                              // disabled={true}
                              name={`nace_codes`}
                              // onChange={handleChange}
                              onChange={() => {
                                // if (values.typeStr !== 'ISO') {
                                  let { nace_codes } = values;

                                  if (nace_codes && nace_codes.length) {
                                    var index = nace_codes.indexOf(NACE_CODE.id);
                                    if (index !== -1) {
                                      nace_codes.splice(index, 1);
                                    } else {
                                      nace_codes.push(NACE_CODE.id);
                                    }
                                  } else {
                                    nace_codes = [];
                                    nace_codes.push(NACE_CODE.id);
                                  }

                                  setFieldValue('nace_codes', nace_codes);
                                // }
                              }}
                              // value={CERTIFICATION.id}
                              checked={values.nace_codes && values.nace_codes.length && values.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                            />
                            <span>
                              {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                            </span>
                          </label>
                        </div>
                      </React.Fragment>
                      ) : null
                    ) : null}
                  </div>
                  
                  <div className="col-sm-6">
                    {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                      i >= 20 ? (
                      <React.Fragment key={i}>
                        <div className="fancy-checkbox">
                          <label>
                            <Input 
                              //disabled={readOnly}
                              type="checkbox" 
                              // disabled={true}
                              name={`nace_codes`}
                              // onChange={handleChange}
                              onChange={() => {
                                //if (values.typeStr !== 'ISO') {
                                  let { nace_codes } = values;

                                  if (nace_codes && nace_codes.length) {
                                    var index = nace_codes.indexOf(NACE_CODE.id);
                                    if (index !== -1) {
                                      nace_codes.splice(index, 1);
                                    } else {
                                      nace_codes.push(NACE_CODE.id);
                                    }
                                  } else {
                                    nace_codes = [];
                                    nace_codes.push(NACE_CODE.id);
                                  }

                                  setFieldValue('nace_codes', nace_codes);
                                // }
                              }}
                              // value={CERTIFICATION.id}
                              checked={values.nace_codes && values.nace_codes.length && values.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                            />
                            <span>
                              {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                            </span>
                          </label>
                        </div>
                      </React.Fragment>
                      ) : null
                    ) : null}
                  </div>
                </div>

                {/* <BootstrapForm.Text className="text-muted">
                  Identify the lowest level NACE code
                </BootstrapForm.Text> */}
              </FormGroup>
            </div>
            : null}   
          </div>
          </div>
        
        </Card>
        : null}
      
        {values.type && values.type.toLowerCase() === 'iso' ?
         <Card>
         <div className="header">
           <h2>CERTIFICATION(S) REQUIRED</h2>
         </div>
         <div className="body">
          <div className="row clearfix">

            <div className="col-sm-12">

                {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                    <React.Fragment key={key}>
                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            
                            type="checkbox" 
                            name={`client.certifications`}
                            // onChange={handleChange}
                            onChange={() => {
                              let certifications = values.certifications ? values.certifications : [];

                              if (certifications.length) {
                                var index = certifications.indexOf(CERTIFICATION.id);
                                if (index !== -1) {
                                  certifications.splice(index, 1);
                                } else {
                                  certifications.push(CERTIFICATION.id);
                                }
                              } else {
                                certifications.push(CERTIFICATION.id);
                              }

                              setFieldValue('certifications', certifications);

                              // console.log(values.client.certifications);
                            }}
                            value={CERTIFICATION.id}
                            checked={values.certifications && values.certifications.length && values.certifications.indexOf(CERTIFICATION.id) !== -1} 
                          />
                          <span>
                            {CERTIFICATION.standard}
                          </span>
                        </label>
                      </div>
                    </React.Fragment>
                  ) : null}

            </div>
          </div>
          </div>
          </Card>
        : null}
        

        {values.type && values.type.toLowerCase() !== 'mspo-sccs' ? 
        <Card>
        <div className="header">
          <h2>ADDITIONAL INFORMATION</h2>
        </div>
        <div className="body">
          <div className="row clearfix">
            <div className="col-sm-12">
              <Label>When do you plan to be certified:</Label>
            </div>              
            <div className="col-sm-6">
              <FormGroup>
                <Label for="TentativeStage1Audit">Tentative Stage 1 Audit</Label>
                <DatePicker
                  autoComplete="off"
                  name="application.tentative_s1_audit" 
                  id="TentativeStage1Audit"
                  value={values.application && values.application.tentative_s1_audit ? values.application.tentative_s1_audit : ""}
                  onChange={date => setFieldValue('application.tentative_s1_audit', moment(date).format('YYYY-MM-DD'))}
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Label for="TentativeStage2Audit">Tentative Stage 2 Audit</Label>
                <DatePicker
                  autoComplete="off"
                  name="application.tentative_s2_audit" 
                  id="TentativeStage2Audit"
                  value={values.application && values.application.tentative_s2_audit ? values.application.tentative_s2_audit : ""}
                  onChange={date => setFieldValue('application.tentative_s2_audit', moment(date).format('YYYY-MM-DD'))}
                />
              </FormGroup>
            </div>
            <div className="col-sm-12">
              <FormGroup>
                <Label for="HasDocumentationReady">Has your management system documentation is ready?</Label>
                <Input 
                  type="select" 
                  className="custom-select" 
                  name="application.documentation_ready" 
                  id="IsDocumentationReady"
                  onChange={handleChange}
                  value={values.application && values.application.documentation_ready ? values.application.documentation_ready : ""} 
                >
                  <option></option>
                  <option value="Y">Yes (please proceed to the next questions)</option>
                  <option value="N">No</option>
                </Input>
              </FormGroup>
            </div>

            {values.application && values.application.documentation_ready && values.application.documentation_ready === 'Y' ?
            <div className="col-sm-6">
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{ borderTop: 'none' }}>Is it implemented?</td>
                    <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Input 
                          type="radio" 
                          name="documentation_implemented" 
                          value="Y" 
                          onClick={handleChange}
                          checked={values.documentation_implemented === 'Y'}
                        />
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="application.documentation_implemented"
                          value="1"
                          onClick={() => {
                            setFieldValue('application.documentation_implemented', 'Y')
                          }}
                          checked={values.application && values.application.documentation_implemented ? values.application.documentation_implemented === 'Y' : ""}
                        />
                        <span>
                          <i></i> Yes
                        </span>
                      </label>
                    </td>
                    <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Input 
                          type="radio" 
                          name="documentation_implemented" 
                          value="N" 
                          onClick={handleChange}
                          checked={values.documentation_implemented === 'N'}
                        />
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="application.documentation_implemented"
                          value="0"
                          onClick={() => {
                            setFieldValue('application.documentation_implemented', 'N')
                          }}
                          checked={values.application && values.application.documentation_implemented ? values.application.documentation_implemented === 'N' : ""}
                        />
                        <span>
                          <i></i> No
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderTop: 'none' }}>Have you conducted Internal Audit?</td>
                    <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Input 
                          type="radio" 
                          name="internal_audit_conducted" 
                          value="Y"
                          onClick={handleChange}
                          checked={values.internal_audit_conducted === 'Y'}
                        />
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="application.internal_audit_conducted"
                          value="1"
                          onClick={() => {
                            setFieldValue('application.internal_audit_conducted', 'Y')
                          }}
                          checked={values.application && values.application.internal_audit_conducted ? values.application.internal_audit_conducted === 'Y' : ""}
                        />
                        <span>
                          <i></i> Yes
                        </span>
                      </label>
                    </td>
                    <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Input 
                          type="radio" 
                          name="internal_audit_conducted" 
                          value="N"
                          onClick={handleChange}
                          checked={values.internal_audit_conducted === 'N'}
                        />
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="application.internal_audit_conducted"
                          value="0"
                          onClick={() => {
                            setFieldValue('application.internal_audit_conducted', 'N')
                          }}
                          checked={values.application && values.application.internal_audit_conducted ? values.application.internal_audit_conducted === 'N' : ""}
                        />
                        <span>
                          <i></i> No
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderTop: 'none' }}>Have you conducted management review?</td>
                    <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Input 
                          type="radio" 
                          name="management_review_conducted" 
                          value="Y" 
                          onClick={handleChange}
                          checked={values.management_review_conducted === 'Y'}
                        />
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="application.management_review_conducted"
                          value="1"
                          onClick={() => {
                            setFieldValue('application.management_review_conducted', 'Y')
                          }}
                          checked={values.application && values.application.management_review_conducted ? values.application.management_review_conducted === 'Y' : ""}
                        />
                        <span>
                          <i></i> Yes
                        </span>
                      </label>
                    </td>
                    <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Input 
                          type="radio" 
                          name="management_review_conducted" 
                          value="N"
                          onClick={handleChange}
                          checked={values.management_review_conducted === 'N'}
                        />
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="application.management_review_conducted"
                          value="0"
                          onClick={() => {
                            setFieldValue('application.management_review_conducted', 'N')
                          }}
                          checked={values.application && values.application.management_review_conducted ? values.application.management_review_conducted === 'N' : ""}
                        />
                        <span>
                          <i></i> No
                        </span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            : null}
          
            <div className="col-sm-12">
              <FormGroup>
                <Label for="HasCertifiedOtherStandard">Has the company been certified to any other standards?</Label>
                <Input 
                  type="select" 
                  className="custom-select" 
                  name="application.has_other_cert" 
                  id="HasCertifiedOtherStandard"
                  onChange={handleChange}
                  value={values.application && values.application.has_other_cert ? values.application.has_other_cert : ""}
                >
                  <option></option>
                  <option value="Y">Yes (please specify standard)</option>
                  <option value="N">No</option>
                </Input>
              </FormGroup>
            </div>
            {values.application && values.application.has_other_cert && values.application.has_other_cert === 'Y' ?
            <React.Fragment>
              <div className="col-sm-12">
                <FormGroup>
                  <Label for="OtherStandard">Standard</Label>
                  <Input 
                    
                    type="text" 
                    name="application.other_standard" 
                    id="OtherStandard"
                    onChange={handleChange}
                    value={values.application && values.application.other_standard ? values.application.other_standard : ""}
                  />
                </FormGroup>
              </div>

              <div className="col-sm-6">
                <FormGroup>
                  <Label for="CertificateCopy">Please Provide Copy of Certificate</Label>
                        <Input
                          type="file" 
                          name="certificate_copy" 
                          onChange={(event) => {
                            console.log(event.currentTarget.files[0])
                            setFieldValue(`certificate_copy`, event.currentTarget.files[0])
                          }}
                        />
                    <div>
                    {values.application.certificate_copy && values.application.certificate_copy.path && values.application.certificate_copy.url ?

                    <a className="btn btn-link" href={values.application.certificate_copy.url} target="_blank" >{values.application.certificate_copy.originalName}</a>  
                  : values.application.certificate_copy && values.application.certificate_copy.path ?
                    <a className="btn btn-link" href={values.application.certificate_copy.path} target="_blank" >{values.application.certificate_copy.originalName}</a> : null }</div>
                </FormGroup>
              </div>
            </React.Fragment>
            : null}

            <div className="col-sm-12">
              <FormGroup>
                <Label for="HasConsultant">The company has consultant?</Label>
                <Input 
                  type="select" 
                  className="custom-select" 
                  name="application.has_consultant" 
                  id="HasConsultant"
                  onChange={handleChange}
                  value={values.application && values.application.has_consultant ? values.application.has_consultant : ""}
                >
                  <option></option>
                  <option value="Y">Yes (please state consultant name)</option>
                  <option value="N">No</option>
                </Input>
              </FormGroup>
            </div>
            {values.application && values.application.has_consultant && values.application.has_consultant === 'Y' ? 
            <div className="col-sm-12">
              <FormGroup>
                <Label for="ConsultantName">Consultant Name</Label>
                <Input 
                  
                  type="text" 
                  name="application.consultant_name" 
                  id="ConsultantName"
                  onChange={handleChange}
                  value={values.application && values.application.consultant_name ? values.application.consultant_name : ""}
                />
              </FormGroup>
            </div>
            : null }

            <div className="col-sm-12">
              <FormGroup>
                <Label for="">How did you hear of NIOSH Certification?</Label>
                <div className="fancy-checkbox">
                  <label>
                    <Input 
                      
                      type="checkbox" 
                      name="application.how_did_you_hear_newspaper"
                      onChange={() => {
                        // setFieldValue('application.how_did_you_hear_newspaper', values.application && values.application.how_did_you_hear_newspaper ? !values.application.how_did_you_hear_newspaper : values.application.how_did_you_hear_newspaper);

                        if (values.application && values.application.how_did_you_hear_newspaper) {
                          setFieldValue('application.how_did_you_hear_newspaper', !values.application.how_did_you_hear_newspaper);
                        } else {
                          setFieldValue('application.how_did_you_hear_newspaper', 1);
                        }
                      }}
                      value="1"
                      checked={values.application && values.application.how_did_you_hear_newspaper ? values.application.how_did_you_hear_newspaper : ""} 
                    />
                    <span>
                      Newspaper
                    </span>
                  </label>
                </div>

                <div className="fancy-checkbox">
                  <label>
                    <Input 
                      
                      type="checkbox" 
                      name="application.how_did_you_hear_website"
                      onChange={() => {
                        // setFieldValue('application.how_did_you_hear_website', values.application && values.application.how_did_you_hear_website ? !values.application.how_did_you_hear_website : "");

                        if (values.application && values.application.how_did_you_hear_website) {
                          setFieldValue('application.how_did_you_hear_website', !values.application.how_did_you_hear_website);
                        } else {
                          setFieldValue('application.how_did_you_hear_website', 1);
                        }
                      }}
                      value="1"
                      checked={values.application && values.application.how_did_you_hear_website ? values.application.how_did_you_hear_website : ""} 
                    />
                    <span>
                      Website
                    </span>
                  </label>
                </div>

                <div className="fancy-checkbox">
                  <label>
                    <Input 
                      
                      type="checkbox" 
                      name="application.how_did_you_hear_facebook"
                      // onClick={handleChange}
                      onChange={() => {
                        // setFieldValue('application.how_did_you_hear_facebook', values.application && values.application.how_did_you_hear_facebook ? !values.application.how_did_you_hear_facebook : "");

                        if (values.application && values.application.how_did_you_hear_facebook) {
                          setFieldValue('application.how_did_you_hear_facebook', !values.application.how_did_you_hear_facebook);
                        } else {
                          setFieldValue('application.how_did_you_hear_facebook', 1);
                        }
                      }}
                      value="1"
                      checked={values.application && values.application.how_did_you_hear_facebook ? values.application.how_did_you_hear_facebook : ""} 
                    />
                    <span>
                      Facebook/Blog
                    </span>
                  </label>
                </div>

                <div className="fancy-checkbox">
                  <label>
                    <Input 
                      
                      type="checkbox" 
                      name="application.how_did_you_hear_others"
                      // onClick={handleChange}
                      onChange={() => {
                        // setFieldValue('application.how_did_you_hear_others', values.application && values.application.how_did_you_hear_others ? !values.application.how_did_you_hear_others : "");

                        if (values.application && values.application.how_did_you_hear_others) {
                          setFieldValue('application.how_did_you_hear_others', !values.application.how_did_you_hear_others);
                        } else {
                          setFieldValue('application.how_did_you_hear_others', 1);
                        }
                      }}
                      value="1"
                      checked={values.application && values.application.how_did_you_hear_others ? values.application.how_did_you_hear_others : ""} 
                    />
                    <span>
                      Others
                    </span>
                  </label>
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
        </Card>
        : null}
        
        {values.type ?            
        <Card>
        <div className="header">
          <h2>DECLARATION</h2>
        </div>
        <div className="body">
          <div className="row clearfix">
            <div className="col-sm-12 pb-5">
            I/We hereby declared that the information given in this application is true.
            </div>
              <div className="col-sm-6 pb-5">
                <Label for="">Signature</Label>
                {/* <SignatureCanvas 
                  canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} 
                  ref={(ref) => { sigCanvas = ref }}
                /> */}
                <SignaturePad 
                    // width={500} 
                    // height={200}
                    ref={ref => sigCanvas = ref} 
                    // onEnd={() => {
                    //   values.declaration_signature = sigCanvas.toDataURL();
                    // }}
                  />
                <Button 
                  block 
                  outline
                  type="button"
                  onClick={() => {
                    sigCanvas.clear()
                  }}>
                    Clear signature
                  </Button>
              </div>
              <div className="col-sm-12">
              <FormGroup>
                <Label for="DeclarationName">Name</Label>
                <Input 
                  //readOnly={readOnly}
                  type="text" 
                  name="application.declaration_name" 
                  id="DeclarationName"
                  onChange={handleChange}
                  value={values.application && values.application.declaration_name ? values.application.declaration_name : ""}
                />
              </FormGroup>
              </div>
              <div className="col-sm-6">
                  <FormGroup>
                    <Label for="DeclarationPosition">Position</Label>
                    <Input 
                      //readOnly={readOnly}
                      type="text" 
                      name="application.declaration_position" 
                      id="DeclarationPosition"
                      onChange={handleChange}
                      value={values.application && values.application.declaration_position ? values.application.declaration_position : ""}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="DeclarationDate">Date</Label>
                    <DatePicker
                      autoComplete="off"
                      name="application.declaration_date" 
                      id="DeclarationDate"
                      value={values.application && values.application.declaration_date ? values.application.declaration_date : ""}
                      onChange={date => setFieldValue('application.declaration_date', moment(date).format('YYYY-MM-DD'))}
                    />
                  </FormGroup>
                </div>
              <div className="col-sm-6 pb-5">
              <Recaptcha
                sitekey="6LfPkjMdAAAAAChGz4Ics5wquwpyCiG6cq30CTrK"
                render="explicit"
                verifyCallback={verifyCallback}
                onloadCallback={recaptchaLoaded}
              />    
              
              </div>
              <div className="col-sm-12">
              <h5>DISCLAIMER</h5>
              </div>
              <div className="col-sm-12">
                Disclaimer regarding use of consultancy services. NIOSH Certification does not warrant or agree with any statement or suggestion that certification would be creation or preparation of a management system.
              </div>
              <div className="col-sm-12">
                &nbsp;</div>
              <div className="col-sm-4">
                <button 
                  type="submit" 
                  className="btn btn-primary"
                  disabled={isSubmitting ? true : false}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>&nbsp;
                <Button 
                  // className="btn btn-outline-secondary"
                  outline
                  color="secondary" 
                  // tag={Link} 
                  //to={props.path}
                  onClick={() => {

                    history.push({
                      pathname: path,
                    });

                  }}
                >
                  Reset
                </Button>
              </div>
          </div>
        </div>
        </Card>
        : null }

          </FormikForm>

          )} 
        </Formik>
        
        </div>
        </div>
        
      </Card>
      </div>
      </div>
    </React.Fragment>
  )
}

export default RegistrationForm;