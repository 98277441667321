import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

import paginationFactory from 'react-bootstrap-table2-paginator';

import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';
import qs from 'query-string';
const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Listing = () => {
  let queryString = useQuery();
  let { type, subtype } = useParams();
  let { path } = useRouteMatch();
  let history = useHistory();

  let [profile, setProfile] = useState({});
  const [refresh, setRefreshing] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [data, setData] = useState([]);
  const [dataCsv, setDataCsv] = useState([]);

  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);
  const { addToast } = useToasts();
  let [token, setToken] = useState('');

  const { ExportCSVButton } = CSVExport;
  
  const columns = [
  {
    editable: false,
    text: "No",
    csvFormatter:(cell, row, rowIndex2) => {
      let rowNumber2 = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex2 + 1);
      return rowNumber2
    },
    formatter: (cell, row, rowIndex) => {
      let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
      return <span>{rowNumber}.</span>;
    }
  },
  {
    text: 'Date of Audit',
    csvFormatter: (cell, row, rowIndex) => {
      return row.audit.audit_plan && row.audit.audit_plan.date_of_audit_start && row.audit.audit_plan.date_of_audit_end ? moment(row.audit.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.audit.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""
  },
    formatter: (cell, row, rowIndex) => {
        return row.audit.audit_plan && row.audit.audit_plan.date_of_audit_start && row.audit.audit_plan.date_of_audit_end ? moment(row.audit.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.audit.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""
    }
  },
  {
    text: 'Date of Approval',
    csvFormatter: (cell, row, rowIndex) => {
      return row.technical_review_1_approved_at ? moment(row.technical_review_1_approved_at).format('DD/MM/YYYY') : ""
  },
    formatter: (cell, row, rowIndex) => {
      return row.technical_review_1_approved_at ? moment(row.technical_review_1_approved_at).format('DD/MM/YYYY') : ""
    }
  },
  {
    text: 'Type of Audit',
    csvFormatter: (cell, row, rowIndex) => {
      if(row.audit.audit_plan && row.audit.audit_plan.stage){
        
       if(row.audit.audit_plan.other_audit)
         return row.audit.audit_plan.other_audit+'\n'+row.audit.audit_plan.stage;
       else
         return row.audit.audit_plan.stage;
     }

       return row.audit.audit_plan && row.audit.audit_plan.stage ? row.audit.audit_plan.stage : '';

   },
    formatter: (cell, row, rowIndex) => {
       if(row.audit.audit_plan && row.audit.audit_plan.stage){
         
        if(row.audit.audit_plan.other_audit)
          return row.audit.audit_plan.other_audit+'\n'+row.audit.audit_plan.stage;
        else
          return row.audit.audit_plan.stage;
      }

        return row.audit.audit_plan && row.audit.audit_plan.stage ? row.audit.audit_plan.stage : '';

    }
  },
  {
    text: 'Organization',
    csvFormatter: (cell, row, rowIndex) => {
      
      if (row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.name) {
          return row.audit.audit_plan.client.name;
      }
  },
    formatter: (cell, row, rowIndex) => {
      
        if (row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.name) {
            return row.audit.audit_plan.client.name;
        }
    }
  }, {
    text: 'Standards',
    csvFormatter: (cell, row, rowIndex) => {
      
      return (
        row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.certifications ? 
          row.audit.audit_plan.client.certifications.map((certification, i) => 
            certification.code
           ) : "")
      },
    formatter: (cell, row, rowIndex) => {
      
      return (
        <ul>{row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.certifications ? 
          row.audit.audit_plan.client.certifications.map((certification, i) => 
            <li>{certification.code}</li>
           ) : ""}&nbsp;</ul>
      )
    }
  }, 
  // {
  //   dataField: 'status_text',
  //   text: 'Status',
  // },
  {
    dataField: 'technical_review_state_text',
    text: 'TR State',
  },
  // {
  //   text: 'Team Leader',
  //   formatter: (cell, row, rowIndex) => {
  //     return (
  //       <ol>{row.client && row.client.audit_plan && row.client.audit_plan.lead_auditors ? 
  //         row.client.audit_plan.lead_auditors.map((auditor, i) => 
  //           <li>{auditor.full_name}</li>
  //          ) : ""}&nbsp;</ol>
  //     )
  //   }
  // }, 
  {
    dataField: 'id',
    text: ' ',
    csvExport: false,
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          {/* <Button outline tag={Link} to={`${path.replace(/:type/, type)}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button> */}
           <OverlayTrigger
              
              placement="top"
              overlay={
                <Tooltip id={`tooltip-1`}>
                  View
                </Tooltip>
              }
            >
          <Button outline tag={Link} to={`${path.replace(/:type/, type)}/view/${cell}/${row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.id ? row.audit.audit_plan.client.id : ''}`} >
            <i className="icon-eye"></i>
          </Button>
          </OverlayTrigger>
          {parseInt(row.technical_review_state) === 1 && permissions && permissions.find(o => o.name.toLowerCase() === 'technical_review.assign') ?
          <OverlayTrigger
            placement="top"
            overlay={
            <Tooltip id={`tooltip-1`}>
              Assign Reviewer
            </Tooltip>
            }
          >
            <Button outline tag={Link} to={`${path.replace(/:type/, type)}/assign/${cell}/${row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.id ? row.audit.audit_plan.client.id : ''}`}>
              <i className="icon-user-following"></i>
            </Button>
            </OverlayTrigger>

          : null}
          
          {(parseInt(row.technical_review_state) < 3 && permissions && permissions.find(o => o.name.toLowerCase() === 'technical_review.update')) || permissions && permissions.find(o => o.name.toLowerCase() === 'technical_review.amend') ?
          
           <OverlayTrigger
             placement="top"
             overlay={
             <Tooltip id={`tooltip-1`}>
               Edit
             </Tooltip>
             }
           >
          <Button outline tag={Link} to={(parseInt(row.technical_review_state)) == 2 && row.technical_review_1_reviewed_at == null && permissions && permissions.find(o => o.name.toLowerCase() === 'technical_review.amend') ? `${path.replace(/:type/, type)}/assign/${cell}/${row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.id ? row.audit.audit_plan.client.id : ''}` : `${path.replace(/:type/, type)}/edit/${cell}/${row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.id ? row.audit.audit_plan.client.id : ''}`}>
            <i className="icon-pencil"></i>
          </Button>
          </OverlayTrigger>
          : null}
          
          {(parseInt(row.technical_review_state) === 2 || parseInt(row.technical_review_state) === 3) && permissions && permissions.find(o => o.name.toLowerCase() === 'technical_review.review') ?
          <OverlayTrigger
          placement="top"
          overlay={
          <Tooltip id={`tooltip-1`}>
            Review
          </Tooltip>
          }
        >
           <Button outline tag={Link} to={`${path.replace(/:type/, type)}/review/${cell}/${row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.id ? row.audit.audit_plan.client.id : ''}`}>
              <i className="icon-note"></i>
            </Button>
            </OverlayTrigger>
          : null}
          {parseInt(row.technical_review_state) === 4 && permissions && permissions.find(o => o.name.toLowerCase() === 'technical_review.approve') ?
          <OverlayTrigger
            placement="top"
            overlay={
            <Tooltip id={`tooltip-1`}>
              Approve
            </Tooltip>
            }
          >
            <Button outline tag={Link} to={`${path.replace(/:type/, type)}/approve/${cell}/${row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.id ? row.audit.audit_plan.client.id : ''}`}>
              <i className="icon-check"></i>
            </Button>
          </OverlayTrigger>
          : null}
          
          {parseInt(row.technical_review_state) === 5 &&  permissions && permissions.find(o => o.name.toLowerCase() === 'technical_review.approve') ?
          <OverlayTrigger
          placement="top"
          overlay={
          <Tooltip id={`tooltip-1`}>
            Approve
          </Tooltip>
          }
        >
           <Button outline tag={Link} to={`${path.replace(/:type/, type)}/approve/${cell}/${row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.id ? row.audit.audit_plan.client.id : ''}`}>
              <i className="icon-check"></i>
            </Button>
            </OverlayTrigger>
          : null}
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    if (!profile.permissions) {
      getMe();
    }
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      let url = '';
      /* switch (type.toUpperCase()) {
        case 'ISO':
         // url = '/operation-reviews?type[]=ISO&status[]=3&status[]=4&status[]=5';
          url = '/operation-reviews?type[]=ISO&status[]=3';
          break;

        case 'MSPO':
          url = '/operation-reviews?type[]=MSPO&type[]=MSPO-SCCS&status[]=3';
          break;

        default:
          break;
      } */
      if(type.toUpperCase()==='ISO'){
        url = '/operation-reviews?type[]=iso&status[]=3';
      }
      else{
        url = '/operation-reviews?type[]=mspo&type[]=mspo-sccs&status[]=3';
      }
      api.get(url+'&=' + Math.random().toString(36).substring(7), {
        params: query,
      })
      .then(function (response) {

        setPermissions(response.data.meta.permissions);

        setData(response.data.data);


        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
        
      })
      .catch(function (error) {
         console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
      setRefreshing(false);
    }

    getData();

    const getDataCsv = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      let url = '';
      /* switch (type.toUpperCase()) {
        case 'ISO':
         // url = '/operation-reviews?type[]=ISO&status[]=3&status[]=4&status[]=5';
          url = '/operation-reviews?type[]=ISO&status[]=3';
          break;

        case 'MSPO':
          url = '/operation-reviews?type[]=MSPO&type[]=MSPO-SCCS&status[]=3';
          break;

        default:
          break;
      } */
      if(type.toUpperCase()==='ISO'){
        url = '/operation-reviews?type[]=iso&status[]=3&limit=-1';
      }
      else{
        url = '/operation-reviews?type[]=mspo&type[]=mspo-sccs&status[]=3&limit=-1';
      }
      api.get(url+'&=' + Math.random().toString(36).substring(7), {
        params: query,
      })
      .then(function (response) {

        setPermissions(response.data.meta.permissions);

        setDataCsv(response.data.data);


        
      })
      .catch(function (error) {
         console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
      setRefreshing(false);
    }

    getDataCsv();
  }, [/* props, */ profile, query]);
  const MyExportCSV = (props) => {
    const handleClick = () => {
     
      // passing my custom data
      props.onExport(dataCsv.filter(o => o.status === "3" && (o.audit.audit_plan.stage === "Stage 2" || o.audit.audit_plan.stage === "Re-Certification Cum Migration" || o.audit.audit_plan.stage === "Re-Certification" || o.audit.audit_plan.stage === "Follow Up Audit" || (o.audit.audit_plan.other_audit === "Scope Extension Audit" && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4")) || (o.audit.audit_plan.other_audit === "Transfer" && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4")) || (parseInt(o.audit.scope_modification) === 1 && o.audit.scope_modification_comments != null && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4")) )));
    };
    return (
      
        <OverlayTrigger
          placement="top"
          overlay={
          <Tooltip id={`tooltip-1`}>
            Export to CSV
          </Tooltip>
          }
        >
           <Button className="btn btn-success"  onClick={ handleClick }>
              <i className="fa fa-file-excel-o"></i>
            </Button>
            </OverlayTrigger>
    );
  };
  // const deleteHandler = async (id) => {
  //   if (window.confirm('Delete this record?')) {
  //     let token = await localStorage.getItem('auth-token');

  //     token = JSON.parse(token);

  //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

  //     api.delete('/audits/' + id)
  //     .then(function (response) {
  //       // console.log(response);

  //       // setData(response.data.data);

  //       addToast('CAP successfully deleted', { appearance: 'success' });

  //       history.push(path.replace(/:type/, type));
  //     })
  //     .catch(function (error) {
  //       // console.log(error.response);
  //       addToast(error.response.data.message, { appearance: 'error' });
  //     });
  //   }
  // }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }


  return (
  <ToolkitProvider
  keyField="id"
  data={data.filter(o => o.status === "3" && (o.audit.audit_plan.stage === "Stage 2" || o.audit.audit_plan.stage === "Re-Certification Cum Migration" || o.audit.audit_plan.stage === "Re-Certification" || o.audit.audit_plan.stage === "Follow Up Audit" || (o.audit.audit_plan.other_audit === "Scope Extension Audit" && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4")) || (o.audit.audit_plan.other_audit === "Transfer" && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4")) || (o.audit.audit_plan.other_audit === "Special Audit" && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4")) || (parseInt(o.audit.scope_modification) === 1 && o.audit.scope_modification_comments != null && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4")) ))}
  columns={ columns }
  exportCSV
>{
    props => (
    <React.Fragment> 
  
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <SidebarToggler />
                {type.toUpperCase()} Certification Approval Panel (CAP)
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Review
                </li>
                <li className="breadcrumb-item">
                  Certification Approval Panel (CAP)
                </li>
                <li className="breadcrumb-item">
                  <Link to={path.replace(/:type/, type)}>
                    {type.toUpperCase()}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>Certification Approval Panel (CAP)</h2>
              </div>
              <div className="body">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                  //  client_name: queryString.get('client_name') ? queryString.get('client_name') : "",
                    start: queryString.get('start') ? queryString.get('start') : "",
                    end: queryString.get('end') ? queryString.get('end') : "",
                    // region: queryString.get('region') ? queryString.get('region') : "",
                    // state: queryString.get('state') ? queryString.get('state') : "",
                    // certifications: queryString.get('certifications') ? queryString.get('certifications') : "",
                    // status: queryString.get('status') ? queryString.get('status') : "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                    setQuery(values);

                    history.push({
                      pathname: props.path,
                      search: searchString
                    });

                    setQuery(values);
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    {/* <FormGroup>
                      <Label for="Name">Organization</Label>
                      <Input 
                        type="text" 
                        // className="custom-select" 
                        name="client_name" 
                        id="Name"
                        onChange={handleChange}
                        value={values.client_name}
                      />
                    </FormGroup> */}
                    <FormGroup>
                        <Label for="Date">Date of Approval</Label>
                        <DateRangePicker 
                         // disabled={readOnly}
                          initialSettings={{
                            timePicker: false
                          }}
                          timePicker={false}
                          timePickerSeconds={false}
                          containerStyles={{ display: "block" }}
                          start={values.start && values.start.length ? moment(values.start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                          end={values.end && values.start.length ? moment(values.end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                          onApply={(event, picker) => { 
                            setFieldValue('start', picker.startDate.format('YYYY-MM-DD'));
                            setFieldValue('end', picker.endDate.format('YYYY-MM-DD'));
                          }}
                        >
                          <Input 
                            autoComplete="off"
                          //  readOnly={readOnly}
                            type="text" 
                            // name="date_of_audit" 
                            id="date"
                            // onChange={handleChange}
                            value={values.start && values.end ? moment(values.start).format('DD/MM/YYYY') + ' - ' + moment(values.end).format('DD/MM/YYYY') : ""}
                          />
                        </DateRangePicker>
                      </FormGroup>
                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <Label>&nbsp;</Label>
                        <FormGroup>
                          <Button 
                            // className="btn btn-outline-secondary"
                            outline
                            color="secondary" 
                            // tag={Link} 
                            // to="/client-database"
                          >
                            Search
                          </Button>
                          <Button 
                            // className="btn btn-outline-secondary"
                            type="button"
                            outline
                            color="link" 
                            // tag={Link} 
                            // to={`${path}`}
                            onClick={() => {
                              setQuery(INITIAL_QUERY);

                              history.push({
                                pathname: props.path,
                              });

                              setQuery(INITIAL_QUERY);
                            }}
                          >
                            Reset
                          </Button>
                        </FormGroup>
                      </div>
                    </div>
                  </FormikForm>
                  )}
                </Formik>

                <hr />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-right"><MyExportCSV { ...props.csvProps } />{/* <ExportCSVButton className="m-r-20 btn btn-success"  { ...props.csvProps }>Export CSV</ExportCSVButton> */}
              </div>
              <div className="body project_report">
                <div className="table">
                <BootstrapTable { ...props.baseProps } 
                remote={true}
                bootstrap4
                bordered={false}
                classes="table-hover js-basic-example dataTable table-custom m-b-0"
                headerClasses="thead-dark"
                keyField="id"
                data={data.filter(o => o.status === "3" && (o.audit.audit_plan.stage === "Stage 2" || o.audit.audit_plan.stage === "Re-Certification Cum Migration" || o.audit.audit_plan.stage === "Re-Certification" || o.audit.audit_plan.stage === "Follow Up Audit" || ((o.audit.audit_plan.other_audit === "Scope Extension Audit" || o.audit.audit_plan.other_audit === "Transfer" ||o.audit.audit_plan.other_audit === "Special Audit" || (o.audit.scope_modification_comments !== null && o.audit.scope_modification !== null)) && (o.audit.audit_plan.stage === "Surveillance 1" || o.audit.audit_plan.stage === "Surveillance 2" || o.audit.audit_plan.stage === "Surveillance 3" || o.audit.audit_plan.stage === "Surveillance 4") )))}
                pagination={ paginationFactory(pagingInfo) } 
                onTableChange={handleTableChange}
                />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
</ToolkitProvider>
  )


}
export default Listing;