import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  // ListGroup,
  // ListGroupItem,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

// import moment from 'moment';

// import DatePicker from 'reactstrap-date-picker';

// import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

const INITIAL_VALUES = {

};

const FormCreate = (props) => {
  let { type, subtype, id } = useParams();

  // let { path } = useRouteMatch();
  let history = useHistory();
  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client" } };

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);
  const [clients, setClient] = useState([]);
//   const [auditors, setAuditor] = useState([]);

  let { readOnly }  = props;

  useEffect(() => {
    // console.log(path);

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);
        if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') === "undefined") {
          history.push(props.path.replace(/:type/, type));
        }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getAuditPlans = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('client-list?exclude_audits=true&sort=clients.name&type=' + type + '&subtype=' + subtype + '&limit=-1')
      .then(function (response) {
         console.log(response.data.data);

        // setClient(response.data.data);

        let clientsArray = [];

        response.data.data.map((plan, i) => {
          let subtype = plan.subtype.replace(/-/, ' ').replace(plan.subtype.charAt(0), plan.subtype.charAt(0).toUpperCase());
            if (plan.type.toUpperCase() === 'ISO') {
              subtype = "";
            }
           
            clientsArray.push({
              id: plan.id,
              name: plan.name + " (" + plan.type.toUpperCase() + " " + subtype + " " + plan.stage + ")"
            })
           
          }
        ).sort((a, b) => a.plan.name - b.plan.name);
       
        setClient(clientsArray);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditPlans();

    // const getClients = async () => {
    //   let token = await localStorage.getItem('auth-token');

    //   token = JSON.parse(token);

    //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    //   api.get('/clients?type=' + type + '&limit=-1')
    //   .then(function (response) {
    //     // console.log(response.data);

    //     setClient(response.data.data);
    //   })
    //   .catch(function (error) {
    //     // console.log(error.response);
    //     if (error && error.response && error.response.data) {
    //       addToast(error.response.data.message, { appearance: 'error' });
    //     } else {
    //       addToast(error.message, { appearance: 'error' });
    //     }
    //   });
    // }

    // getClients();

    //   const getAuditors = async () => {
    //     let token = await localStorage.getItem('auth-token');

    //     token = JSON.parse(token);

    //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    //     api.get('/auditors?limit=-1')
    //     .then(function (response) {
    //       // console.log(response.data);

    //       setClient(response.data.data);
    //     })
    //     .catch(function (error) {
    //       // console.log(error.response);
    //       if (error && error.response && error.response.data) {
    //         addToast(error.response.data.message, { appearance: 'error' });
    //       } else {
    //         addToast(error.message, { appearance: 'error' });
    //       }
    //     });
    //   }

    //   getAuditors();

      // const getData = async () => {
      //   let token = await localStorage.getItem('auth-token');

      //   token = JSON.parse(token);

      //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      //   api.get('/audits/' + id)
      //   .then(function (response) {
      //     // console.log(response.data);

      //     delete response.data.id;

      //     setData(response.data);
      //   })
      //   .catch(function (error) {
      //     // console.log(error.response);
      //     if (error && error.response && error.response.data) {
      //       addToast(error.response.data.message, { appearance: 'error' });
      //     } else {
      //       addToast(error.message, { appearance: 'error' });
      //     }
      //   });
      // }

      // getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2 style={{ textTransform: "capitalize" }}>
              <SidebarToggler />
              {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""} Audit Report
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                Audit Report
              </li>
              <li className="breadcrumb-item" style={{ textTransform: "capitalize" }}>
                <Link to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}>
                  {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
          
          if (!id) {
            await api.post('/audits', JSON.stringify(values), {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(function (response) {
              // console.log(response.data.data.id);

              addToast('Audit successfully saved', { appearance: 'success' });

              // history.push('/audit/audits');

              // window.location = '/client-application/iso';

              if (response.data.data.id) {
                history.push(`${props.path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${response.data.data.id}?tab=introduction`);
              }
            })
            .catch(function (error) {
              if (error && error.response && error.response.data) {
                addToast(error.response.data.error.message, { appearance: 'error' });
              } else {
                addToast(error.message , { appearance: 'error' });
              }
            });
          } else {
            // api.put('/audits/' + id, JSON.stringify(values), {
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            // })
            // .then(function (response) {
            //   // console.log(response);

            //   addToast('Audit successfully updated', { appearance: 'success' });

            //   history.push('/audit/audits');

            //   // window.location = '/client-application/iso';
            // })
            // .catch(function (error) {
            //   addToast(error.message, { appearance: 'error' });
            // });
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <Card>
                  <div className="header">
                    <h2>AUDIT REPORT</h2>
                  </div>
                  <div className="body">
                      <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Organization">Organization</Label>
                          <Input 
                            readOnly={readOnly}
                            type="select" 
                            className="custom-select" 
                            name="audit_plan_id" 
                            id="Organization"
                            onChange={handleChange}
                            value={values.audit_plan_id}
                          >
                            <option></option>
                            {clients && clients.map((client, i) => 
                              <option key={i} value={client.id}>{client.name}</option>
                            )}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? 'Creating Audit Report...' : 'Create'} {/* {id ? 'Update' : 'Create'} */}
                        </button>&nbsp;
                        <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export default FormCreate;