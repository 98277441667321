import React from 'react';

import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

// import ISO from './ISO';
// import MSPO from './MSPO';
// import MSPO_SCCS from './MSPO_SCCS';

import Listing from './Listing';
import ClientListing from '../Client';
import View from './View';
import ViewDocument from './ViewDocument';

const ClientApplication = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect
          to={{
              pathname: `${path}/iso`,
              // state: { from: props.location }
          }}
        />
      </Route>
      <Route path={`${path}/client-database`}>
        <ClientListing />
      </Route>
      <Route path={`${path}/iso`}>
        <Listing type={'iso'} />
      </Route>
      <Route path={`${path}/mspo`}>
        <Listing type={'mspo'} />
      </Route>
      <Route path={`${path}/mspo-sccs`}>
        <Listing type={'mspo-sccs'} />
      </Route>
      <Route 
          path={`${path}/view/:id`} 
          render={(props) => <View path={path} readOnly={true} {...props} />}
        />
        {/*       <Route 
          path={`${path}/view/roccertificate/:id`} 
          render={(props) => <ViewDocument path={path} readOnly={true} {...props} />}
        />*/}
      {/* <Route 
          path={`${path}/view/certificatecopy/:id`} 
          render={() => <ViewDocument path={path} />}
        />    */}
      {/* <Route path={`${path}/view/roccertificate/:id`} >
        <ViewDocument path={`${path}`} readOnly={true} {...props} />
      </Route> */}
     {/*  <Route path={`${path}/view/certificatecopy/:id`} >
      <ViewDocument path={`${path}`} readOnly={true} {...props} />
      </Route> */}
    </Switch>
  )
}

export default ClientApplication;