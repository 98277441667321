import firebase from 'firebase';

export const config = {
  apiKey: "AIzaSyCxoMWEGbMC09BGJ52Lf4_x7T23A0q8nzI",
  authDomain: "scuds-9deec.firebaseapp.com",
  databaseURL: "https://scuds-9deec.firebaseio.com",
  projectId: "scuds-9deec",
  storageBucket: "scuds-9deec.appspot.com",
  messagingSenderId: "156512594263",
  appId: "1:156512594263:web:b1136e09557845f390f612",
  measurementId: "G-PF3WRQ4Z7Q",
};
firebase.initializeApp(config);

export const db = firebase.database();

export const askForPermissionToRecieveNotifications = async () => {
  try {
      const messaging = firebase.messaging();
      // await messaging.requestPermission();
      let token = await messaging.getToken();
      // console.log('token: ', token);
      localStorage.setItem('notification-token', token);
    
      let user = await localStorage.getItem('auth-user');
      user = JSON.parse(user);
      // console.log(user.email);
      db.ref("token/" + window.btoa(user.email)).set({token: token});
      console.log('Token set: ' + token);
    
      messaging.onTokenRefresh(async () => {
        // console.log('TokenRefresh');
        let token = await messaging.getToken();
        localStorage.setItem('notification-token', token);
        
        let user = await localStorage.getItem('auth-user');
        user = JSON.parse(user);
        
        db.ref("token/" + window.btoa(user.email)).set({token: token});
      });

      return token;
  } catch (error) {
      console.error(error);
  }
}

export const notificationHandler = async () => {
  const messaging = firebase.messaging();

  messaging.onMessage(function (payload) {
  // alert(payload);
  
  console.log(payload);

    let n = new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: payload.notification.icon,
      click_action: payload.notification.click_action,
    });

    // n.onclick = function (event) {
    //   // console.log(event);

    //   window.open(payload.notification.click_action, '_blank');
    // }
  });
}