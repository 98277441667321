import React, { useEffect, useState } from 'react';

import {
  Link,
  Switch,
  Redirect,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Listing from './Listing';
import Form from './Form';

import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

const Client = () => {
  let { path } = useRouteMatch();

  let [profile, setProfile] = useState({});

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();
  }, []);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Client Database
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Technical 
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  Client Database
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path={path}>
          {/* {profile && profile.client_id ? 
            <Redirect
            to={{
                pathname: `${path}/view/${profile.client_id}`,
                // state: { from: props.location }
            }}
          />
          : 
            <Listing />
          } */}
          <Listing />
        </Route>
        <Route path={`${path}/create`}>
          <Form readOnly={false} />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <Form path={path} readOnly={false} />
        </Route>
        <Route path={`${path}/view/:id`}>
          <Form path={path} readOnly={true} />
        </Route>
      </Switch>
    </div>
  )
}

export default Client;