import React from 'react';

import {
  // Link,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Listing from './Listing';
// import Form from './Form';

// import SidebarToggler from '../../components/SidebarToggler';

const AuditorLog = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Listing />
      </Route>
      {/* <Route path={`${path}/create`}>
        <Form readOnly={false} />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <Form path={path} readOnly={false} />
      </Route>
      <Route path={`${path}/view/:id`}>
        <Form path={path} readOnly={true} />
      </Route> */}
    </Switch>
  )
}

export default AuditorLog;