import React, { useContext, useEffect, useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import AuthContext from '../../authContext';

import api from '../../utils/api';

const PROFILE = {
  'id': null,
  'name': '~',
  'email': null,
};

const UserAccount = () => {
  const { setAuthState } = useContext(AuthContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  let [profile, setProfile] = useState(PROFILE);

  useEffect(() => {
    // console.log(authState);

    // setProfile(JSON.parse(localStorage.getItem('auth-user')));

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me', null, {
        headers: {
          Authorization: 'Bearer ' + token.access_token,
        }
      })
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          if (error.response.data.message === 'Unauthenticated.') {
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-user');
            setAuthState('');
          }
        } else {
          console.log(error.message);
        }
      });
    }

    getMe();
  }, []);

  const logout = () => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('auth-user');
    setAuthState('');
  }

  return (
    <div className="user-account">
      <img src={`https://ui-avatars.com/api/?name=${profile.name.replace(/ /g, "+")}`} alt="" className="rounded-circle user-photo" />
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
        <span>Welcome,</span>
        <DropdownToggle className="user-name  text-wrap" color="link" caret style={{ paddingLeft: 0 }}>
          <strong>{profile && profile.client && profile.client.name ? profile.client.name : (profile && profile.name ? profile.name : null)}</strong>
        </DropdownToggle>
        <DropdownMenu 
          tag="ul" 
          className="dropdown-menu-right account animated flipInY"
          style={{ marginTop: "5px" }}
        >
          <DropdownItem 
            tag="li" 
            // className={false}
          >
            <Link to="/profile/change-password">
              <i className="icon-key"></i> Change Password
            </Link>
          </DropdownItem>
          <DropdownItem 
            tag="li" 
            // className={false}
          >
            <a href="/" onClick={(e) => {
              e.preventDefault();

              logout();
            }}>
              <i className="icon-power"></i> Logout
            </a>
          </DropdownItem>
          {/* <DropdownItem>B</DropdownItem>
          <DropdownItem>C</DropdownItem>
          <DropdownItem>D</DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default UserAccount;