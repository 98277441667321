import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { objectToFormData } from 'object-to-formdata';

import { useToasts } from 'react-toast-notifications';

import classnames from 'classnames';

import api from '../../utils/api';

import SidebarToggler from '../../components/SidebarToggler';

const Form = (props) => {
  let { id } = useParams();
  let history = useHistory();
  const { addToast } = useToasts();

  const [load, reload] = useState(false);
  const [token, setToken] = useState('');
  const [data, setData] = useState();

  useEffect(() => {

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        setToken(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/stakeholder-list/' + id)
          .then(function (response) {
            delete response.data.id;
            console.log(response);
            setData(response.data);

            if (response.data && response.data.stakeholder_list && response.data.stakeholder_list.path) {
              console.log(response.data.stakeholder_list_url);
              let config = {
                type: response.data && response.data.stakeholder_list.originalExtension ? response.data.stakeholder_list.originalExtension : 'xlsx',
                height: window.innerHeight - 110,
                  document: {
                      fileType: response.data && response.data.stakeholder_list.originalExtension ? response.data.stakeholder_list.originalExtension : 'xlsx',
                      key: `${id}-${Date.now()}`,
                      title: 'Stakeholder List',
                      url: response.data.stakeholder_list.url,
                      permissions: {
                          download: true,
                          edit: false,
                          print: true,
                          review: false,
                      }
                  },
                  documentType: 'spreadsheet',
                  editorConfig: {
                      callbackUrl: '',
                      mode: 'view',
                      customization: {
                          zoom: 70,
                          customer: {
                              address: '',
                              info: '',
                              logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                              mail: 'iskandar@greentagpro.com',
                              name: 'Greentagpro Solution',
                              www: 'greentagpro.com',
                          },
                          chat: false,
                          forcesave: true,
                          comments: false,
                      }
                  },
              }

              new window.DocsAPI.DocEditor("placeholder", config);
            }
          
            reload(false);
          })
          .catch(function (error) {
             console.log(error.response);
            if (error && error.response && error.response.data) {
              addToast(error.response.data.message, { appearance: 'error' });
            } else {
              addToast(error.message, { appearance: 'error' });
            }
          });
      }

      getData();
    // }
  }
  }, [load])

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <SidebarToggler />
                Stakeholder List
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Home
                </li>
                <li className="breadcrumb-item">
                  <Link to={props.path.replace(/:id/, '')}>
                    Stakeholder List
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>Stakeholder List</h2>
              </div>
              <div className="body">
                <Formik
                  enableReinitialize={true}
                  initialValues={data}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let token = await localStorage.getItem('auth-token');

                    token = JSON.parse(token);

                    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                    values._method = 'PUT';

                    //values.
                    var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                    
                    await api({
                      method: 'POST',
                      url: '/stakeholder-list/' + id,
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                      data: data,
                    })
                    .then(function (response) {
                      addToast('Stakeholder List successfully uploaded', { appearance: 'success' });
                      window.location.reload();
                    })
                    .catch(function (error) {
                      addToast(error.message, { appearance: 'error' });
                    });
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <FormikForm>
                      <FormGroup>
                        <Label for="">Stakeholder List</Label>
                        <div className="input-group">
                          <div className="custom-file">
                            <Input
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            //accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              className="custom-file-input"
                              type="file" 
                              name="stakeholder_list" 
                              id="stakeholder_list"
                              onInput={(event) => {
                                 console.log(event.target.files[0]);

                                setFieldValue('stakeholder_list', event.target.files[0])
                              }}
                            />
                            <Label className="custom-file-label">
                              {values && values.stakeholder_list && values.stakeholder_list.name ? values.stakeholder_list.name : 'No file chosen'}
                            </Label>
                          </div>
                          <div className="input-group-append">
                            <button 
                              disabled={isSubmitting}
                              className="btn btn-outline-secondary" 
                              type="submit"
                              >
                                {isSubmitting ? 'Uploading...' : 'Upload'}
                              </button>
                          </div>
                        </div>

                        {/* {data.audit_note && data.audit_note.url ? <div><a className="btn btn-link" href={data.audit_note.url}>{data.audit_note.originalName}</a></div> : null} */}
                      </FormGroup>
                    </FormikForm>
                  )}
                </Formik>

                <div id="placeholder"></div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Form;