import React, { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import {
  ButtonGroup,
  Button,
  Card,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';


import { 
  Formik,
  Form as FormikForm
} from 'formik';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

import moment from "moment";

import api from '../../utils/api';

import { useToasts } from 'react-toast-notifications';

import qs from 'query-string';

const INITIAL_QUERY = {

  name: '',
  submitted: '',
  year: '',
  start: '', 
  end: '',
};

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {

  const { addToast } = useToasts();

  let { path } = useRouteMatch();

  let history = useHistory();
  
  let queryString = useQuery();

  const [profile, setProfile] = useState({});

  const [permissions, setPermissions] = useState([]);

  const [data, setData] = useState([]);
  const [dataCsv, setDataCsv] = useState([]);

  const [query, setQuery] = useState(INITIAL_QUERY);

  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const [load, reload] = useState(true);

  const columns = [
    {
      editable: false,
      text: "No",
      csvExport: false,
      formatter: (cell, row, rowIndex) => {
        let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
        return <span>{rowNumber}.</span>;
      }
    },
    {
      editable: false,
      text: "Audit Start",
      formatter: (cell, row, rowIndex) => {
        return row.audit_plan && row.audit_plan.date_of_audit_start ? moment(row.audit_plan.date_of_audit_start).format('DD/MM/YYYY') : ""

      },
      csvFormatter: (cell, row, rowIndex) => {
        return row.audit_plan && row.audit_plan.date_of_audit_start ? moment(row.audit_plan.date_of_audit_start).format('DD/MM/YYYY') : ""

      }
    },
    {
      editable: false,
      text: "Audit End",
      formatter: (cell, row, rowIndex) => {
        return row.audit_plan && row.audit_plan.date_of_audit_end ? moment(row.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""

      },
      csvFormatter: (cell, row, rowIndex) => {
        return row.audit_plan && row.audit_plan.date_of_audit_end ? moment(row.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""

      }
    },
    {
    text: "Date Submission",
    dataField: "date",
    formatter: (cell, row , rowIndex) => {
      return row.date ? moment(row.date).format('DD/MM/YYYY') : ""
    },
    csvFormatter: (cell, row , rowIndex) => {
      return row.date ? moment(row.date).format('DD/MM/YYYY') : ""
    },
  }, 
  {
    text: "Company Name",
    dataField: "client.name",
    csvFormatter: (cell, row , rowIndex) => {
      return row.audit_plan && row.audit_plan.client && row.audit_plan.client.name ? row.audit_plan.client.name : ""
    },
  },  
  {
    text: "How do you first hear about NIOSH Certification?",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
      if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='WEB'){
        return 'Web';
      }
      else if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='OTH'){
        return 'Other';
      }
      else if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='EMP'){
        return 'Employer/Colleagues';
      }
      else if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='DOSH'){
        return 'DOSH';
      }
      else if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='NEWS'){
        return 'Newspaper/Magazine';
      }
      else if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='EX'){
        return 'Exhibition';
      }
      else if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='TV'){
        return 'TV/Radio';
      }
      else{
        return '';
      }

    }
  }, 
  {
    text: "Please State",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
        return row.first_hear_about_niosh_cert_other ? row.first_hear_about_niosh_cert_other : '';
    }
  }, 
  {
    text: "The nature of dealing at NIOSH Certification?",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
      if(row.nature_of_dealing_at_niosh_cert && row.nature_of_dealing_at_niosh_cert=='C'){
        return 'Certification';
      }
      else if(row.first_hear_about_niosh_cert && row.first_hear_about_niosh_cert=='T'){
        return 'Training';
      }
      else{
        return '';
      }

    }
  }, 
  {
    text: "Frequency of dealing with NIOSH Certification (Average in a year)",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
      if(row.frequency_of_dealing_at_niosh_cert && row.frequency_of_dealing_at_niosh_cert==1){
        return 'Once';
      }
      else if(row.frequency_of_dealing_at_niosh_cert && row.frequency_of_dealing_at_niosh_cert==2){
        return '2 - 5 times';
      }
      else if(row.frequency_of_dealing_at_niosh_cert && row.frequency_of_dealing_at_niosh_cert==3){
        return '6 - 9 times';
      }
      else if(row.frequency_of_dealing_at_niosh_cert && row.frequency_of_dealing_at_niosh_cert==4){
        return '> 10 times';
      }
      else{
        return '';
      }

    }
  }, 
  {
    text: "Would you be interested in obtaining NIOSH Certification services in the future?",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
        return row.interested_in_obtaining_cert_in_future ? row.interested_in_obtaining_cert_in_future : '';
    }
  }, 
  {
    text: "Please State",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
        return row.interested_in_obtaining_cert_in_future_state ? row.interested_in_obtaining_cert_in_future_state : '';
    }
  }, 
  {
    text: "service_0",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.service_0 ? row.service_0 : '';
    }
  }, 
  {
    text: "service_1",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.service_1 ? row.service_1 : '';
    }
  },
  {
    text: "service_2",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.service_2 ? row.service_2 : '';
    }
  },
  {
    text: "service_3",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.service_3 ? row.service_3 : '';
    }
  },
  {
    text: "audit_0",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.audit_0 ? row.audit_0 : '';
    }
  },
  {
    text: "audit_1",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.audit_1 ? row.audit_1 : '';
    }
  },
  {
    text: "audit_2",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.audit_2 ? row.audit_2 : '';
    }
  },
  {
    text: "audit_3",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.audit_3 ? row.audit_3 : '';
    }
  },
  {
    text: "audit_4",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.audit_4 ? row.audit_4 : '';
    }
  },
  {
    text: "suggestion",
    hidden: true,
    csvFormatter: (cell, row, rowIndex) => {
          return row.suggestion ? row.suggestion : '';
    }
  },
  {
    text: "Audit Type",
    csvExport: false,
    dataField: "audit_plan.stage",
    formatter: (cell, row, rowIndex) => {
      return row.audit_plan && row.audit_plan.stage ? row.audit_plan.type.toUpperCase() + ' ' + row.audit_plan.stage : ""; 
    }
  }, {
    dataField: "id",
      text: "",
      csvExport: false,
      formatter: (cell, row, rowIndex) => {
        // let type;
    
        // switch (parseInt(row.type)) {
        //   default:
        //   case 1:
        //     type = 'iso';
        //     break;
    
        //   case 2:
        //     type = 'mspo';
        //     break;
    
        //   case 3:
        //     type = 'mspo-sccs';
        //     break;
        // }
        
        return (
          <ButtonGroup size="sm">
            <Button outline tag={Link} to={`${path}/view/${cell}`} >
              <i className="icon-eye"></i>
            </Button>
            {permissions && permissions.find(o => o.name.toLowerCase() === 'client_feedback.update') ?
            <Button outline tag={Link} to={`${path}/edit/${cell}`}>
              <i className="icon-pencil"></i>
            </Button>
            : null}
            {permissions && permissions.find(o => o.name.toLowerCase() === 'client_feedback.delete') ?
            <Button outline color="danger" onClick={() => deleteHandler(cell)}>
              <i className="icon-trash"></i>
            </Button>
            : null}
          </ButtonGroup>
        )
      },
      style: { textAlign: 'right' }
  }];
  let year = 0;
  
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.date == null && profile && profile.roles && profile.roles[0].name && profile.roles[0].name.toLowerCase() === 'client') {
      style.backgroundColor = '#FAE7E7';
    } 
    return style;
  };
  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();
    
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/client-feedbacks', {
        params: query,
      })
      .then(function (response) {

        setPermissions(response.data.meta.permissions);

        setData(response.data.data);
        setDataCsv(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
          showTotal: true,
          paginationTotalRenderer: customTotal,
        }));

        reload(false);
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }

        reload(false);
      });
    }

    getData();

    const getDataCsv = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/client-feedbacks?limit=-1', {
        params: query,
      })
      .then(function (response) {

        setPermissions(response.data.meta.permissions);

        setDataCsv(response.data.data);

        reload(false);
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }

        reload(false);
      });
    }

    getDataCsv();

  }, [load, query]);

  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete('/client-feedbacks/' + id)
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        addToast('Client feedback successfully deleted', { appearance: 'success' });

        history.push(`${path}`);

        reload(true);
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  
  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      
      sizePerPage: sizePerPage,
      
    }));
  }
  const MyExportCSV = (props) => {
    const handleClick = () => {
     
      // passing my custom data
      props.onExport(dataCsv);
    };
    return (
      
        <OverlayTrigger
          placement="top"
          overlay={
          <Tooltip id={`tooltip-1`}>
            Export to CSV
          </Tooltip>
          }
        >
           <Button className="btn btn-success"  onClick={ handleClick }>
              <i className="fa fa-file-excel-o"></i>
            </Button>
            </OverlayTrigger>
    );
  };

  const options = [];
  let start_year = new Date().getFullYear();
  for (let i=start_year;i>start_year-5;i--) options.push(i);

  return (
    <ToolkitProvider
    keyField="id"
    data={data}
    columns={ columns }
    exportCSV
  >{
      props => (
    <React.Fragment>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <Card>
            <div className="header">
              <h2>CLIENT FEEDBACK LIST</h2>
              {/* {permissions && permissions.find(o => o.name.toLowerCase() === 'client_feedback.create') ?
              <ul className="header-dropdown">
                <li>
                  <Button 
                    className="m-t-10 m-l-20"
                    color="primary" 
                    tag={Link} 
                    to={`${path}/create`}
                  >
                    Add New
                  </Button>
                </li>
              </ul>
              : null} */}
            </div>
          {profile && profile.roles && profile.roles[0].name && profile.roles[0].name.toLowerCase() !== 'client' ? 
          <div className="body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: queryString.get('name') ? queryString.get('name') : "",
                submitted: queryString.get('submitted') ? queryString.get('submitted') : "0",
                year: queryString.get('year') ? queryString.get('year') : "",
                start: queryString.get('start') ? queryString.get('start') : "",
                end: queryString.get('end') ? queryString.get('end') : "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                history.push({
                  pathname: path,
                  search: searchString
                });

                setQuery(values);
                
              }}
            >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
              <FormikForm>
                <FormGroup>
                  <Label for="Name">Company Name</Label>
                  <Input 
                    type="text" 
                    // className="custom-select" 
                    name="name" 
                    id="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </FormGroup>
                <div className="row clearfix">
                  <div className="col-sm-12">
                  <FormGroup>
                    <Label for="Submitted">Submitted</Label>
                    <Input type="select" 
                      className="custom-select" 
                      name="submitted" 
                      id="submitted"
                      onChange={handleChange}
                      value={values.submitted}
                    >
                      <option></option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Input>
                  </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Submitted">Year</Label>
                      <Input 
                        color="primary" 
                        onChange={handleChange}
                        type="select" 
                        className="custom-select" 
                        name="year"
                        id="year"
                      >
                        <option>Year</option>
                        {options.map(option => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                        ))}
                      </Input>                    
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                  <FormGroup>
                    <Label for="Date">Date of Audit</Label>
                    <DateRangePicker 
                      // disabled={readOnly}
                      initialSettings={{
                        timePicker: false
                      }}
                      timePicker={false}
                      timePickerSeconds={false}
                      containerStyles={{ display: "block" }}
                      start={values.start && values.start.length ? moment(values.start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                      end={values.end && values.start.length ? moment(values.end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                      onApply={(event, picker) => { 
                        setFieldValue('start', picker.startDate.format('YYYY-MM-DD'));
                        setFieldValue('end', picker.endDate.format('YYYY-MM-DD'));
                      }}
                    >
                      <Input 
                        autoComplete="off"
                      //  readOnly={readOnly}
                        type="text" 
                        // name="date_of_audit" 
                        id="date"
                        // onChange={handleChange}
                        value={values.start && values.end ? moment(values.start).format('DD/MM/YYYY') + ' - ' + moment(values.end).format('DD/MM/YYYY') : ""}
                      />
                    </DateRangePicker>
                  </FormGroup>                  
                  </div>
                </div>
                
                <div className="row clearfix">
                  <div className="col-sm-4">
                    <Label>&nbsp;</Label>
                    <FormGroup>
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        // tag={Link} 
                        // to="/client-database"
                      >
                        Search
                      </Button>
                      <Button 
                        // className="btn btn-outline-secondary"
                        type="button"
                        outline
                        color="link" 
                        // tag={Link} 
                        // to={`${path}`}
                        onClick={() => {
                          history.push({
                            pathname: path,
                          });
          
                          setQuery(INITIAL_QUERY);
                        }}
                      >
                        Reset
                      </Button>
                    </FormGroup>
                  </div>
                </div>
              </FormikForm>
              )}
            </Formik>

            <hr />
          </div>
          : null}
          {profile && profile.roles && profile.roles[0].name && profile.roles[0].name.toLowerCase() === 'ncsb admin' ?
            <div className="col-lg-12 col-md-12 col-sm-12 text-right"><MyExportCSV { ...props.csvProps } />
            </div>
            : ''}
            <div className="body project_report">
              <div className="table">
                <BootstrapTable { ...props.baseProps }
                  remote={true}
                  bootstrap4
                  bordered={false}
                  classes="table-hover js-basic-example dataTable table-custom m-b-0"
                  headerClasses="thead-dark"
                  keyField='id' 
                  data={ data } 
                  columns={ columns }
                  pagination={ 
                    paginationFactory(pagingInfo)
                  }
                  onTableChange={handleTableChange}
                  rowStyle={ rowStyle }
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
    )
  }
  </ToolkitProvider>
  );
}

export default Listing;