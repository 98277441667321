import React from 'react';

import {
  // Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import ApplicationForm from './ApplicationForm';
import CompetencyMatrix from './CompetencyMatrix';

const ClientApplication = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route exact path={path}>
        <Redirect
          to={{
              pathname: `${path}/application-form`,
              // state: { from: props.location }
          }}
        />
      </Route> */}
      <Route path={`${path}`}>
        <ApplicationForm path={`${path}/application-form`} />
      </Route>
      <Route path={`${path}/competency-matrix`}>
        <CompetencyMatrix path={`${path}/competency-matrix`} />
      </Route>
    </Switch>
  )
}

export default ClientApplication;