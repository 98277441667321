import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SidebarToggler from "../../components/SidebarToggler";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Button,
} from "reactstrap";
import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import classnames from 'classnames';

import api from './../../utils/api';
import { validateYupSchema } from "formik";
const INITIAL_QUERY = {}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const BODDashboard = () => {
  
  const [activeTab, setActiveTab] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [data, setData] = useState({});
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [isLoading, setLoading] = useState(true);
  let queryString = useQuery();
  let month = 0;
  let year = 0;
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);
      year = selectedYear  ?  selectedYear : new Date().getFullYear();
      month = selectedMonth  ?  selectedMonth : 0;
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('/dashboard?month=' + month +'&year=' + year , {

      //api.get('/dashboard?year=' + year , {
        params: query,
      })
     // await api.get('/dashboard')
      .then(function (response) {
        setData(response.data);
    


        console.log(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
    }

    getData();
  }, []);
/* 
  const handleChange = event => {
    console.log(event.target.value);
    setSelectedMonth(event.target.value);

  }; */
  const options = [];
  let start_year = new Date().getFullYear();
  for (let i=start_year;i>start_year-5;i--)
      options.push(i);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Technical Dashboard
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
                
              </li>
              <li className="breadcrumb-item">Technical Dashboard</li>
              {/* <li className="breadcrumb-item active">Departments List</li> */}
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-right"> 
          <Input 
            type="select" 
            className="custom-select" 
            name="month" 
            id="Month"
            style={{ width: "3cm" }}
           // onChange={handleChange}
            onChange={(e) => {
             // setQuery(INITIAL_QUERY);

              setSelectedMonth(e.target.value);

              let year = 0;
              let { value } = e.currentTarget;

                  let token = localStorage.getItem('auth-token');

                  token = JSON.parse(token);
                  
                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                  year = selectedYear  ?  selectedYear : new Date().getFullYear();
                  api.get('/dashboard?year='+year+'&month='+value)
                  
                  //api.get('/dashboard' + value)
                  .then((response) => {
                    console.log(response.data.data);
                    setData(response.data);
                    setLoading(false);

                  })
                  .catch((error) => {
                    setLoading(false);
                  }); /* */
                }}
          >
            <option>Month</option>
            {[
              {
                month: 0,
                name: 'All Months'
              },
              {
                month: 1,
                name: 'January'
              },
              {
                month: 2,
                name: 'February'
              },
              {
                month: 3,
                name: 'March'
              },
              {
                month: 4,
                name: 'April'
              },
              {
                month: 5,
                name: 'May'
              },
              {
                month: 6,
                name: 'June'
              },
              {
                month: 7,
                name: 'July'
              },
              {
                month: 8,
                name: 'August'
              },
              {
                month: 9,
                name: 'September'
              },
              {
                month: 10,
                name: 'October'
              },
              {
                month: 11,
                name: 'November'
              },
              {
                month: 12,
                name: 'December'
              },
            ].map((month) => 
              <option value={month.month} /* selected={new Date().getMonth()+1 == month.month ?  true :  false} */>{month.name}</option>
            )}
          </Input>

          <Input 
            outline
            color="primary" 
            onChange={(e) => {
              // setQuery(INITIAL_QUERY);
              let { value } = e.currentTarget;
              setSelectedYear(e.target.value);
              let month = selectedMonth ? selectedMonth : 0;
                  let token = localStorage.getItem('auth-token');
                  token = JSON.parse(token);
                  
                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                  api.get('/dashboard?year='+value+'&month='+month)
                //  console.log(year+' AND '+month)
                  //api.get('/dashboard' + value)
                  .then((response) => {
                    setData(response.data);
                    setLoading(false);

                  })
                  .catch((error) => {
                    setLoading(false);
                  });
                }}
            type="select" 
            className="custom-select" 
            name="year"
            id="year"
            style={{ width: "3cm" }}
          >
            <option>Year</option>
            {options.map(option => (
            <option key={option} selected={ option==new Date().getFullYear() ?  true :  false}  value={option}>
            {option}
            </option>
            ))}
          </Input>
              &nbsp;
              <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-1`}>
                      Reset
                    </Tooltip>
                  }
                >

                <Button outline
                color="primary"
                type="reset"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href='/technical';
                  }}
                >
                  <i className="icon-refresh"></i>
                </Button>
                </OverlayTrigger>
          </div>
        </div>
      </div>

      {/* <ul className="nav nav-tabs nav-tabs-new">
        <li class="nav-item">
          <button class="nav-link active show">Contract Review</button>
        </li>
        <li class="nav-item">
          <button class="nav-link">Certification Approval Process & Issuance Of Certificate</button>
        </li>
        <li class="nav-item">
          <button class="nav-link">Auditor Development & Performance Monitoring</button>
        </li>
      </ul> */}

      <Nav tabs>
        <NavItem key={1}>
          <NavLink
            className={classnames({ active: activeTab === 1 })}
            onClick={() => { 
              toggle(1);
            }}
          >
            Contract Review
          </NavLink>
        </NavItem>

        <NavItem key={2}>
          <NavLink
            className={classnames({ active: activeTab === 2 })}
            onClick={() => { 
              toggle(2);
            }}
          >
            Certification Approval Process & Issuance Of Certificate
          </NavLink>
        </NavItem>

        <NavItem key={3}>
          <NavLink
            className={classnames({ active: activeTab === 3 })}
            onClick={() => { 
              toggle(3);
            }}
          >
            Auditor Development & Performance Monitoring
          </NavLink>
        </NavItem>

        <NavItem key={4}>
          <NavLink
            className={classnames({ active: activeTab === 4 })}
            onClick={() => { 
              toggle(4);
            }}
          >
            Data Reporting To Authority
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="content ms-3">
                    <div>To inform sales team that the application documents are incomplete (1 day)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.contract_review ? data.technical.contract_review[0].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.contract_review ? data.technical.contract_review[0].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>To prepare contract review within 2 days after receiving a complete of application documents with clear information (2 days)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.contract_review ? data.technical.contract_review[1].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.contract_review ? data.technical.contract_review[1].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="content ms-3">
                    <div>No. Of CR issued by schemes</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.data && data.data.total_cr_issued ? data.data.total_cr_issued : '~'}</h5>
                      </div>
                    </div>
                  </div>
                  <table className="table">
                    <tbody>
                      {data.data && data.data.cr_issued ? data.data.cr_issued.map((row) => 
                        <tr>
                          <th>{row.code}</th><td className="text-right">{row.total}</td>
                        </tr>
                      ) : <></>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane tabId={2}>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Arrangement on CAP appointment and send complete documents to appointed CAP for review (2 days)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">0</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">0%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="texxt ms-3">
                    <div>Receive complete CAP documents from panel - within 3 working days<br/>* REMARKS (out of control) (3 days)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[1].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[1].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Submission of CAP Claim (3 days)<br /><br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[2].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[2].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>To issue ICAR to relevant units if receive any comment from CAP after receiving documents from panel (1 days)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[3].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[3].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Prepare and send draft of certificate to client after CAP (1 days)<br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[4].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[4].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Received Certificate from CEO Office, scan and hand over hardcopy certificate to Operation Unit (2 days)<br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[5].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.cap ? data.technical.cap[5].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane tabId={3}>
          <div className="row clearfix">
            <div className="col-md-12">
              <h6>D1 - New Auditor Application Without 3<sup>rd</sup> Party Audit Experience</h6>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Notification on receiving the application (1 day)<br /><br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development  && data.technical.auditor_development.d1 ? data.technical.auditor_development.d1[0].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development && data.technical.auditor_development.d1 ? data.technical.auditor_development.d1[0].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Auditor Document Review process until notification to applicant and operation unit (3 days)<br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development  && data.technical.auditor_development.d1 ? data.technical.auditor_development.d1[1].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development && data.technical.auditor_development.d1 ? data.technical.auditor_development.d1[1].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>To furnish report AEP, update competency matrix and issuance auditor appointment letter two days after closing meeting (2 days)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">0</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">0%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-12">
              <h6>D2 - New Auditor Application With 3<sup>rd</sup> Party Audit Experience</h6>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Notification on receiving the application (1 day)<br /><br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development  && data.technical.auditor_development.d2 ? data.technical.auditor_development.d2[0].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development && data.technical.auditor_development.d2 ? data.technical.auditor_development.d2[0].percentage : '~'}%</h5>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Auditor Document Review process until notification to applicant and operation unit (3 days)<br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development  && data.technical.auditor_development.d2 ? data.technical.auditor_development.d2[1].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.auditor_development && data.technical.auditor_development.d2 ? data.technical.auditor_development.d2[1].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>To furnish report AEP, update competency matrix and issuance auditor appointment letter two days after closing meeting (2 days)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">0</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">0%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-12">
              <h6>Performance Monitoring</h6>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>To conduct witness audit for new auditor (Once approved AEP)<br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">0</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">0%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>To conduct witness audit for every auditor (Once every 3 years)<br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">0</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">0%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

         {/*    <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>To complete eveluation on witnessed on site certification audit (CAP-01-02) and update the Auditor Competency Matrix (2 days)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">0</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">0%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </TabPane>

        <TabPane tabId={4}>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Reporting to MPOCC, MSPO Trace (7 Days after issuance of certificate to client)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.report_to_authority ? data.technical.report_to_authority[0].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.report_to_authority ? data.technical.report_to_authority[0].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Reporting via FM1101 to DSM (3 days after receiving notification from DSM)</div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.report_to_authority ? data.technical.report_to_authority[1].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.report_to_authority ? data.technical.report_to_authority[1].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card mb-2">
                <div className="card-body top_counter">
                  <div className="text ms-3">
                    <div>Reporting to ISO (Yearly)<br /><br /></div>
                  </div>
                  <div className="list-group list-group-custom list-group-flush">
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>No. Of Activities</div>
                        <h5 class="mb-0">{data.technical && data.technical.report_to_authority ? data.technical.report_to_authority[2].total : '~'}</h5>
                      </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center py-3">
                      <div class="icon text-center me-3">
                        <i class="fa fa-user"></i>
                      </div>
                      <div class="content">
                        <div>Performance</div>
                        <h5 class="mb-0">{data.technical && data.technical.report_to_authority ? data.technical.report_to_authority[2].percentage : '~'}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default BODDashboard;