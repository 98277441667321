import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,

} from "reactstrap";

import { objectToFormData } from 'object-to-formdata';

import { useToasts } from 'react-toast-notifications';

import classnames from 'classnames';

import BootstrapSwitchButton from "bootstrap-switch-button-react"

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { Editor } from '@tinymce/tinymce-react';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import api from '../../utils/api';

import SidebarToggler from '../../components/SidebarToggler';

import moment from 'moment';

const INITIAL_VALUES = {
  production_and_licenses: []
}

const STATES = [
  {
    'label': 'Johor',
    'value': 'JHR',
  },
  {
    'label': 'Kedah',
    'value': 'KDH'
  },
  {
    'label': 'Kelantan',
    'value': 'KLN'
  },
  {
    'label': 'Melaka',
    'value': 'MLK'
  },
  {
    'label': 'Negeri Sembilan',
    'value': 'NSN'
  },
  {
    'label': 'Pahang',
    'value': 'PHG'
  },
  {
    'label': 'Perak',
    'value': 'PRK'
  },
  {
    'label': 'Pulau Pinang',
    'value': 'PNG'
  },
  {
    'label': 'Sabah',
    'value': 'SBH'
  },
  {
    'label': 'Sarawak',
    'value': 'SRK'
  },
  {
    'label': 'Selangor',
    'value': 'SGR'
  },
  {
    'label': 'Terengganu',
    'value': 'TRG'
  },
  {
    'label': 'Wilayah Persekutuan Kuala Lumpur',
    'value': 'WPK'
  },
  {
    'label': 'Wilayah Persekutuan Labuan',
    'value': 'WPL'
  },
  {
    'label': 'Wilayah Persekutuan Putrajaya',
    'value': 'WPP'
  },
];

const Form = (props) => {
  let { id } = useParams();
  
  const { addToast } = useToasts();

  const [load, reload] = useState(false);

  //const [data, setData] = useState();

  const [profile, setProfile] = useState({
    subtype: null
  });
  
  const [activeTab, setActiveTab] = useState(1);
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  
  const [data, setData] = useState(INITIAL_VALUES);
  
  const basicData = Object.values(data);

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
        .then(function (response) {
          console.log(response.data);

          setProfile(response.data);
        })
        .catch(function (error) {
        });
    }

    getMe();

      if (id) {
        const getData = async () => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          api.get('/basic-data-informations/' + id)
            .then(function (response) {
              setData(response.data);
              
              if (response.data && response.data.company_background && response.data.company_background.url) {
                let config = {
                  type: response.data && response.data.company_background.originalExtension ? response.data.company_background.originalExtension : 'pdf',
                  height: window.innerHeight - 110,
                    document: {
                        fileType: response.data && response.data.company_background.originalExtension ? response.data.company_background.originalExtension : 'pdf',
                        key: `${id}-${Date.now()}`,
                        title: 'Basic Data Information',
                        url: response.data.company_background.url,
                        permissions: {
                            download: true,
                            edit: false,
                            print: true,
                            review: false,
                        }
                    },
                    documentType: 'spreadsheet',
                    editorConfig: {
                        callbackpath: '',
                        mode: 'view',
                        customization: {
                            zoom: 70,
                            customer: {
                                address: '',
                                info: '',
                                logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                                mail: 'iskandar@greentagpro.com',
                                name: 'Greentagpro Solution',
                                www: 'greentagpro.com',
                            },
                            chat: false,
                            forcesave: true,
                            comments: false,
                        }
                    },
                }

                new window.DocsAPI.DocEditor("placeholder", config);
              }
            
              reload(false);
            })
            .catch(function (error) {
              if (error && error.response && error.response.data) {
                addToast(error.response.data.message, { appearance: 'error' });
              } else {
                addToast(error.message, { appearance: 'error' });
              }
            });
        }

        getData();
      // }
      }
  }, [load])

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <SidebarToggler />
                Basic Data Information
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Home
                </li>
                <li className="breadcrumb-item">
                  <Link to={props.path.replace(/:id/, '')}>
                    Basic Data Information
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>BASIC DATA INFORMATION</h2>
              </div>
              <div className="body">
                <Nav pills>
                  {[{
                    id: 1,
                    label: "Basic Data Information",
                  },
                  {
                    id: 2,
                    label: "Details Of " + (profile && profile.subtype !== 'mspo-sccs' ? "Plantation Unit(s)" : "Processing Facility"),
                  },
                  {
                    id: 3,
                    label: "Location And Map",
                  }
                ].map((tab, i) => 
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === tab.id })}
                        onClick={() => { toggle(tab.id); }}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <hr />
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Formik 
                      enableReinitialize={true}
                      initialValues={data}
                      onSubmit={async (values, { setSubmitting }) => {
                        api.put('/basic-data-informations/' + id, JSON.stringify(values), {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        })
                        .then(function (response) {
                          addToast('Basic data information successfully updated', { appearance: 'success' });
                        })
                        .catch(function (error) {
                          addToast(error.message, { appearance: 'error' });
                        });
                      }}
                    >
                      {({ isSubmitting, values, setFieldValue, handleChange }) => (
                        <FormikForm>
                          <div className="col-sm-12">
                            <FormGroup>
                              <Label for="company_background">Company Background</Label>
                              <Editor 
                                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                value={values && values.company_background ? values.company_background : ''}
                                init={{
                                  height: 300,
                                  menubar: '', // 'file edit view insert format tools table tc help',
                                  plugins: [
                                    'lists table help'
                                  ],
                                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | table help',
                                  paste_data_images: true,
                                  content_style: "body { font-family: Arial; font-size: 10pt; }",
                                }}
                                onEditorChange={(content) => {
                                  setFieldValue('company_background', content);
                                }}
                              />
                            </FormGroup>
                          </div>

                          <div className="mb-4 col-sm-4">
                            <button type="submit" className="btn btn-primary">
                              Update
                            </button>
                          </div>

                        </FormikForm>
                      )}
                    </Formik>
                  </TabPane>

                  <TabPane tabId={2}>
                  {profile && profile.subtype === 'part-2' ?
                        <FormGroup>
                          <div class='row padding-top'>

                          <div class="col-lg-6">
                          <a href="https://api.ncsbaudit.greentagpro.com/smallholders_master_template.xls" className="btn" target="_blank"><i class="fa fa-download"></i> Download Excel Template</a>  
                          </div>
                          <div class="col-lg-6">
                            <Formik
                        enableReinitialize={true}
                        initialValues={data}
                        
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                          let token = await localStorage.getItem('auth-token');
                          token = JSON.parse(token);
      
                          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                          
                          values._method = 'PUT';
                          
                          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                          
                          await api({
                            method: 'POST',
                            url: '/basic-data-informations/' + id,
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                            data: data,
                          })
                          .then(function (response) {
                            addToast('Basic data information successfully uploaded', { appearance: 'success' });
      
                            window.location.reload();
                          })
                          .catch(function (error) {
                            addToast(error.message, { appearance: 'error' });
                          });
                        }}
                      >
                        {({ isSubmitting, values, setFieldValue, handleChange }) => (
                          <FormikForm>
                            <FormGroup>
                              <div className="input-group">
                                <div className="custom-file">
                                  <Input
                                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  //accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    className="custom-file-input"
                                    type="file" 
                                    name="smallholders" 
                                    id="smallholders"
                                    onInput={(event) => {
                                      setFieldValue('smallholders', event.target.files[0])
                                    }}
                                  />
                                  <Label className="custom-file-label">
                                    {values && values.smallholders && values.smallholders.name ? values.smallholders.name : 'No file chosen'}
                                  </Label>
                                </div>
                                <div className="input-group-append">
                                  <button 
                                    disabled={isSubmitting}
                                    className="btn btn-outline-secondary" 
                                    type="submit"
                                    >
                                      {isSubmitting ? 'Uploading...' : 'Upload'}
                                    </button>
                                </div>
                              </div>
                            </FormGroup>
                          </FormikForm>
                        )}
                      </Formik>
                          </div>
                          </div>
                          <div class='row padding-top'>

                          <div className="table" class="col-lg-12">
                          <Label for="company_background">Details of {profile && profile.subtype !== 'mspo-sccs' ? "Plantation Unit(s)" : "Processing Facility"} </Label>
                            <BootstrapTable 
                              // remote={true}
                              bootstrap4
                              bordered={false}
                              classes="table-hover table-responsive js-basic-example dataTable table-custom m-b-0"
                              headerClasses="thead-dark"
                              keyField="id"
                              data={data && data.production_and_licenses ? data.production_and_licenses : []} 
                              columns={[
                                {
                                  editable: false,
                                  text: "No",
                                  formatter: (cell, row, rowIndex) => {
                                    return rowIndex + 1;
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "entity_id",
                                  text: "Entity ID",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div>
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "estate_name",
                                  text: "Name",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "mbop_license",
                                  text: "MPOB License No.",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "estate_address",
                                  text: "Office Name",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "spoc_code",
                                  text: "SPOC Code",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "lot_no",
                                  text: "Lot No.",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "kampung",
                                  text: "Kampung",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "mukim",
                                  text: "Mukim",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "district",
                                  text: "District",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "state",
                                  text: "State",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {STATES.map((STATE, i) => 
                                          (cell === STATE.value ? STATE.label.toUpperCase() : null)
                                        )}
                                        &nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "total_certified_area",
                                  text: "Total Certified Area (Ha)",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "total_planted_areas",
                                  text: "Total Planted Area (Ha)",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "longitude",
                                  text: "Longitude",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "latitude",
                                  text: "Latitude",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "cert_date",
                                  text: "Certificate Date",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {moment(cell).format('DD/MM/YY')}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "cert_expiry",
                                  text: "Certificate Expiry Date",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {moment(cell).format('DD/MM/YY')}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  }
                                },
                                {
                                  editable: false,
                                  dataField: "cert_name",
                                  text: "Certificate Name",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  },
                                },
                                {
                                  editable: false,
                                  dataField: "cert_no",
                                  text: "Certificate No.",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  },
                                },
                                {
                                  editable: false,
                                  dataField: "cert_body",
                                  text: "Certification Body",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  },
                                },
                                {
                                  editable: false,
                                  dataField: "cert",
                                  text: "Certification",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <div className="form-control-plaintext">
                                        {cell}&nbsp;
                                      </div>
                                    )
                                  },
                                  style: {
                                    whiteSpace: "pre-wrap"
                                  },
                                },                                    
/*                                     {
                                  editable: false,
                                  dataField: 'id',
                                  text: "",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <Button 
                                        type="button" 
                                        color="danger"
                                        onClick={async (e) => {
                                          if (!window.confirm('Delete this row?')) {
                                            return;
                                          }
                                          
                                          let token = await localStorage.getItem('auth-token');

                                          token = JSON.parse(token);

                                          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                                          
                                          await api.delete('/production-and-licenses/' + cell)
                                            .then(function (response) {
                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              if (error && error.response && error.response.data) {
                                                addToast(error.response.data.message, { appearance: 'error' });
                                              } else {
                                                addToast(error.message, { appearance: 'error' });
                                              }
                                            });
                                        }}
                                      >
                                        <i className="icon-trash"></i>
                                      </Button>
                                    )
                                  },
                                  footer: () => {
                                    return (
                                      <Button type="submit" color="primary">
                                        <i className="icon-plus"></i>
                                      </Button>
                                    )
                                  }
                                }
*/                                  ]}
                              
                            />
                            </div>
                          </div>
                        </FormGroup>
                      
                    : null}
                    {/* TABLE 1 : Details of Plantation Unit(s) */}
                    {profile && profile.subtype !== 'part-2' ?
                      <Formik 
                      enableReinitialize={true}
                      initialValues={{}}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        let token = await localStorage.getItem('auth-token');

                        token = JSON.parse(token);

                        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                        values.client_id = id;
                        
                        if (values.latitude && values.latitude.length && values.longitude && values.longitude.length) {
                          values.gps_coordinates = values.latitude + ',' + values.longitude;
                        }

                        api.post('/production-and-licenses', JSON.stringify(values), {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        })
                        .then(function (response) {
                          addToast('Row successfully updated', { appearance: 'success' });

                          reload(true);
                        })
                        .catch(function (error) {
                          addToast(error.message, { appearance: 'error' });
                        });

                        resetForm();
                      }}
                    >
                      {({ isSubmitting, values, setFieldValue, handleChange }) => (
                        <FormikForm>
                          <div className="col-sm-12">
                            <FormGroup>
                              <div className="table">
                              <Label for="company_background">Details of {profile && profile.subtype === 'part-3' ? 'Plantation Unit(s)' :  profile.subtype === 'part-4' ? 'Mill Unit(s)' :  profile.subtype === 'mspo-sccs' ? 'Of Processing Facility(s)' : 'Plantation Unit(s)'}</Label>
                                <BootstrapTable 
                                  // remote={true}
                                  bootstrap4
                                  bordered={false}
                                  classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                  headerClasses="thead-dark"
                                  keyField="id"
                                  data={data && data.production_and_licenses ? data.production_and_licenses : []} 
                                  columns={[
                                    {
                                      editable: false,
                                      text: "No",
                                      formatter: (cell, row, rowIndex) => {
                                        return rowIndex + 1;
                                      },
                                      footer: () => {
                                        return "";
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      dataField: "entity_id",
                                      text: "Entity ID",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type={profile && profile.subtype === 'part-2' ? 'textarea' : 'text'}
                                            name="entity_id"
                                            onChange={handleChange}
                                            value={values && values.entity_id ? values.entity_id : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      dataField: "estate_name",
                                      text: (profile && profile.subtype === 'part-2' ? 'Name' : '') + (profile && profile.subtype === 'part-3' ? 'Estate Name' : '') + (profile && profile.subtype === 'part-4' ? 'Name' : '') + (profile && profile.subtype === 'mspo-sccs' ? 'Name Of Processing Facility' : ''),
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type={profile && profile.subtype === 'part-2' ? 'textarea' : 'text'}
                                            name="estate_name"
                                            onChange={handleChange}
                                            value={values && values.estate_name ? values.estate_name : ""}
                                            required
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      dataField: "mbop_license",
                                      text: "MPOB License No.",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type={profile && profile.subtype === 'part-2' ? 'textarea' : 'text'}
                                            name="mbop_license"
                                            onChange={handleChange}
                                            value={values && values.mbop_license ? values.mbop_license : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      dataField: "estate_address",
                                      text: (profile && profile.subtype === 'part-2' ? 'Office Name' : 'Address of ') + (profile && profile.subtype === 'part-3' ? 'Estate' : '') + (profile && profile.subtype === 'part-4' ? 'Mill' : '') + (profile && profile.subtype === 'mspo-sccs' ? 'Processing Facility' : ''),
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="estate_address"
                                            onChange={handleChange}
                                            value={values && values.estate_address ? values.estate_address : ""}
                                          />
                                        )
                                      },
                                      editor: {
                                        type: Type.TEXTAREA
                                      },
                                      editorStyle: {
                                        minHeight: "250px",
                                        // minWidth: "250px",
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      dataField: "spoc_code",
                                      text: "SPOC Code",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type={profile && profile.subtype === 'part-2' ? 'textarea' : 'text'}
                                            name="spoc_code"
                                            onChange={handleChange}
                                            value={values && values.spoc_code ? values.spoc_code : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && (profile.subtype === 'part-4' || profile.subtype === 'mspo-sccs') ? false : true,
                                      dataField: "mill_capacity",
                                      text: (profile && profile.subtype === 'part-4' ? 'Mill Capacity' : '') + (profile && profile.subtype === 'mspo-sccs' ? 'Processing Facility Capacity' : ''),
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type="text"
                                            name="mill_capacity"
                                            onChange={handleChange}
                                            value={values && values.mill_capacity ? values.mill_capacity : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? true : false,
                                      dataField: "gps_coordinates",
                                      text: "GPS Coordinate",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type="text"
                                            name="gps_coordinates"
                                            onChange={handleChange}
                                            value={values && values.gps_coordinates ? values.gps_coordinates : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype !== 'part-4' ? true : false,
                                      dataField: "total_mill_area",
                                      text: "Total Mill Area (Ha)",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        return (
                                          <Input 
                                            type="text"
                                            name="total_mill_area"
                                            onChange={handleChange}
                                            value={values && values.total_mill_area ? values.total_mill_area : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && (profile.subtype === 'part-2' || profile.subtype === 'part-4') ? true : false,
                                      editable: profile && (profile.subtype === 'mspo-sccs') ? true : false,
                                      dataField: "total_certified_area",
                                      text: profile && (profile.subtype === 'mspo-sccs') ? "Total Facility Area (Ha)" : "Total Certified Area (Ha)",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="form-control-plaintext">
                                            {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="text"
                                            disabled={profile.subtype === 'mspo-sccs' ? false : true}
                                            name="total_certified_area"
                                            onChange={handleChange}
                                            value={values && values.total_certified_area ? values.total_certified_area : ""}
                                          />
                                        )
                                      },
                                    },
                                    
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "lot_no",
                                      text: "Lot No.",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="lot_no"
                                            onChange={handleChange}
                                            value={values && values.lot_no ? values.lot_no : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "kampung",
                                      text: "Kampung",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="kampung"
                                            onChange={handleChange}
                                            value={values && values.kampung ? values.kampung : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "mukim",
                                      text: "Mukim",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="mukim"
                                            onChange={handleChange}
                                            value={values && values.mukim ? values.mukim : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "district",
                                      text: "District",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="text"
                                            name="district"
                                            onChange={handleChange}
                                            // value={values && values.district ? values.district : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "state",
                                      text: "State",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                        editor: {
                                        type: Type.SELECT,
                                        options: STATES
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="select"
                                            name="state"
                                            onChange={handleChange}
                                            // value={values && values.district ? values.district : ""}
                                          >
                                            <option></option>
                                            {STATES.map((STATE, i) => 
                                              <option value={STATE.value}>{STATE.label}</option>
                                            )}
                                          </Input>
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "mpso",
                                      text: "MPSO (Ha)",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="mpso"
                                            onChange={handleChange}
                                            value={values && values.mpso ? values.mpso : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "bertanam",
                                      text: "Bertanam (Ha)",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="bertanam"
                                            onChange={handleChange}
                                            value={values && values.bertanam ? values.bertanam : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "longitude",
                                      text: "Longitude",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="longitude"
                                            onChange={handleChange}
                                            value={values && values.longitude ? values.longitude : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "latitude",
                                      text: "Latitude",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="latitude"
                                            onChange={handleChange}
                                            value={values && values.latitude ? values.latitude : ""}
                                          />
                                        )
                                      },
                                    },
                                    // {
                                    //   hidden: profile && profile.subtype === 'part-2' ? false : true,
                                    //   // editable: false,
                                    //   dataField: "longitude",
                                    //   text: "Longitude",
                                    //   formatter: (cell, row, rowIndex) => {
                                    //     return (
                                    //       <div className="editable form-control-plaintext">
                                    //         {cell}&nbsp;
                                    //       </div>
                                    //     )
                                    //   },
                                    //   style: {
                                    //     whiteSpace: "pre-wrap"
                                    //   },
                                    //   footer: () => {
                                    //     // return "";
                                    //     return (
                                    //       <Input 
                                    //         type="textarea"
                                    //         name="longitude"
                                    //         onChange={handleChange}
                                    //         value={values && values.longitude ? values.longitude : ""}
                                    //       />
                                    //     )
                                    //   },
                                    // },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "cert_date",
                                      text: "Certificate Date",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {moment(cell).format('DD/MM/YY')}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                        <DateRangePicker 
                                          singleDatePicker
                                          containerStyles={{ display: "block" }}
                                          // startDate={values.date_of_audit_start && values.date_of_audit_start.length ? moment(values.date_of_audit_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          onApply={(event, picker) => {
                                            editorProps.onUpdate(picker.startDate.format('YYYY-MM-DD'))
                                          }}
                                        >
                                          <Input 
                                            autoComplete="off"
                                            readOnly={true}
                                            type="text"  
                                            id="cert_date"
                                            value={moment(value).format('DD-MM-YYYY')}
                                          />
                                        </DateRangePicker>
                                      ),
                                      footer: () => {
                                        // return "";
                                        return (
                                          <DateRangePicker 
                                          singleDatePicker
                                          containerStyles={{ display: "block" }}
                                          // startDate={values.date_of_audit_start && values.date_of_audit_start.length ? moment(values.date_of_audit_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          onApply={(event, picker) => {
                                            // editorProps.onUpdate(picker.startDate.format('YYYY-MM-DD'))
                                              
                                              setFieldValue('cert_date', picker.startDate.format('YYYY-MM-DD'))
                                          }}
                                        >
                                          <Input 
                                            autoComplete="off"
                                            readOnly={true}
                                            type="text"  
                                            id="cert_date"
                                            value={values.cert_date && values.cert_date.length > 0 ? moment(values.cert_date).format('DD-MM-YY') : ''}
                                          />
                                        </DateRangePicker>
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "cert_expiry",
                                      text: "Certificate Expiry Date",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {moment(cell).format('DD/MM/YY')}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                        <DateRangePicker 
                                          singleDatePicker
                                          containerStyles={{ display: "block" }}
                                          // startDate={false}
                                          // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          onApply={(event, picker) => {
                                            editorProps.onUpdate(picker.startDate.format('YYYY-MM-DD'))
                                          }}
                                        >
                                          <Input 
                                            autoComplete="off"
                                            readOnly={true}
                                            type="text"  
                                            id="cert_expiry"
                                            value=""
                                          />
                                        </DateRangePicker>
                                      ),
                                      footer: () => {
                                        // return "";
                                        return (
                                          <DateRangePicker 
                                          singleDatePicker
                                          containerStyles={{ display: "block" }}
                                          // startDate={values.date_of_audit_start && values.date_of_audit_start.length ? moment(values.date_of_audit_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          onApply={(event, picker) => {
                                            // editorProps.onUpdate(picker.startDate.format('YYYY-MM-DD'))
                                              
                                              setFieldValue('cert_expiry', picker.startDate.format('YYYY-MM-DD'))
                                          }}
                                        >
                                          <Input 
                                            autoComplete="off"
                                            readOnly={true}
                                            type="text"  
                                            id="cert_expiry"
                                            value={values.cert_expiry && values.cert_expiry.length > 0 ? moment(values.cert_expiry).format('DD-MM-YY') : ''}
                                          />
                                        </DateRangePicker>
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "cert_name",
                                      text: "Certificate Name",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="cert_name"
                                            onChange={handleChange}
                                            value={values && values.cert_name ? values.cert_name : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "cert_no",
                                      text: "Certificate No.",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="cert_no"
                                            onChange={handleChange}
                                            value={values && values.cert_no ? values.cert_no : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "cert_body",
                                      text: "Certification Body",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="cert_body"
                                            onChange={handleChange}
                                            value={values && values.cert_body ? values.cert_body : ""}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? false : true,
                                      // editable: false,
                                      dataField: "cert",
                                      text: "Certification",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      footer: () => {
                                        // return "";
                                        return (
                                          <Input 
                                            type="textarea"
                                            name="cert"
                                            onChange={handleChange}
                                            value={values && values.cert ? values.cert : ""}
                                          />
                                        )
                                      },
                                    },
                                    
                                    {
                                      hidden: profile && profile.subtype === 'part-2' ? true : false,
                                      dataField: "sampled",
                                      text: "Sampled",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <BootstrapSwitchButton
                                            disabled={true}
                                            checked={parseInt(cell) === 1}
                                            onlabel={<i className="icon-check"></i>}
                                            offlabel={<i className="icon-uncheck"></i>}
                                          />
                                        )
                                      },
                                      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                        <BootstrapSwitchButton
                                          checked={parseInt(value) === 1}
                                          onlabel={<i className="icon-check"></i>}
                                          offlabel={<i className="icon-uncheck"></i>}
                                          onChange={(checked) => {
                                            editorProps.onUpdate(checked);
                                          }}
                                          value={value}
                                        />
                                      ),
                                      footer: () => {
                                        // return "";
                                        return (
                                          <BootstrapSwitchButton
                                            checked={false}
                                            onlabel={<i className="icon-check"></i>}
                                            offlabel={<i className="icon-uncheck"></i>}
                                            onChange={(checked: boolean) => {
                                                setFieldValue("sampled", checked)
                                            }}
                                          />
                                        )
                                      },
                                    },
                                    {
                                      editable: false,
                                      dataField: 'id',
                                      text: "",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <Button 
                                            type="button" 
                                            color="danger"
                                            onClick={async (e) => {
                                              if (!window.confirm('Delete this row?')) {
                                                return;
                                              }
                                              
                                              let token = await localStorage.getItem('auth-token');

                                              token = JSON.parse(token);

                                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                                              
                                              await api.delete('/production-and-licenses/' + cell)
                                                .then(function (response) {
                                                  reload(true);
                                                })
                                                .catch(function (error) {
                                                  if (error && error.response && error.response.data) {
                                                    addToast(error.response.data.message, { appearance: 'error' });
                                                  } else {
                                                    addToast(error.message, { appearance: 'error' });
                                                  }
                                                });
                                            }}
                                          >
                                            <i className="icon-trash"></i>
                                          </Button>
                                        )
                                      },
                                      footer: () => {
                                        return (
                                          <Button type="submit" color="primary">
                                            <i className="icon-plus"></i>
                                          </Button>
                                        )
                                      }
                                    }
                                  ]}
                                  cellEdit={ 
                                    cellEditFactory({ 
                                      mode: "click",
                                      blurToSave: true,
                                      afterSaveCell: async (oldValue, newValue, row, column) => {
                                        if (oldValue === null && newValue === "") return;

                                        if (oldValue === newValue) return;

                                        let token = await localStorage.getItem('auth-token');

                                        token = JSON.parse(token);

                                        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                        let values = {
                                          [column.dataField]: newValue
                                        }

                                        api.put('/production-and-licenses/' + row.id, JSON.stringify(values), {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        })
                                        .then(function (response) {
                                          addToast('Row successfully updated', { appearance: 'success' });

                                          reload(true);
                                        })
                                        .catch(function (error) {
                                          addToast(error.message, { appearance: 'error' });
                                        });
                                      }
                                    })
                                  }
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </FormikForm>
                      )}
                    </Formik>
                    : null}
                    
                    {/* TABLE 2 : MPOB License Details */}
                    {profile && profile.subtype !== 'part-2' ?
                    <Formik 
                      enableReinitialize={true}
                      initialValues={{}}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        let token = await localStorage.getItem('auth-token');

                        token = JSON.parse(token);

                        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                        values.client_id = id;

                        api.post('/production-and-licenses', JSON.stringify(values), {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        })
                        .then(function (response) {
                          addToast('Row successfully updated', { appearance: 'success' });

                          reload(true);
                        })
                        .catch(function (error) {
                          addToast(error.message, { appearance: 'error' });
                        });

                        resetForm();
                      }}
                    >
                      {({ isSubmitting, values, setFieldValue, handleChange }) => (
                        <FormikForm>
                          <div className="col-sm-12">
                            <FormGroup>
                              <div className="table">
                                <Label for="company_background">MPOB License Details</Label>
                                <BootstrapTable 
                                  // remote={true}
                                  bootstrap4
                                  bordered={false}
                                  classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                  headerClasses="thead-dark"
                                  keyField="id"
                                  data={data && data.production_and_licenses ? data.production_and_licenses : []} 
                                  columns={[
                                    {
                                      editable: false,
                                      text: "No",
                                      formatter: (cell, row, rowIndex) => {
                                        return rowIndex + 1;
                                      },
                                    },
                                    {
                                      editable: false,
                                      dataField: "estate_name",
                                      text: (profile && profile.subtype === 'part-3' ? 'Estate' : '') + (profile && profile.subtype === 'part-4' ? 'Mill' : '') + " Name " + (profile && profile.subtype === 'mspo-sccs' ? 'Of Processing Facility' : ''),
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                    },
                                    {
                                      dataField: "mbop_license",
                                      text: "MPOB License No.",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                    },
                                    {
                                      dataField: "scope_of_activity",
                                      text: "Scope Of Activity",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {cell}&nbsp;
                                          </div>
                                        )
                                      },
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                      editor: {
                                        type: Type.TEXTAREA
                                      },
                                    },
                                    {
                                      dataField: "expiry_date",
                                      text: "Expiry Date",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <div className="editable form-control-plaintext">
                                            {moment(cell).format('DD-MM-YYYY') != 'Invalid date' ? moment(cell).format('DD-MM-YYYY') : ''}&nbsp;
                                          </div>
                                        )
                                      },
                                      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                        <DateRangePicker 
                                          singleDatePicker
                                          containerStyles={{ display: "block" }}
                                          // startDate={values.date_of_audit_start && values.date_of_audit_start.length ? moment(values.date_of_audit_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                          onApply={(event, picker) => {
                                            editorProps.onUpdate(picker.startDate.format('YYYY-MM-DD'))
                                          }}
                                        >
                                          <Input 
                                            autoComplete="off"
                                            readOnly={true}
                                            type="text"  
                                            id="DateOfAudit"
                                            value={moment(value).format('DD-MM-YYYY')}
                                          />
                                        </DateRangePicker>
                                      ),
                                      style: {
                                        whiteSpace: "pre-wrap"
                                      },
                                    }
                                  ]}
                                  cellEdit={ 
                                    cellEditFactory({ 
                                      mode: "click",
                                      blurToSave: true,
                                      afterSaveCell: async (oldValue, newValue, row, column) => {
                                        if (oldValue === null && newValue === "") return;

                                        if (oldValue === newValue) return;

                                        let token = await localStorage.getItem('auth-token');

                                        token = JSON.parse(token);

                                        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                        let values = {
                                          [column.dataField]: newValue
                                        }

                                        api.put('/production-and-licenses/' + row.id, JSON.stringify(values), {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        })
                                        .then(function (response) {
                                          addToast('Row successfully updated', { appearance: 'success' });

                                          reload(true);
                                        })
                                        .catch(function (error) {
                                          addToast(error.message, { appearance: 'error' });
                                        });
                                      }
                                    })
                                  }
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </FormikForm>
                      )}
                    </Formik>
                    : null}
                    
                    {profile && profile.subtype === 'part-3' ?
                      <React.Fragment>
                        <Formik 
                          enableReinitialize={true}
                          initialValues={data && data.production_and_licenses ? data.production_and_licenses : []}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            values.client_id = id;

                            api.post('/production-and-licenses', JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <Label for="company_background">Area And FFB Production</Label>
                                  <div className="table">
                                    <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      headerClasses="thead-dark"
                                      keyField="id"
                                      data={data && data.production_and_licenses ? data.production_and_licenses : []} 
                                      columns={[
                                        {
                                          editable: false,
                                          text: "No",
                                          formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                          },
                                        },
                                        {
                                          editable: false,
                                          dataField: "estate_name",
                                          text: "Estates Name",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          dataField: "immature_area_0",
                                          text: "Immature Area (Ha) 0-3 Yrs",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          dataField: "immature_area_3",
                                          text: "Mature Area (Ha) >3 Yrs",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          editable: false,
                                          dataField: "total_planted_areas",
                                          text: "Total Planted Areas (Ha)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          dataField: "conservation_area",
                                          text: "Conservation Area (HBV, Steep Land, etc) Ha",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          dataField: "others",
                                          text: "Others (Building, Line, Sites, Nursery, etc) Ha",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          editable: false,
                                          dataField: "total_unplanted_areas",
                                          text: "Total Unplanted Area (Ha)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          editable: false,
                                          dataField: "total_certified_area",
                                          text: "Total Certified Area (Ha)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }

                                            api.put('/production-and-licenses/' + row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>

                        <Formik 
                          enableReinitialize={true}
                          initialValues={data && data.production_and_licenses ? data.production_and_licenses : []}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            values.client_id = id;

                            api.post('/production-and-licenses', JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <Label for="company_background">Production Volume</Label>
                                  <div className="table">
                                    <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      headerClasses="thead-dark"
                                      keyField="id"
                                      data={data && data.production_and_licenses ? data.production_and_licenses : []} 
                                      columns={[
                                        {
                                          editable: false,
                                          text: "No",
                                          formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                          },
                                          footer: ''
                                        },
                                        {
                                          editable: false,
                                          dataField: "estate_name",
                                          text: "Estates Name",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: 'Total'
                                        },
                                        {
                                          editable: false,
                                          dataField: "total_certified_area",
                                          text: "Total Certified Area (Ha)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                         // footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          dataField: "ffb_production_prev_year",
                                          text: "FFB Production (Actual Previous Year)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                          //footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
                                          footerStyle: {
                                            textIndent: "5%"
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        },
                                        {
                                          dataField: "ffb_production_current_year",
                                          text: "FFB Production (Estimate Current Year)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                         // footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
                                          footerStyle: {
                                            textIndent: "5%"
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                        }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }

                                            api.put('/production-and-licenses/' + row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />

                                    
                                  </div>
                                </FormGroup>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>

                        <Formik 
                          enableReinitialize={true}
                          initialValues={profile}
                          // initialValues={data && data.production_and_licenses ? data.production_and_licenses : []} 
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            values.client_id = id;
                            
                            api.put('/basic-data-informations'+ id, JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      condensed
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      keyField="id"
                                      // data={basicData}
                                   //  data={values ? values.basic_data : []} 
                                     data={profile && profile.basic_data && profile.basic_data.basic_data_informations ? profile.basic_data.basic_data_informations : []} 
                                     columns={[
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                &nbsp;
                                              </div>
                                            )
                                          },
                                          footer: ''
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                &nbsp; OER(%) 
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: ''
                                        },
                                        {
                                          editable: false,
                                          text: "",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                &nbsp;
                                              </div>
                                            )
                                          },
                                          footer: '',
                                          style: {
                                            whiteSpace: "pre-wrap",
                                            paddingRight: "50px"
                                          }
                                        },
                                        {
                                          dataField: "oer_actual",
                                          text: columnData => {
                                            return (
                                             <Input 
                                                placeholder="0"
                                                type="text"
                                                name="oer_actual"
                                                onChange={(event) => {
                                                  setFieldValue(`oer_actual`, event.currentTarget.value)
                                                }}
                                               // value={data.oer_actual ? values.oer_actual : null}
                                                value={values && values.oer_actual ? values.oer_actual : null}
                                            />
                                           
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da',
                                              width: '298px'
                                              
                                            };
                                          
                                          }
                                        },
                                        {
                                          dataField: "oer_estimate",
                                          text: columnData => {
                                            return (
                                             <Input 
                                                type="text"
                                                name="oer_estimate"
                                                onChange={(event) => {
                                                  setFieldValue(`oer_estimate`, event.currentTarget.value)
                                                }}
                                                value={values && values.oer_estimate ? values.oer_estimate : null}
                                                //value={data.oer_estimate ? values.oer_estimate : null}  
                                                  
                                            />
                                           
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da',
                                              width: '308px'
                                              
                                            };
                                          
                                          },
                                        }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }

                                            api.put('/basic-data-informations/' + row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />

                                    
                                  
                                </FormGroup>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>
                        </React.Fragment>
                      : null}
                    
                    {profile && profile.subtype === 'part-4' ?
                        <Formik 
                          enableReinitialize={true}
                          initialValues={{}}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            values.client_id = id;

                            api.post('/mill-processing-data', JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <Label for="company_background">Mill Processing Data</Label>
                                  <div className="table">
                                    <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      headerClasses="thead-dark"
                                      keyField="id"
                                      data={data && data.mill_processing_data ? data.mill_processing_data : []} 
                                      columns={[
                                        {
                                          editable: false,
                                          text: "No",
                                          formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                          },
                                          footer: ''
                                        },
                                        {
                                          editable: false,
                                          dataField: "supply_base",
                                          text: "Supply Base",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                {cell ?  cell : null}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: 'Total    ',
                                        },
                                        {
                                          dataField: "ffb_actual",
                                          text: "Actual Previous Financial Year FFB Processed (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                          footerStyle: {
                                            textIndent: "10%"
                                          }
                                        },
                                        {
                                          dataField: "cpo_actual",
                                          text: "Actual Previous Financial Year CPO Production (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                          footerStyle: {
                                            textIndent: "10%"
                                          }
                                        },
                                        {
                                          dataField: "pk_actual",
                                          text: "Actual Previous Financial Year PK Production (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                          footerStyle: {
                                            textIndent: "10%"
                                          }
                                        },
                                        {
                                          dataField: "ffb_estimate",
                                          text: "Estimate Previous Financial Year FFB Processed (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                          footerStyle: {
                                            textIndent: "10%"
                                          }
                                        },
                                        {
                                          dataField: "cpo_estimate",
                                          text: "Estimate Previous Financial Year CPO Production (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                          footerStyle: {
                                            textIndent: "10%"
                                          }
                                        },
                                        {
                                          dataField: "pk_estimate",
                                          text: "Estimate Previous Financial Year PK Production (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                          footerStyle: {
                                            textIndent: "10%"
                                          }
                                          //footer: columnData => columnData.reduce((acc, item) => Math.round((acc + parseFloat(item) + Number.EPSILON) * 100) / 100, 0),
                                        }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }

                                            api.put('/mill-processing-data/' + row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />
                                  </div>
                                
                                
                                </FormGroup>

                              </div>
                            </FormikForm>
                          )}
                        </Formik>
                        

                    : null}
                    
                    {profile && profile.subtype === 'part-4' ?
                        <Formik 
                          enableReinitialize={true}
                          initialValues={{}}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            //values.client_id = id;

                            api.put('/basic-data-informations '+ id, JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                    
                                    <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      // headerClasses="thead-dark"
                                      keyField="id"
                                      data={profile && profile.basic_data && profile.basic_data.basic_data_informations ? profile.basic_data.basic_data_informations : []} 
                                      columns={[
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                &nbsp;
                                              </div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap",
                                            width: "20px"
                                          }, */
                                          footer: ''
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Conversion Rate</div>
                                            )
                                          },
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Actual OER(%)</div>
                                            )
                                          },  
                                          style: {
                                            fontSize: "6pt"
                                          },
                                          footer: '',
                                        },
                                        {
                                          dataField: "oer_actual",
                                          text: columnData => {
                                            return (
                                             <Input 
                                                type="text"
                                                name="oer_actual"
                                                onChange={(event) => {
                                                  setFieldValue(`oer_actual`, event.currentTarget.value)
                                                }}
                                                value={values  && values.oer_actual ? values.oer_actual : null}
                                                
                                            />
                                           
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                            
                                          },
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Actual KER(%)</div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          dataField: "ker_actual",
                                          text: columnData => {
                                            return (
                                             <Input 
                                                type="text"
                                                name="ker_actual"
                                                onChange={(event) => {
                                                  setFieldValue(`ker_actual`, event.currentTarget.value)
                                                }}
                                                value={values  && values.ker_actual ? values.ker_actual : null}
                                            />
                                           
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                            
                                          },
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Estimate OER(%)</div>
                                            )
                                          },  
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: '',
                                        },
                                        {
                                          dataField: "oer_estimate",
                                          text: columnData => {
                                            return (
                                             <Input 
                                                type="text"
                                                name="oer_estimate"
                                                onChange={(event) => {
                                                  setFieldValue(`oer_estimate`, event.currentTarget.value)
                                                }}
                                                value={values && values.oer_estimate ? values.oer_estimate : null}
                                                  
                                            />
                                           
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                            
                                          },
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Estimate KER(%)</div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          dataField: "ker_estimate",
                                          text: columnData => {
                                            return (
                                             <Input 
                                                type="text"
                                                name="ker_estimate"
                                                onChange={(event) => {
                                                  setFieldValue(`ker_estimate`, event.currentTarget.value)
                                                }}
                                                value={values && values.ker_estimate ? values.ker_estimate : null}
                                            />
                                           
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                            
                                          },
                                        }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }

                                            api.put('/basic-data-informations/' + row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />
                                
                                
                                </FormGroup>

                              </div>
                            </FormikForm>
                          )}
                        </Formik>
                        

                    : null}

                    {profile && profile.subtype === 'mspo-sccs' ?
                      <React.Fragment>
                        <Formik 
                          enableReinitialize={true}
                          initialValues={{}}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            values.client_id = id;
                            
                            values.actual = 1;
                            
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            // var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                            
                            // console.log(data); return;
                            
                            await api.post('/non-mspo-products', JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            // await api({
                            //   method: 'POST',
                            //   url: '/non-mspo-products',
                            //   headers: {
                            //     'Content-Type': 'multipart/form-data',
                            //   },
                            //   data: data,
                            // })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <Label for="company_background">Volume of Non MSPO and MSPO Certified Products Received and Sold By Month (Actual)</Label>
                                  <div className="table">
                                    <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      headerClasses="thead-dark"
                                      keyField="id"
                                      data={data && data.non_mspo_products_received_and_sold_volume ? data.non_mspo_products_received_and_sold_volume.filter(o => o.actual == 1) : []} 
                                      columns={[
                                        {
                                          editable: false,
                                          text: "No",
                                          formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                          },
                                          footer: () => {
                                            return null;
                                          }
                                        },
                                        {
                                          // editable: false,
                                          dataField: "monthly_period",
                                          text: "Monthly Period (1 Year)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                            <DateRangePicker 
                                              showDropdowns
                                              singleDatePicker
                                              containerStyles={{ display: "block" }}
                                              onApply={(event, picker) => {
                                                editorProps.onUpdate(picker.startDate.format('MMM YYYY').toUpperCase())
                                              }}
                                            >
                                              <Input 
                                                autoComplete="off"
                                                type="text"  
                                                id="monthly_period"
                                                value={moment(value).format('MMM YYYY')}
                                                style={{width:"100px"}}
                                              />
                                            </DateRangePicker>
                                          ),
                                          footer: () => {
                                            // return "";
                                            return (
                                              <DateRangePicker 
                                              showDropdowns
                                              singleDatePicker
                                              containerStyles={{ display: "block" }}
                                              onApply={(event, picker) => {
                                                  setFieldValue('monthly_period', picker.startDate.format('MMM YYYY').toUpperCase())
                                              }}
                                            >
                                              <Input 
                                                style={{width:"100px"}}
                                                autoComplete="off"
                                                type="text"  
                                                id="monthly_period"
                                                value={values && values.monthly_period && values && values.monthly_period.length > 0 ? moment(values && values.monthly_period).format('MMM YYYY').toUpperCase() : ''}
                                              />
                                            </DateRangePicker>
                                            
                                            )
                                          },
                                        },
                                        {
                                          dataField: "category_of_status_1",
                                          text: "Category of Status",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "MSPO Certified",
                                              label: "MSPO Certified"
                                            }, {
                                              value: "Non MSPO Certified",
                                              label: "Non MSPO Certified"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="category_of_status_1"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>MSPO Certified</option>
                                                <option>Non MSPO Certified</option>
                                              </Input>
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "ffb_received",
                                          text: "FFB Received (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="ffb_received"
                                                onChange={handleChange}
                                                value={values && values.ffb_received ? values.ffb_received : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          dataField: "ffb_processed",
                                          text: "FFB Processed (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="ffb_processed"
                                                onChange={handleChange}
                                                value={values && values.ffb_processed ? values.ffb_processed : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          dataField: "category_of_status_2",
                                          text: "Category of Status",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "MSPO Certified",
                                              label: "MSPO Certified"
                                            }, {
                                              value: "Non MSPO Certified",
                                              label: "Non MSPO Certified"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="category_of_status_2"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>MSPO Certified</option>
                                                <option>Non MSPO Certified</option>
                                              </Input>
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "type_of_products",
                                          text: "Type of Products",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "CPO",
                                              label: "CPO"
                                            }, {
                                              value: "PK",
                                              label: "PK"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="type_of_products"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>CPO</option>
                                                <option>PK</option>
                                              </Input>
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "volume_produced",
                                          text: "Volume Produced (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="volume_produced"
                                                onChange={handleChange}
                                                value={values && values.volume_produced ? values.volume_produced : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "volume_sold",
                                          text: "Volume Sold (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="volume_sold"
                                                onChange={handleChange}
                                                value={values && values.volume_sold ? values.volume_sold : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "supply_chain_model",
                                          text: "Supply Chain Model",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? cell : '-'}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "Mass Balance",
                                              label: "Mass Balance"
                                            }, {
                                              value: "Segregation",
                                              label: "Segregation"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="supply_chain_model"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>Mass Balance</option>
                                                <option>Segregation</option>
                                              </Input>
                                            )
                                          },
                                          
                                        },
                                        {
                                      editable: false,
                                      dataField: 'id',
                                      text: "",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <Button 
                                            type="button" 
                                            color="danger"
                                            onClick={async (e) => {
                                              if (!window.confirm('Delete this row?')) {
                                                return;
                                              }
                                              
                                              let token = await localStorage.getItem('auth-token');

                                              token = JSON.parse(token);

                                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                                              
                                              await api.delete('/non-mspo-products/' + row.id)
                                                .then(function (response) {
                                                  reload(true);
                                                })
                                                .catch(function (error) {
                                                  if (error && error.response && error.response.data) {
                                                    addToast(error.response.data.message, { appearance: 'error' });
                                                  } else {
                                                    addToast(error.message, { appearance: 'error' });
                                                  }
                                                });
                                            }}
                                          >
                                            <i className="icon-trash"></i>
                                          </Button>
                                        )
                                      },
                                      footer: () => {
                                        return (
                                          <Button type="submit" color="primary">
                                            <i className="icon-plus"></i>
                                          </Button>
                                        )
                                      }
                                    }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }

                                            api.put('/non-mspo-products/' + row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>
                        <Formik 
                          enableReinitialize={true}
                          initialValues={{}}
                          // initialValues={data && data.production_and_licenses ? data.production_and_licenses : []} 
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            values.client_id = id;
                            
                            api.put('/basic-data-informations'+ id, JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      condensed
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      keyField="id"
                                      // data={basicData}
                                    //  data={values ? values.basic_data : []} 
                                    data={profile && profile.basic_data && profile.basic_data.basic_data_informations ? profile.basic_data.basic_data_informations : []} 
                                      columns={[
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                &nbsp;
                                              </div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap",
                                            width: "20px"
                                          }, */
                                          footer: ''
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext"> </div>
                                            )
                                          },
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Actual OER(%)</div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          dataField: "oer_actual",
                                          text: columnData => {
                                            return (
                                              <Input 
                                                placeholder="0"
                                                type="text"
                                                name="oer_actual"
                                                onChange={(event) => {
                                                  setFieldValue(`oer_actual`, event.currentTarget.value)
                                                }}
                                                value={values && values.oer_actual ? values.oer_actual : null}
                                                
                                            />
                                            
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                          
                                          }
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Actual KER(%)</div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          dataField: "ker_actual",
                                          text: columnData => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="ker_actual"
                                                onChange={(event) => {
                                                  setFieldValue(`ker_actual`, event.currentTarget.value)
                                                }}
                                                value={values && values.ker_actual ? values.ker_actual : null}
                                            />
                                            
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                          
                                        },
                                        },
                                        
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;
              
                                            if (oldValue === newValue) return;
              
                                            let token = await localStorage.getItem('auth-token');
              
                                            token = JSON.parse(token);
              
                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
              
                                            let values = {
                                              [column.dataField]: newValue
                                            }
                                            
                                            
                                            api.put('/basic-data-informations/'+ row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });
              
                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />

                                    
                                  
                                </FormGroup>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>

                        <Formik 
                          enableReinitialize={true}
                          initialValues={{}}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            values.client_id = id;
                            
                            // values.actual = 1;
                            
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            // var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                            
                            // console.log(data); return;
                            
                            await api.post('/non-mspo-products', JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            // await api({
                            //   method: 'POST',
                            //   url: '/non-mspo-products',
                            //   headers: {
                            //     'Content-Type': 'multipart/form-data',
                            //   },
                            //   data: data,
                            // })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <Label for="company_background">Volume of Non MSPO and MSPO Certified Products Received and Sold By Month (Forecast)</Label>
                                  <div className="table">
                                    <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      headerClasses="thead-dark"
                                      keyField="id"
                                      data={data && data.non_mspo_products_received_and_sold_volume ? data.non_mspo_products_received_and_sold_volume.filter(o => o.actual !== 1) : []} 
                                      columns={[
                                        {
                                          editable: false,
                                          text: "No",
                                          formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                          },
                                          footer: () => {
                                            return null;
                                          }
                                        },
                                        {
                                          // editable: false,
                                          dataField: "monthly_period",
                                          text: "Monthly Period (1 Year)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                            <DateRangePicker 
                                              showDropdowns
                                              singleDatePicker
                                              containerStyles={{ display: "block" }}
                                              onApply={(event, picker) => {
                                                editorProps.onUpdate(picker.startDate.format('MMM YYYY'))
                                              }}
                                            >
                                              <Input 
                                                autoComplete="off"
                                                type="text"  
                                                id="monthly_period"
                                                value={moment(value).format('MMM YYYY')}
                                                style={{width:"100px"}}
                                              />
                                            </DateRangePicker>
                                          ),
                                          footer: () => {
                                            return (
                                              <DateRangePicker 
                                              showDropdowns
                                              singleDatePicker
                                              containerStyles={{ display: "block" }}
                                              onApply={(event, picker) => {
                                                  setFieldValue('monthly_period', picker.startDate.format('MMM YYYY').toUpperCase())
                                              }}
                                            >
                                              <Input 
                                                style={{width:"100px"}}
                                                autoComplete="off"
                                                type="text"  
                                                id="monthly_period"
                                                value={values && values.monthly_period && values && values.monthly_period.length > 0 ? moment(values && values.monthly_period).format('MMM YYYY').toUpperCase() : ''}
                                              />
                                            </DateRangePicker>
                                            
                                            )
                                          },
                                        },
                                        {
                                          dataField: "category_of_status_1",
                                          text: "Category of Status",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "MSPO Certified",
                                              label: "MSPO Certified"
                                            }, {
                                              value: "Non MSPO Certified",
                                              label: "Non MSPO Certified"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="category_of_status_1"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>MSPO Certified</option>
                                                <option>Non MSPO Certified</option>
                                              </Input>
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "ffb_received",
                                          text: "FFB Received (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="ffb_received"
                                                onChange={handleChange}
                                                value={values && values.ffb_received ? values.ffb_received : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          dataField: "ffb_processed",
                                          text: "FFB Processed (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="ffb_processed"
                                                onChange={handleChange}
                                                value={values && values.ffb_processed ? values.ffb_processed : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          dataField: "category_of_status_2",
                                          text: "Category of Status",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "MSPO Certified",
                                              label: "MSPO Certified"
                                            }, {
                                              value: "Non MSPO Certified",
                                              label: "Non MSPO Certified"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="category_of_status_2"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>MSPO Certified</option>
                                                <option>Non MSPO Certified</option>
                                              </Input>
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "type_of_products",
                                          text: "Type of Products",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "CPO",
                                              label: "CPO"
                                            }, {
                                              value: "PK",
                                              label: "PK"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="type_of_products"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>CPO</option>
                                                <option>PK</option>
                                              </Input>
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "volume_produced",
                                          text: "Volume Produced (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="volume_produced"
                                                onChange={handleChange}
                                                value={values && values.volume_produced ? values.volume_produced : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "volume_sold",
                                          text: "Volume Sold (MT)",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? parseFloat(cell).toFixed(2) : 0}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="volume_sold"
                                                onChange={handleChange}
                                                value={values && values.volume_sold ? values.volume_sold : ""}
                                              />
                                            )
                                          },
                                        },
                                        {
                                          // editable: false,
                                          dataField: "supply_chain_model",
                                          text: "Supply Chain Model",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell ? cell : '-'}&nbsp;
                                              </div>
                                            )
                                          },
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          editor: {
                                            type: Type.SELECT,
                                            options: [{
                                              value: "Mass Balance",
                                              label: "Mass Balance"
                                            }, {
                                              value: "Segregation",
                                              label: "Segregation"
                                            }]
                                          },
                                          footer: () => {
                                            return (
                                              <Input 
                                                type="select"
                                                name="supply_chain_model"
                                                onChange={handleChange}
                                              >
                                                <option></option>
                                                <option>Mass Balance</option>
                                                <option>Segregation</option>
                                              </Input>
                                            )
                                          },
                                        },
                                        {
                                      editable: false,
                                      dataField: 'id',
                                      text: "",
                                      formatter: (cell, row, rowIndex) => {
                                        return (
                                          <Button 
                                            type="button" 
                                            color="danger"
                                            onClick={async (e) => {
                                              if (!window.confirm('Delete this row?')) {
                                                return;
                                              }
                                              
                                              let token = await localStorage.getItem('auth-token');

                                              token = JSON.parse(token);

                                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                                              
                                              await api.delete('/non-mspo-products/' + row.id)
                                                .then(function (response) {
                                                  reload(true);
                                                })
                                                .catch(function (error) {
                                                  if (error && error.response && error.response.data) {
                                                    addToast(error.response.data.message, { appearance: 'error' });
                                                  } else {
                                                    addToast(error.message, { appearance: 'error' });
                                                  }
                                                });
                                            }}
                                          >
                                            <i className="icon-trash"></i>
                                          </Button>
                                        )
                                      },
                                      footer: () => {
                                        return (
                                          <Button type="submit" color="primary">
                                            <i className="icon-plus"></i>
                                          </Button>
                                        )
                                      }
                                    }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }

                                            api.put('/non-mspo-products/' + row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>
                        <Formik 
                          enableReinitialize={true}
                          initialValues={{}}
                          // initialValues={data && data.production_and_licenses ? data.production_and_licenses : []} 
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            values.client_id = id;
                            api.put('/basic-data-informations/'+ id, JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });

                            resetForm();
                          }}
                        >
                          {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <FormikForm>
                              <div className="col-sm-12">
                                <FormGroup>
                                  <BootstrapTable 
                                      // remote={true}
                                      bootstrap4
                                      condensed
                                      bordered={false}
                                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                                      keyField="id"
                                      // data={basicData}
                                    //  data={values ? values.basic_data : []} 
                                      data={profile && profile.basic_data && profile.basic_data.basic_data_informations ? profile.basic_data.basic_data_informations : []}                                      
                                      columns={[
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">
                                                &nbsp;
                                              </div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap",
                                            width: "20px"
                                          }, */
                                          footer: ''
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext"> </div>
                                            )
                                          },
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Forecast OER(%)</div>
                                            )
                                          },  
                                          style: {
                                            whiteSpace: "pre-wrap"
                                          },
                                          footer: '',
                                        },
                                        {
                                          dataField: "oer_estimate",
                                          text: columnData => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="oer_estimate"
                                                onChange={(event) => {
                                                  setFieldValue(`oer_estimate`, event.currentTarget.value)
                                                }}
                                                value={values && values.oer_estimate ? values.oer_estimate : null}                                      
                                            />
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                          
                                          },
                                        },
                                        {
                                          editable: false,
                                          text: " ",
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="form-control-plaintext">Forecast KER(%)</div>
                                            )
                                          },  
                                          /* style: {
                                            whiteSpace: "pre-wrap"
                                          }, */
                                          footer: '',
                                        },
                                        {
                                          dataField: "ker_estimate",
                                          text: columnData => {
                                            return (
                                              <Input 
                                                type="text"
                                                name="ker_estimate"
                                                onChange={(event) => {
                                                  setFieldValue(`ker_estimate`, event.currentTarget.value)
                                                }}
                                                value={values && values.ker_estimate ? values.ker_estimate : null}
                                            />
                                            
                                          )},
                                          formatter: (cell, row, rowIndex) => {
                                            return (
                                              <div className="editable form-control-plaintext">
                                                {cell}&nbsp;
                                              </div>
                                            )
                                          },
                                          validator: (newValue, row, column) => {
                                            if (isNaN(newValue)) {
                                              return {
                                                valid: false,
                                                message: 'Value should be numeric'
                                              };
                                            }
                                            return true;
                                          },
                                          footer: '',
                                          style: (cell, row, rowIndex, colIndex) => {
                                            
                                            return {
                                              border: '1px dotted #ced4da'
                                              
                                            };
                                            
                                          },
                                        }
                                      ]}
                                      cellEdit={ 
                                        cellEditFactory({ 
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: async (oldValue, newValue, row, column) => {
                                            if (oldValue === null && newValue === "") return;

                                            if (oldValue === newValue) return;

                                            let token = await localStorage.getItem('auth-token');

                                            token = JSON.parse(token);

                                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                            let values = {
                                              [column.dataField]: newValue
                                            }
                                            
                                            api.put('/basic-data-informations/'+ row.id, JSON.stringify(values), {
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            })
                                            .then(function (response) {
                                              addToast('Row successfully updated', { appearance: 'success' });

                                              reload(true);
                                            })
                                            .catch(function (error) {
                                              addToast(error.message, { appearance: 'error' });
                                            });
                                          }
                                        })
                                      }
                                    />

                                    
                                  
                                </FormGroup>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>
                      </React.Fragment>
                    : null}
                    
                  </TabPane>
                  
                  <TabPane tabId={3}>
                    <div className="alert alert-info">Copy &amp; paste or drag &amp; drop image in the editor</div>
                    
                    <br />
                    
                    <Formik 
                      enableReinitialize={true}
                      initialValues={data}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        api.put('/basic-data-informations/' + id, JSON.stringify(values), {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        })
                        .then(function (response) {
                          addToast('Location and map information successfully updated', { appearance: 'success' });
                        })
                        .catch(function (error) {
                          addToast(error.message, { appearance: 'error' });
                        });
                      }}
                    >
                      {({ isSubmitting, values, setFieldValue, handleChange }) => (
                        <FormikForm>
                          <div className="row clearfix">
                            <div className="col-sm-12">
                              <FormGroup>
                                <Label for="LocationAndMap">Geo Coordinate Location Maps For All {(profile && profile.subtype === 'part-3' ? 'Estates' : '') + (profile && profile.subtype === 'part-4' ? 'Mills' : '')} (**Best image size maximum 465px width and 350px height)</Label>
                               
                                <Editor 
                                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                  value={values && values.location_map ? values.location_map : ''}
                                  init={{
                                    height: 500,
                                    menubar: '', // file edit view insert format tools table tc help',
                                    plugins: [
                                      'advlist autolink lists link image charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                                    paste_data_images: true,
                                    content_style: "body { font-family: Arial; font-size: 10pt; }",
                                  }}
                                  onEditorChange={(content) => {
                                    setFieldValue('location_map', content);
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </div>
                          
                          <div className="row clearfix">
                            <div className="col-sm-12">
                              <FormGroup>
                                <Label for="Map">{(profile && profile.subtype === 'part-3' ? 'Estate' : '') + (profile && profile.subtype === 'part-4' ? 'Mill' : '')} Maps For All {(profile && profile.subtype === 'part-3' ? 'Estates' : '') + (profile && profile.subtype === 'part-4' ? 'Mills' : '')} (**Best image size maximum 595px width and 377px height)</Label>
                                <Editor 
                                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                  value={values && values.map ? values.map : ''}
                                  init={{
                                    height: 500,
                                    menubar: '', // 'file edit view insert format tools table tc help',
                                    plugins: [
                                      'advlist autolink lists link image charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                                    paste_data_images: true,
                                    content_style: "body { font-family: Arial; font-size: 10pt; }",
                                  }}
                                  onEditorChange={(content) => {
                                    setFieldValue('map', content);
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </div>
                          
                          <div className="row clearfix">
                            <div className="mb-4 col-sm-4">
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                            </div>
                          </div>
                          
                        </FormikForm>
                      )}
                    </Formik>
                  </TabPane>
                  
                  <TabPane tabId={4}>
                    <Formik 
                      enableReinitialize={true}
                      initialValues={data}
                      onSubmit={async (values, { setSubmitting }) => {
                        api.put('/basic-data-informations/' + id, JSON.stringify(values), {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        })
                        .then(function (response) {
                          addToast('Basic data information successfully updated', { appearance: 'success' });
                        })
                        .catch(function (error) {
                          addToast(error.message, { appearance: 'error' });
                        });
                      }}
                    >
                      {({ isSubmitting, values, setFieldValue, handleChange }) => (
                        <FormikForm>
                          <div className="col-sm-12">
                            <FormGroup>
                              <Label for="stakeholder_list">Stakeholder List</Label>
                              <Editor 
                                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                  value={values && values.stakeholder_list ? values.stakeholder_list : ''}
                                  init={{
                                    height: 300,
                                    menubar: '', // 'file edit view insert format tools table tc help',
                                    plugins: [
                                      'lists table help'
                                    ],
                                    toolbar: 'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | table help',
                                    paste_data_images: true,
                                    content_style: "body { font-family: Arial; font-size: 10pt; }",
                                  }}
                                  onEditorChange={(content) => {
                                    setFieldValue('stakeholder_list', content);
                                  }}
                                />
                            </FormGroup>
                          </div>

                          <div className="mb-4 col-sm-4">
                            <button type="submit" className="btn btn-primary">
                              Update
                            </button>
                          </div>

                        </FormikForm>
                      )}
                    </Formik>
                  </TabPane>
                </TabContent>
                
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Form;