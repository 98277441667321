import React from 'react';

import {
  Link,
  // Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  Button,
  ButtonGroup,
} from 'reactstrap';
// import ISO from './ISO';
// import MSPO from './MSPO';
// import MSPO_SCCS from './MSPO_SCCS';

import SidebarToggler from "../../../components/SidebarToggler";

import Listing from './Listing';
import Assign from './Assign';
import Form from './Form';

const OperationReview = () => {
  let { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${path}`}>
          <Listing />
        </Route>
        {/* <Route 
          path={`${path}/create`} 
          render={(props) => <Form path={path} {...props} />} 
        /> */}

        <Route 
          path={`${path}/edit/:id`} 
          render={(props) => <Form path={path} mode="reviewer" {...props} />} 
        />
        <Route 
          path={`${path}/lead-auditor/edit/:id`} 
          render={(props) => <Form path={path} mode="lead_auditor_comments" {...props} />} 
        />
        <Route 
          path={`${path}/view/:id`} 
          render={(props) => <Form path={path} readOnly={true} {...props} />} 
        />
        <Route 
          path={`${path}/assign/:id`} 
          render={(props) => <Assign path={path} {...props} />} 
        />
        <Route 
          path={`${path}/review/:id`} 
          render={(props) => <Form path={path} readOnly={true} review={true} {...props} />} 
        />
        <Route 
          path={`${path}/approve/:id`} 
          render={(props) => <Form path={path} readOnly={true} approval={true} {...props} />} 
        />
      </Switch>
      </React.Fragment>
  )
}

export default OperationReview;