import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import {
  Link,
  // Route,
  // Switch,
  useParams,
  // useHistory,
  // useLocation,
  // useRouteMatch,
} from "react-router-dom";

import api from '../../../utils/api';

// import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import './style.css';


const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const ViewAuthenticateCertificate = (props) => {
  let { id,certificate_no } = useParams();
 // const { "*" : certificate_no } = useParams();
  const [data, setData] = useState({});
  const [token, setToken] = useState('');

  useEffect(() => {
    
  //  let id = 484;
    if (id) {
      const getData = async () => {
       
        console.log(window.location.href);
        console.log(certificate_no);
        api.get('/'+id+'/certificate/'+certificate_no)
        .then(function (response) {
        //  delete response.data.id;
          console.log(response.data)
          setData(response.data);
        })
        .catch(function (error) {
          
        });
      }

      getData();
    }
  }, []);

 
  return (
    <React.Fragment>
      <div className="row clearfix" style={{fontFamily:"Arial"}}>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card planned_task">
                    {/* <div style={{marginLeft: '300px'}}> */}
                      <img src="https://scuds.nioshcert.com/header.png" title="www.nioshcert.com.my" style={{width:"100%", height:"90%", marginTop:0, objectPosition:'right top'}} />
                   {/*  </div> */}
                    <div class="body" style={{ width: '100%', height: 'auto',  backgroundSize: 'contain', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL + '/logo_bg.png'})`,backgroundRepeat: 'no-repeat',backgroundAttachment: 'fixed'}}>
                   

                    {/* <div style={{marginLeft: '150px',marginRight: '190px'}}> */}<img src="https://scuds.nioshcert.com/titleHeaderNoBg.png" title="www.nioshcert.com.my" style={{width:"70%", height:"70%", marginTop:0, objectPosition:'left'}} />{/* </div> */}



                    <table className="table table-borderless authCert" style={{ /* width: "1000px", marginLeft: '190px',marginRight: '190px',*/ border:0}}>
                    {data.error || !data.cert  ? 
                    
                       <tbody>
                       <tr>
                       <td style={{ fontWeight: "bold",width: "70%" }}>
                       <br/>
                       <br/>
                       <br/>
                        Certificate Not Found<br/>
                        The QR code scanned is not linked to a valid certificate issued by NIOSH Certification.  
                        <br/>
                        <br/>

                       </td>

                       </tr>
                       </tbody>
                    :
                        <tbody>
                          <tr><td style={{ fontWeight: "bold",width: "30%" }}>Certificate Number</td><td style={{ whiteSpace: "pre-wrap",width: "70%" }}>{data.cert && data.cert.certificate_no ? data.cert.certificate_no : ''}</td></tr>
                          <tr><td style={{ fontWeight: "bold" }}>Client Name</td><td style={{ whiteSpace: "pre-wrap" }}>{data.cert && data.cert.client_name ? data.cert.client_name : ""}</td></tr>
                          <tr><td style={{ fontWeight: "bold" }}>Client Address</td><td style={{ whiteSpace: "pre-wrap" }}>{data.cert && data.cert.client_address ? data.cert.client_address : ""}</td></tr>
                          <tr><td style={{ fontWeight: "bold" }}>Scheme</td><td style={{ whiteSpace: "pre-wrap" }}>{data.cert &&  data.cert.scheme ? data.cert.scheme : ""}</td></tr>
                          <tr><td style={{ fontWeight: "bold" }}>Scope</td><td style={{ whiteSpace: "pre-wrap" }}>{data.cert && data.cert.scope ? data.cert.scope : ""}</td></tr>
                          <tr><td style={{ fontWeight: "bold" }}>Certificate Validity</td><td style={{ whiteSpace: "pre-wrap" }}>{data.cert && data.cert.certificate_validity ? data.cert.certificate_validity : ""}</td></tr>
                          <tr><td style={{ fontWeight: "bold" }}>Certification Status</td><td style={{ whiteSpace: "pre-wrap",fontWeight: "bold",color:`${data.cert && data.cert.certificate_status_color ? data.cert.certificate_status_color: 'blue'}` }}>{data.cert && data.cert.certificate_status ? data.cert.certificate_status : ""}</td></tr>
                        </tbody>
                    }
                    </table>
                    <br/>
                    <table className="table table-borderless authCert" style={{ padding:"1px",/* width: "1000px", marginLeft: '190px',marginRight: '190px',*/ border:0}}>
                    {data.error || !data.cert  ? null :
                      <tr>
                        <th colspan="2" style={{ fontWeight: "bold"}}>Multi Sites Details</th>
                        </tr>
                      }
                    
                    {!data.error ? data.cert  ? data.multisites && data.multisites.length ? data.multisites.map((site, i) => <tbody style={{ fontSize:'12pt' }}>
                    <tr><td style={{ width: "13%" }}>No.: </td><td style={{ whiteSpace: "pre-wrap" }}>{i+1}</td></tr>
                    <tr><td style={{ width: "13%" }}>Site Address: </td><td style={{ whiteSpace: "pre-wrap" }}>{site.site_name}<br/>{site.site_address}<br/></td></tr>
                    <tr style={{borderBottom: "0.5px solid #E1E1E1"}}><td style={{ width: "13%" }}>Scope: </td><td style={{ whiteSpace: "pre-wrap" }}> {site.scope ? site.scope : ""}<br/></td></tr></tbody> 
                      ) : 
                      <tbody style={{ fontSize:'12pt' }}>
                      <tr><td style={{ width: "13%" }}>No.: </td><td style={{ whiteSpace: "pre-wrap", align:"left"}}>N/A</td></tr>
                      <tr><td style={{ width: "13%" }}>Site Address: </td><td style={{ whiteSpace: "pre-wrap", align:"left" }}>N/A</td></tr>
                      <tr><td style={{ width: "13%" }}>Scope: </td><td style={{ whiteSpace: "pre-wrap", align:"left" }}>N/A</td></tr>
                      </tbody> 
                      :
                      <tbody style={{ fontSize:'12pt' }}>
                      <tr><td style={{ width: "13%" }}>No.: </td><td style={{ whiteSpace: "pre-wrap", align:"left" }}>N/A</td></tr>
                      <tr><td style={{ width: "13%" }}>Site Address: </td><td style={{ whiteSpace: "pre-wrap", align:"left" }}>N/A</td></tr>
                      <tr><td style={{ width: "13%" }}>Scope: </td><td style={{ whiteSpace: "pre-wrap", align:"left" }}>N/A</td></tr>
                      </tbody> 
                    : null}
                    

                    </table>

                  {data.error || !data.cert  ?
                  <div style={{ height:"155px"}}></div>  : null}     
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                  <p /* style={{marginLeft: '190px',marginRight: '190px', textalign: 'left', fontSize: '12pt',color:'#000'}} */>Notes: If you want to get more information about our services and promotions, please visit our website at <a href='http://www.nioshcert.com.my'>www.nioshcert.com.my</a>. </p>
                  </div>
            </div>
        </div>
        </div>
    </React.Fragment>
  );
}

export default ViewAuthenticateCertificate;