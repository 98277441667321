import React from 'react';

import {
  Link,
  // Switch,
  // Route,
  useRouteMatch,
} from 'react-router-dom';

// import Listing from './Listing';
// import Form from './Form';

import SidebarToggler from '../../components/SidebarToggler';

const AuditNotes = () => {
  let { path } = useRouteMatch();

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Audit Notes
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  Audit Notes
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <Switch>
        <Route exact path={path}>
          <Listing />
        </Route>
        <Route path={`${path}/create`}>
          <Form readOnly={false} />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <Form path={path} readOnly={false} />
        </Route>
        <Route path={`${path}/view/:id`}>
          <Form path={path} readOnly={true} />
        </Route>
      </Switch> */}
    </div>
  )
}

export default AuditNotes;