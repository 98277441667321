import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  ButtonGroup,
  ToggleButton
} from 'react-bootstrap';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

// import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { Editor } from '@tinymce/tinymce-react';


import { objectToFormData } from 'object-to-formdata';

import api from '../../utils/api';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import DatePicker from 'reactstrap-date-picker';

const STATES = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Wilayah Persekutuan Kuala Lumpur',
  'Wilayah Persekutuan Labuan',
  'Wilayah Persekutuan Putrajaya',
];

const INITIAL_VALUES = {
  certifications: [],
  sampled_units: [],
};

const Form = (props) => {
  let { id } = useParams();

  let { path } = useRouteMatch();
  let history = useHistory();
  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client" } };

  const [CERTIFICATIONS, setCertifications] = useState({});

  const [NACE_CODES, setNaceCodes] = useState([]);

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);

  let [profile, setProfile] = useState({});

  const [load, reload] = useState(false);

  let { readOnly }  = props;

  useEffect(() => {
    // console.log(path);

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        if (!readOnly & response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client.update') === "undefined") {
          history.push(props.path);
        }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    const getNaceCodes = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/nace-codes')
      .then(function (response) {
        // console.log(response.data);

        setNaceCodes(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getNaceCodes();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.get('/clients/' + id)
        .then(function (response) {
           console.log(response.data);
           console.log('id dia'+id);
          delete response.data.id;          

          if (response.data.certifications) {
            let selectedCertifications = [];
            response.data.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.certifications = selectedCertifications;
          }

          if (response.data.nace_codes) {
            let selectedCodes = [];
            response.data.nace_codes.map((nace_code, key) => {
              return selectedCodes.push(nace_code.id);
            });

            response.data.nace_codes = selectedCodes;
          }

          if(response.data.audits){
         //   let selectedScopeModification = '';
            var scope_modification_comments = response.data.audits.filter(h => h.scope_modification_comments!==null).reduce((a, b) => {
              return (a.audit_plan_id) > (b.audit_plan_id)  ? a : b;
           },{}).scope_modification_comments;
           response.data.scope_modification_comments = scope_modification_comments;
        //   console.log("maxPropsed: ", scope_modification_comments);
          }

          setData(response.data);

          reload(false);
        })
        .catch(function (error) {
           console.log(error.response);
          if (error && error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`Client ID #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }

          reload(false);
        });
        
      }

      getData();
    }
  }, [id, load]);


  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        if (readOnly) {
          return;
        }

        if (values.certifications.length === 0) {
          addToast('Please select at least one certification', { appearance: 'error' });

          return;
        }

        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        
        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

        if (!id) {
          await api({
            method: 'POST',
            url: '/clients',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          // api.post('/clients', JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            // console.log(response);

            addToast('Client successfully saved', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            if (error && error.response && error.response.data && error.response.data.data && (error.response.data.data.message || error.response.data.data.messages)) {
              if(error.response.data.data.messages){
                if(error.response.data.data.messages.contact_person_email || error.response.data.data.messages.email){
                  addToast('The email is required', { appearance: 'error' });
                }
              }
              else{
                addToast(error.response.data.data.message, { appearance: 'error' });
              }  
            } else {
              addToast(error.message, { appearance: 'error' });
            }         
          });
        } else {

          /* data.append('_method', 'PUT');
          await api({
            method: 'POST',
            url: '/clients/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          }) */
          var obj = values;
          await api.put('/clients/' + id, JSON.stringify(obj), {
             headers: {
               'Content-Type': 'application/json',
             },
           })
          .then(function (response) {
             console.log(response);

            addToast('Client successfully updated', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            
            if (error && error.response && error.response.data && error.response.data.data && (error.response.data.data.message || error.response.data.data.messages)) {
              if(error.response.data.data.messages){
                if(error.response.data.data.messages.contact_person_email || error.response.data.data.messages.email){
                  addToast('The email is required', { appearance: 'error' });
                }
              }
              else{
                addToast(error.response.data.data.message, { appearance: 'error' });
              }  
            } else {
              addToast(error.message, { appearance: 'error' });
            }

 
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
        <FormikForm>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header">
                  <h2>COMPANY DETAILS</h2>
                </div>
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-sm-2">
                      <FormGroup>
                        <Label for="ClientNo">Client No.</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="no" 
                          id="ClientNo"
                          onChange={handleChange}
                          value={values.no}
                          // required
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="OriginalDate">Original Date</Label>
                        <DatePicker
                          autoComplete="off"
                          name="original_date" 
                          id="OriginalDate"
                          value={values.original_date}
                          onChange={date => setFieldValue('original_date', moment(date).format('YYYY-MM-DD'))}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-4">
                      <FormGroup>
                        <Label for="ClientSector">Sector</Label>
                        <Input 
                          type="select" 
                          className="custom-select" 
                          name="sector" 
                          id="ClientSector"
                          onChange={handleChange}
                          value={values.sector}
                        >
                          <option></option>
                          <option>GLC</option>
                          <option>GOVERNMENT</option>
                          <option>PRIVATE</option>
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ClientName">Client Name</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="name" 
                          id="ClientName"
                          onChange={handleChange}
                          value={values.name}
                          required
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ClientAddress1">Full Address</Label>
                        <Input 
                          readOnly={readOnly}
                          type="textarea" 
                          name="address_1" 
                          id="ClientAddress1"
                          onChange={handleChange}
                          value={values.address_1}
                        />
                      </FormGroup>

                      {/* <FormGroup>
                        <Label for="ClientAddress2">Address (Line 2)</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="address_2" 
                          id="ClientAddress2"
                          onChange={handleChange}
                          value={values.address_2}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="ClientAddress3">Address (Line 3)</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="address_3" 
                          id="ClientAddress3"
                          onChange={handleChange}
                          value={values.address_3}
                        />
                      </FormGroup> */}
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="ClientPostcode">Postcode</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="postcode" 
                          id="ClientPostcode"
                          onChange={handleChange}
                          value={values.postcode}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="ClientState">State</Label>
                        <Input 
                          type="select" 
                          className="custom-select" 
                          name="state" 
                          id="ClientState"
                          onChange={handleChange}
                          value={values.state}
                        >
                          <option></option>
                          {STATES.map((STATE, key) => <option key={key} value={STATE}>{STATE}</option>)}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                  <div className="col-sm-6">
                      <FormGroup>
                        <Label for="Tel">Tel No.</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="tel" 
                          id="tel"
                          onChange={handleChange}
                          value={values.tel}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="Webmail">Webmail</Label>
                        <Input 
                          readOnly={readOnly}
                          type="email" 
                          name="email" 
                          id="Webmail"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="PICName">Person In Charge (PIC)</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="contact_person_name" 
                          id="PICName"
                          onChange={handleChange}
                          value={values.contact_person_name}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="PICPhone">PIC H/Phone No.</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="contact_person_phone" 
                          id="PICPhone"
                          onChange={handleChange}
                          value={values.contact_person_phone}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="PICEmail">PIC Email</Label>
                        <Input 
                          readOnly={readOnly}
                          type="email" 
                          name="contact_person_email" 
                          id="PICEmail"
                          onChange={handleChange}
                          value={values.contact_person_email}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="LastAuditDate">Last Audit Date</Label>
                        <DatePicker
                          autoComplete="off"
                          name="last_audit_date" 
                          id="LastAuditDate"
                          value={values.last_audit_date}
                          onChange={date => setFieldValue('last_audit_date', moment(date).format('YYYY-MM-DD'))}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ScopeOfCertification">Scope Of Certification</Label>
                        <Editor 
                          disabled={readOnly}
                          apiKey={process.env.REACT_APP_TINYMCE_KEY}
                          value={data.scope_of_certification}
                          init={{
                            height: 300,
                            menubar: 'file edit view insert format tools table tc help',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                            paste_data_images: true,
                            content_style: "body { font-family: Arial; font-size: 10pt; }",
                          }}
                          onEditorChange={(content) => {
                            setFieldValue('scope_of_certification', content);
                          }}
                        />
                       {/*  <Input 
                          readOnly={readOnly}
                          type="textarea" 
                          name="scope_of_certification" 
                          id="ScopeOfCertification" 
                          onChange={handleChange}
                          value={values.scope_of_certification} 
                        /> */}
                        
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ScopeModification">Scope of Certifications Modification</Label>
                        <Input 
                          disabled={true}
                          type="textarea" 
                          name="scope_modification_comments" 
                          id="ScopeModification" 
                          onChange={handleChange}
                          value={values.scope_modification_comments} 
                        />
                        
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ScopeOfCertification">Scope Of Certification - General  (**For Certificate purposes)</Label>
                        <Editor 
                          disabled={readOnly}
                          apiKey={process.env.REACT_APP_TINYMCE_KEY}
                          value={values.scope_of_certification_general}
                          init={{
                            height: 300,
                            menubar: 'file edit view insert format tools table tc help',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                            paste_data_images: true,
                            content_style: "body { font-family: Arial; font-size: 10pt; }",
                          }}
                          onEditorChange={(content) => {
                            setFieldValue('scope_of_certification_general', content);
                          }}
                        />
                     
                        
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ScopeOfCertification">Scope Of Certification - Full (**For Certificate purposes)</Label>
                        <Editor 
                          disabled={readOnly}
                          apiKey={process.env.REACT_APP_TINYMCE_KEY}
                          value={values.scope_of_certification_full}
                          init={{
                            height: 300,
                            menubar: 'file edit view insert format tools table tc help',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                            paste_data_images: true,
                            content_style: "body { font-family: Arial; font-size: 10pt; }",
                          }}
                          onEditorChange={(content) => {
                            setFieldValue('scope_of_certification_full', content);
                          }}
                        />
                       {/*  <Input 
                          readOnly={readOnly}
                          type="textarea" 
                          name="scope_of_certification" 
                          id="ScopeOfCertification" 
                          onChange={handleChange}
                          value={values.scope_of_certification} 
                        /> */}
                        
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="CertificationsRequired">Certifications Required</Label>
                        {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={readOnly}
                                type="checkbox" 
                                name={`certifications`}
                                // onChange={handleChange}
                                onChange={() => {
                                  let certifications = values.certifications ? values.certifications : [];

                                  if (certifications.length) {
                                    var index = certifications.indexOf(CERTIFICATION.id);
                                    if (index !== -1) {
                                      certifications.splice(index, 1);
                                    } else {
                                      certifications.push(CERTIFICATION.id);
                                    }
                                  } else {
                                    certifications.push(CERTIFICATION.id);
                                  }

                                  setFieldValue('certifications', certifications);

                                  // console.log(values.client.certifications);
                                }}
                                value={CERTIFICATION.id}
                                checked={values.certifications && values.certifications.length && values.certifications.indexOf(CERTIFICATION.id) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                      ) : null}
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="SectorCode">Sector Code</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="sector_code" 
                          id="SectorCode" 
                          onChange={handleChange}
                          value={values && values.sector_code ? values.sector_code : null} 
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="NaceCode">Nace Code</Label>
                        {/* <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="client.nace_code" 
                          id="NaceCode" 
                          onChange={handleChange}
                          value={values.client && values.client.nace_code ? values.client.nace_code : null} 
                        /> */}
                        {/* <Input 
                          multiple
                          readOnly={readOnly}
                          type="select" 
                          className="custom-select" 
                          name="client.nace_code" 
                          id="NaceCode"
                          onChange={handleChange}
                          value={values.client.nace_code}
                        >
                          <option></option>
                          {NACE_CODES && NACE_CODES.map((NACE_CODE, i) => 
                            <option value={NACE_CODE.code}>{NACE_CODE.code} - {NACE_CODE.description}</option>
                          )}
                        </Input> */}
                        <div className="row">
                          <div className="col-sm-6">
                            {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                              i < 20 ? (
                              <React.Fragment key={i}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={readOnly}
                                      type="checkbox" 
                                      // disabled={true}
                                      name={`nace_codes`}
                                      // onChange={handleChange}
                                      onChange={() => {
                                        // if (values.typeStr !== 'ISO') {
                                          let { nace_codes } = values;

                                          if (nace_codes && nace_codes.length) {
                                            var index = nace_codes.indexOf(NACE_CODE.id);
                                            if (index !== -1) {
                                              nace_codes.splice(index, 1);
                                            } else {
                                              nace_codes.push(NACE_CODE.id);
                                            }
                                          } else {
                                            nace_codes = [];
                                            nace_codes.push(NACE_CODE.id);
                                          }

                                          setFieldValue('nace_codes', nace_codes);
                                        // }
                                      }}
                                      // value={CERTIFICATION.id}
                                      checked={values.nace_codes && values.nace_codes.length && values.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                    />
                                    <span>
                                      {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                    </span>
                                  </label>
                                </div>
                              </React.Fragment>
                              ) : null
                            ) : null}
                          </div>
                          
                          <div className="col-sm-6">
                            {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                              i >= 20 ? (
                              <React.Fragment key={i}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={readOnly}
                                      type="checkbox" 
                                      // disabled={true}
                                      name={`nace_codes`}
                                      // onChange={handleChange}
                                      onChange={() => {
                                        //if (values.typeStr !== 'ISO') {
                                          let { nace_codes } = values;

                                          if (nace_codes && nace_codes.length) {
                                            var index = nace_codes.indexOf(NACE_CODE.id);
                                            if (index !== -1) {
                                              nace_codes.splice(index, 1);
                                            } else {
                                              nace_codes.push(NACE_CODE.id);
                                            }
                                          } else {
                                            nace_codes = [];
                                            nace_codes.push(NACE_CODE.id);
                                          }

                                          setFieldValue('nace_codes', nace_codes);
                                        // }
                                      }}
                                      // value={CERTIFICATION.id}
                                      checked={values.nace_codes && values.nace_codes.length && values.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                    />
                                    <span>
                                      {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                    </span>
                                  </label>
                                </div>
                              </React.Fragment>
                              ) : null
                            ) : null}
                          </div>
                        </div>

                        {/* <BootstrapForm.Text className="text-muted">
                          Identify the lowest level NACE code
                        </BootstrapForm.Text> */}
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      Mandays Calculation: based on ACB – OPMC 2; medium risk due geographical 
                      factor access (travel by road not far by each operating units), already 
                      established appropriate system as per MSPO questionnaire & identified 
                      stakeholders pre-consulted). The sampling = 1.5√2 sites = 2 samples. 
                      As per questionnaire form calculation result.

                      <div className="table">
                        <table className="table mt-4">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Year</th>
                              <th>Man Days</th>
                              <th>Unit Price</th>
                              <th>Total Amount</th>
                              <th>Include SST</th>
                              <th>SST 6%</th>
                              <th>Grand Total</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                          {values.mandays_calculations && values.mandays_calculations.map((CALCULATION, key) => 
                            <tr key={key}>
                              <td>
                                <Input type="hidden" value={values.mandays_calculations[key].id} />
                                <Input 
                                  readOnly={readOnly}
                                  type="text" 
                                  name={`mandays_calculations.${key}.product_name`}
                                  // onChange={handleChange}
                                  onChange={(event) => {
                                    setFieldValue(`mandays_calculations.${key}.product_name`, event.currentTarget.value);

                                    // console.log(values);
                                  }}
                                  value={values.mandays_calculations[key].product_name}
                                />
                              </td>
                              <td>
                                <Input 
                                  
                                  readOnly={readOnly}
                                  type="text" 
                                  name={`mandays_calculations.${key}.year`}
                                  // onChange={handleChange}
                                  onChange={(event) => {
                                    setFieldValue(`mandays_calculations.${key}.year`, event.currentTarget.value);
                                  }}
                                  value={values.mandays_calculations[key].year}
                                />
                              </td>
                              <td>
                                <Input 
                                  className="text-right"
                                  readOnly={readOnly}
                                  type="number" 
                                  min={1}
                                  step={1}
                                  name={`mandays_calculations.${key}.mandays`}
                                  // onChange={handleChange}
                                  onChange={(event) => {
                                    setFieldValue(`mandays_calculations.${key}.mandays`, event.currentTarget.value);

                                    let mandays = event.currentTarget.value;
                                    let price = values.mandays_calculations[key].unit_price;
                                    let total = mandays * price;
                                    let sst = total * 6 / 100;
                                    let grand_total = 0;
                                    
                                    if (values.mandays_calculations[key].include_sst) {
                                      grand_total = total + sst;
                                    } else {
                                      grand_total = total; // - sst;
                                    }
                                    
                                    setFieldValue(`mandays_calculations.${key}.total`, total);
                                    setFieldValue(`mandays_calculations.${key}.sst`, sst);
                                    setFieldValue(`mandays_calculations.${key}.grand_total`, grand_total);
                                  }}
                                  value={values.mandays_calculations[key].mandays}
                                />
                              </td>
                              <td>
                                <Input 
                                  className="text-right"
                                  readOnly={readOnly}
                                  type="text" 
                                  name={`mandays_calculations.${key}.unit_price`}
                                  // onChange={handleChange}
                                  onChange={(event) => {
                                    setFieldValue(`mandays_calculations.${key}.unit_price`, event.currentTarget.value);

                                    let mandays = values.mandays_calculations[key].mandays;
                                    let price = event.currentTarget.value;
                                    let total = mandays * price;
                                    let sst = total * 6 / 100;
                                    let grand_total = 0;
                                    
                                    if (values.mandays_calculations[key].include_sst) {
                                      grand_total = total + sst;
                                    } else {
                                      grand_total = total; // - sst;
                                    }
                                    
                                    setFieldValue(`mandays_calculations.${key}.total`, total.toFixed(2));
                                    setFieldValue(`mandays_calculations.${key}.sst`, sst.toFixed(2));
                                    setFieldValue(`mandays_calculations.${key}.grand_total`, grand_total.toFixed(2));
                                  }}
                                  value={readOnly ? values.mandays_calculations[key].unit_price.toFixed(2) : values.mandays_calculations[key].unit_price}
                                />
                              </td>
                              <td>
                                <Input 
                                  className="text-right"
                                  readOnly={true}
                                  type="text" 
                                  name={`mandays_calculations.${key}.total`}
                                  // onChange={handleChange}
                                  // onChange={(event) => {
                                  //   setFieldValue(`mandays_calculations.${key}.total`, event.currentTarget.value);
                                  // }}
                                  value={parseFloat(values.mandays_calculations[key].total).toFixed(2)}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => {
                                        if (event.currentTarget.checked) {
                                          setFieldValue(`mandays_calculations.${key}.include_sst`, 1);
                                        } else {
                                          setFieldValue(`mandays_calculations.${key}.include_sst`, 0);
                                        }
                                        
                                        let mandays = values.mandays_calculations[key].mandays;
                                        let price = values.mandays_calculations[key].unit_price;
                                        let total = mandays * price;
                                        let sst = total * 6 / 100;
                                        let grand_total = 0;

                                        if (event.currentTarget.checked) {
                                          grand_total = total + sst;
                                        } else {
                                          grand_total = total; // - sst;
                                        }
                                    
                                        setFieldValue(`mandays_calculations.${key}.total`, total);
                                        setFieldValue(`mandays_calculations.${key}.sst`, sst);
                                        setFieldValue(`mandays_calculations.${key}.grand_total`, grand_total);
                                        
                                      }}
                                      checked={parseInt(values.mandays_calculations[key].include_sst)}
                                    />
                                    <span>

                                    </span> 
                                  </label>
                                </div>                                          
                              </td>
                              <td>
                                <Input 
                                  className="text-right"
                                  readOnly={true}
                                  type="text" 
                                  name={`mandays_calculations.${key}.sst`}
                                  // onChange={handleChange}
                                  // onChange={(event) => {
                                  //   setFieldValue(`mandays_calculations.${key}.sst`, event.currentTarget.value);
                                  // }}
                                  value={parseFloat(values.mandays_calculations[key].sst).toFixed(2)}
                                />
                              </td>
                              <td>
                                <Input 
                                  className="text-right"
                                  readOnly={true}
                                  type="text" 
                                  name={`mandays_calculations.${key}.grand_total`}
                                  // onChange={handleChange}
                                  // onChange={(event) => {
                                  //   setFieldValue(`mandays_calculations.${key}.grand_total`, event.currentTarget.value);
                                  // }}
                                  value={parseFloat(values.mandays_calculations[key].grand_total).toFixed(2)}
                                />
                              </td>
                              <td>
                                {!readOnly ?
                                <Button
                                  type="button"
                                  outline
                                  color="danger"
                                  onClick={async () => {
                                    if (window.confirm('Delete this row?')) {
                                      let rowId = values.mandays_calculations[key].id;

                                      if (!rowId) {
                                        let { mandays_calculations } = values;

                                        delete mandays_calculations[key];

                                        setFieldValue('mandays_calculations', mandays_calculations);
                                      } else {
                                        let token = await localStorage.getItem('auth-token');

                                        token = JSON.parse(token);

                                        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                        api.delete('/clients/' + id + '/mandays-calculations/' + rowId)
                                        .then(function (response) {
                                          // console.log(response);

                                          // setData(response.data.data);

                                          // addToast('Client successfully deleted', { appearance: 'success' });

                                          history.push(path.replace(/:id/, id));

                                          reload(true);
                                        })
                                        .catch(function (error) {
                                          // console.log(error.response);
                                          addToast(error.response.data.message, { appearance: 'error' });
                                        });
                                      }
                                    }
                                  }}
                                >
                                  <i className="icon-trash"></i>
                                </Button>
                                : null}
                              </td>
                            </tr>
                          )}
                          {!readOnly ?
                          <tr>
                            <td colSpan="8">
                              <Button 
                                block 
                                outline
                                onClick={(e) => {
                                  e.preventDefault();

                                  let { mandays_calculations } = values;
                                  if (!mandays_calculations.length) {
                                    mandays_calculations = [];
                                  }
                                  mandays_calculations.push({});

                                  // setData(data => {
                                  //   return {...data, branches: branches}
                                  // })

                                  // console.log(branches);

                                  setFieldValue('mandays_calculations', mandays_calculations);

                                  // console.log(values);
                                }}
                              >
                                Add Row
                              </Button>
                            </td>
                          </tr>
                          : ""}
                          </tbody>
                        </table>

                        {/* <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.mandays_calculations ? data.mandays_calculations : []} 
                          columns={[
                            {
                              dataField: "sites",
                              text: "Sites",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="mandays_calculation.sites"
                                    onChange={handleChange}
                                    // value={values.mandays_calculations.sites || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "stages",
                              text: "Stages",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="mandays_calculation.stages"
                                    onChange={handleChange}
                                    // value={values.mandays_calculations.stages || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "auditors",
                              text: "Auditors",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="mandays_calculation.auditors"
                                    onChange={handleChange}
                                    // value={values.mandays_calculations.auditors || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "days",
                              text: "Days",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="mandays_calculation.days"
                                    onChange={handleChange}
                                    // value={values.mandays_calculations.days || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "total_mandays",
                              text: "Total Mandays",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="mandays_calculation.total_mandays"
                                    onChange={handleChange}
                                    // value={values.mandays_calculations.total_mandays || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "travelling_allowance",
                              text: "Travelling Allowance",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="mandays_calculation.travelling_allowance"
                                    onChange={handleChange}
                                    //value={values.travelling_allowance || ""}
                                  />
                                )
                              },
                            },
                            {
                              editable: false,
                              text: "",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <Button 
                                    color="danger" 
                                    type="button"
                                    onClick={() => {
                                      // console.log(cell);
                                      // deleteHandler('/audits/' + id + '/document-reviews/' + cell);
                                    }}
                                  >
                                    <i className="icon-trash"></i>
                                  </Button>
                                )
                              },
                              footer: () => {
                                return (
                                  <Button color="primary" type="button">
                                    <i className="icon-plus"></i>
                                  </Button>
                                )
                              }
                            }
                          ]}
                          cellEdit={ 
                            cellEditFactory({ 
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (oldValue, newValue, row, column) => {

                              }
                            })
                          }
                        /> */}
                      </div>
                    </div>
                  </div>
                  {values.application && values.application.iso_type && values.application.iso_type.toLowerCase() === "mspo" ?
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="no_of_smallholders_sampled">
                        Sampled Units 
                      </Label>
                      <div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Management Units</th>
                              <th>Area Certified (Ha) / Mill Capacity (MT/Hr)</th>
                              <th>To Be Sampled</th>
                              <th>Initial Certification</th>
                              <th>Annual Surveillance 1</th>
                              <th>Annual Surveillance 2</th>
                              <th>Annual Surveillance 3</th>
                              <th>Annual Surveillance 4</th>
                              <th>Re-Certification</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.sampled_units && values.sampled_units.map((SAMPLED_UNIT, key) => 
                            <tr key={key}>
                              <td>
                                <Input 
                                  readOnly={readOnly}
                                  type="text" 
                                  name={`sampled_units.${key}.management_units`}
                                  // onChange={handleChange}
                                  onChange={(event) => {
                                     
                                    setFieldValue(`sampled_units.${key}.management_units`, event.currentTarget.value);

                                    // console.log(values);
                                  }}
                                  value={values.sampled_units[key].management_units}
                                />
                              </td>
                              <td>
                                <Input 
                                  readOnly={readOnly}
                                  type="text" 
                                  name={`sampled_units.${key}.area`}
                                  // onChange={handleChange}
                                  onChange={(event) => {
                                    setFieldValue(`sampled_units.${key}.area`, event.currentTarget.value);

                                    // console.log(values);
                                  }}
                                  value={values.sampled_units[key].area}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => {
                                        /* if (values.sampled_units[key].to_be_sampled === true || event.currentTarget.checked === true) {
                                          setFieldValue(`sampled_units.${key}.to_be_sampled`, 'true');
                                        } else {
                                          setFieldValue(`sampled_units.${key}.to_be_sampled`, 'false');
                                        } */
                                        setFieldValue(`sampled_units.${key}.to_be_sampled`, event.currentTarget.checked === true ? 'true' : 'false')
                                      }}
                                      checked={values.sampled_units[`${key}`].to_be_sampled === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => { 
                                        setFieldValue(`sampled_units.${key}.initial_certification`, event.currentTarget.checked === true ? 'true' : 'false')
                                      }}
                                      checked={values.sampled_units[`${key}`].initial_certification === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => {
                                        setFieldValue(`sampled_units.${key}.annual_surveillance_1`, event.currentTarget.checked === true ? 'true' : 'false')
                                      }}
                                      checked={values.sampled_units[`${key}`].annual_surveillance_1 === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => {
                                        setFieldValue(`sampled_units.${key}.annual_surveillance_2`, event.currentTarget.checked === true ? 'true' : 'false')
                                      }}
                                      checked={values.sampled_units[`${key}`].annual_surveillance_2 === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => {
                                        setFieldValue(`sampled_units.${key}.annual_surveillance_3`, event.currentTarget.checked === true ? 'true' : 'false')
                                      }}
                                      checked={values.sampled_units[`${key}`].annual_surveillance_3 === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => {
                                        setFieldValue(`sampled_units.${key}.annual_surveillance_4`, event.currentTarget.checked === true ? 'true' : 'false')
                                      }}
                                      checked={values.sampled_units[`${key}`].annual_surveillance_4 === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      onChange={(event) => {
                                        setFieldValue(`sampled_units.${key}.re_certification`, event.currentTarget.checked === true ? 'true' : 'false')
                                      }}
                                      checked={values.sampled_units[`${key}`].re_certification === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td>
                                {!readOnly ?
                                <Button
                                  type="button"
                                  outline
                                  color="danger"
                                  onClick={async () => {
                                    if (window.confirm('Delete this row?')) {
                                      let { sampled_units } = values;

                                      // delete sampled_units[key];
                                      
                                      sampled_units.splice(key, 1);
                                      
                                      console.log(sampled_units.length);
                                      
                                      if (sampled_units.length == 0) {
                                        setFieldValue('sampled_units', '');
                                      } else {
                                        setFieldValue('sampled_units', sampled_units);
                                      }
                                    }
                                  }}
                                >
                                  <i className="icon-trash"></i>
                                </Button>
                                : null}
                              </td>
                            </tr>
                            )}
                            {!readOnly ?
                            <tr>
                              <td colSpan="10">
                                <Button 
                                  block 
                                  outline
                                  onClick={(e) => {
                                    e.preventDefault();

                                    let { sampled_units } = values;
                                    if (sampled_units === null) {
                                      sampled_units = [];
                                    }
                                    sampled_units.push({});

                                    // setData(data => {
                                    //   return {...data, branches: branches}
                                    // })

                                    // console.log(branches);

                                    setFieldValue('sampled_units', sampled_units);

                                    // console.log(values);
                                  }}
                                >
                                  Add Row
                                </Button>
                              </td>
                            </tr>
                            : ""}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>
                  </div>
                  : ""}
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Status">Status</Label>
                        <div>
                        <ButtonGroup toggle className="mb-4">
                          <ToggleButton 
                            type="radio" 
                            name="status"
                            value="CONTRACT_REVIEW"
                            onChange={handleChange}
                            checked={values.status === "CONTRACT_REVIEW"}
                          >
                            Contract Review
                          </ToggleButton>

                          <ToggleButton 
                            type="radio" 
                            name="status"
                            value="QUOTATION"
                            onChange={handleChange}
                            checked={values.status === "QUOTATION"}
                          >
                            Quotation
                          </ToggleButton>

                          <ToggleButton 
                            type="radio" 
                            name="status"
                            value="AGREEMENT"
                            onChange={handleChange}
                            checked={values.status === "AGREEMENT"}
                          >
                            Agreement
                          </ToggleButton>

                          <ToggleButton 
                            type="radio" 
                            name="status"
                            value="ACKNOWLEDGEMENT"
                            onChange={handleChange}
                            checked={values.status === "ACKNOWLEDGEMENT"}
                          >
                            Acknowledgement
                          </ToggleButton>

                          <ToggleButton 
                            type="radio" 
                            name="status"
                            value="ACTIVE"
                            onChange={handleChange}
                            checked={values.status === "ACTIVE"}
                          >
                            Active
                          </ToggleButton>

                          <ToggleButton 
                            type="radio" 
                            name="status"
                            value="WITHDRAW"
                            onChange={handleChange}
                            checked={values.status === "WITHDRAW"}
                          >
                            Withdraw
                          </ToggleButton>

                          <ToggleButton 
                            type="radio" 
                            name="status"
                            value="SUSPEND"
                            onChange={handleChange}
                            checked={values.status === "SUSPEND"}
                          >
                            Suspend
                          </ToggleButton>
                        </ButtonGroup>
                        </div>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-4">
                      {readOnly === false ? 
                      <React.Fragment>
                      <button type="submit" className="btn btn-primary">
                        {id ? 'Update' : 'Create'}
                      </button>&nbsp;
                      </React.Fragment> : null}
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={profile && profile.client_id ? '/' : props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}

export default Form;