import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  // useHistory,
  // useParams,
} from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Card,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

// import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  // let history = useHistory();

  const [data, setData] = useState([]);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const { addToast } = useToasts();

  const columns = [
  {
    text: 'Date of Audit',
    formatter: (cell, row, rowIndex) => {
      return row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ? moment(row.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""
    }
  },
  {
    text: 'Type of Audit',
    formatter: (cell, row, rowIndex) => {
      return row.audit_plan && row.audit_plan.stage ? row.audit_plan.stage : '';
    }
  },
  {
    text: 'Organization',
    formatter: (cell, row, rowIndex) => {
        if (row.audit_plan && row.audit_plan.client.name) {
            return row.audit_plan.client.name;
        }
    }
  }, {
    text: 'Standards',
    formatter: (cell, row, rowIndex) => {
      return (
        <ul>{row.audit_plan.client && row.audit_plan.client.certifications ? 
          row.audit_plan.client.certifications.map((certification, i) => 
            <li>{certification.code}</li>
            ) : ""}&nbsp;</ul>
      )
    }
  }, 
  // {
  //   text: 'Team Leader',
  //   formatter: (cell, row, rowIndex) => {
  //     return (
  //       <ol>{row.client && row.client.audit_plan && row.client.audit_plan.lead_auditors ? 
  //         row.client.audit_plan.lead_auditors.map((auditor, i) => 
  //           <li>{auditor.full_name}</li>
  //          ) : ""}&nbsp;</ol>
  //     )
  //   }
  // }, 
  {
    dataField: 'id',
    text: ' ',
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          <Button outline tag={Link} to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          {/* <Button outline tag={Link} to={`${path}/edit/${cell}`}>
            <i className="icon-pencil"></i>
          </Button> */}
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/audits?type[]=ISO&type[]=MSPO&type[]=MSPO-SCCS', {
        params: query,
      })
      .then(function (response) {
        // console.log(response);

        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getData();
  }, [props]);

  // const deleteHandler = async (id) => {
  //   if (window.confirm('Delete this record?')) {
  //     let token = await localStorage.getItem('auth-token');

  //     token = JSON.parse(token);

  //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

  //     api.delete('/audits/' + id)
  //     .then(function (response) {
  //       // console.log(response);

  //       // setData(response.data.data);

  //       addToast('Audit summary successfully deleted', { appearance: 'success' });

  //       history.push(path);
  //     })
  //     .catch(function (error) {
  //       // console.log(error.response);
  //       addToast(error.response.data.message, { appearance: 'error' });
  //     });
  //   }
  // }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <React.Fragment>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <Card>
            <div className="header">
              <h2>Audit Summary</h2>
              {/* <ul className="header-dropdown">
                <li>
                  <Button 
                    className="m-t-10 m-l-20"
                    color="primary" 
                    tag={Link} 
                    to={`${path}/create`}
                  >
                    Add New
                  </Button>
                </li>
              </ul> */}
            </div>
            <div className="body project_report">
              <div className="table">
                <BootstrapTable 
                  remote={true}
                  bootstrap4
                  bordered={false}
                  classes="table-hover js-basic-example dataTable table-custom m-b-0"
                  headerClasses="thead-dark"
                  keyField="id"
                  data={ data } 
                  columns={ columns }
                  pagination={ 
                    paginationFactory(pagingInfo)
                  }
                  onTableChange={handleTableChange}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Listing;