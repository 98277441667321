import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  // Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { Editor } from '@tinymce/tinymce-react';

import classnames from 'classnames';

import api from '../../../utils/api';

import moment from 'moment';

import { useToasts } from 'react-toast-notifications';

import SidebarToggler from '../../../components/SidebarToggler';

import { objectToFormData } from 'object-to-formdata';

import './style.css';
import { ExitStatus } from 'typescript';
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const INITIAL_VALUES = {

};

const View = (props) => {
  let { id, type, subtype } = useParams();

  let { path } = useRouteMatch();

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);

  const [permissions, setPermissions] = useState([]);

  const [token, setToken] = useState('');

  const [isSubmitting, setSubmitting] = useState(false);
  
  const [isSubmitting1, setSubmitting1] = useState(false);

  const [activeTab, setActiveTab] = useState('1');
  const [count, setCount] = useState(0);
  // const [profile, setProfile] = useState();

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  let i = 1;
  
  const [body, setFieldValue] = useState('');

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
      
      if (token) {
        // console.log(`${process.env.REACT_APP_API_URL}/audit-plans/${id}?token=${token.access_token}&time=${Date.now()}`);
        let config = {
          type: 'desktop',
          height: window.innerHeight - 110,
            document: {
                fileType: 'docx',
                key: `${id}-${Date.now()}`,
                title: 'Audit Plan',
                url: `${process.env.REACT_APP_API_URL}/audit-plans/${id}?export=true&token=${token.access_token}&time=${Date.now()}`,
                permissions: {
                    download: true,
                    edit: false,
                    print: true,
                    review: false,
                }
            },
            documentType: 'text',
            editorConfig: {
                callbackUrl: '',
                mode: 'view',
                customization: {
                    zoom: 70,
                    customer: {
                        address: '',
                        info: '',
                        logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                        mail: 'iskandar@greentagpro.com',
                        name: 'Greentagpro Solution',
                        www: 'greentagpro.com',
                    },
                    chat: false,
                    forcesave: true,
                    comments: false,
                }
            },
        }

        new window.DocsAPI.DocEditor("placeholder", config);
      }
    }

    getToken();
    

    if (id) {
      const getData = async (profile) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audit-plans/' + id)
        .then(function (response) {
          console.log(response.data.data);

          let reactElement;
          if (response.data.data.appendix_a && !response.data.data.appendix_a.url) {
            reactElement = htmlToReactParser.parse(response.data.data.appendix_a.replace(/table width="\d+"/g, "table"));
            response.data.data.appendix_a = reactElement;
          }

          let config = {
            type: response.data.data.appendix_a && response.data.data.appendix_a.originalExtension ? response.data.data.appendix_a.originalExtension : 'pdf',
            height: window.innerHeight - 110,
              document: {
                  fileType: 'pdf',
                  key: `${id}-${Date.now()}`,
                  title: 'Appendix',
                  url: response.data.data.appendix_a && response.data.data.appendix_a.url ? response.data.data.appendix_a.url : `${process.env.REACT_APP_API_URL}/audit-plans/${id}?export=appendix&token=${token.access_token}&time=${Date.now()}`,
                  permissions: {
                      download: false,
                      edit: false,
                      print: true,
                      review: false,
                  }
              },
              documentType: 'text',
              editorConfig: {
                  callbackUrl: '',
                  mode: 'view',
                  customization: {
                      zoom: 70,
                      customer: {
                          address: '',
                          info: '',
                          logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                          mail: 'iskandar@greentagpro.com',
                          name: 'Greentagpro Solution',
                          www: 'greentagpro.com',
                      },
                      chat: false,
                      forcesave: true,
                      comments: false,
                  }
              },
          }
  
          new window.DocsAPI.DocEditor("placeholder_appendix_a", config);

          if (response.data.data.audit_plan) {
            reactElement = htmlToReactParser.parse(response.data.data.audit_plan.replace(/table width="\d+"/g, "table"));
            response.data.data.audit_plan = reactElement;
          }

          delete response.data.data.id;

          setPermissions(response.data.meta.permissions);
          
          // let mandays = response.data.data.audit_plan.mandays && response.data.data.audit_plan.mandays.length ? response.data.data.audit_plan.mandays.filter(function (el) {
          //   return el != null;
          // }) : [];
          
          // let audit_dates = response.data.data.audit_plan.audit_dates && response.data.data.audit_plan.audit_dates.length ? response.data.data.audit_plan.audit_dates.filter(function (el) {
          //   return el != null;
          // }) : [];
          
          let totalMandays = 0;
          
          let tblLeadAuditors = '';
          if (response.data.data.lead_auditors && response.data.data.lead_auditors.length) {
            for (i = 0; i < response.data.data.lead_auditors.length; i++) {
              var manday = 0;
              if(response.data.data.mandays && response.data.data.mandays.length){
                response.data.data.mandays.map((LEAD_AUDITOR_DAY,l)=>{ 
                  if(LEAD_AUDITOR_DAY.auditor_id){
                    console.log('Sini : '+response.data.data.lead_auditors[i].id+' == '+LEAD_AUDITOR_DAY.auditor_id);
                    if(response.data.data.lead_auditors[i].id==LEAD_AUDITOR_DAY.auditor_id){
                   // if(LEAD_AUDITOR_DAY.auditor_id.indexOf(response.data.data.lead_auditors[i].id) > -1){
                      manday = LEAD_AUDITOR_DAY.mandays;
                    } 
                  }
                  else{

                      manday = LEAD_AUDITOR_DAY[i][l] ? LEAD_AUDITOR_DAY[i][l] : 0; //+'====='+response.data.data.audit_team_members[i].id);

                  }
                });
              }
              var full_name = response.data.data.lead_auditors[i].full_name;
              
              var auditDate = 'N/A'; // response.data.data.auditor_date[i][response.data.data.lead_auditors[i].id];
              /* if (response.data.data.auditor_date && response.data.data.auditor_date[i] && response.data.data.auditor_date[i][response.data.data.lead_auditors[i].id]) {
                auditDate = response.data.data.auditor_date[i][response.data.data.lead_auditors[i].id];
              } */
              if(response.data.data.auditor_date && response.data.data.auditor_date.length){
                
                response.data.data.auditor_date.map((LEAD_AUDITOR,t)=>{ 
                  if(LEAD_AUDITOR.auditor_id){
                    if(response.data.data.lead_auditors[i].id==LEAD_AUDITOR.auditor_id){
                      auditDate = LEAD_AUDITOR.date;
                    } 
                  }
                  else{
                    response.data.data.auditor_date.map((LEAD_AUDITOR,t)=>{ 

                      auditDate = LEAD_AUDITOR[i][t] ? LEAD_AUDITOR[i][t] : 0; 

                    });
                  }
                });
              }     
               // response.data.data.mandays[i][response.data.data.lead_auditors[i].id];
              
              /* if (response.data.data.mandays && response.data.data.mandays[i] && response.data.data.mandays[i][response.data.data.lead_auditors[i].id]) {
                manday = response.data.data.mandays[i][response.data.data.lead_auditors[i].id];
              } */
              if (response.data.data.mandays && response.data.data.mandays[i] && response.data.data.mandays[i][response.data.data.lead_auditors[i].id] /* && response.data.data.mandays[i].auditor_id == response.data.data.lead_auditors[i].id */) {
              //  manday = response.data.data.mandays[i].mandays;
              }
             // console.log(response.data.data.mandays.some(item => item.hasOwnProperty('auditor_id')));
             // console.log('here mandays:'+response.data.data.mandays[i].auditor_id+'=='+response.data.data.lead_auditors[i].id);
              tblLeadAuditors += '<tr>';
              tblLeadAuditors += '<td style="padding: 3px"><b>Lead Auditor:</b></td>';
              tblLeadAuditors += '<td style="padding: 3px"><b>' + full_name + ' - ' + (auditDate) + ' (' + (manday) + ' manday(s))</b></td>';
              tblLeadAuditors += '</tr>';
              
              // console.log(response.data.data.auditor_date);
              
              totalMandays += parseFloat(manday); //parseInt(response.data.data.mandays[i][response.data.data.lead_auditors[i].id]);
            }
          } else {
              tblLeadAuditors += '<tr>';
              tblLeadAuditors += '<td style="padding: 3px">Lead Auditor:</td><td></td>';
              tblLeadAuditors += '</tr>';
          }
          
          let tblAuditors = '';
          if (response.data.data.audit_team_members && response.data.data.audit_team_members.length) {
            for (i = 0; i < response.data.data.audit_team_members.length; i++) {
              var full_name = response.data.data.audit_team_members[i].full_name
              var manday = 0;
              if(response.data.data.mandays && response.data.data.mandays.length){
                
                response.data.data.mandays.map((TEAM_MEMBER,t)=>{ 
                  if(TEAM_MEMBER.auditor_id){
                    if(response.data.data.audit_team_members[i].id==TEAM_MEMBER.auditor_id){

                    //if(TEAM_MEMBER.auditor_id.indexOf(response.data.data.audit_team_members[i].id) > -1){
                      manday = TEAM_MEMBER.mandays;
                    } 
                  }
                  else{
                    response.data.data.mandays.map((TEAM_MEMBER_DAY,t)=>{ 

                      manday = TEAM_MEMBER_DAY[i][t] ? TEAM_MEMBER_DAY[i][t] : 0; //+'====='+response.data.data.audit_team_members[i].id);

                    });
                    //manday = response.data.data.mandays[i][response.data.data.audit_team_members[i].id];
                  }
                });
              }
              var auditDate = 'N/A'; // response.data.data.auditor_date[i][response.data.data.audit_team_members[i].id]
              if(response.data.data.auditor_date && response.data.data.auditor_date.length){
                
                response.data.data.auditor_date.map((TEAM_MEMBER,t)=>{ 
                  if(TEAM_MEMBER.auditor_id){
                    if(response.data.data.audit_team_members[i].id==TEAM_MEMBER.auditor_id){

                    //if(TEAM_MEMBER.auditor_id.indexOf(response.data.data.audit_team_members[i].id) > -1){
                      auditDate = TEAM_MEMBER.date;
                    } 
                  }
                  else{
                    response.data.data.auditor_date.map((TEAM_MEMBER_DAY,t)=>{ 

                      auditDate = TEAM_MEMBER_DAY[i][t] ? TEAM_MEMBER_DAY[i][t] : 0; 

                    });
                  }
                });
              }             
              var auditRole = 'Auditor'; // response.data.data.auditor_date[i][response.data.data.audit_team_members[i].id]
              if(response.data.data.auditor_role && response.data.data.auditor_role.length){
                
                response.data.data.auditor_role.map((TEAM_MEMBER,t)=>{ 
                  if(TEAM_MEMBER.auditor_id){
                    if(response.data.data.audit_team_members[i].id==TEAM_MEMBER.auditor_id){

                    //if(TEAM_MEMBER.auditor_id.indexOf(response.data.data.audit_team_members[i].id) > -1){
                      auditRole = TEAM_MEMBER.role;
                    } 
                  }
                  else{
                    response.data.data.auditor_role.map((TEAM_MEMBER_DAY,t)=>{ 

                      auditRole = TEAM_MEMBER_DAY[i][t] ? TEAM_MEMBER_DAY[i][t] : 0; 

                    });
                  }
                });
              }   
              /* if (response.data.data.auditor_date && response.data.data.auditor_date[i] && response.data.data.auditor_date[i][response.data.data.audit_team_members[i].id]) {
                auditDate = response.data.data.auditor_date[i][response.data.data.audit_team_members[i].id];
              } */
              
              /* var manday = 0; // response.data.data.mandays[i][response.data.data.audit_team_members[i].id]
              if (response.data.data.mandays && response.data.data.mandays[i] && response.data.data.mandays[i][response.data.data.audit_team_members[i].id]) {
                manday = response.data.data.mandays[i][response.data.data.audit_team_members[i].id];
              } */
              
              tblAuditors += '<tr>';
              tblAuditors += '<td style="padding: 3px"><b>'+auditRole+':</b></td>';
              tblAuditors += '<td style="padding: 3px"><b>' + full_name + ' - ' + (auditDate) + ' (' + (manday) + ' manday(s))</b></td>';
              tblAuditors += '</tr>';
              
              totalMandays += parseFloat(manday); // parseInt(response.data.data.mandays[i][response.data.data.audit_team_members[i].id]);
            }
          } else {
              tblAuditors += '<tr>';
              tblAuditors += '<td style="padding: 3px">Auditor:</td><td></td>';
              tblAuditors += '</tr>';
          }
          let estates = '';
          let part3 = '';
          
          if (subtype.toLowerCase() === 'part-2') {
            estates += '<tr>';
            estates += '<td style="padding: 3px">Total Smallholders:</td><td style="padding: 3px">' + (response.data.data.no_of_smallholders_total ? response.data.data.no_of_smallholders_total : 0) + '</td>';
            estates += '</tr>';
            
            estates += '<tr>';
            estates += '<td style="padding: 3px">Sampled Smallholders:</td><td style="padding: 3px">' + response.data.data.no_of_smallholders_sampled + '</td>';
            estates += '</tr>';
          }
          
          if (subtype.toLowerCase() === 'part-3') {
            estates += '<tr>';
            estates += '<td style="padding: 3px">Total Unit(s):</td><td style="padding: 3px">' + (response.data.data.no_of_smallholders_total ? response.data.data.no_of_smallholders_total : 0) + '</td>';
            estates += '</tr>';
            
            estates += '<tr>';
            estates += '<td style="padding: 3px">Sampled Unit(s):</td><td style="padding: 3px">' + (response.data.data.no_of_smallholders_sampled ? response.data.data.no_of_smallholders_sampled : 0) /* (response.data.data.client.sampled_units && response.data.data.client.sampled_units.length ? response.data.data.client.sampled_units.filter(o => o.to_be_sampled === true).length : 0) */ + '</td>';
            estates += '</tr>';
            
            part3 = '<tr> \
              <td colspan="2" style="padding: 3px"> \
                <table> \
                  <thead> \
                    <tr> \
                      <th style="padding: 3px">Management Unit</th> \
                      <th style="padding: 3px">Initial Certification</th> \
                      <th style="padding: 3px">Anual Surveillance 1</th> \
                      <th style="padding: 3px">Anual Surveillance 2</th> \
                      <th style="padding: 3px">Anual Surveillance 3</th> \
                      <th style="padding: 3px">Anual Surveillance 4</th> \
                      <th style="padding: 3px">Re-Certification</th> \
                    </tr> \
                  </thead> \
                  <tbody>';
/*               if (response.data.data.client.sampled_units && response.data.data.client.sampled_units.length) {
                response.data.data.client.sampled_units.map((item, i) => {
 */                  
              if (response.data.data.sampled_units && response.data.data.sampled_units.length) {
              response.data.data.sampled_units.map((item, i) => {
                part3 += '<tr>';
                part3 += '<td style="padding: 3px">' + item.management_units + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.initial_certification == 'true' || item.initial_certification == '1' ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_1 == 'true' || item.annual_surveillance_1 == '1' ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_2 == 'true' || item.annual_surveillance_2 == '1' ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_3 == 'true' || item.annual_surveillance_3 == '1' ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_4 == 'true' || item.annual_surveillance_4 == '1' ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.re_certification == 'true' || item.re_certification == '1' ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '</tr>';
              });
            }
            
            part3 += '</tbody> \
                </table> \
              </td> \
            </tr>';
          }
          
          
          response.data.data.body = '<p style="text-decoration: underline; font-weight: bold; text-align: center;">Audit Confirmation and Auditor Appointment</p> \
<p>Dear Sir / Madam,<p> \
<p>Below is the audit details and appointed lead auditor and auditor(s) for the <b>' + (response.data.data.stage ?? null) + ' Audit</b> at <b>' + response.data.data.client.name + '</b> for your perusal.</p> \
<p>Kindly refer the details below:- </p> \
<p style="font-weight: bold; text-decoration: underline">' + (response.data.data.stage ?? '') + ' Audit ' + (subtype.toLowerCase() !== 'mspo-sccs' ? 'MS 2530-' + subtype.substring(5).toUpperCase() + ':2013' : 'MSPO-SCCS') + ' - ' + response.data.data.client.name + '</p> \
<table border="1" cellpadding="0" cellspacing="0"> \
  <tbody> \
    <tr> \
      <td style="width: 112.5px; padding: 3px">Audit Type:</td> \
      <td style="width: 514px; padding: 3px">' + (response.data.data.stage ?? '') + '</td> \
    </tr> \
    <tr> \
      <td style="width: 112.5px; padding: 3px">Audit Method:</td> \
      <td style="width: 514px; padding: 3px">' + (response.data.data.audit_method ?? '') + '</td> \
    </tr> \
    <tr> \
      <td style="padding: 3px"><b>Audit Date:</b></td> \
      <td style="padding: 3px"><b>' + (response.data.data.date_of_audit_start && response.data.data.date_of_audit_end ? moment(response.data.data.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(response.data.data.date_of_audit_end).format('DD/MM/YYYY') : "") + '</b></td> \
    </tr><tr> \
      <td style="padding: 3px"><b>Person In Charge:</b></td> \
      <td style="padding: 3px"><b>' + (response.data.data.client && response.data.data.client.contact_person_name ? response.data.data.client.contact_person_name.toUpperCase() : '') + ' (' + (response.data.data.client && response.data.data.client.contact_person_phone ? response.data.data.client.contact_person_phone : '') + ' ' + (response.data.data.client && response.data.data.client.tel ? response.data.data.client.tel : '') + ')</b></td> \
    </tr> ' + tblLeadAuditors + tblAuditors + '<tr> \
      <td style="padding: 3px">Audit No.:</td> \
      <td style="padding: 3px">' + response.data.data.audit_no + '</td> \
    </tr> \
    <tr> \
      <td style="padding: 3px">Audit Mandays:</td> \
      <td style="padding: 3px">' + totalMandays + '</td> \
    </tr>'
          
    if (subtype.toLowerCase() !== 'mspo-sccs') {
    response.data.data.body += '<tr> \
      <td style="padding: 3px">Risk Category:</td> \
      <td style="padding: 3px">' + (response.data.data.sample_unit_risk_category_text ? response.data.data.sample_unit_risk_category_text : '') + '  Risk</td> \
    </tr>'
    }
          
    response.data.data.body += estates + part3 + ' \
  </tbody> \
</table> \
<p>Be ensure to revert back to me if there are any objections to the audit team composition due to conflict of interest within 1 working day upon receiving this email.</p> \
<p>Attached are the <a href="https://' + window.location.host + '/application-review/client-feedbacks"><b>Client Feedback Form</b></a>, <a href="https://' + window.location.host + '/auditor/auditor-evaluations"><b>Auditor Evaluation Form</b></a>, <b>Invitation Letter for Stakeholder</b> and <a href="https://' + window.location.host + '/basic-data-information/edit/' + response.data.data.client.id + '"><b>Basic Data Information</b></a> for your attention and kind action.</p> \
<p>Kindly forward the invitation letter to internal and external stakeholder and fill the Client Feedback Form and Auditor Evaluation Form for our improvement in the future. Also, fill Basic Data Information for completing the audit report. Your cooperation is highly appreciated.</p> \
<p style="font-weight: bold">Please fill the Questionnaire in Relation Onsite Audit Covid-19 and submit to us prior audit date.</p> \
<p style="font-weight: bold">Herewith the required documents (checklist) as preparation for the full remote audit.</p> \
<p>Do not hesitate to contact us if you need further clarification.</p> \
<p>Upon receiving this email, the Lead Auditor Cc copied in this email will contact you and submit the audit plan. Kindly contact <b>' + (response.data.data.lead_auditors && response.data.data.lead_auditors.length ? response.data.data.lead_auditors[0].prefix.toUpperCase() + ' ' : '') + (response.data.data.lead_auditors && response.data.data.lead_auditors.length ? response.data.data.lead_auditors[0].full_name : '') + ' (Lead auditor) (' + (response.data.data.lead_auditors && response.data.data.lead_auditors.length ? response.data.data.lead_auditors[0].business_mobile : '') + ')</b> for further arrangement.</p> \
<p>&nbsp;</p> \
<p>Dear Lead Auditor,</p> \
<p><b>Attached Previous Audit Report, Certificate, CAS22-03 & MSPO Surveillance Questionnaire</b> for your reference and kindly provide the audit plan as per given template from NIOSH Certification.</p> \
<p>&nbsp;</p> \
<p>Thanks & Regards,</p> \
<p>&nbsp;</p> \
<p style="font-weight: bold">Pekerja dan Cabaran Budaya Norma Baru</p>' + profile.name + ' \
<p><b>NIOSH CERTIFICATION SDN. BHD.</b><br> \
<i>(A wholly owned subsidiary of <b>NIOSH Malaysia</b>)</i><br> \
7<sup>th</sup> Floor NIOSH Tower, Lot 1 Jalan 15/1 Section 15,<br> \
43650 Bandar Baru Bangi, Selangor Darul Ehsan, Malaysia<br> \
www.nioshcert.com.my | www.linkedin.com/company/nioshcertification \
</p> \
<p>Disclaimer: <span style="font-size: 7.5pt"><span style="color: rgb(97,189,109); font-weight: bold">Please consider the environment before printing this email</span> \
"This message (including any attachments) is intended for the recipient\'s use only. They may contain proprietary statement or legal private and privileged information. \
No confidentiality or privilege waived or lost due to any transmission errors. If you are not the intended recipient, please immediately delete all copies from your \
system, destroy any copies and notify the sender immediately. You may not, directly or indirectly, use, disclose, distribute, print, copy or rely on any part of this \
message if you are not the intended recipient. Any views expressed in this message (including any attachments) are those of the individual sender and not those of any \
member of NIOSHCert, unless other States message and the sender is allowed to state their views of any such entity. "</span></p>';
          
          response.data.data.include_invitation_for_stakeholder = 1;
          setData(response.data.data);
          
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      const getMe = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.post('/auth/me')
        .then(function (response) {
          console.log(response.data);
          
          // localStorage.setItem('auth-user', JSON.stringify(response.data));

          let profile = response.data;

          // setProfile(profile => response.data);

          getData(profile);
          
          // console.log(response.data.name);

          // if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_report.update') === "undefined") {
          //   history.push(props.path.replace(/:type/, type));
          // }
        })
        .catch(function (error) {
          // if (error && error.response && error.response.data) {
          //   if (error.response.data.message === 'Unauthenticated.') {
          //     localStorage.removeItem('auth-token');
          //     localStorage.removeItem('auth-user');
          //     setAuthState('');
          //   }
          // } else {
          //   console.log(error.message);
          // }
        });
      }

      getMe();
    }
  }, [id]);

  const sendNotificationHandler = async () => {
    if (!window.confirm('Send notification to client?')) {
      return;
    }
    
    setSubmitting(true);

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    await api.post('/audit-plans/notification/' + id)
    
    .then(function (response) {

      addToast('Notification has been sent', { appearance: 'success' });

      setSubmitting(false);
      
      // toggleModal();
    })
    .catch(function (error) {

      if (error && error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
        
        addToast(error.response.data.data.message, { appearance: 'error' });
      
      } else {
        
        addToast(error.message, { appearance: 'error' });
      }

      setSubmitting(false);
    });
  }
  
  // const sendAuditConfirmationHandler = async () => {
  //   if (!window.confirm('Send audit confirmation to client?')) {
  //     return;
  //   }
    
  //   setSubmitting1(true);
    
  //   // setTimeout(() => {
  //   //   setSubmitting1(false);
  //   // }, 5000);

  //   let token = await localStorage.getItem('auth-token');

  //   token = JSON.parse(token);

  //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

  //   await api.post('/audit-plans/send-audit-confirmation/' + id)
  //   .then(function (response) {
  //     // console.log(response.data);

  //     addToast('Audit confirmation has been sent', { appearance: 'success' });

  //     setSubmitting1(false);
      
  //     toggleModal();
  //   })
  //   .catch(function (error) {
  //     // console.log(error.response);
  //     if (error && error.response && error.response.data) {
  //       addToast(error.response.data.message, { appearance: 'error' });
  //     } else {
  //       addToast(error.message, { appearance: 'error' });
  //     }

  //     setSubmitting1(false);
  //   });
  // }

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              {type.toUpperCase()} Audit Plan
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                Audit Plan
              </li>
              <li className="breadcrumb-item">
                <Link to={path.replace(/:type/, type).replace(/:subtype/, subtype).replace('view/:id', '')}>
                  {type.toUpperCase()}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card invoice1">
            <div className="body">

            <Nav pills>
              <NavItem>
                <NavLink 
                className={classnames({ active: activeTab === '1' })} 
                onClick={() => { 
                  toggle('1');
                }}>
                  Audit Plan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink 
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { 
                  toggle('2');
                }}>
                  Appendix A
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div id="placeholder"></div>
              </TabPane>
              <TabPane tabId="2">
                <div id="placeholder_appendix_a"></div>
              </TabPane>
            </TabContent>

              <div className="col-md-12 text-right">
                <hr />
                {/* <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audit-plans/${id}?export=true&token=${token}`);
                  }}
                >
                  <i className="icon-printer"></i>
                </button>&nbsp; */}
                
                {type && type.toUpperCase() !== 'ISO' ?
                  permissions && permissions.find(o => o.name.toLowerCase() === 'audit_plan.send_audit_confirmation') ?
                    <button 
                      // disabled={isSubmitting}
                      className="btn btn-outline-secondary" 
                      type="button" 
                      onClick={toggleModal}
                    >
                      <i className="icon-envelope"></i> {'Send Audit Confirmation'}
                    </button>
                   : null
                  : null}
                &nbsp;
                {permissions && permissions.find(o => o.name.toLowerCase() === 'audit_plan.notify_client') ?
                <button 
                  disabled={isSubmitting}
                  className="btn btn-outline-secondary" 
                  type="button" 
                  onClick={sendNotificationHandler}
                >
                  <i className="icon-envelope"></i> {isSubmitting ? 'Sending Audit Plan...' : 'Send Audit Plan'}
                </button>
                : null}
                &nbsp;
                <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audit-plans/view/${id}?token=${token}`);
                  }}
                >
                  <i className="icon-printer"></i>
                </button>&nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
        <Formik 
          enableReinitialize={true}
          initialValues={data}
          onSubmit={async (values, { setSubmitting }) => {
            // console.log(values);
            
            setSubmitting1(true);
            
            let formData = {
              id: id,
              to: values.to,
              cc: values.cc,
              bcc: values.bcc,
              body: values.body,
              include_invitation_for_stakeholder: values.include_invitation_for_stakeholder,
              invitation_for_stakeholder_ref_no: values.invitation_for_stakeholder_ref_no,
              attachments: values.attachments,
            };
            
            if (typeof formData.to === 'undefined') {
              formData.to = [(data && data.email_clients ? data.email_clients : ''),(data && data.client && data.client.email ? data.client.email: ''),(data && data.client && data.client.contact_person_email ? data.client.contact_person_email : '' ),(data.lead_auditors.business_email ? data.lead_auditors.business_email : data.lead_auditors.private_email )].join(',').replace(/^,+|,+$/g, '');
            }
            
            // throw new Error();

            // return;

            let token = await localStorage.getItem('auth-token');

            token = JSON.parse(token);

            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

            formData = objectToFormData(formData, {indices: true, booleansAsIntegers: true});

            await api({
              method: 'POST',
              url: '/audit-plans/send-audit-confirmation/' + id,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formData,
            })
            .then(function (response) {
              addToast('Audit confirmation has been sent', { appearance: 'success' });

              setSubmitting1(false);
              
              toggleModal();
              //history.push(props.path + '?refresh=' + Date.now());
              window.location.reload();
            })
            .catch(function (error) {
              addToast(error.message, { appearance: 'error' });
              
              setSubmitting1(false);
            });

            // values.type = type;

            // values.subtype = subtype;

            // // console.log(values);

            // if (!id) {
            //   api.post('/audit-plans', JSON.stringify(values), {
            //     headers: {
            //       'Content-Type': 'application/json',
            //     },
            //   })
            //   .then(function (response) {
            //     // console.log(response);

            //     // addToast('Audit plan successfully saved', { appearance: 'success' });

            //     // history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));

            //     // window.location = '/client-application/iso';
            //   })
            //   .catch(function (error) {
            //     addToast(error.message, { appearance: 'error' });
            //   });
            // } else {
            //   api.put('/audit-plans/' + id, JSON.stringify(values), {
            //     headers: {
            //       'Content-Type': 'application/json',
            //     },
            //   })
            //   .then(function (response) {
            //     // console.log(response);

            //     addToast('Audit plan successfully updated', { appearance: 'success' });

            //     // history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));

            //     // window.location = '/client-application/iso';
            //   })
            //   .catch(function (error) {
            //     addToast(error.message, { appearance: 'error' });
            //   });
            // }
          }}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => (
            <FormikForm>
              <ModalHeader toggle={toggleModal}>Auditor Confirmation</ModalHeader>
              <ModalBody className="p-0">
                {/*<iframe 
                  src={`${process.env.REACT_APP_API_URL}/audit-plans/preview-audit-confirmation/${id}?token=${token}`}
                  style={{ border: 0, height: "320px", width: "100%" }}
                >
                </iframe> */}
                
                <div className="alert alert-info">Use comma for separating multiple email addressess</div>
                
                <FormGroup row className="m-2">
                  <Label sm={1} for="To">To</Label>
                  <Col sm={11}>
                    <Input 
                      // readOnly
                      type="textarea" 
                      row="4"
                      name="to" 
                      id="To"
                      defaultValue={[(data && data.email_clients ? data.email_clients : data.client && data.client.email ? data.client.email : data.client && data.client.contact_person_email ? data.client.contact_person_email : null),
                                     (data && data.lead_auditors ? data.lead_auditors.map((auditor, i) => auditor.business_email ? auditor.business_email : auditor.private_email): null), 
                                     (data && data.audit_team_members ? data.audit_team_members.map((member, i) => member.business_email ? member.business_email : member.private_email): null),
                                     (data && data.other_members && data.other_members.length ? data.other_members.map((member, i) => member.email):null),
                                    ].join(',').replace(/^,+|,+$/g, '')}
                      onChange={handleChange}
                      // rows={10}
                      required
                    />
                  </Col>
                </FormGroup>
                
                <FormGroup row className="m-2">
                  <Label sm={1} for="CC">CC</Label>
                  <Col sm={11}>
                    <Input 
                      // readOnly
                      type="text" 
                      name="cc" 
                      id="CC"
                      defaultValue={['sustainabilityassurance@nioshcert.com.my']}
                      onChange={handleChange}
                     // value={values.cc}
                      // rows={10}
                    />
                  </Col>
                </FormGroup>
                
                <FormGroup row className="m-2">
                  <Label sm={1} for="BCC">BCC</Label>
                  <Col sm={11}>
                    <Input 
                      // readOnly
                      type="text" 
                      name="bcc" 
                      id="BCC"
                      defaultValue=""
                      onChange={handleChange}
                      value={values.bcc}
                      // rows={10}
                    />
                  </Col>
                </FormGroup>
                
                <Editor 
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  // disabled={true}
                  value={values.body}
                  init={{
                    height: 500,
                    menubar: 'file edit view insert format tools table tc help',
                    menubar: 'file edit view insert format tools table tc help',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    paste_data_images: false,
                    content_style: "body { font-family: Arial; font-size: 10pt; }",
                    convert_urls: false
                  }}
                  onEditorChange={(content) => {
                   setFieldValue('body', content);
                  }}
                />
                <div className="m-2 row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Ruj">Invitation for Stakeholder Consultation Ref. No.</Label>
                      <Input 
                        // readOnly={readOnly}
                        type="text" 
                        name="invitation_for_stakeholder_ref_no" 
                        id="Ruj"
                        onChange={handleChange}
                        value={values.invitation_for_stakeholder_ref_no}
                        // rows={10}
                      />
                    </FormGroup>
                    
                    <FormGroup>
                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            name="include_invitation_for_stakeholder"
                            type="checkbox" 
                            onChange={() => {
                              if (values.include_invitation_for_stakeholder) {
                                setFieldValue('include_invitation_for_stakeholder', !values.include_invitation_for_stakeholder);
                              } else {
                                setFieldValue('include_invitation_for_stakeholder', 1);
                              }
                            }}
                            value="1"
                            checked={values.include_invitation_for_stakeholder} 
                          />
                          <span>
                            <Button 
                              outline
                              // className="m-2"
                              // disabled={isSubmitting1}
                              // color="primary" 

                              onClick={() => {
                                
                                window.open(`${process.env.REACT_APP_API_URL}/audit-plans/${id}?export=invitation-for-stakeholder-consultation&token=${token}&time=${Date.now()}`);
                               // window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.id}&token=${token.access_token}`);
                               // window.open(`https://${window.location.host}/audit-module/audit-plan/invitation-for-stakeholder-consultation/${id}`);
                                
                                //window.open(`https://${window.location.host}/audit-module/audit-plan/invitation-for-stakeholder-consultation/${id}`);
                              }}
                            >
                            <i className="icon-doc"></i> Invitation for Stakeholder Consultation
                          </Button>
                          </span>
                        </label>
                      </div>
                      
                    </FormGroup>
                    
                    <FormGroup>
                      <Label for="">Upload attachments</Label>
                      
                      {values && values.attachments ? values.attachments.map((attachment, i) => 
                      
                      <React.Fragment key={i}>
                        <br />
                        <div id={i} class="alert alert-info alert-dismissible" role="alert">
                        <Input
                          readOnly
                          type="text"
                          defaultValue={values.attachments[i].name}
                        />
                        <button 
                        type="button" 
                        class="close" 
                        data-dismiss="alert" 
                        aria-label="Close" 
                        onClick={() => {
                          if (window.confirm('Delete this file?')) {
                            let { attachments } = values;

                            delete attachments[i];

                            setFieldValue('attachments', attachments);
                          }
                        }}
                        
                        >
                          <span aria-hidden="true">×</span></button>
                      {/*   {values.attachments[i].name} */}
                        
                        </div>
                        
                      </React.Fragment>
                      )
                    : null}
                      <br />
                      <Input 
                        type="file" 
                        multiple
                        name="attachments" 
                        id="attachments"
                        onChange={(event) => {
                          const files = event.currentTarget.files;

                          let attachments = values.attachments;
                          if (typeof attachments === 'undefined') {
                            attachments = [];
                          }
                          
                          for (let i = 0; i < files.length; i++) {
                            attachments.push(files[i]);
                            setFieldValue('attachments', attachments);
                            
                            // console.log(attachments);
                          }
                          
                          // console.log(event.currentTarget.files[0]);
                          // setFieldValue('attachments', event.currentTarget.files)
                          
                          // let attachments = values.attachments;
                          // // console.log(attachments);
                          // if (typeof attachments === "undefined") {
                          //   attachments = [];
                          // }
                          // // attachments.push(event.currentTarget.files);
                          
                          // for (var i; i < event.currentTarget.files.length; i++) {
                          //   attachments.push(event.currentTarget.files[i]);
                          // }
                          
                          // setFieldValue('attachments', values.attachments);
                        }}
                      />
                    </FormGroup>

                  </div>
                </div>

              </ModalBody>
              <ModalFooter>
                <Button 
                    type="submit"
                    // disabled={true}
                    color="primary" 
                    // onClick={sendAuditConfirmationHandler}
                  >
                  {isSubmitting1 ? 'Sending...' : 'Send'}
                </Button>{' '}
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default View;