import React, { useEffect, useState } from "react";

import {
  Link,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  // Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from 'classnames';

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

const ClientInformation = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;
          console.log(response.data.data);
          setData(response.data.data);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          api.put('/audits/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            addToast('Audit successfully updated', { appearance: 'success' });
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card>
              <div className="header">
                <h2>Client Information</h2>
              </div>
              <div className="body">
                <Nav pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { toggle('1'); }}
                    >
                      2.1 Organization Information
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { toggle('2'); }}
                    >
                      2.2 Contact Information
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { toggle('3'); }}
                    >
                      2.3 Organization Background
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <hr />
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <FormGroup>
                      <Label>Organization Name</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.name ? data.audit_plan.client.name : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Address</Label>
                      <p>
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.address_1 ? data.audit_plan.client.address_1 : ""}{" "}
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.address_2 ? data.audit_plan.client.address_2 : ""}{" "}
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.address_3 ? data.audit_plan.client.address_3 : ""}{" "}
                        <br />
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.postcode ? data.audit_plan.client.postcode : ""}{" "}
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.state ? data.audit_plan.client.state : ""}&nbsp;
                      </p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Phone No.</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.tel ? data.audit_plan.client.tel : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Fax No.</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.fax ? data.audit_plan.client.fax : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Email</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.email ? data.audit_plan.client.email : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Website</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.website ? data.audit_plan.client.website : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Sector Code</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.sector_code ? data.audit_plan.client.sector_code : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>NACE Code</Label>
                      <ul>
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.nace_codes ? data.audit_plan.client.nace_codes.map((nace, i) => 
                          <li>{nace.code} <span className="text-muted">{nace.description}</span></li>
                        ) : ""}
                      </ul>
                    </FormGroup>

                    <FormGroup>
                      <Label>Total Staff</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.client_data_details && data.audit_plan.client.client_data_details.length ? data.audit_plan.client.client_data_details.map((clientdata,i)=> clientdata.new_no_of_employees ): '0' /* data.audit_plan.client.total_staffs!= '0' ? data.audit_plan.client.total_staffs : '0' */ }
                     {/*  && data.audit_plan.client.client_data_details && data.audit_plan.client.client_data_details.length && data.audit_plan.client.client_data_details.new_no_of_employees  && data.audit_plan.client.client_data_details.new_no_of_employees!=null ? data.audit_plan.client.client_data_details.new_no_of_employees : data.audit_plan.client.total_staffs */}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Top Management Representative</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.client_data_details && data.audit_plan.client.client_data_details.length ? data.audit_plan.client.client_data_details.map((clientdata,i)=> clientdata.new_mr_name  ? clientdata.new_mr_name : data.audit_plan.client.management_representative_name  ) : '-' }
                     &nbsp;</p>
                    </FormGroup>


                  </TabPane>
                  <TabPane tabId="2">
                    <FormGroup>
                      <Label>Contact Name</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.contact_person_name ? data.audit_plan.client.contact_person_name : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Position</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.contact_person_position ? data.audit_plan.client.contact_person_position : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Phone No.</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.contact_person_phone ? data.audit_plan.client.contact_person_phone : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Email</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.contact_person_email ? data.audit_plan.client.contact_person_email : ""}&nbsp;</p>
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId="3">
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={data.organization_background}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('organization_background', content);
                        }}
                      />
                    </FormGroup>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>&nbsp;
                        <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path.replace(/:type/, props.type)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default ClientInformation;