import React, { useEffect, useState } from "react";

import {
  Link,
  // Route,
  // Switch,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from "react-router-dom";

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import {
  Button,
  Input,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label
} from "reactstrap";

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import { objectToFormData } from 'object-to-formdata';
import { useToasts } from 'react-toast-notifications';

import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

import classnames from 'classnames';

import moment from 'moment';

import './style.css';
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { onLoad } from "@sentry/browser";

let config = {
  document: {
    url: ''
  }
};

/* const INITIAL_VALUES = {
  certification_info: [],
}; */


const ViewDraftCertificate = (props) => {

  let { type, subtype, id } = useParams();

  let history = useHistory();

  let [profile, setProfile] = useState({});

  const [data, setData] = useState({});

  const [token, setToken] = useState('');
  const [schemeCode, setSchemeCode] = useState('ISO 9001:2015');
  

  const { addToast } = useToasts();

  const [isSending, setSending] = useState(false);

  const [modal, setModal] = useState(false);

  const [activeTab1, setActiveTab1] = useState(1);
  const [activeTab2, setActiveTab2] = useState(1);
  const [activeTab3, setActiveTab3] = useState(2);

  const toggle1 = tab1 => {
    if(activeTab1 !== tab1) setActiveTab1(tab1);
  }
  const toggle2 = tab2 => {
    if(activeTab2 !== tab2) setActiveTab2(tab2);
  }
  const toggle3 = tab3 => {
    if(activeTab3 !== tab3) setActiveTab3(tab3);
  }

  const [activeObject, setActiveObject] = useState(null);
  function getClass(index) {
    return index === activeObject?.id ? "active" : "inactive";
  }
    /* let cert_id;
 const toggleModal = (cert_id) => setModal({
   isOpen: !modal,
   cert_id: cert_id
  }); */
/*   const toggleModal = mod =>{ 
    
    if(modal !== mod) setModal(modal);
  
  } */
 const toggleModal2 = () => setModal(!modal);
 const toggleModal3 = () => setModal(!modal);
 const toggleModal = () => setModal(!modal);
 /* const toggleModal = (event) => {
  let id = event.target.id;
  if(id==='Amendment'){
    setModal(!modal);
  }
  } */

 useEffect(() => {
     const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
         setProfile(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
      });
    }

    getMe(); 

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        setToken(token);
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;
        
          setData(response.data.data);
          
          let cert = null;
          let scope = 0;

          
          if ( response.data.data.audit_plan
            && response.data.data.audit_plan.type
            && response.data.data.audit_plan.type.toUpperCase() === 'ISO' 
            && response.data.data.audit_plan.client 
            && response.data.data.audit_plan.client.certifications){
                response.data.data.audit_plan.client.certifications.map((certification, i) => {
                  if(activeTab1 === 2 ){
                        scope = activeTab3;
                      }
                    
                     console.log('scope == '+activeTab3);
                    
                    console.log('come here activetab1=='+activeTab1+' activetab3=='+activeTab3+'scope='+scope);
                    console.log('/audits/'+id+'?export=certificate&cert='+certification.id+'&scope='+scope);
                    if (response.data.meta.roles[0].name.toLowerCase() != 'ncsb admin' ) {
                      config = {
                        type: 'desktop',
                        height: '1000px',
                        document: {
                            fileType: 'pdf',
                            key: `${id}-${Date.now()}`,
                            title: 'Certificate',
                          //  url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.id}&scope=${scope}&token=${token.access_token}&time=${Date.now()}`,
                             url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.id}&scope=${scope}&token=${token.access_token}&time=${Date.now()}`,
                            permissions: {
                                download: true,
                                edit: false,
                                print: false,
                                review: false,
                            }
                          },
                          documentType: 'text',
                          editorConfig: {
                              callbackUrl: '',
                              mode: 'view',
                              customization: {
                                  zoom: 70,
                                  customer: {
                                      address: '',
                                      info: '',
                                      logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                                      mail: 'iskandar@greentagpro.com',
                                      name: 'Greentagpro Solution',
                                      www: 'greentagpro.com',
                                  },
                                  chat: false,
                                  forcesave: true,
                                  comments: false,
                              }
                          },
                      }
                    }else{
                      config = {
                        type: 'desktop',
                        height: '1000px',
                        document: {
                            fileType: 'docx',
                            key: `${id}-${Date.now()}`,
                            title: 'Certificate',
                            url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.id}&scope=${scope}&token=${token.access_token}&time=${Date.now()}`,
                          //  url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.id}&scope=${scope}&token=${token.access_token}&time=${Date.now()}`,
                            permissions: {
                                download: false,
                                edit: false,
                                print: false,
                                review: false,
                            }
                          },
                          documentType: 'text',
                          editorConfig: {
                              callbackUrl: '',
                              mode: 'view',
                              customization: {
                                  zoom: 70,
                                  customer: {
                                      address: '',
                                      info: '',
                                      logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                                      mail: 'iskandar@greentagpro.com',
                                      name: 'Greentagpro Solution',
                                      www: 'greentagpro.com',
                                  },
                                  chat: false,
                                  forcesave: true,
                                  comments: false,
                              }
                          },
                      }
                    }
                      new window.DocsAPI.DocEditor(`placeholder_${certification.id}`, config);
                    //  console.log('sini ada : '+`placeholder_${certification.id}`);
                    if(activeTab1 === 2){
                      new window.DocsAPI.DocEditor(`placeholder_${scope}`, config);
                      console.log('if sini ada : '+`placeholder_${scope}`);
                    }
                })
          }
          
          
          
          if ( response.data.data.audit_plan
            && response.data.data.audit_plan.type
            && (response.data.data.audit_plan.type.toUpperCase() === 'MSPO' || response.data.data.audit_plan.type.toUpperCase() === 'MSPO-SCCS')
            && response.data.data.audit_plan.client 
            && response.data.data.audit_plan.client.certifications){
              response.data.data.audit_plan.client.certifications.map((certification, i) => {
              

              config = {
                type: 'desktop',
                height: '1000px',
                document: {
                    fileType: 'docx',
                    key: `${id}-${Date.now()}`,
                    title: 'Certificate',
                    url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.id}&scope=0&token=${token.access_token}&time=${Date.now()}`,
//                    url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.id}&token=${token.access_token}&time=${Date.now()}`,
                    permissions: {
                        download: false,
                        edit: false,
                        print: false,
                        review: false,
                    }
                  },
                  documentType: 'text',
                  editorConfig: {
                      callbackUrl: '',
                      mode: 'view',
                      customization: {
                          zoom: 70,
                          customer: {
                              address: '',
                              info: '',
                              logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                              mail: 'iskandar@greentagpro.com',
                              name: 'Greentagpro Solution',
                              www: 'greentagpro.com',
                          },
                          chat: false,
                          forcesave: true,
                          comments: false,
                      }
                  },
              }

                new window.DocsAPI.DocEditor('placeholder', config);
              })

          }

        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
             addToast(error.response.data.message, { appearance: 'error' });
          } else {
            // addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id, config]);

  const sendNotificationHandler = async (date) => {
    if(date != null){
      if (!window.confirm('Client has been notified on '+moment(data.notify_client_draft_date).format('DD/MM/YY HH:mm')+'. Are you sure to send it again ?')) {
        return;
      }
    }
    else{
      if (!window.confirm('Send notification to client ?')) {
        return;
      }
    }
    

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    await api.post('/audits/notification/' + id)
    
    .then(function (response) {

      addToast('Notification has been sent', { appearance: 'success' });

      //setSubmitting(false);
      
      // toggleModal();
    })
    .catch(function (error) {

      if (error && error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
        
        addToast(error.response.data.data.message, { appearance: 'error' });
      
      } else {
        
        addToast(error.message, { appearance: 'error' });
      }

      //setSubmitting(false);
    });
  }

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ').toUpperCase()}` : ""} 
              {data.certificate_approved_admin == 1  ? " Certificate" : " Draft Certificate"}
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                {data.certificate_approved_admin == 1  ? " Certificate" : " Draft Certificate Confirmation"}
              </li>
              <li className="breadcrumb-item">
                <Link to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}>
                  {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ').toUpperCase()}` : ""}
                </Link>
              </li> 
            </ul>
          </div>
          {profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin' ?
            data.certificate_approved === 1  && data.certificate_approved_admin !== 1 ?
              <div className="col-lg-4 col-md-4 col-sm-12 text-right">
                <Formik 
                  initialValues={data}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
              
                    if (window.confirm('Proceed to generate e-Certificate ?')) {
                      values.certificate_approved_admin = 1;

                      let token = await localStorage.getItem('auth-token');
          
                      token = JSON.parse(token);
                      
                      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                      await api.put('/audits/confirm-certificate/'+id, JSON.stringify(values), {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      })
                      .then(function (response) {
                        
                          addToast('Certificate is confirmed', { appearance: 'success' });                                      
                          window.location.reload(); 
                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });
                        
                      });
                    }
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <FormikForm>
                      <Button 
                            type="submit"
                            disabled={isSubmitting}
                            color="success" 
                            >
                          <i className="icon-check"></i> {isSubmitting ? 'Confirming...' : 'Confirm Draft Certificate'}
                      </Button>
                      &nbsp;
                      <Button 
                        outline
                        color="primary" 
                        onClick={() => sendNotificationHandler(data.notify_client_draft_date)}
                      ><i className="icon-envelope"></i> Notify Client 
                      </Button>  
                      &nbsp;
                      {data.notify_client_draft_date != null ? 
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-1`}>
                              {'Notified at '+moment(data.notify_client_draft_date).format('DD/MM/YY HH:mm')}
                            </Tooltip>
                          }
                        ><i className="icon-info primary" color="primary"></i>
                        </OverlayTrigger>
                      : null}
                    </FormikForm>
                  )}
                </Formik>  
              </div>
            :
              <div className="col-lg-4 col-md-4 col-sm-12 text-right">
              <Button 
              outline
              color="primary" 
              onClick={() => sendNotificationHandler(data.notify_client_draft_date)}
            >
            <i className="icon-envelope"></i> Notify Client 
            </Button>  
            &nbsp;
              {data.notify_client_draft_date != null ? 
                <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-1`}>
                    {'Notified at '+moment(data.notify_client_draft_date).format('DD/MM/YY HH:mm')}
                  </Tooltip>
                }
              ><i className="icon-info primary" color="primary" ></i></OverlayTrigger>
              : null}
            </div> 
          :
            null
          }
        </div>
      </div>        
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card invoice1">
            <div className="body">

            { data.audit_plan 
              && data.audit_plan.type 
              && data.audit_plan.type.toUpperCase() === 'ISO' 
              && data.multisites && data.multisites.length ? 
                <Nav tabs>
                  <NavItem key={1}>
                    <NavLink
                      className={classnames({ active: activeTab1 === 1 })}
                      onClick={() => { 
                        toggle1(1);
                      }}
                    >
                      Certificate
                    </NavLink>
                  </NavItem>
                  
                  <NavItem key={2}>
                    <NavLink
                      className={classnames({ active: activeTab1 === 2 })}
                      onClick={() => { 
                        toggle1(2);
                      }}
                    >
                    Multisites Certificate
                    </NavLink>
                  </NavItem>
                </Nav>
              : null}
            <br/>
                
            <Nav pills key={1}>
              { data.audit_plan 
                && data.audit_plan.type 
                && data.audit_plan.type.toUpperCase() === 'ISO' 
                && data.audit_plan.client 
                && data.audit_plan.client.certifications ? 
                  data.audit_plan.client.certifications.map((certification, i) => 
                
                  <NavItem key={certification.id}>
                    <NavLink
                      className={classnames({ active:  activeTab1 === 1 ? activeTab3 === certification.id : activeTab2 === certification.id })}
                      onClick={() => { 
                        setSchemeCode(certification.code)

                        activeTab1 === 1 ?
                        toggle3(certification.id)
                        :
                        toggle2(certification.id)
                        ;
                      }}
                    >
                      {certification.code} 
                    </NavLink>
                  </NavItem>
                  ) 
                : null}
            </Nav>

            { data.audit_plan 
                && data.audit_plan.type 
                && data.audit_plan.type.toUpperCase() === 'ISO' 
                && data.multisites && data.multisites.length ?
            <hr />    : null }

            <Nav pills key={2}>
              {activeTab1 === 2
                && data.audit_plan && data.audit_plan.type 
                && data.audit_plan.type.toUpperCase() === 'ISO' 
                && data.multisites && data.multisites.length ? 
                  data.multisites.map((multisite, i) =>
                    multisite.certification_id===activeTab2 ?
                      <NavItem key={multisite.id}>
                        <NavLink
                            className={classnames({ active: activeTab3 === multisite.id })}
                            onClick={() => { 
                              toggle3(multisite.id);
                            }}
                          >
                            {multisite.site_name}
                          </NavLink>
                      </NavItem>
                  : null ) 
              : null}
            </Nav>
            
            <TabContent activeTab={activeTab3}>
              {activeTab1 === 1 ?
                data.audit_plan 
                && data.audit_plan.type 
                && data.audit_plan.type.toUpperCase() === 'ISO' 
                && data.certification_info ?
                  data.certification_info.map((certification, i) => 
                  <TabPane tabId={certification.certificate_id}>
                    <div id={`placeholder_${certification.certificate_id}`}></div>

                    <hr />
    
                    {/*  DOWNLOAD CERTIFICATE - ISO */}
                        
                    {data.certificate_approved_admin == 1 ?
                      profile.roles 
                      &&  profile.roles[0].name.toLowerCase() !== 'ncsb admin' ? 

                        // ************ CLIENT ***********************
                    
                        <div className="col-md-12 text-right">
                          <button 
                            className="btn btn-outline-secondary" 
                            type="button" 
                            onClick={() => {
                                window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.certificate_id}&scope=0&token=${token.access_token}`);
                            }}
                          >
                            <i className="icon-arrow-down"></i> Download Certificate
                          </button>
                        </div>        
                        :      
                        // *************ADMIN *******************    
                      <div className="col-md-12 text-right">
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button" 
                          onClick={() => {
                              window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.certificate_id}&scope=0&token=${token.access_token}`);
                          }}
                        >
                          <i className="icon-arrow-down"></i> Download Certificate (MS Word)
                        </button>
                        &nbsp;
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button" 
                          onClick={() => {
                              window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.certificate_id}&scope=0&token=${token.access_token}`);
                          }}
                        >
                          <i className="icon-arrow-down"></i> Download Certificate (PDF)
                        </button>
                        &nbsp; 
                        <button 
                          className="btn btn-info" 
                          type="button"
                          onClick={toggleModal2}
                        >
                          <i className="icon-settings"></i> Settings
                        </button>
                      </div>
                      : null
                    }
      
      
                    {/* BUTTON CONFIRM & AMENDMENT */}
                        
                    { data.certificate_approved !== 1
                      && certification.confirmed_status != 1 ?
                      profile.roles 
                      && (profile.roles[0].name.toLowerCase() == 'ncsb admin' || profile.roles[0].name.toLowerCase() == 'client') 
                      && profile.roles[0].name.toLowerCase() !== 'ncsb admin' ? 

                          // ************ CLIENT ***********************
                          <div className="col-md-12 text-right">
                            <Formik 
                              enableReinitialize={true}
                              initialValues={data}
                              onSubmit={async (values, { setSubmitting, resetForm }) => {
                            
                              if (window.confirm('Proceed to generate certificate of '+schemeCode+'?')) {
                                
                                
                                let token = await localStorage.getItem('auth-token');
                                
                                token = JSON.parse(token);

                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                values.certification_info[i].certificate_id = certification.certificate_id;
                                values.certification_info[i].confirmed_status = 1;   
                               // values._method = 'PUT';
                                var obj = values;
                                // var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                                
                              //  data.append('_method', 'PUT');
                              // data.append('certificate_approved', '1');
                              await api.put('/audits/confirm-certificate/'+id, JSON.stringify(obj), {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              })

                                // await api({
                                //   method: 'POST',
                                // //  url: '/audits/' + id,
                                //   headers: {
                                //     'Content-Type': 'multipart/form-data',
                                //   },
                                //   data: data,
                                // })
                                .then(function (response) {
                                 // addToast('Certificate is confirmed', { appearance: 'success' });
                                 if(response.data && response.data.is_approved == 1){
                                  setActiveObject( {clientName: data && data.audit_plan && data.audit_plan.client ? data.audit_plan.client.name :'', displayFor:'Confirmation' });
                                  toggleModal();
                                 } 
                                 else{ 
                                  addToast('Certificate is confirmed', { appearance: 'success' });
                                  window.location.reload(); }
                                  // history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
                                })
                                .catch(function (error) {
                                  addToast(error.message, { appearance: 'error' });
                                  
                                });
                              }
                              }}
                            >
                            {({ isSubmitting, values, setFieldValue, handleChange }) => (
                              <FormikForm>
                                <Button 
                                  type="submit"
                                  disabled={isSubmitting}
                                  color="success" 
                                >
                                <i className="icon-check"></i> {isSubmitting ? 'Confirming...' : 'Confirm'}
                                </Button>

                                &nbsp;
                                <Button 
                                  className="btn btn-secondary" 
                                  type="button"
                                  //data-target={`#modal${certification.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setActiveObject( {id: id, certification_info : data.certification_info, displayFor:'Amendment' });

                                    toggleModal();
                                  }}
                                  id = {'Amendment'}
                                ><i className="icon-share-alt"></i> Amendment 
                                </Button>
                              </FormikForm>
                            )}
                            </Formik> 
                            
                            
                          </div>  
                          :
                          null
                      : null
                    } 
                                    
                  </TabPane>          
                  ) 
                :  null
              : null}
              

              {/* ISO - TAB MULTISITES (FOR MULTISITE NO NEED BUTTON CONFIRM AND AMEND) */}
              {activeTab1 === 2 ?
                data.audit_plan 
                && data.audit_plan.type 
                && data.audit_plan.type.toUpperCase() === 'ISO' 
                && data.certification_multisites 
                && data.certification_multisites.length ? 
                   data.certification_multisites.map((certification, i) => 
                    <TabPane tabId={certification.asoc_id}>
                    <div id={`placeholder_${certification.asoc_id}`}></div>
                    <hr />
    
                    {/*  DOWNLOAD CERTIFICATE - ISO MULTISITES */}

                    {data.certificate_approved_admin == 1 
                      && profile.roles 
                      && profile.roles[0].name.toLowerCase() !== 'ncsb admin' ? 

                        // ************ CLIENT OR OTHER THAN ADMIN ***********************

                        <div className="col-md-12 text-right">
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button" 
                          onClick={() => {
                            window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.certificate_id}&scope=${certification.asoc_id}&token=${token.access_token}`);
                          }}
                        >
                          <i className="icon-arrow-down"></i> Download Certificate
                        </button>
                        </div>  
                      :
                      <div className="col-md-12 text-right">
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button" 
                          onClick={() => {
                            
                            window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.certificate_id}&scope=${certification.asoc_id}&token=${token.access_token}`);
                          }}
                        >
                          <i className="icon-arrow-down"></i> Download Certificate (MS Word)
                        </button>
                        &nbsp;
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button" 
                          onClick={() => {
                            window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.certificate_id}&scope=${certification.asoc_id}&token=${token.access_token}`);
                          }}
                        >
                          <i className="icon-arrow-down"></i> Download Certificate (PDF)
                        </button>
                        &nbsp;
                        <button 
                        className="btn btn-info" 
                        type="button"
                        onClick={toggleModal2}
                        >
                          <i className="icon-settings"></i> Settings
                        </button>
                      </div>
                    }
                    </TabPane>          
                    ) 
                  :  
                  null
              : null}                 
            </TabContent>
           
          {/* MSPO - NO TAB */}
          
          { data.audit_plan 
            && data.audit_plan.type 
            && (data.audit_plan.type.toUpperCase() === 'MSPO' || data.audit_plan.type.toUpperCase() === 'MSPO-SCCS') ? 

            <div className="col-lg-12 col-md-12 text-right">

              <div id="placeholder"></div>
              
              <hr />
              { data.certificate_approved != 1 
                && profile && profile.roles 
                && (profile.roles[0].name.toLowerCase() === 'ncsb admin' || profile.roles[0].name.toLowerCase() === 'client')  
                && profile.roles[0].name.toLowerCase() !== 'ncsb admin' ? 
                <Formik 
                  //enableReinitialize={true}
                  initialValues={data}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    if (window.confirm('Proceed to generate certificate? ')) {

                      let token = await localStorage.getItem('auth-token');
          
                      token = JSON.parse(token);
                      
                      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                      values.certification_info[0].confirmed_status = 1;   
                     // values._method = 'PUT';
                      var obj = values;
                      await api.put('/audits/confirm-certificate/'+id, JSON.stringify(obj), {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      })
                     /*  api.post('audits/'+id, JSON.stringify(obj), {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      }) */
                      .then(function (response) {
                        if(response.data && response.data.is_approved == 1){
                            setActiveObject( {clientName: data && data.audit_plan && data.audit_plan.client ? data.audit_plan.client.name :'', displayFor:'Confirmation' });
                            toggleModal();
                        }
                         else{ 
                          addToast('Certificate is confirmed', { appearance: 'success' });
                          window.location.reload(); 
                        }

                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });
                        
                      });
                    }
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <FormikForm>
                      <Button 
                            type="submit"
                            disabled={isSubmitting}
                            color="success" 
                            >
                          <i className="icon-check"></i> {isSubmitting ? 'Confirming...' : 'Confirm'}
                      </Button>
                      &nbsp;
                      <Button 
                        className="btn btn-secondary" 
                        type="button"
                        name="btnAmend"
                        id={'Amendment'}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleModal();
                        }}
                      >
                      <i className="icon-share-alt"></i> Amendment
                      </Button>
                    </FormikForm>
                  )}
                </Formik>                
                
               
              : 
                null
              }
             
              {/*  DOWNLOAD CERTIFICATE - MSPO */}
              {/* ADD && profile.roles[0].name.toLowerCase() != 'client' ON 18JAN2022*/}

             { data.certificate_approved == 1 && profile.roles  && profile.roles[0].name.toLowerCase() == 'ncsb admin' && data.certification_info ?
                data.certification_info.map((certification, i) => 
                
                <div className="col-lg-12 col-md-12 text-right">
                  <button 
                    // disabled={isSubmitting}
                    className="btn btn-outline-secondary" 
                    type="button" 
                    
                    onClick={() => {
                        window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.certificate_id}&scope=0&token=${token.access_token}`);
                    }}
                  >
                    <i className="icon-arrow-down"></i> Download Certificate (MS Word)
                  </button>
                  &nbsp;
                  <button 
                    // disabled={isSubmitting}
                    className="btn btn-outline-secondary" 
                    type="button" 
                    
                    onClick={() => {
                        window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.certificate_id}&scope=0&token=${token.access_token}`);
                    }}
                  >
                    <i className="icon-arrow-down"></i> Download Certificate (PDF)
                  </button>
                  &nbsp;
                  <Button 
                  className="btn btn-info" 
                  type="button"
                  onClick={toggleModal2}
                >
                <i className="icon-settings"></i> Settings
                </Button>
                </div>
              ):null}    

              { data.certificate_approved == 1 && data.certificate_approved_admin == 1 && profile.roles  && profile.roles[0].name.toLowerCase() != 'ncsb admin' && data.certification_info ?
                data.certification_info.map((certification, i) => 
                
                <div className="col-lg-12 col-md-12 text-right">
                <button 
                  // disabled={isSubmitting}
                  className="btn btn-outline-secondary" 
                  type="button" 
                  
                  onClick={() => {
                      window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&pdf=true&cert=${certification.certificate_id}&scope=0&token=${token.access_token}`);
                  }}
                >
                  <i className="icon-arrow-down"></i> Download Certificate
                </button>
              </div>
             ):null}    
            </div>
          : null}   

            </div>
          </div>
        </div>
      </div>

 {/* MODAL CERTIFICATION IS COFIRM */}
    
 {data.certificate_approved === 1 && profile && profile.roles && profile.roles[0].name.toLowerCase() == 'client' ? 
    <Modal className="modal-md" isOpen={modal} toggle={toggleModal3}>
    
          <ModalHeader toggle={toggleModal3}>E - Certificate Management System Standard for {data && data.audit_plan && data.audit_plan.client ? data.audit_plan.client.name : ''} </ModalHeader>
          <ModalBody className="p-0">
          <div className="m-2 row clearfix">
            <div className="col-sm-12">
            <p>Your draft certificate is ready. In order to access e-Certificate, appreciate if your good side can clear the outstanding payment. </p>

            <p>Any matter raised in regards to the above notification, you may directly contact the person in charge or email us at <a href="mailto:msc@nioshcert.com.my">msc@nioshcert.com.my</a></p>
            {/*<b>Congratulations</b> on your achievement for obtaining Management System Standard Certification. 
            We will notify you to access e-Certificate once the payment is done.
             This is an electronically generated certificate and kindly click on <b>Download Certificate</b> button below to download. */}
            </div>
          </div>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal3}>Close</Button>
          </ModalFooter>
       
    </Modal>
    : null} 


    {/* MODAL FOR SETTING ROW FOR TABLE IN CERT */}
    
    {profile && profile.roles && profile.roles[0].name.toLowerCase() == 'ncsb admin' ? 
    <Modal className="modal-md" isOpen={modal} toggle={toggleModal2}>
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {

        console.log(values.setting_row_certificate);
        
        
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
       // values._method = 'PUT';
       // var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
      //  data.append('_method', 'PUT');
      //  console.log(data);
        /* await api({
          method: 'POST',
          url: '/audits/' + id,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        }) */

        await api.put('/audits/' + id, JSON.stringify(values), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          addToast('Setting is successfully saved', { appearance: 'success' });

         // history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
          toggleModal2();
     //     history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
        })
        .catch(function (error) {
          addToast(error.message, { appearance: 'error' });
          
        });

      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
        <FormikForm>
          <ModalHeader toggle={toggleModal2}>Setting For Table in Certificate </ModalHeader>
          <ModalBody className="p-0">
          <div className="m-2 row clearfix">
            <div className="col-sm-12">
            <Label for="SettingRowCertificate">Number of row(s)</Label>
            <Input 
              type="text" 
              name="setting_row_certificate"
              value={values.setting_row_certificate ? values.setting_row_certificate : null}
              onChange={(event) => {
                setFieldValue(`setting_row_certificate`, event.currentTarget.value);  
              }}
              required
            />                                
              </div>
              <div className="col-sm-12"><small>By default, it is set as 10</small></div>
          </div>

          </ModalBody>
          <ModalFooter>
            <Button 
              type="submit"
              disabled={isSubmitting}
              color="primary" 
              >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal2}>Cancel</Button>
          </ModalFooter>
        </FormikForm>
      )}
    </Formik>
    </Modal>
    : null} 

  {/* Modal for Amendment */}
  { data.certificate_approved !== 1 
    && profile 
    && profile.roles 
    && profile.roles[0].name.toLowerCase() == 'client' ? 
    
    <Modal className="modal-md" object={activeObject} isOpen={modal} toggle={toggleModal}>
      <Formik 
      enableReinitialize={true}
      initialValues={activeObject}


      onSubmit={async (values, { setSubmitting }) => {
        console.log('notes :'+values.certification_info[0].certificate_amendment_note);
        values.certificate_amendment_note =values.certification_info[0].certificate_amendment_note;
        
        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
       /*  data.append('_method', 'PUT');
        
        await api({
          method: 'POST',
          url: '/audits/send-amendment-note-certificate/' + id,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        }) */
        await api.put('/audits/send-amendment-note-certificate/' + values.id, JSON.stringify(values), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          addToast('Amendment note has been sent', { appearance: 'success' });

        // setSubmitting1(false);
          history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
          //toggleModal();
         // window.location.reload()
        })
        .catch(function (error) {
          addToast(error.message, { appearance: 'error' });
          
        });

      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
        <FormikForm>
          <ModalHeader  toggle={toggleModal}>{values.displayFor == "Confirmation" ? 'E - Certificate Management System Standard for '+values.clientName : 'Amendment Notes'}</ModalHeader>
          <ModalBody className="p-0">
          <div className="m-2 row clearfix">
            
            {values.displayFor == "Amendment" ?
            <div className="col-sm-12">
            <Input 
              type="textarea" 
              name={`certification_info.0.certificate_amendment_note`}
              //value={values.certificate_amendment_note? values.certificate_amendment_note : null}
              value={values.certification_info && values.certification_info[0].certificate_amendment_note ? values.certification_info[0].certificate_amendment_note : null}
              onChange={(event) => {
                setFieldValue(`certification_info.0.certificate_amendment_note`, event.currentTarget.value);  
              }}
              required
            /></div> :   <div className="col-sm-12"><p>Your draft certificate is ready. In order to access e-Certificate, appreciate if your good side can clear the outstanding payment. </p>

            <p>Any matter raised in regards to the above notification, you may directly contact the person in charge or email us at <a href="mailto:msc@nioshcert.com.my">msc@nioshcert.com.my</a></p>
            
            {/* <b>Congratulations</b> on your achievement for obtaining Management System Standard Certification. This is an electronically generated certificate and kindly click on <b>Download Certificate</b> button below to download. */} </div>}                             
              
          </div>

          </ModalBody>{values.displayFor == "Amendment" ?
          <ModalFooter>
            <Button 
              type="submit"
              disabled={isSubmitting}
              color="primary" 
              >
              {isSubmitting ? 'Sending...' : 'Send'}
            </Button> 
            &nbsp;<Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
          : 
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
          }
        </FormikForm>
      )}
    </Formik>
  </Modal>  
  : null}

  

  </div>
  );
}

export default ViewDraftCertificate;