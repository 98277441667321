import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  // useLocation,
  useHistory,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  // useFormik, 
  Formik,
  Form as FormikForm,
  // FieldArray,
} from 'formik';

import moment from 'moment';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import { useToasts } from 'react-toast-notifications';

import { objectToFormData } from 'object-to-formdata';

import api from '../../../utils/api';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const Form = (props) => {
  let { id } = useParams();

  let history = useHistory();

  const [CERTIFICATIONS, setCertifications] = useState({});

  const [data, setData] = useState({});

  const { addToast } = useToasts();

  useEffect(() => {
    // console.log(props);

    // const getNaceCodes = async () => {
    //   let token = await localStorage.getItem('auth-token');

    //   token = JSON.parse(token);

    //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    //   api.get('/nace-codes')
    //   .then(function (response) {
    //     // console.log(response.data);

    //     setNaceCodes(response.data);
    //   })
    //   .catch(function (error) {
    //     // console.log(error.response);
    //     if (error && error.response && error.response.data) {
    //       addToast(error.response.data.message, { appearance: 'error' });
    //     } else {
    //       addToast(error.message, { appearance: 'error' });
    //     }
    //   });
    // }

    // getNaceCodes();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditors/' + id)
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          if (response.data.certifications) {
            let selectedCertifications = [];
            response.data.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.certifications = selectedCertifications;
          }

          setData(response.data);

          // if (!props.auditor) {
          //   if (response.data.signature && response.data.signature.length > 0)
          //   sigCanvas.fromData(response.data.signature);
          // }
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [props, id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

        if (id) {
          data.append('_method', 'PUT');

          api({
            method: 'POST',
            url: '/auditors/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response);

            addToast('Competency matrix has been updated', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            if (error && error.response && error.response.data) {
              addToast(error.response.data.message, { appearance: 'error' });
            } else {
              addToast(error.message, { appearance: 'error' });
            }
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>AUDITOR COMPETENCY LIST</h2>
                {/* <ul className="header-dropdown">
                  <li>
                    <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path}/create`}
                    >
                      Add New
                    </Button>
                  </li>
                </ul> */}
              </div>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="FullName">Full Name</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        name="full_name" 
                        id="FullName"
                        // onChange={handleChange}
                        value={values.full_name}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="">Management Systems</Label>
                      {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                disabled={true}
                                // readOnly={readOnly}
                                type="checkbox" 
                                name={`certifications`}
                                // onChange={handleChange}
                                // onChange={() => {
                                //   let { certifications } = values;

                                //   if (certifications && certifications.length) {
                                //     var index = certifications.indexOf(CERTIFICATION.id);
                                //     if (index !== -1) {
                                //       certifications.splice(index, 1);
                                //     } else {
                                //       certifications.push(CERTIFICATION.id);
                                //     }
                                //   } else {
                                //     certifications = [];
                                //     certifications.push(CERTIFICATION.id);
                                //   }

                                //   setFieldValue('certifications', certifications);
                                // }}
                                value={CERTIFICATION.id}
                                checked={values.certifications && values.certifications.length && values.certifications.indexOf(CERTIFICATION.id) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Type">Type</Label>
                      
                      <div>
                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="cm_type"
                            value="I"
                            onClick={() => {
                              setFieldValue('cm_type', 'I')
                            }}
                            checked={values.cm_type && values.cm_type ? values.cm_type === 'I' : ""}
                          />
                          <span>
                            <i></i> Initial Qualification
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="cm_type"
                            value="U"
                            onClick={() => {
                              setFieldValue('cm_type', 'U')
                            }}
                            checked={values.cm_type && values.cm_type ? values.cm_type === 'U' : ""}
                          />
                          <span>
                            <i></i> Upgrading
                          </span>
                        </label>
                      </div>
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="NACECode">NACE Code Apply</Label>
                      <Input 
                        // readOnly={true}
                        type="text" 
                        name="cm_nace_code" 
                        id="NACECode"
                        // onChange={handleChange}
                        value={values.cm_nace_code}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="MethodOfEvaluation">Method Of Evaluation</Label>
                      
                      <div>
                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="cm_method_of_evaluation"
                            value="I"
                            onClick={() => {
                              setFieldValue('cm_method_of_evaluation', 'W')
                            }}
                            checked={values.cm_method_of_evaluation && values.cm_method_of_evaluation ? values.cm_method_of_evaluation === 'W' : ""}
                          />
                          <span>
                            <i></i> Write Up
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="cm_method_of_evaluation"
                            value="I"
                            onClick={() => {
                              setFieldValue('cm_method_of_evaluation', 'I')
                            }}
                            checked={values.cm_method_of_evaluation && values.cm_method_of_evaluation ? values.cm_method_of_evaluation === 'I' : ""}
                          />
                          <span>
                            <i></i> Interview
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="cm_method_of_evaluation"
                            value="O"
                            onClick={() => {
                              setFieldValue('cm_method_of_evaluation', 'O')
                            }}
                            checked={values.cm_method_of_evaluation && values.cm_method_of_evaluation ? values.cm_method_of_evaluation === 'O' : ""}
                          />
                          <span>
                            <i></i> On site observation
                          </span>
                        </label>
                      </div>

                    </FormGroup>
                  </div>
                </div>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan={3}>SECTION A - GENERAL COMPETENCE REQUIREMENT</th>
                    </tr>
                    <tr>
                      <th>No.</th>
                      <th>General Competence Requirement</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      'Minimum - a diploma in the relevant disciplines',
                      'Pass  IRCA Lead auditor course for QMS/OSHMS/EMS/MSPO or any equivalents lead auditor course recognized by NIOSH Certification AEP',
                      'Minimum 3 years working experience in respective industry',
                      'Minimum 4 complete audits covering different facilities, processes and/or management systems',
                      'Know and understand the following concept Certification standards and guidelines :  ISO 9000, ISO 9001, ISO 9004 and ISO 19011',
                      'Fluent in Bahasa Malaysia and English both spoken and written.',
                      'Met the minimum requirements of respective NIOSHCert Competence Req',
                      'Know and understand the following concept ISO/IEC 17021-1 and ISO/IEC TS 17021-2, 3 &10 requirements as per Auditor Competence Requirements, CAS 08-09',
                    ].map((row, i) =>
                      <tr>
                        <td>{i + 1}</td>
                        <td style={{ whiteSpace: "pre-wrap" }}>{row}</td>
                        <td>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                // readOnly={true}
                                key={i}
                                type="checkbox" 
                                name="cm_section_a_status" 
                                // id=""
                                value={row}
                                onClick={handleChange}
                                checked={values.cm_section_a_status && values.cm_section_a_status.includes(row)} 
                              />
                              <span></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan={4}>SECTION B - SPECIFIC COMPETENCE REQUIREMENTS</th>
                    </tr>
                    <tr>
                      <th>No.</th>
                      <th style={{ width: "30%" }}>Specific Competence Requirements</th>
                      <th style={{ width: "30%" }}>Status</th>
                      <th style={{ width: "30%" }}>Comment By Evaluator</th>
                    </tr>
                  </thead>
                  <tbody>
                      {[
                        'Education<br /><span class="text-muted">Please state the Degree/ Diploma</span>',
                        'Work experience<br /><span class="text-muted">Please state the work experience related to the NACE Code</span>',
                        'Auditing experience / training<br /><span class="text-muted">Please identify companies and days of auditing and  list training attended</span>',
                        'Knowledge of business sector - Understanding of variation in size, scale and practices within the business sector<br /><span class="text-muted">Please elaborate in detail</span>',
                      ].map((row, i) => 
                        <tr>
                          <td>{i + 1}</td>
                          <td style={{ whiteSpace: "pre-wrap" }}>{htmlToReactParser.parse(row)}</td>
                          <td>
                            <Input 
                              // readOnly={true}
                              type="textarea" 
                              name={`cm_section_b_status.${i}`}
                              id=""
                              onChange={handleChange}
                              value={values.cm_section_b_status && values.cm_section_b_status[i] ? values.cm_section_b_status[i] : ''}
                              // required
                            />
                          </td>
                          <td>
                            <Input 
                              // readOnly={true}
                              type="textarea" 
                              name={`cm_section_b_comment.${i}`}
                              id=""
                              onChange={handleChange}
                              value={values.cm_section_b_comment && values.cm_section_b_comment[i] ? values.cm_section_b_comment[i] : ''}
                              // required
                            />
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan={3}>SECTION C - EVALUATION OF COMPETENCE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      'For auditor who apply NACE code under QMS, please fill up CAS 08-09-QMS (QMS competence assessment form)',
                      'For auditor who apply NACE code under OSHMS, please fill up CAS 08-09-OSHMS (OSHMS competence assessment form)',
                      'For auditor who apply NACE code under EMS, please fill up CAS 08-09-EMS (EMS competence assessment form)',
                      'Auditor must fill up the assessment form and brief in detail for evaluation of competence for the scope apply.',
                    ].map((row, i) =>
                      <tr>
                        <td>{i + 1}</td>
                        <td style={{ whiteSpace: "pre-wrap" }}>{row}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="OverallComment">Overall Comment and Recommendation</Label>
                      <Input 
                        type="textarea" 
                        name="cm_overall_comment" 
                        id="OverallComment"
                        onChange={handleChange}
                        value={values.cm_overall_comment}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="OnsiteWitnessEvaluation">On-site Witness Evaluation</Label>
                      <Input 
                        type="textarea" 
                        name="cm_onsite_witness_evaluation" 
                        id="OnsiteWitnessEvaluation"
                        onChange={handleChange}
                        value={values.cm_onsite_witness_evaluation}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Status">Status</Label>
                      <Input 
                        type="text" 
                        name="cm_status" 
                        id="Status"
                        onChange={handleChange}
                        value={values.cm_status}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

              </div>
            </Card>

            <Card>
              <div className="header">
                <h2>EVALUATED BY</h2>
                {/* <ul className="header-dropdown">
                  <li>
                    <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path}/create`}
                    >
                      Add New
                    </Button>
                  </li>
                </ul> */}
              </div>
              <div className="body">

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="EvaluatedBy">Name</Label>
                      <Input 
                        type="text" 
                        name="cm_evaluated_by" 
                        id="EvaluatedBy"
                        onChange={handleChange}
                        value={values.cm_evaluated_by}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="EvaluatorDesignation">Designation</Label>
                      <Input 
                        type="text" 
                        name="cm_evaluator_designation" 
                        id="EvaluatorDesignation"
                        onChange={handleChange}
                        value={values.cm_evaluator_designation}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="">Date</Label>
                      <DateRangePicker 
                        drops="up"
                        singleDatePicker
                        containerStyles={{ display: "block" }}
                        startDate={values.cm_evaluation_date && values.cm_evaluation_date.length ? moment(values.cm_evaluation_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => { 
                          setFieldValue('cm_evaluation_date', picker.startDate.format('YYYY-MM-DD'));
                          // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                          // setAuditStartDate(moment(picker.startDate));

                          // setAuditEndDate(moment(picker.endDate));
                        }}
                      >
                        <Input 
                          disabled={values.cm_evaluation_date && values.cm_evaluation_date.length}
                          autoComplete="off"
                          // readOnly={true}
                          type="text" 
                          // name="date_of_audit" 
                          // id="closed_date"
                          // onChange={handleChange}
                          value={values.cm_evaluation_date ? moment(values.cm_evaluation_date).format('DD/MM/YYYY') : ""}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                </div>

              </div>
            </Card>

            <Card>
              <div className="header">
                <h2>APPROVED BY</h2>
                {/* <ul className="header-dropdown">
                  <li>
                    <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path}/create`}
                    >
                      Add New
                    </Button>
                  </li>
                </ul> */}
              </div>
              <div className="body">

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="ApprovalRole">Role</Label>
                      <div>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="role"
                            value="Lead Auditor"
                            onClick={() => {
                              setFieldValue('role', 'Lead Auditor')
                            }}
                            checked={values.role && values.role ? values.role === 'Lead Auditor' : ""}
                          />
                          <span>
                            <i></i> Lead Auditor
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="role"
                            value="Lead Auditor in Training"
                            onClick={() => {
                              setFieldValue('role', 'Lead Auditor in Training')
                            }}
                            checked={values.role && values.role ? values.role === 'Lead Auditor in Training' : ""}
                          />
                          <span>
                            <i></i> Lead Auditor in Training
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="role"
                            value="Auditor"
                            onClick={() => {
                              setFieldValue('role', 'Auditor')
                            }}
                            checked={values.role && values.role ? values.role === 'Auditor' : ""}
                          />
                          <span>
                            <i></i> Auditor
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="role"
                            value="Audit in Training"
                            onClick={() => {
                              setFieldValue('role', 'Audit in Training')
                            }}
                            checked={values.role && values.role ? values.role === 'Audit in Training' : ""}
                          />
                          <span>
                            <i></i> Auditor in Training
                          </span>
                        </label>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="ApprovalStatus">Status</Label>
                      <div>
                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="cm_approval_status"
                            value="A"
                            onClick={() => {
                              setFieldValue('cm_approval_status', 'A')
                            }}
                            checked={values.cm_approval_status && values.cm_approval_status ? values.cm_approval_status === 'A' : ""}
                          />
                          <span>
                            <i></i> Approved
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="cm_approval_status"
                            value="N"
                            onClick={() => {
                              setFieldValue('cm_approval_status', 'N')
                            }}
                            checked={values.cm_approval_status && values.cm_approval_status ? values.cm_approval_status === 'N' : ""}
                          />
                          <span>
                            <i></i> Not Approved
                          </span>
                        </label>
                      </div>
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="ApprovedBy">Name</Label>
                      <Input 
                        type="text" 
                        name="cm_approved_by" 
                        id="ApprovedBy"
                        onChange={handleChange}
                        value={values.cm_approved_by}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="ApproverDesignation">Designation</Label>
                      <Input 
                        type="text" 
                        name="cm_approver_designation" 
                        id="ApproverDesignation"
                        onChange={handleChange}
                        value={values.cm_approver_designation}
                        // required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="">Date</Label>
                      <DateRangePicker 
                        drops="up"
                        singleDatePicker
                        containerStyles={{ display: "block" }}
                        startDate={values.cm_approved_date && values.cm_approved_date.length ? moment(values.cm_approved_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => { 
                          setFieldValue('cm_approved_date', picker.startDate.format('YYYY-MM-DD'));
                          // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                          // setAuditStartDate(moment(picker.startDate));

                          // setAuditEndDate(moment(picker.endDate));
                        }}
                      >
                        <Input 
                          disabled={values.cm_approved_date && values.cm_approved_date.length}
                          autoComplete="off"
                          // readOnly={true}
                          type="text" 
                          // name="date_of_audit" 
                          // id="closed_date"
                          // onChange={handleChange}
                          value={values.cm_approved_date ? moment(values.cm_approved_date).format('DD/MM/YYYY') : ""}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={isSubmitting ? true : false}
                    >
                      {id ? 'Update' : 'Create'}
                    </button>&nbsp;
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>

              </div>
            </Card>

          </div>
        </div>
      </FormikForm>
    )}
    </Formik>
  )
}

export default Form;