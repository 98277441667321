import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  // Badge,
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import Select from 'react-select'

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import api from '../../utils/api';

import { useToasts } from 'react-toast-notifications';

// import { objectToFormData } from 'object-to-formdata';

import qs from 'query-string';

const INITIAL_QUERY = {
  // name: "",
  role: '',
  client_id: ''
}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 5,
  totalSize: 1,
  hideSizePerPage: true,
  pageStartIndex: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  let history = useHistory();
  let queryString = useQuery();

  let [profile, setProfile] = useState({});
  const [refresh, setRefreshing] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);
  const [roles, setRole] = useState([]);
  const [clients, setClient] = useState([]);

  const { addToast } = useToasts();

  const columns = [
  {
    editable: false,
    text: "No",
    formatter: (cell, row, rowIndex) => {
     let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
      return <span>{rowNumber}.</span>;
    }
  },
  {
    dataField: 'name',
    text: 'Name',
    style: {
      whiteSpace: 'pre-wrap',
    },
    // formatter: (cell, row, rowIndex) => {
    //   return (
    //     {cell}
    //   )
    // },
  },
  {
    dataField: 'email',
    text: 'Email',
    style: {
      whiteSpace: 'pre-wrap',
    },
    // formatter: (cell, row, rowIndex) => {
    //   return (
    //     {cell}
    //   )
    // },
  },
  // {
  //   text: 'Group',
  // },
  {
    dataField: 'roles[0].name',
    text: 'Group',
    formatter: (cell, row, rowIndex) => {
      return row.roles[0].name.replace('NCSB', 'NioshCert');
    },
  },
  {
    text: 'Client',
    dataField: 'client.name',
  },
  {
    dataField: 'id',
    text: ' ',
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          <Button outline tag={Link} to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'user.update') ?
          <Button outline tag={Link} to={`${path}/edit/${cell}`}>
          <i className="icon-pencil"></i>
          </Button>
          : null}
          {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'user.delete') ?
          <Button 
            disabled={cell <= 2 ? true : false}
            outline 
            color="danger" 
            onClick={() => deleteHandler(cell)}>
            <i className="icon-trash"></i>
          </Button>
          : null}
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getRoles = async () => {
      let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/roles')
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          setRole(response.data);
        })
        .catch(function (error) {

        });
    }

    getRoles();
    
    const getClients = async () => {
      let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/clients?limit=-1')
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          // setClient(response.data.data);
          
          let clients = [];
          response.data.data.map((client, i) => clients.push({
            label: client.name,
            value: client.id,
            // contact_no: auditor.contact_no,
          }));

          setClient(clients);
        })
        .catch(function (error) {

        });
    }

    getClients();

    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/users?' + queryString.toString(), {
        params: query,
      })
      .then(function (response) {
        // console.log(response);

        setData(response.data.data);
        console.log(response.data.data);
        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });

      setRefreshing(false);
    }

    getData();
  }, [query, refresh]);

  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete('/users/' + id)
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        addToast('User successfully deleted', { appearance: 'success' });

        history.push(path);

        setRefreshing(true);
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <Card>
          <div className="header">
            <h2>USER LIST</h2>
            {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'user.create') ?
            <ul className="header-dropdown">
              <li>
                <Button 
                  className="m-t-10 m-l-20"
                  color="primary" 
                  tag={Link} 
                  to={`${path}/create`}
                >
                  Add New
                </Button>
              </li>
            </ul>
            : null}
          </div>
          <div className="body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: queryString.get('name') ? queryString.get('name') : "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                history.push({
                  pathname: path,
                  search: searchString
                });

                setQuery(values);
              }}
            >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
              <FormikForm>

                {/* <FormGroup>
                  <Label for="Role">Group</Label>
                  <Input 
                    // readOnly={readOnly}
                    type="select" 
                    className="custom-select" 
                    name="role_id" 
                    id="Role"
                    onChange={handleChange}
                    value={values.role_id}
                  >
                    <option></option>
                    {roles.map((role, i) => 
                      <option value={role.id}>{role.name}</option>
                    )}
                  </Input>
                </FormGroup> */}
                    
                <FormGroup>
                  <Label for="Name">Name</Label>
                  <Input 
                    type="text" 
                    // className="custom-select" 
                    name="name" 
                    id="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </FormGroup>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Email">Email</Label>
                      <Input 
                        // readOnly={readOnly}
                        type="text" 
                        // className="custom-select" 
                        name="email" 
                        id="Email"
                        onChange={handleChange}
                        value={values.email}
                      />
                    </FormGroup>
                  </div>
                </div>
                  
                <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Role">Group</Label>
                        <Input 
                          // readOnly={readOnly}
                          type="select" 
                          className="custom-select" 
                          name="role" 
                          id="Role"
                          onChange={(e) => {
                            // console.log(role);
                            
                            setFieldValue('role', e.currentTarget.value);
                            
                            if (parseInt(e.currentTarget.value) !== 3) {
                              setFieldValue('client_id', '');
                            }
                          }}
                          value={values.role}
                        >
                          <option></option>
                          {roles.map((role, i) => 
                            <option value={role.id}>{role.name.replace('NCSB', 'NioshCert')}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                {values.role && parseInt(values.role) === 3 ?
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Client">Client</Label>
                      <Select
                        inputId="Client"
                        name="client"
                        // isMulti
                        placeholder=""
                        openMenuOnFocus={true}
                        options={clients} 
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            color: '#495057',
                            backgroundColor: 'transparent', 
                            borderColor: '#ced4da !important', 
                            boxShadow: 'none',
                            outline: 'none',
                          })
                        }}
                        
                        onChange={(selectedClient) => {
                          // console.log(e);
                          setFieldValue('client', JSON.stringify(selectedClient));
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>
                : null}

                <div className="row clearfix">
                  <div className="col-sm-4">
                    <Label>&nbsp;</Label>
                    <FormGroup>
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        // tag={Link} 
                        // to="/client-database"
                      >
                        Search
                      </Button>
                      <Button 
                        // className="btn btn-outline-secondary"
                        type="button"
                        outline
                        color="link" 
                        // tag={Link} 
                        // to={`${path}`}
                        onClick={() => {
                          history.push({
                            pathname: path,
                          });
                          
                          setFieldValue('name', '');
                          setFieldValue('email', '');
                          setFieldValue('role', '');
                          setFieldValue('client_id', '');
          
                          setQuery(INITIAL_QUERY);
                        }}
                      >
                        Reset
                      </Button>
                    </FormGroup>
                  </div>
                </div>
              </FormikForm>
              )}
            </Formik>

            <hr />
          </div>
          <div className="body project_report">
            <div className="table">
              <BootstrapTable 
                remote={true}
                bootstrap4
                bordered={false}
                classes="table-hover js-basic-example dataTable table-custom m-b-0"
                headerClasses="thead-dark"
                keyField='id' 
                data={ data } 
                columns={ columns }
                pagination={ 
                  paginationFactory(pagingInfo)
                }
                onTableChange={handleTableChange}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Listing;