import React, { useEffect, useState } from "react";

import {
  // Link,
  useParams,
} from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';

import {
  // Button,
  Card,
  FormGroup,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

const PerformanceToDate = () => {
  let { id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  useEffect(() => {
    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          setData(response.data.data);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          api.put('/audits/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            addToast('Audit successfully updated', { appearance: 'success' });
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card>
              <div className="header">
                <h2>Performance To Date</h2>
              </div>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <div className="table">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={[]} 
                          columns={[
                            {
                              "text": "Non Conformity No.",
                            },
                            {
                              "text": "Non Conformity Description"
                            },
                            {
                              "text": "Process",
                            },
                            {
                              "text": "Standard",
                            },
                            {
                              "text": "Clause",
                            },
                            {
                              "text": "Grade",
                            },
                            {
                              "text": "Issue Date",
                            },
                            {
                              "text": "Date Of Completion",
                            },
                            {
                              "text": "Verification Of Corrective Action",
                            },
                            {
                              "text": "",
                            },
                          ]}
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        />
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default PerformanceToDate;