import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  // useParams,
  useLocation,
} from 'react-router-dom';

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import qs from 'query-string';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}
const STATES = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Wilayah Persekutuan Kuala Lumpur',
  'Wilayah Persekutuan Labuan',
  'Wilayah Persekutuan Putrajaya',
];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  let history = useHistory();
  let queryString = useQuery();

  const [profile, setProfile] = useState({});
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const { addToast } = useToasts();

  const columns = [
    {
      editable: false,
      text: "No",
      formatter: (cell, row, rowIndex) => {
       let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
        return <span>{rowNumber}.</span>;
      }
    },
    {
      text: 'State',
      formatter: (cell, row, rowIndex) => {
          return row.audit_plan && row.audit_plan.client &&  row.audit_plan.client.state ?  row.audit_plan.client.state : "" ;
      }
    },
    {
      text: 'Organization Name',
      formatter: (cell, row, rowIndex) => {
          return row.audit_plan && row.audit_plan.client &&  row.audit_plan.client.name ? row.audit_plan.client.name :"" ;
      }
    },
    {
      text: 'Auditor Name',
      formatter: (cell, row, rowIndex) => {
          return row.auditor ?  row.auditor.full_name : "" ;
      }
    },
    {
      text: 'Invoice No.',
      formatter: (cell, row, rowIndex) => {
          return row.invoice_no ?  row.invoice_no : "" ;
      }
    },
  {
    text: 'Date of Audit',
    formatter: (cell, row, rowIndex) => {
      return row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ? moment(row.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""
    }
  },
  
  {
    text: 'Claim Date',
    formatter: (cell, row, rowIndex) => {
      return row.created_at  ? moment(row.created_at).format('DD/MM/YYYY') : ""
    }
  },  {
    text: 'Approved Date',
    formatter: (cell, row, rowIndex) => {
      return row.approved_at  ? moment(row.approved_at).format('DD/MM/YYYY') : ""
    }
  },{
    text: 'Total Amount (RM)',
    formatter: (cell, row, rowIndex) => {
      return row.total_amount  ? row.total_amount : "";
    }
  }, {
    text: 'Status',
    formatter: (cell, row, rowIndex) => {
      let statusColor="success";
      
        if(row.status && row.status == 1 && row.action == 'save_draft')
            statusColor = "info";
        if(row.status && (row.status==2 || row.status==3)){
          statusColor = "warning";
        }
        if(row.status && (row.status==5 || row.status==6)){
          statusColor="danger";
        }
             
        return (<span><Badge color={statusColor}>{row.status_text}</Badge></span>)
      
      
     
    }
  },
  {
    dataField: 'id',
    text: ' ',
    csvExport: false,
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          
          <Button outline tag={Link}  to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          {row.status == 1 && row.action && row.action=="save_draft" ?
          <Button outline tag={Link}  to={`${path}/edit/${cell}`}>
            <i className="icon-pencil"></i>
          </Button>
          : ''}
          {row.action && row.action=="save_draft" ?
          <Button outline color="danger" onClick={() => deleteHandler(cell)}>
            <i className="icon-trash"></i>
          </Button>
          : ''} 
          {row.status == 2 && row.reviewed_by == profile.id /* && permissions && permissions.find(o => o.name.toLowerCase() === 'auditor_claim.review') */ ?
          <OverlayTrigger
          placement="top"
          overlay={
          <Tooltip id={`tooltip-1`}>
            Review
          </Tooltip>
          }
        >
           <Button outline tag={Link} to={`${path}/review/${cell}`}>
              <i className="icon-note"></i>
            </Button>
            </OverlayTrigger>
          : null}
          {row.status == 3 && row.approved_by == profile.id /* && permissions && permissions.find(o => o.name.toLowerCase() === 'auditor_claim.approve')  */ ?
          <OverlayTrigger
            placement="top"
            overlay={
            <Tooltip id={`tooltip-1`}>
              Approve
            </Tooltip>
            }
          >
           <Button outline tag={Link} to={`${path}/approval/${cell}`}>
              <i className="icon-check"></i>
            </Button>
          </OverlayTrigger>
          : null}
          {(row.status == 5 || row.status == 6) && row.auditor_id == profile.auditor_id /* && permissions && permissions.find(o => o.name.toLowerCase() === 'auditor_claim.approve')  */ ?
          <OverlayTrigger
            placement="top"
            overlay={
            <Tooltip id={`tooltip-1`}>
              Edit
            </Tooltip>
            }
          >
           <Button outline tag={Link} to={`${path}/edit/${cell}`}>
              <i className="icon-pencil"></i>
            </Button>
          </OverlayTrigger>
          : null}
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
         setProfile(response.data);
         console.log(response.data)
      })
      .catch(function (error) {
      });
    }

    getMe();
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditor-claims', {
        params: query,
      })
      .then(function (response) {
       //  console.log(response);

        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getData();
  }, [props, query]);

  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete('/auditor-claims/' + id)
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        addToast('Claim successfully deleted', { appearance: 'success' });

        history.push(path);
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <SidebarToggler />
                Auditor Claim
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Auditor
                </li>
                <li className="breadcrumb-item">
                  <Link to={props.path}>
                    Auditor Claim
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>AUDITOR CLAIM LIST</h2>
                {<ul className="header-dropdown">
                  <li>
                    <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path}/create`}
                    >
                      Add New
                    </Button>
                  </li>
                </ul>}
              </div>
              <div className="body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                client_name  : queryString.get('client_name') ? queryString.get('client_name') : '',
                auditor_name  : queryString.get('auditor_name') ? queryString.get('auditor_name') : '',
                invoice_no: queryString.get('invoice_no') ? queryString.get('invoice_no') : "",
                start: queryString.get('start') ? queryString.get('start') : "",
                end: queryString.get('end') ? queryString.get('end') : "",
                state: queryString.get('state') ? queryString.get('state') : "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});
                setQuery(values);

                history.push({
                  pathname: path,
                  search: searchString
                });

                setQuery(values);
              }}
            >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
              <FormikForm>
                <div className="row clearfix">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <FormGroup>
                      <Label for="Name">Organization Name</Label>
                      <Input 
                        type="text" 
                        // className="custom-select" 
                        name="client_name"
                        id="Name"
                        onChange={handleChange}
                        value={values.client_name}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <FormGroup>
                      <Label for="Name">Auditor Name</Label>
                      <Input 
                        type="text" 
                        // className="custom-select" 
                        name="auditor_name"
                        id="Name"
                        onChange={handleChange}
                        value={values.auditor_name}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <FormGroup>
                      <Label for="invoice_no">Invoice No</Label>
                      <Input 
                        type="text" 
                        name="invoice_no"
                        id="invoice_no"
                        onChange={handleChange}
                        value={values.invoice_no}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <FormGroup>
                      <Label for="Date">Date of Audit</Label>
                      <DateRangePicker 
                        // disabled={readOnly}
                        initialSettings={{
                          timePicker: false
                        }}
                        timePicker={false}
                        timePickerSeconds={false}
                        containerStyles={{ display: "block" }}
                        start={values.start && values.start.length ? moment(values.start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        end={values.end && values.start.length ? moment(values.end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => { 
                          setFieldValue('start', picker.startDate.format('YYYY-MM-DD'));
                          setFieldValue('end', picker.endDate.format('YYYY-MM-DD'));
                        }}
                      >
                        <Input 
                          autoComplete="off"
                        //  readOnly={readOnly}
                          type="text" 
                          // name="date_of_audit" 
                          id="date"
                          // onChange={handleChange}
                          value={values.start && values.end ? moment(values.start).format('DD/MM/YYYY') + ' - ' + moment(values.end).format('DD/MM/YYYY') : ""}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormGroup>
                      <Label for="invoice_no">State</Label>
                      <Input 
                        type="select" 
                        className="custom-select" 
                        name="state" 
                        id="state"
                        onChange={handleChange}
                        value={values.state}
                        
                      >
                        <option></option>
                        {STATES.map((STATE, key) => <option key={key} value={STATE}>{STATE}</option>)}
                      </Input>
                    </FormGroup>
                  </div>
              </div>
                
                <div className="row clearfix">
                  <div className="col-sm-4">
                    <Label>&nbsp;</Label>
                    <FormGroup>
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        // tag={Link} 
                        // to="/client-database"
                      >
                        Search
                      </Button>
                      <Button 
                        // className="btn btn-outline-secondary"
                        type="button"
                        outline
                        color="link" 
                        // tag={Link} 
                        // to={`${path}`}
                        onClick={() => {
                          history.push({
                            pathname: path,
                          });
          
                          setQuery(INITIAL_QUERY);
                        }}
                      >
                        Reset
                      </Button>
                    </FormGroup>
                  </div>
                </div>
              </FormikForm>
              )}
            </Formik>

            <hr />
          </div>
              <div className="body project_report">
                <div className="table">
                  <BootstrapTable 
                    remote={true}
                    bootstrap4
                    bordered={false}
                    classes="table-hover js-basic-example dataTable table-custom m-b-0"
                    headerClasses="thead-dark"
                    keyField="id"
                    data={profile && profile.roles && profile.roles[0].name.toLowerCase() != 'auditor' ? data.filter(o => o.action !== 'save_draft') : data}

                  //  data={ data } 
                    columns={ columns }
                    pagination={ 
                      paginationFactory(pagingInfo)
                    }
                    onTableChange={handleTableChange}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Listing;