import React, { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  // useLocation,
  useParams,
  // useRouteMatch,
} from 'react-router-dom';

// import {
//   InputGroup,
// } from 'react-bootstrap';

import {
  // ButtonGroup,
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import { objectToFormData } from 'object-to-formdata';

// import DateRangePicker from 'react-bootstrap-daterangepicker';

import moment from 'moment';

const INITIAL_VALUES = {};

const Form = (props) => {
  let { id } = useParams();
  let history = useHistory();

  const [data, setData] = useState(INITIAL_VALUES);

  const [auditors, setAuditor] = useState([]);

  // const [profile, setProfile] = useState({});

  const { addToast } = useToasts();

  let { readOnly }  = props;

  useEffect(() => {
    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditors?limit=-1')
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          setAuditor(response.data.data);
        })
        .catch(function (error) {

        });
    }

    getAuditors();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditor-evaluations/' + id)
        .then(function (response) {
          console.log(response.data);
          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`Auditor evaluation #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    } else {
      const getClient = async (client_id) => {
        let token = await localStorage.getItem('auth-token');
  
        token = JSON.parse(token);
  
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
  
        await api.get('/clients/' + client_id)
        .then(function (response) {
          
          // let oldData = {
          //   client_id: response.data.id,
          //   old_company_name: response.data.name,
          //   old_company_address: response.data.address_1,
          //   old_no_of_employees: 0,
          //   old_no_of_sites: 0,
          //   old_mr_name: response.data.management_representative_name,
          //   old_scope_of_certification: response.data.scope_of_certification,
          // }
  
          // setData(oldData);

          setData({
            client: {
              sector: response.data.sector,
              name: response.data.name,
            }
          });
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`Client Data ID #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;
  
              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
  
          // reload(false);
        });
        
      }

      const getMe = async () => {
        let token = await localStorage.getItem('auth-token');
  
        token = JSON.parse(token);
  
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
  
        await api.post('/auth/me')
        .then(function (response) {
          // localStorage.setItem('auth-user', JSON.stringify(response.data));
  
          // setProfile(response.data);
  
          // console.log(response.data.client_id);
  
          if (!id && response.data.client_id) {
            getClient(response.data.client_id);
          }
  
          // if (!id) {
          //   if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client_data.create') === "undefined") {
          //     history.push(props.path);
          //   }
          // } else {
          //   if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client_data.update') === "undefined") {
          //     history.push(props.path);
          //   }
          // }
        })
        .catch(function (error) {
          // if (error && error.response && error.response.data) {
          //   if (error.response.data.message === 'Unauthenticated.') {
          //     localStorage.removeItem('auth-token');
          //     localStorage.removeItem('auth-user');
          //     setAuthState('');
          //   }
          // } else {
          //   console.log(error.message);
          // }
        });
      }
  
      getMe();
    }
  }, [id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

        if (!id) {
          await api({
            method: 'POST',
            url: '/auditor-evaluations',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response);

            addToast('Auditor evaluation form successfully saved', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        } else {
          data.append('_method', 'PUT');

          data.append('date', moment().format('YYYY-MM-DD'));

          await api({
            method: 'POST',
            url: '/auditor-evaluations/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response);

            addToast('Auditor evaluation form has been updated', { appearance: 'success' });

            history.push(props.path);
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>AUDITOR EVALUATION DETAILS</h2>
              </div>
              <div className="body">

              <FormGroup>
                <Label for="">Company Name</Label>
                <Input
                  readOnly={true}
                  type="text"
                  defaultValue={values.client && values.client.name ? values.client.name : ""}
                />
              </FormGroup>

              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="">Audit No.</Label>
                    <Input
                      readOnly={true}
                      type="text"
                      name="audit_no"
                      onChange={handleChange}
                      value={values.audit_plan && values.audit_plan.audit_no ? values.audit_plan.audit_no : ""}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="">Audit Type</Label>
                    <Input
                      readOnly={true}
                      type="text"
                      name="stage"
                      onChange={handleChange}
                      value={values.audit_plan && values.audit_plan.stage ? values.audit_plan.stage : ""}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="AuditDate">Audit Date</Label>
                    {/* <DateRangePicker 
                      disabled={true}
                      drops="up"
                      singleDatePicker={true}
                      containerStyles={{ display: "block" }}
                      startDate={values.audit_date && values.audit_date.length ? moment(values.audit_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                      // endDate={values.last_audit_date_end && values.last_audit_date_end.length ? moment(values.last_audit_date_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                      onApply={(event, picker) => {
                        // console.log(picker.startDate.format('YYYY-MM-DD'));
                        // console.log(picker.endDate.format('YYYY-MM-DD'));

                        // setFieldValue('last_audit', picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                        
                        setFieldValue('audit_date', picker.startDate.format('YYYY-MM-DD'));
                        // setFieldValue('last_audit_date_end', picker.endDate.format('YYYY-MM-DD'));
                        
                      }}
                    > */}
                      <Input 
                        autoComplete="off"
                        // readOnly={true}
                        disabled={true}
                        type="text" 
                        // name="last_audit" 
                        // id="LastAudit"
                        // onChange={handleChange}
                        value={values.audit_plan && values.audit_plan.date_of_audit_start && values.audit_plan.date_of_audit_end? moment(values.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(values.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""}
                      />
                    {/* </DateRangePicker> */}
                  </FormGroup>
                </div>
              </div>

              <FormGroup>
                <Label for="">Auditor Name</Label>
                <Input
                  // readOnly={true}
                  disabled={true}
                  type="select"
                  name="auditor_id"
                  onChange={handleChange}
                  value={values.auditor_id}
                >
                  <option></option>
                  {auditors && auditors.length ? auditors.map((auditor, i) => 
                    <option value={auditor.id}>{auditor.full_name}</option>
                  ) : null}
                </Input>
              </FormGroup>

              <table className="table">
                  <thead>
                    <tr>
                      <th>Auditor</th>
                      <th>Excellent</th>
                      <th>Good</th>
                      <th>Need Improvement</th>
                      <th>Poor</th>
                      <th>N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        field: '',
                        label: 'Punctuality',
                      },
                      {
                        field: '',
                        label: 'Knowledgeable, Informative'
                      },
                      {
                        field: '',
                        label: 'Diplomatic',
                      },
                      {
                        field: '',
                        label: 'Helpful',
                      },
                      {
                        field: '',
                        label: 'Oral communication',
                      },
                      {
                        field: '',
                        label: 'Relevance of question',
                      },
                      {
                        field: '',
                        label: 'Attire',
                      }
                    ].map((item, i) =>
                      <tr>
                        <td>{item.label}</td>
                        {['4', '3', '2', '1', '0'].map((score, j) =>
                        <td style={{ textAlign: "center" }}>
                          <label className="fancy-radio">
                            <Input
                              required={true}
                              disabled={readOnly}
                              type="radio"
                              name={`auditor_${i}`}
                              value={score}
                              onClick={() => {
                                setFieldValue(`auditor_${i}`, score)
                              }}
                              checked={values[`auditor_${i}`] ? parseInt(values[`auditor_${i}`]) === parseInt(score) : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>

                <table className="table">
                  <thead>
                    <tr>
                      <th>Audit</th>
                      <th>Excellent</th>
                      <th>Good</th>
                      <th>Need Improvement</th>
                      <th>Poor</th>
                      <th>N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        field: '',
                        label: 'The assessment team was on time',
                      },
                      {
                        field: '',
                        label: 'During the opening meeting, the assessment team explained the objectives, assessment program and attended to your concerns'
                      },
                      {
                        field: '',
                        label: 'The assessor\’s demonstrated technical proficiency in the area assessed',
                      },
                      {
                        field: '',
                        label: 'The assessment team demonstrated courtesy, professionalism and constructive & positive approach.',
                      },
                      {
                        field: '',
                        label: 'You or your staff members were adequately informed of the assessment finding/major issues/NCR raised.',
                      },
                      {
                        field: '',
                        label: 'You had the opportunity to provide explanations or responses to assessment findings as they were raised during the assessment process.',
                      },
                      {
                        field: '',
                        label: 'During the closing meeting, all findings/NCRs were adequately discussed/explained.',
                      },
                      {
                        field: '',
                        label: 'The summary report was clear/accurate and acceptable.',
                      },
                      {
                        field: '',
                        label: 'The assessment team conclusion on your compliance to certification was clear and relevant.',
                      }
                    ].map((item, i) =>
                      <tr>
                        <td>{item.label}</td>
                        {['4', '3', '2', '1', '0'].map((score, j) =>
                        <td style={{ textAlign: "center" }}>
                          <label className="fancy-radio">
                            <Input
                              required={true}
                              disabled={readOnly}
                              type="radio"
                              name={`audit_${i}`}
                              value={score}
                              onClick={() => {
                                setFieldValue(`audit_${i}`, score)
                              }}
                              checked={values[`audit_${i}`] ? parseInt(values[`audit_${i}`]) === parseInt(score) : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>

                <FormGroup>
                  <Label for="Comment">Overall Comment</Label>
                  <Input 
                    // readOnly={readOnly}
                    disabled={readOnly}
                    type="textarea" 
                    name="comment" 
                    id="Comment" 
                    onChange={handleChange}
                    value={values.comment} 
                    rows={5}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="Date">Date</Label>
                  {/* <DateRangePicker 
                    disabled={readOnly}
                    drops="up"
                    singleDatePicker={true}
                    containerStyles={{ display: "block" }}
                    startDate={values.date && values.date.length ? moment(values.date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                    // endDate={values.last_audit_date_end && values.last_audit_date_end.length ? moment(values.last_audit_date_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                    onApply={(event, picker) => {
                      // console.log(picker.startDate.format('YYYY-MM-DD'));
                      // console.log(picker.endDate.format('YYYY-MM-DD'));

                      // setFieldValue('last_audit', picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                      
                      setFieldValue('date', picker.startDate.format('YYYY-MM-DD'));
                      // setFieldValue('last_audit_date_end', picker.endDate.format('YYYY-MM-DD'));
                      
                    }}
                  > */}
                    <Input 
                      autoComplete="off"
                      readOnly={readOnly}
                      disabled={readOnly}
                      type="text" 
                      // name="last_audit" 
                      // id="LastAudit"
                      // onChange={handleChange}
                      value={values.date && values.date ? moment(values.date).format('DD/MM/YYYY') : (!readOnly ? moment().format('DD/MM/YYYY') : '')}
                    />
                  {/* </DateRangePicker> */}
                </FormGroup>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    {!readOnly ? 
                    <React.Fragment>
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={isSubmitting ? true : false}
                    >
                      {id ? 'Submit' : 'Submit'}
                    </button>&nbsp;
                    </React.Fragment>
                    : null}
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                
              </div>
            </Card>
          </div>
        </div>
      </FormikForm>
      )}
    </Formik>
  )
}

export default Form;