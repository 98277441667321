import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useToasts } from 'react-toast-notifications';

import AuthContext from './../../authContext';

import api from '../../utils/api';

import './style.css';

const Login = () => {
  let [login, setLogin] = useState({});
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const { setAuthState } = useContext(AuthContext);

  const { addToast } = useToasts();

  const handleSubmit = async (e) => {
    e.preventDefault();

    document.getElementsByClassName('page-loader-wrapper')[0].style = 'display: block';

    const querystring = require('querystring');

    await api.post('/auth/login', querystring.stringify(login), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(function (response) {
     

      api.post('/auth/me', null, {
        headers: {
          Authorization: 'Bearer ' + response.data.access_token,
        }
      })
      .then(function (response) {
       

        localStorage.setItem('auth-user', JSON.stringify(response.data));
      })
      .catch(function (error) {
        
      });

      setAuthState('signIn');
      
      localStorage.setItem('auth-token', JSON.stringify(response.data));

      history.replace(from);
    })
    .catch(function (error) {
      if(error && error.response && error.response.data){
        addToast(error.response.data.error, { appearance: 'error' });
      }
      else{
        addToast(error.message, { appearance: 'error' });
      }
    })
    .then(function () {
      document.getElementsByClassName('page-loader-wrapper')[0].style = 'display: none';
    });

    // setTimeout(() => {
    //   setAuthState('signIn');

    //   history.replace(from);
    // }, 100);
  }

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    login[name] = value;
    setLogin(login);

  }

  return (
    <div className="container vh-100 d-flex align-items-center justify-content-center">
      <div className="row">
        <div class="col-md-5 px-3">
          <div className="top mb-3">
            <img src="/nioshcert.png" className="img-fluid" alt="" />
          </div>
          <Card className="bg-success rounded-5">
            <div className="header">
              <h3 className="text-center text-white my-2">Login Account</h3>
            </div>
            <div className="body">
              <form className="form-auth-small" onSubmit={handleSubmit}>
                <FormGroup className="mb-5 input-group-lg">
                  <Label className="control-label sr-only">Email</Label>
                  <Input bsSize="lg" type="email" name="email" placeholder="Email" className="rounded-20px" onChange={handleInputChange} />
                </FormGroup>
                <FormGroup className="input-group-lg">
                  <Label className="control-label sr-only">Password</Label>
                  <Input bsSize="lg" type="password" name="password" placeholder="Password" className="rounded-20px" onChange={handleInputChange} />
                </FormGroup>
                <FormGroup className="clearfix">
                  <Label className="fancy-checkbox element-left">
                    <Input type="checkbox" name="remember" value="true" onChange={handleInputChange} />{' '}
                    <span className="text-white">Remember me</span>
                  </Label>
                </FormGroup>
                <div className="text-center mb-2">
                  <Button size="lg" color="light" className="text-success rounded-20px" style={{ fontSize: '1.25rem' }}>Login</Button>
                </div>
                <div className="bottom">
                  <p className="helper-text m-b-10 text-white text-center">
                    <i className="fa fa-lock"></i> <Link className="text-white" to="/forgot-password">Forgot password?</Link>
                  </p>
                </div>
              </form>
            </div>
          </Card>
        </div>
        <div class="col-md-7 d-none d-md-block text-center">
          <img src="/bg.png" className="img-fluid" alt="" style={{ width: '800px' }} />
        </div>
      </div>
    </div>
  )
}

export default Login;