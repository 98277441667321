import React from 'react';

import {
  // Link,
  Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Listing from './Listing';

const ContractReview = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect
          to={{
              pathname: `${path}/iso`,
              // state: { from: props.location }
          }}
        />
      </Route>
      {/* <Route path={`${path}/part-3`}>
        <Listing type="Part 3" />
      </Route> */}
      <Route path={`${path}/mspo`}>
        <Listing type="MSPO" />
      </Route>
      <Route path={`${path}/mspo-sccs`}>
        <Listing type="MSPO-SCCS" />
      </Route>
      <Route path={`${path}/iso`}>
        <Listing type="ISO" />
      </Route>
    </Switch>
  )
}

export default ContractReview;