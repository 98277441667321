import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import classnames from 'classnames';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

import moment from 'moment';
import { objectToFormData } from 'object-to-formdata';
const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();
/*
 const INITIAL_PEER_REVIEW = {
  review_date: '',
  status_of_summary_report_comments: '',
  introduction_comments: '',
  scope_comments: '',
  information_of_audit_team_comments: '',
  management_commitment_comments: '',
  transparency_comments: '',
  compliance_to_legal_requirements_comments: '',
  social_responsibility_health_employee_comments: '',
  environment_natural_resources_comments: '',
  best_practices_comments: '',
  development_of_new_planting_comments: '',
  remarks: '',
} */

const Form = (props) => {
  let { id, client_id } = useParams();

  let history = useHistory();

  let [profile, setProfile] = useState({});

  let [token, setToken] = useState('');

  const { addToast } = useToasts();

  const [load, setLoaded] = useState(false);

  const [data, setData] = useState({});
  const [reference, setReference] = useState([]);
  const [peerReviewData, setPeerReviewData] = useState([]);

  const [CERTIFICATIONS, setCertifications] = useState({});
    
  const [auditors, setAuditor] = useState([]);

  const [USERS, setUser] = useState([{}]);

  let { readOnly, assign, approval, review }  = props;

  const [activeTab, setActiveTab] = useState('0');

  const toggle = tab => {

    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);
      
      setToken(token.access_token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
         setProfile(response.data);
        /* if (!readOnly && response.data.data.permissions && typeof response.data.data.permissions.find(o => o.name.toLowerCase() === 'peer_review.update') === "undefined") {
          history.push(props.path);
        } */
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();
      
      const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=private_mobile&limit=-1')
      .then(function (response) {
        // console.log(response.data);

        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.full_name,
          value: auditor.id,
          contact_no: auditor.contact_no,
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/users?limit=-1')
      .then(function (response) {
        // console.log(response.data);

        setUser(response.data.data.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.get('/operation-reviews/' + id)
        .then(function (response) {
          console.log(response);
        //  delete response.data.data.id;

          if (response.data.data.audit.audit_plan.client.certifications) {
            let selectedCertifications = [];
            response.data.data.audit.audit_plan.client.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.data.audit.audit_plan.client.certifications = selectedCertifications;
          }
          setData(response.data.data);

          if (response.data.data.peer_reviews) {
            response.data.data.peer_reviews.map((peer_review, i) => {
                peerReviewData[peer_review.id] = peer_review;
                setPeerReviewData(peerReviewData);
              }
            );
          } 
          //check is peer review panel, 
          setActiveTab(response.data.data.peer_reviews && response.data.data.peer_reviews[0] && response.data.data.peer_reviews[0].peer_reviewer_id ? response.data.data.peer_reviews[0].peer_reviewer_id : 0);
          if (response.data.data.peer_reviews && response.data.data.peer_reviews[0] && response.data.data.peer_reviews[0].peer_reviewer_id!==null) {
            console.log(response.data.data.peer_reviews);
            console.log(response.data.meta.user && response.data.meta.user.id ? response.data.meta.user.id : 'tiada');
            if(response.data.data.peer_reviews[0].peer_reviewer_id === response.data.meta.user.id ){
              setActiveTab(response.data.data.peer_reviews[0].peer_reviewer_id);
              toggle(response.data.data.peer_reviews[0].peer_reviewer_id);
            }
          else  if(response.data.data.peer_reviews[1] && response.data.data.peer_reviews[1].peer_reviewer_id && response.data.meta.user.id == response.data.data.peer_reviews[1].peer_reviewer_id){
              setActiveTab(response.data.data.peer_reviews[1].peer_reviewer_id);
              toggle(response.data.data.peer_reviews[1].peer_reviewer_id);
            }
             else
            {
              setActiveTab(response.data.data.peer_reviews[0].peer_reviewer_id);
              toggle(response.data.data.peer_reviews[0].peer_reviewer_id);
            } 
          }
          setLoaded(true);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data.data) {
            addToast(error.response.data.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
          setLoaded(true);
        });
      }

      getData();
    }
    const getReferences = async () => {
     
      console.log(JSON. stringify(data));
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('/operation-reviews?client[]='+client_id)
      .then(function (response) {
         console.log(response);
         let references = [];
         response.data.data.map((reference, i) => references.push({
           label: reference.audit.audit_plan.stage,
           value: reference.id,
         }));
 
         setReference(references);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getReferences();
  }, [id]);

  return (
    
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header d-flex justify-content-between align-items-center">
                  <h2>Peer Review</h2> 
                  <Input 
                    outline
                    color="primary" 
                    onChange={(e) => {
                      let id = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                      window.open(`/boss-chart/view/`+id);
                    }}
                    type="select" 
                    className="custom-select" 
                    name="reference"
                    id="opr_id"
                    style={{ width: "3cm" }}
                  >
                    <option>Reference</option>
                    {reference && reference.length ? reference.map((refer, i) => 
                    <option value={refer.value}>{refer.label.toUpperCase()}</option>
                  ) : ""}
                  </Input>                  
                  {/* <Button 
                      outline
                      float-right
                      color="primary" 
                      onClick={() => {
                        window.open(`/boss-chart/view/`+id);
                      }}>
                    <i className="icon-graph"></i> Reference
                    </Button>   */}

                </div>
                
                <div className="body">

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="AuditNo">Audit No.</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="audit_no" 
                          id="AuditNo"
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan ? data.audit.audit_plan.audit_no : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ClientName">Client Name</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="client_name" 
                          id="ClientName"
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan && data.audit.audit_plan.client ? data.audit.audit_plan.client.name : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="AuditStandards">Audit Standard(s)</Label>
                        {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                        data.audit && data.audit.audit_plan && data.audit.audit_plan.client.certifications && data.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1 ? 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={true}
                                type="checkbox" 
                                disabled={true}
                                // name={`certifications`}
                                // onChange={handleChange}
                                // onChange={() => {
                                //   if (values.typeStr !== 'ISO') {
                                //     let { certifications } = values;

                                //     if (certifications && certifications.length) {
                                //       var index = certifications.indexOf(CERTIFICATION.id);
                                //       if (index !== -1) {
                                //         certifications.splice(index, 1);
                                //       } else {
                                //         certifications.push(CERTIFICATION.id);
                                //       }
                                //     } else {
                                //       certifications = [];
                                //       certifications.push(CERTIFICATION.id);
                                //     }

                                //     setFieldValue('certifications', certifications);
                                //   }
                                // }}
                                // value={CERTIFICATION.id}
                                checked={data.audit && data.audit.audit_plan && data.audit.audit_plan.client.certifications && data.audit.audit_plan.client.certifications.length && data.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard} {data.audit.audit_plan.type && data.audit.audit_plan.type.toUpperCase() === 'MSPO' && data.audit.audit_plan.subtype ? <span className="text-muted">{data.audit.audit_plan.subtype.replace(/-/, ' ').replace(data.audit.audit_plan.subtype.charAt(0), data.audit.audit_plan.subtype.charAt(0).toUpperCase())}</span> : ""}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                         : null
                      ) : null}
                      </FormGroup>
                    </div>
                  </div>
                  
                  <div className="row clearfix">
                    <div className="col-sm-6">
                    {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() !== 'peer_review.review') ?  null : null}
                      <FormGroup>
                        <Label for="AuditType">Audit Type</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="type_of_audit" 
                          id="TypeOfAudit"
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan ? data.audit.audit_plan.stage : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="AuditDate">Audit Date</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="audit_date" 
                          id="AuditDate"
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan && data.audit.audit_plan.date_of_audit_start && data.audit.audit_plan.date_of_audit_end ? moment(data.audit.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(data.audit.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>
                 
                  <Nav tabs>
                  {load === false ?
                  <NavItem>
                    <NavLink>
                      <Spinner size="sm" color="primary" />
                    </NavLink>
                  </NavItem>
                  : null}
                
                  {data.peer_reviews && data.peer_reviews ? data.peer_reviews.map((peer_reviewer, i) => 
                  ((profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.update') || profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.view')) || (profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.review') && peer_reviewer.peer_reviewer_id === profile.id) ? 
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === peer_reviewer.peer_reviewer_id })}
                        onClick={() => { toggle(peer_reviewer.peer_reviewer_id); }}
                      >
                        {peer_reviewer.reviewers.name}
                      </NavLink>
                    </NavItem>
                  : null)
                  ) : ''}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    {peerReviewData && peerReviewData ? peerReviewData.map((peerReviewItemData, i) => 
                    ((profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.update') || profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.view')) || (profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.review') && peerReviewItemData.peer_reviewer_id === profile.id) ? 
                    <TabPane key={i} tabId={peerReviewItemData.peer_reviewer_id}>
                      <Formik 
                        key={i}
                        enableReinitialize={true}
                        initialValues={data}
                        /* initialValues={peerReviewData && peerReviewData[activeTab] ? peerReviewData[activeTab] : INITIAL_PEER_REVIEW} */
                        onSubmit={async (values, { setSubmitting }) => {
                          let token = await localStorage.getItem('auth-token');
                          
                          token = JSON.parse(token);

                          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                          values.peer_reviewer_id = activeTab;
                                                                                        
                         /*  if (props.review) {
                            values.action = 'send_for_approval';
                            values.peer_review_reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');
                          }

                          if (props.approval) {
                            values.action = 'approved';
                            values.peer_review_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
                          } */

                        //  if(peerReviewItemData.peer_reviewer_id === profile.id && values.peer_review_reviewed_at===null){
                            if(profile.id===values.peer_review_reviewer_id){
                              values.action = "review 1";
                              values.peer_review_reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');
                            }
                        //  }
                          console.log('peerReviewItemData.peer_review_approver_id '+peerReviewItemData.peer_review_approver_id );
                          console.log('profile.id '+profile.id );
                          console.log('values.peer_review_approved_at '+values.peer_review_approved_at );
                          console.log('values.peer_review_approver_id '+values.peer_review_approver_id );

                          //if(peerReviewItemData.peer_review_approver_id === profile.id && values.peer_review_approved_at==null){
                            if(profile.id==values.peer_review_approver_id && values.peer_review_approved_at==null ){
                              console.log('come here');
                              values.action = "review 2";
                              values.peer_review_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
                            }
                         // }        
                          values.peer_review_id = peerReviewItemData.id;
                          values.review_date= moment().format('YYYY-MM-DD HH:mm:ss');
                          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                          data.append('_method', 'PUT');
    
                          if (values.id) {
                            await api({
                              method: 'POST',
                              url: '/operation-reviews/' + id,
                              headers: {
                                'Content-Type': 'multipart/form-data',
                              },
                              data: data,
                            })
                           /*  await api.put('/operation-reviews/' + id + '/peer-reviews/' + values.id, JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            }) */
                            .then(function (response) {
                              
                              addToast('Peer review successfully updated', { appearance: 'success' });
                              //history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
                              history.push(props.path + '?refresh=' + Date.now());
                            })
                            .catch(function (error) {
                              if(error.message =='Network Error'){
                                addToast('Peer review successfully updated', { appearance: 'success' });
                                history.push(props.path + '?refresh=' + Date.now());
                              }
                              else
                              {
                                addToast(error.message, { appearance: 'error' });
                              }
                            });
                          } /* else {
                            await api.post('/operation-reviews/' + id + '/peer-reviews', JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Peer review successfully updated', { appearance: 'success' });

                              history.push(props.path + '?refresh=' + Date.now());
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });
                          } */
                        }}
                      >
                      {({ isSubmitting, values, setFieldValue, handleChange }) => (
                        <FormikForm>
                          <div className="row clearfix">
                            <div className="col-sm-6">
                              <FormGroup>
                                <Label for="ReviewDate">Review Date</Label>
                                <Input 
                                  readOnly={true}
                                  type="text" 
                                  name="review_date" 
                                  id="ReviewDate"
                                  // onChange={handleChange}
                                  defaultValue={peerReviewItemData.review_date ? moment(peerReviewItemData.review_date).format('DD-MM-YYYY') : (profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.update') || profile.permissions.find(o => o.name.toLowerCase() === 'peer_review.review')) ? moment().format('DD-MM-YYYY') : null}
                                  // required
                                />
                              </FormGroup>
                            </div>
                          </div>

                          <p>Introductory Information on Audit Report</p>

                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th style={{ width: "12.5%" }}>Section</th>
                                <th style={{ width: "12.5%" }}>Complete?</th>
                                <th style={{ width: "75%" }}>Comments / Recommendations</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                {
                                  value: 'status_of_summary_report',
                                  label: 'Status of summary report',
                                },
                                {
                                  value: 'introduction',
                                  label: 'Introduction',
                                },
                                {
                                  value: 'scope',
                                  label: 'Scope',
                                }, 
                                {
                                  value: 'information_of_audit_team',
                                  label: 'Information of Audit Team'
                                },
                                {
                                  value: 'estate_address_and_gps_coords',
                                  label: 'Estate address and GPS Coordinate (*Please verify the accuracy on Estate site address and GPS location)'
                                }].map((item, i) =>
                              <tr>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.label}</td>
                                <td>{readOnly}
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`${item.value}_status`}
                                      value="Y"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'A')
                                      // }}
                                      checked={peerReviewItemData[`${item.value}_status`] ? peerReviewItemData[`${item.value}_status`] === 'Y' : null}
                                    />
                                    <span>
                                      <i></i> Yes
                                    </span>
                                  </label>
                                  <br />
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`${item.value}_status`}
                                      value="N"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'D')
                                      // }}
                                      checked={peerReviewItemData[`${item.value}_status`] ? peerReviewItemData[`${item.value}_status`] === 'N': null}
                                    />
                                    <span>
                                      <i></i> No
                                    </span>
                                  </label>
                                  <br />
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`${item.value}_status`}
                                      value="NA"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'D')
                                      // }}
                                      checked={peerReviewItemData[`${item.value}_status`] ? peerReviewItemData[`${item.value}_status`] === 'NA': null}
                                    />
                                    <span>
                                      <i></i> NA
                                    </span>
                                  </label>
                                </td>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                  {readOnly ?
                                    htmlToReactParser.parse(peerReviewItemData[`${item.value}_comments`])
                                  :
                                    <Editor 
                                      key={i}
                                      apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                      value={peerReviewItemData[`${item.value}_comments`]}
                                      init={{
                                        height: 250,
                                        menubar: 'file edit view insert format tools table tc help',
                                        plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                                        paste_data_images: true,
                                        // images_upload_url: 'postAcceptor.php',
                                        content_style: "body { font-family: Arial; font-size: 10pt; }",
                                      }}
                                      onEditorChange={(content) => {
                                        setFieldValue(`${item.value}_comments`, content);
                                      }}
                                    />
                                  }
                                </td>
                              </tr>
                              )}
                            </tbody>
                          </table>

                          <p>Audit Requirements: Principles Summaries</p>

                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th style={{ width: "12.5%" }}>Section</th>
                                <th style={{ width: "12.5%" }}>Complete?</th>
                                <th style={{ width: "75%" }}>Comments / Recommendations</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                {
                                  value: 'management_commitment',
                                  label: '<strong>Principle 1:</strong><br />Management Commitment and Responsibility',
                                },
                                {
                                  value: 'transparency',
                                  label: '<strong>Principle 2:</strong><br />Transparency',
                                },
                                {
                                  value: 'compliance_to_legal_requirements',
                                  label: '<strong>Principle 3:</strong><br />Compliance to Legal Requirements',
                                },
                                {
                                  value: 'social_responsibility_health_employee',
                                  label: '<strong>Principle 4:</strong><br />Social Responsibility, Health, Safety and Employment Condition',
                                },
                                {
                                  value: 'environment_natural_resources',
                                  label: '<strong>Principle 5:</strong><br />Environment, Natural Resources, Biodiversity and Ecosystem Services',
                                },
                                {
                                  value: 'best_practices',
                                  label: '<strong>Principle 6:</strong><br />Best Practices',
                                },
                                {
                                  value: 'development_of_new_planting',
                                  label: '<strong>Principle 7:</strong><br />Development of New Planting',
                                },
                              ].map((item, i) =>
                              <tr>
                                <td style={{ whiteSpace: "pre-wrap" }}>{htmlToReactParser.parse(item.label)}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`${item.value}_status`}
                                      value="Y"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'A')
                                      // }}
                                      checked={peerReviewItemData[`${item.value}_status`] ? peerReviewItemData[`${item.value}_status`] === 'Y':null}
                                    />
                                    <span>
                                      <i></i> Yes
                                    </span>
                                  </label>
                                  <br />
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`${item.value}_status`}
                                      value="N"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'D')
                                      // }}
                                      checked={peerReviewItemData[`${item.value}_status`] ? peerReviewItemData[`${item.value}_status`] === 'N':null}
                                    />
                                    <span>
                                      <i></i> No
                                    </span>
                                  </label>
                                  <br />
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`${item.value}_status`}
                                      value="NA"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'D')
                                      // }}
                                      checked={peerReviewItemData[`${item.value}_status`] ? peerReviewItemData[`${item.value}_status`] === 'NA':null}
                                    />
                                    <span>
                                      <i></i> NA
                                    </span>
                                  </label>
                                </td>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                  {readOnly ? 
                                    htmlToReactParser.parse(peerReviewItemData[`${item.value}_comments`])
                                  :
                                    <Editor 
                                      key={i}
                                      apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                      value={peerReviewItemData[`${item.value}_comments`]}
                                      init={{
                                        height: 250,
                                        menubar: 'file edit view insert format tools table tc help',
                                        plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                                        paste_data_images: true,
                                        content_style: "body { font-family: Arial; font-size: 10pt; }",
                                      }}
                                      onEditorChange={(content) => {
                                        setFieldValue(`${item.value}_comments`, content);
                                      }}
                                    />
                                  }
                                </td>
                              </tr>
                              )}
                            </tbody>
                          </table>

                          <div className="row clearfix">
                            <div className="col-sm-12">
                              <FormGroup>
                                <Label for="">CONCLUSION: Peer Reviewer agrees with the certification recommendation of the auditor?</Label>
                                <div>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name="conclusion"
                                      value="Y"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'A')
                                      // }}
                                      checked={peerReviewItemData.conclusion ? peerReviewItemData.conclusion === 'Y' : null}
                                    />
                                    <span>
                                      <i></i> Yes
                                    </span>
                                  </label>

                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name="conclusion"
                                      value="N"
                                      onClick={handleChange}
                                      // onClick={() => {
                                      //   setFieldValue('checklist', 'A')
                                      // }}
                                      checked={peerReviewItemData.conclusion ? peerReviewItemData.conclusion === 'N' : null}
                                    />
                                    <span>
                                      <i></i> No
                                    </span>
                                  </label>
                                </div>
                              </FormGroup>
                            </div>
                          </div>

                          <div className="row clearfix">
                            <div className="col-sm-12">
                              <FormGroup>
                                <Label for="">Remarks</Label>
                                {readOnly ?
                                  htmlToReactParser.parse(peerReviewItemData.remarks)
                                : 
                                  <Editor 
                                    key={i}
                                    apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                    value={peerReviewItemData.remarks ? peerReviewItemData.remarks : null}
                                    init={{
                                      height: 250,
                                      menubar: 'file edit view insert format tools table tc help',
                                      plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                      ],
                                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                                      paste_data_images: true,
                                      content_style: "body { font-family: Arial; font-size: 10pt; }",
                                    }}
                                    onEditorChange={(content) => {
                                      setFieldValue('remarks', content);
                                    }}
                                  />
                                }
                              </FormGroup>
                            </div>
                          </div>

                          {!review && !approval && !readOnly ? 
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                // readOnly={readOnly}
                                type="checkbox" 
                                disabled={readOnly || peerReviewItemData.review}
                                name="review"
                                // onChange={handleChange}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.checked);
                                }}
                                value={peerReviewItemData.review }
                                checked={peerReviewItemData.review} 
                              />
                              <span>
                                Send for review and approval?
                              </span>
                            </label>
                          </div>
                          : null}

                          {!readOnly ?
                          <React.Fragment>
                          <div className="row clearfix">
                            <div className="col-sm-4">
                              <button 
                                type="submit" 
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? !values.review ?  'Saving...':'Submitting...' :  !values.review ? 'Save' : 'Submit' }
                              </button>&nbsp;
                              
                              {/* <Button 
                                // className="btn btn-outline-secondary"
                                outline
                                color="secondary" 
                                tag={Link} 
                                to={props.path}
                              >
                                Cancel
                              </Button> */}
                            </div>
                          </div>
                          </React.Fragment> : null}
                        </FormikForm>
                      )}
                      </Formik>
                    </TabPane>
                    : null)) : ''}
                  </TabContent>
                  
                </div>
              </Card>

             
            </div>
          </div>
        
  )
}

export default Form;
