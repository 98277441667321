import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";

import {
  Link,
  useRouteMatch
} from 'react-router-dom';

import {
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

import {
  Button,
  ButtonGroup,
  // Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import SidebarToggler from '../../../components/SidebarToggler';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import moment from 'moment';

import api from '../../../utils/api';

import { useToasts } from 'react-toast-notifications';

const EventCalendar = () => {
  let { path } = useRouteMatch();
  
  const { addToast } = useToasts();
  
  const [token, setToken] = useState();
  
  const [modal, setModal] = useState(false);
  
  const [data, setData] = useState({});

  const toggleModal = () => setModal(!modal);

  // let [eventInfo, setEventInfo] = useState({
  //   title: "test"
  // });

  useEffect(() => {
    const getToken = async () => {
        let token = await localStorage.getItem('auth-token');
  
        token = JSON.parse(token);
      
        setToken(token.access_token);
    }
    
    getToken();
    
    const element = document.querySelector("body");
    
    element.classList.add('page-calendar');
  }, []);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Calendar
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item active">
                Audit Module
              </li>
              <li className="breadcrumb-item active">
                <Link to={path}>
                  Calendar
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div id="calendar">
                <FullCalendar 
                  defaultView="dayGridMonth" 
                  plugins={[ dayGridPlugin, interactionPlugin ]}
                  // events={ events }
                  // resources={process.env.REACT_APP_API_URL + "/audit-plans?events=1"}
                  eventSources={[
                    {
                      url: process.env.REACT_APP_API_URL + "/audit-plans?events=1&token=" + token
                    },
                    {
                      url: process.env.REACT_APP_API_URL + "/events?token=" + token
                    }
                  ]}
                  eventRender={(info) => {
                    if (info.event.extendedProps.source === 'audit-plan') {
                      const popover = (
                        <Popover id="popover-basic">
                          <Popover.Title as="h3">{info.event.title}</Popover.Title>
                          <Popover.Content>
                            Lead Auditor: <ul>{info.event.extendedProps.lead_auditors && info.event.extendedProps.lead_auditors.length ? info.event.extendedProps.lead_auditors.map((lead_auditor, i) => 
                              <li>{lead_auditor.full_name}</li>
                            ) : ""}</ul><br /> 
                            Audit Team Members: <ul>{info.event.extendedProps.audit_team_members && info.event.extendedProps.audit_team_members.length ? info.event.extendedProps.audit_team_members.map((member, i) => 
                              <li>{member.full_name}</li>
                            ) : ""}</ul>

                          </Popover.Content>
                        </Popover>
                      );

                      let divId = "event-" + info.event.id;
                      const content = (
                        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
                            <div className="fc-content" id={divId}>
                              <span className="fc-title">{info.event.title}</span>
                            </div>
                        </OverlayTrigger>
                      );

                      ReactDOM.render(content, info.el);
                    }
                  }}
                  eventMouseEnter={(info) => {
                    // let eventInfo = {
                    //   title: info.event.title,
                    // }
                    // setEventInfo(eventInfo);
                  }}
                  dateClick={(info) => {
                    toggleModal();
                    console.log(info);
                    
                    setData({
                      start: info.dateStr,
                      end: info.dateStr
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
        <Formik 
          enableReinitialize={true}
          initialValues={data}
          onSubmit={async (values, { setSubmitting }) => {
             console.log(values);
            
            let token = await localStorage.getItem('auth-token');

            token = JSON.parse(token);

            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
            
            await api.post('/events', JSON.stringify(values), {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(function (response) {
              // console.log(response.data.data.id);

              addToast('Event successfully created', { appearance: 'success' });

              // history.push(`${props.path}`);
            })
            .catch(function (error) {
              addToast(error.message, { appearance: 'error' });
            });
          }}
        >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <ModalHeader toggle={toggleModal}>Create New Event</ModalHeader>
            <ModalBody className="p-0">
              <div className="m-2 row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="Title">Title</Label>
                    <Input 
                      // readOnly={readOnly}
                      type="text" 
                      name="title" 
                      id="Title"
                      onChange={handleChange}
                      value={values.title}
                      // rows={10}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="StartDate">Date</Label>
                    <DateRangePicker 
                      containerStyles={{ display: "block" }}
                      startDate={values.start && values.start.length ? moment(values.start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                      endDate={values.end && values.end.length ? moment(values.end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                      onApply={(event, picker) => {
                        setFieldValue('start', picker.startDate.format('YYYY-MM-DD'));
                        setFieldValue('end', picker.endDate.format('YYYY-MM-DD'));
                      }}
                    >
                      <Input 
                        autoComplete="off"
                       // readOnly={readOnly}
                        type="text" 
                        // name="date_of_audit" 
                        id="DateOfAudit"
                        // onChange={handleChange}
                        value={values.start && values.end ? moment(values.start).format('DD/MM/YYYY') + ' - ' + moment(values.end).format('DD/MM/YYYY') : ""}
                      />
                    </DateRangePicker>
                    {/* <DateRangePicker 
                      // disabled={readOnly}
                      initialSettings={{
                        timePicker: true
                      }}
                      timePicker={true}
                      timePickerSeconds={false}
                      containerStyles={{ display: "block" }}
                      startDate={values.start && values.start.length ? moment(values.start, "YYYY-MM-DD hh:mm:ss+ZZ").format('MM/DD/YYYY HH:mm:ss') : moment().format('MM/DD/YYYY')}
                      endDate={values.end && values.end.length ? moment(values.end, "YYYY-MM-DD hh:mm:ss+ZZ").format('MM/DD/YYYY HH:mm:ss') : moment().format('MM/DD/YYYY')}
                      onApply={(event, picker) => { 
                        setFieldValue('start', moment(picker.startDate, "YYYY-MM-DD hh:mm:ss+ZZ").format('YYYY-MM-DD'));
                        setFieldValue('end', moment(picker.endDate, "YYYY-MM-DD hh:mm:ss+ZZ").format('YYYY-MM-DD'));
                      }}
                    >
                      <Input 
                        autoComplete="off"
                        // readOnly={readOnly}
                        type="text" 
                        // name="date_of_audit" 
                        id="StartDate"
                        // onChange={handleChange}
                        value={values.start && values.end ? moment(values.start, "YYYY-MM-DD hh:mm:ss+ZZ").format('DD/MM/YYYY HH:mm:ss') + ' - ' + moment(values.end, "YYYY-MM-DD hh:mm:ss+ZZ").format('DD/MM/YYYY HH:mm:ss') : ""}
                      />
                    </DateRangePicker> */}
                  </FormGroup>

                  <FormGroup>
                    <div className="fancy-checkbox">
                      <label>
                        <Input 
                          type="checkbox"
                          checked={values.allDay} 
                          onChange={(e) => {
                              setFieldValue('allDay', e.currentTarget.checked ? 1 : 0);
                            }}
                          name="allDay"
                        />
                        <span>
                          All-day event
                        </span>
                      </label>
                    </div>
                  </FormGroup>
                  
                  <FormGroup>
                    <Label for="Visibility">Visibility</Label>
                     {[{
                        label: 'Niosh Certification',
                      },
                      {
                        label: 'Auditor',
                      }].map((VISIBILITY, key) => 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                type="checkbox"
                                checked={values.visibility && values.visibility.length && values.visibility.indexOf(VISIBILITY.label) !== -1}
                                onChange={(e) => {
                                  let { visibility } = values;
                                  if (visibility && visibility.length) {
                                    var index = visibility.indexOf(VISIBILITY.label);
                                    if (index !== -1) {
                                      visibility.splice(index, 1);
                                    } else {
                                      visibility.push(VISIBILITY.label);
                                    }
                                  } else {
                                    visibility = [];
                                    visibility.push(VISIBILITY.label);
                                  }

                                  setFieldValue('visibility', visibility);
                                }}
                                name="visibility"
                              />
                              <span>
                                {VISIBILITY.label}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                      )}
                    
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button 
                  type="submit"
                  color="primary" 
                >
                {isSubmitting ? 'Creating...' : 'Create'}
              </Button>{' '}
              <Button color="secondary" onClick={() => {
                  toggleModal();
                  setData({});
                }}>Cancel</Button>
            </ModalFooter>
          </FormikForm>
        )}
        </Formik>
      </Modal>
    </div>
  )
}

export default EventCalendar;