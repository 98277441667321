import React, { useEffect, useState } from "react";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import QRCode from "react-qr-code";

import {
  ButtonGroup,
  ToggleButton
} from 'react-bootstrap';

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';

import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import api from "../../../../utils/api";

const Attendance = (props) => {
  let { id } = useParams();

  let history = useHistory();

  const { addToast } = useToasts();

  const [token, setToken] = useState('');

  const [data, setData] = useState([]);

  const [activeTab, setActiveTab] = useState(1);

  const [load, reload] = useState(true);

  const [category, setCategory] = useState("OP");

  const columns = [
    {
      text: 'Name',
      dataField: 'name',
      style: {
        whiteSpace: "pre-wrap"
      }
    },
    {
      text: 'Email',
      dataField: 'email',
      style: {
        whiteSpace: "pre-wrap"
      }
    },
    {
      text: 'Organization',
      dataField: 'organization',
      style: {
        whiteSpace: "pre-wrap"
      }
    },
    {
      text: 'Department',
      dataField: 'department',
      style: {
        whiteSpace: "pre-wrap"
      }
    },
    {
      // hidden: category !== "OP",
      text: 'Opening',
      dataField: 'opening',
      formatter: (cell, row, rowIndex) => {
        // return moment().utc(cell).tz('Asia/Kuala_Lumpur').format('DD/MM/YY HH:mm a');

        if (cell !== null) {
          if(cell < '2021-11-19')
            return moment.utc(cell).add(8, 'hours').format('DD/MM/YY');
          else
            return moment(cell).format('DD/MM/YY');
        }
      },
      style: {
        whiteSpace: "pre-wrap"
      }
    },
    {
      // hidden: category !== "CL",
      text: 'Closing',
      dataField: 'closing',
      formatter: (cell, row, rowIndex) => {
        if (cell !== null) {
          if(cell < '2021-11-19')
            return moment.utc(cell).add(8, 'hours').format('DD/MM/YY');
          else
            return moment(cell).format('DD/MM/YY');
        }
      }
    }
  ];

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get(`/attendances/${id}?category=${category}`)
      .then(function (response) {
        // console.log(response);

        // delete response.data.id;

        setData(response.data);

        reload(false);
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getData();
  }, [id, load]);

  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          // let token = await localStorage.getItem('auth-token');

          // token = JSON.parse(token);

          // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          // api.put('/audits/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          // .then(function (response) {
          //   addToast('Audit successfully updated', { appearance: 'success' });
          // })
          // .catch(function (error) {
          //   addToast(error.message, { appearance: 'error' });
          // });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card>
              <div className="header">
                <h2>Attendance</h2>
              </div>
              <div className="body">
                <Nav pills>
                  {[{
                    id: 1,
                    label: "QR Code",
                  },
                  {
                    id: 2,
                    label: "Attendance List",
                  },
                ].map((tab, i) => 
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === tab.id })}
                        onClick={() => { toggle(tab.id); }}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <hr />
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <FormGroup>
                      {/* <Label for="Status">Show QR Code for: </Label> */}
                      <div>
                      <ButtonGroup toggle className="mb-4">
                        <ToggleButton 
                          type="radio" 
                          name="status"
                          value="OP"
                          onChange={() => {
                            setCategory("OP");
                          }}
                          checked={category === "OP"}
                        >
                          Opening Meeting
                        </ToggleButton>
                        <ToggleButton 
                          type="radio" 
                          name="status"
                          value="CL"
                          onChange={() => {
                            setCategory("CL");
                          }}
                          checked={category === "CL"}
                        >
                          Closing Meeting
                        </ToggleButton>
                      </ButtonGroup>
                      </div>
                    </FormGroup>

                    <QRCode 
                      value={`https://${window.location.host}/attendance/${id}?category=${category}`} 
                    />

                    <br />

                    <br />

                    {category === "CL" ?
                    <button 
                    className="btn btn-outline-secondary" 
                    type="button"
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=closing-meeting&token=${token}`);
                    }}
                  >
                    <i className="icon-printer"></i> Closing Meeting
                  </button>
                  : null}
                    
                  </TabPane>
                  <TabPane tabId={2}>
                    <BootstrapTable 
                      remote={true}
                      bootstrap4
                      bordered={false}
                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                      headerClasses="thead-dark"
                      keyField="id"
                      data={ data } 
                      columns={ columns }
                    />
                  </TabPane>
                  
                </TabContent>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default Attendance;