import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import moment from 'moment';

import DatePicker from 'reactstrap-date-picker';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

import SidebarToggler from "../../../components/SidebarToggler";

import { objectToFormData } from 'object-to-formdata';

const FormMspo = (props) => {
  let { id, type, subtype } = useParams();
  let { path } = useRouteMatch();

  let history = useHistory();
  let [token, setToken] = useState('');
  const { addToast } = useToasts();
  const [standard, setStandard] = useState();

  const [data, setData] = useState({});

  const [auditors, setAuditor] = useState([]);

  const [USERS, setUser] = useState([{}]);

  const [CERTIFICATION, setCertifications] = useState({});

  const [readOnly, setreadOnly] = useState(false);

  const [permissions, setPermissions] = useState([]);

  let { view, approval, review }  = props;
  let i=0;
  let date = moment().format('DD-MM-YYYY');
  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);
      setToken(token.access_token);
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        // if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'technical_review.update') === "undefined") {
        //   history.push(props.path);
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/users?limit=-1')
      .then(function (response) {
        // console.log(response.data);

        setUser(response.data.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        api.get('/audit-confirmation/' + id)
       // api.get('/audits/' + id)
        .then(function (response) {
        //  delete response.data.id;
        // console.log(response.data);
        setData(response.data.data);
         // console.log(response.data.meta)
        setPermissions(response.data.meta.permissions);
        
         if(response.data.data.audit_confirmations!==null){
          setreadOnly(true);
        } 

        if (response.data.data.audit_plan.type.toUpperCase() === 'MSPO' && response.data.data.audit_plan.subtype.toUpperCase() === 'PART-2') {
          setStandard("MS 2530-2:2013");
        }
        if (response.data.data.audit_plan.type.toUpperCase() === 'MSPO' && response.data.data.audit_plan.subtype.toUpperCase() === 'PART-3') {
          setStandard("MS 2530-3:2013");
        }
        if (response.data.data.audit_plan.type.toUpperCase() === 'MSPO' && response.data.data.audit_plan.subtype.toUpperCase() === 'PART-4') {
          setStandard("MS 2530-4:2013");
        }
        if (response.data.data.audit_plan.type.toUpperCase() === 'MSPO' && response.data.data.audit_plan.subtype.toUpperCase() === 'MSPO-SCCS') {
          setStandard("MSPO-SCCS-01");
        }
        console.log(response.data.data);
          // reload(false);.
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        values.date = moment().format('DD-MM-YYYY');
      
        //delete values.audit;
        values.title = [
          "Company Background","Executive Summary", "Summary of Audit Findings", "Stakeholder Consultation Session", "Audit Summary Report","MSPO Logo Usage","Acknowledgement"
        ];
        values.question = [
          {"title":"Company Background","question":"Is the company name and address in accordance with R.OC and MPOB License?"},
          {"title":"Company Background","question":"Is the audit scope in accordance to the scope of certification?"},{"title":"Company Background","question":"Did the number of plantation/mill unit(s) stated correctly?"},
          {"title":"Company Background","question":"Did the plantation/mill name(s) stated correctly?"},
          {"title":"Company Background","question":"Did the plantation/mill address(es) stated correctly?"},
          {"title":"Company Background","question":"Did the each GPS Coordinate(s) stated correctly?"},{"title":"Company Background","question":"Is the MPOB License no. stated correctly?"},
          {"title":"Company Background","question":"Is the total planted areas (Ha) for each unit stated correctly?"},{"title":"Company Background","question":"Is the total certified area (Ha)/Mill Capacity (Mt/Hr) for each unit stated correctly?"},
          {"title":"Executive Summary","question":"All the details has been stated clearly and correctly in the executive summary"},
          {"title":"Summary of Audit Findings","question":"Does the total of minor comformity, major comformity and opportunities for improvement (OFI) match the totals on Summary of Audit Findings?"},
          {"title":"Stakeholder Consultation Session","question":"Was the list of stakeholders stated correctly in the report?"},
          {"title":"Stakeholder Consultation Session","question":"Is there any comment or amendment required in this session?"},
          {"title":"Audit Summary Report","question":"Were all sites/samples duly verified during assessment?"},
          {"title":"Audit Summary Report","question":"Was Audit Summary Report fulfilled properly? (included recommendation and conclusion)"},
          {"title":"Audit Summary Report","question":"Is there any comment/amendment rewuired on the contents clause/indicator?"},
          {"title":"MSPO Logo Usage","question":"Was the comformance uses of MSPO Logo been verified and stated in the audit report?"},
          {"title":"Acknowledgement","question":"I hereby confirmed and received the audit report of the company as mentioned above."},
          {"title":"Acknowledgement","question":"I hereby agreed that all the details stated in the audit report are correct with no amendment."},
          {"title":"Acknowledgement","question":"I hereby acknowledged that upon submitting this form any changes made after will be under our responsibility and NIOSH Certification has the right to change if any reissuance of certificate requires."}
        ];
        values.len = values.question.length;
        if(values.comment){console.log('here');}
        const dataList = [];
        for (i=0; i < values.len; i++){
          dataList.push({'title': values.question[i]['title'],'question':values.question[i]['question'],'answer':values.value[i] ? values.value[i]:null,'comment':values.comment && values.comment[i] ? values.comment[i]:null});
        } /**/
        values.data = dataList ;

        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      //  var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
      //  data.append('_method', 'PUT');

      // await api({
      //   method: 'POST',
      //   url: '/audit-confirmation/' + id,
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      //   data: data,
      // })
      api.post('/audit-confirmation', JSON.stringify(values), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
          // await api.put('/operation-reviews/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            addToast('Confirmation & Acknowledgement form successfully submitted', { appearance: 'success' });

            history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
          })
          .catch(function (error) {
             addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <div className="container-fluid">
            <div className="block-header">
              <div className="row">
                <div className="col-lg-10 col-md-8 col-sm-12">
                  <h2>
                    <SidebarToggler />
                    {type.toUpperCase()} Report Confirmation &amp; Acknowledgement
                  </h2>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="icon-home"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      Audit
                    </li>
                    <li className="breadcrumb-item">
                      Audit Report
                    </li>
                    <li className="breadcrumb-item">
                      Report Confirmation &amp; Acknowledgement
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={props.path.replace(/:type/, type)}>
                        {type.toUpperCase()}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 pull-right">
                
                  {permissions && permissions.find(o => o.name.toLowerCase() === 'audit_report.update') ?
                    <Button 
                      outline
                      color="primary"    
                      onClick={() => {
                            window.open(`${props.path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${id}?tab=team_leader_recommendation`);
                          }}
                        >
                      <i className="icon-pencil"></i> Audit Report 
                    </Button>
                    :
                    <Button 
                      outline
                      color="primary"   
                      onClick={() => {
                            window.open(`${props.path.replace(/:type/, type).replace(/:subtype/, subtype)}/view/${id}?tab=introduction`);
                          }}
                        >
                      <i className="icon-eye"></i> Audit Report 
                    </Button>
                  }
                </div>
              </div>
            </div>


            <FormikForm>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                  <Card>
                    <div className="header">
                      <h2>Report Confirmation &amp; Acknowledgement</h2>
                    </div>
                  
                    <div className="body">
                        <FormGroup>
                        <Label>Organization Name</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="client_name" 
                          id="ClientName"
                          // onChange={handleChange}
                          value={values.audit_plan && values.audit_plan.client ? values.audit_plan.client.name :  ''}
                          // required
                        />
                        </FormGroup>

                        <FormGroup>
                            <Label for="AuditStandard">Audit Standard(s)</Label>
                            <Input 
                            readOnly={true}
                            type="text" 
                            name="certification_code" 
                            id="certificationCode"
                            // onChange={handleChange}
                            value={standard ? standard : null}
                            />
                        </FormGroup>
        
                      <FormGroup>
                        <Label>Audit Date</Label>
                        {/* <p>{data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.name ? data.audit.audit_plan.client.name : ""}&nbsp;</p> */}
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="audit_date" 
                          id="AuditDate"
                          // onChange={handleChange}
                          value={values.audit_plan && values.audit_plan.audit_date ? values.audit_plan.audit_date : null}
                          // required
                        />

                    </FormGroup>

                    <FormGroup>
                    <Input type="hidden" name="audit_id" value={id} />
                        <div>
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th>Company Background</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>N/A</th>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                {
                                  field: "Is the company name and address in accordance with R.OC and MPOB License?"
                                },
                                {
                                  field: "Is the audit scope in accordance to the scope of certification?"
                                },
                                {
                                  field: "Did the number of plantation/mill unit(s) stated correctly?"
                                },
                                {
                                    field: "Did the plantation/mill name(s) stated correctly?"
                                },
                                {
                                    field: "Did the plantation/mill address(es) stated correctly?"
                                },
                                {
                                    field: "Did the each GPS Coordinate(s) stated correctly?"
                                },
                                {
                                    field: "Is the MPOB License no. stated correctly?"
                                },
                                {
                                    field: "Is the total planted areas (Ha) for each unit stated correctly?"
                                },
                                {
                                    field: "Is the total certified area (Ha)/Mill Capacity (Mt/Hr) for each unit stated correctly?"
                                },

                              ].map((item, i) => 
                                <tr key={i}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>{item.field} 
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={false}
                                        type="radio"
                                        name={`value.${i}`}
                                        value="Y"
                                        onClick={() => {
                                          setFieldValue(`value.${i}`, 'Y')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i}`].item == item.field && values.audit_confirmations.items[`${i}`].status ? values.audit_confirmations.items[`${i}`].status === 'Y' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={false}
                                        type="radio"
                                        name={`value.${i}`}
                                        value="N"
                                        onClick={() => {
                                          setFieldValue(`value.${i}`, 'N')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i}`].item == item.field && values.audit_confirmations.items[`${i}`].status ? values.audit_confirmations.items[`${i}`].status === 'N' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i}`}
                                        value=""
                                        onClick={() => {
                                          setFieldValue(`value.${i}`, 'NA')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i}`].item == item.field && values.audit_confirmations.items[`${i}`].status ? values.audit_confirmations.items[`${i}`].status === 'NA' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <Input
                                      type="textarea"
                                      name={`comment.${i}`}
                                      onChange={handleChange}
                                      value={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i}`].comment ? values.audit_confirmations.items[`${i}`].comment : null}
                                      readOnly={readOnly}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            <thead className="thead-dark">
                            <tr>
                                <th>Executive Summary</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>N/A</th>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                {
                                  field: "All the details has been stated clearly and correctly in the executive summary"
                                },
                              ].map((item, i) => 
                                <tr key={`${i + 9}`}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>{item.field}
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 9}`}
                                        value="Y"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 9}`, 'Y')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 9}`].item == item.field && values.audit_confirmations.items[`${i + 9}`].status ? values.audit_confirmations.items[`${i + 9}`].status === 'Y' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 9}`}
                                        value="N"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 9}`, 'N')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 9}`].item == item.field && values.audit_confirmations.items[`${i + 9}`].status ? values.audit_confirmations.items[`${i + 9}`].status === 'N' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 9}`}
                                        value=""
                                        onClick={() => {
                                          setFieldValue(`value.${i + 9}`, 'NA')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 9}`].item == item.field && values.audit_confirmations.items[`${i + 9}`].status ? values.audit_confirmations.items[`${i + 9}`].status === 'NA' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <Input
                                      readOnly={readOnly}
                                      type="textarea"
                                      name={`comment.${i + 9}`}
                                      onChange={handleChange}
                                      value={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 9}`].comment ? values.audit_confirmations.items[`${i + 9}`].comment : null}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            <thead className="thead-dark">
                            <tr>
                                <th>Summary of Audit Findings</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>N/A</th>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                {
                                  field: "Does the total of minor comformity, major comformity and opportunities for improvement (OFI) match the totals on Summary of Audit Findings?"
                                },
                              ].map((item, i) => 
                                <tr key={`${i + 10}`}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>{item.field}
                                  
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 10}`}
                                        value="Y"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 10}`, 'Y')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 10}`].item == item.field && values.audit_confirmations.items[`${i + 10}`].status ? values.audit_confirmations.items[`${i + 10}`].status === 'Y' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 10}`}
                                        value="N"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 10}`, 'N')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 10}`].item == item.field && values.audit_confirmations.items[`${i + 10}`].status ? values.audit_confirmations.items[`${i + 10}`].status === 'N' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 10}`}
                                        value=""
                                        onClick={() => {
                                          setFieldValue(`value.${i + 10}`, 'NA')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 10}`].item == item.field && values.audit_confirmations.items[`${i + 10}`].status ? values.audit_confirmations.items[`${i + 10}`].status === 'NA' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <Input
                                      readOnly={readOnly}
                                      type="textarea"
                                      name={`comment.${i + 10}`}
                                      onChange={handleChange}
                                      value={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 10}`].comment ? values.audit_confirmations.items[`${i + 10}`].comment : null}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            <thead className="thead-dark">
                            <tr>
                                <th>Stakeholder Consultation Session</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>N/A</th>
                                <th>Comment</th>
                            </tr>
                            </thead>
                            <tbody>
                              {[
                                {
                                  field: "Was the list of stakeholders stated correctly in the report?"
                                },
                                {
                                  field: "Is there any comment or amendment required in this session?"
                                },
                              ].map((item, i) => 
                                <tr key={i}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>{item.field}
                                  
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 11}`}
                                        value="Y"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 11}`, 'Y')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 11}`].item == item.field && values.audit_confirmations.items[`${i + 11}`].status ? values.audit_confirmations.items[`${i + 11}`].status === 'Y' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 11}`}
                                        value="N"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 11}`, 'N')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 11}`].item == item.field && values.audit_confirmations.items[`${i + 11}`].status ? values.audit_confirmations.items[`${i + 11}`].status === 'N' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 11}`}
                                        value=""
                                        onClick={() => {
                                          setFieldValue(`value.${i + 11}`, 'NA')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 11}`].item == item.field && values.audit_confirmations.items[`${i + 11}`].status ? values.audit_confirmations.items[`${i + 11}`].status === 'NA' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <Input
                                      readOnly={readOnly}
                                      type="textarea"
                                      name={`comment.${i + 11}`}
                                      onChange={handleChange}
                                      value={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 11}`].comment ? values.audit_confirmations.items[`${i + 11}`].comment : null}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            <thead className="thead-dark">
                            <tr>
                                <th>Audit Summary Report</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>N/A</th>
                                <th>Comment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[
                                {
                                  field: "Were all sites/samples duly verified during assessment?"
                                },
                                {
                                  field: "Was Audit Summary Report fulfilled properly? (included recommendation and conclusion)"
                                },
                                {
                                    field: "Is there any comment/amendment rewuired on the contents clause/indicator?"
                                },
                                ].map((item, i) => 
                                <tr key={i}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>{item.field}
                                  
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 13}`}
                                        value="Y"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 13}`, 'Y')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 13}`].item == item.field && values.audit_confirmations.items[`${i + 13}`].status ? values.audit_confirmations.items[`${i + 13}`].status === 'Y' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 13}`}
                                        value="N"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 13}`, 'N')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 13}`].item == item.field && values.audit_confirmations.items[`${i + 13}`].status ? values.audit_confirmations.items[`${i + 13}`].status === 'N' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 13}`}
                                        value=""
                                        onClick={() => {
                                          setFieldValue(`value.${i + 13}`, 'NA')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 13}`].item == item.field && values.audit_confirmations.items[`${i + 13}`].status ? values.audit_confirmations.items[`${i + 13}`].status === 'NA' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <Input
                                      readOnly={readOnly}
                                      type="textarea"
                                      name={`comment.${i + 13}`}
                                      onChange={handleChange}
                                      value={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 13}`].comment ? values.audit_confirmations.items[`${i + 13}`].comment : null}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            <thead className="thead-dark">
                            <tr>
                                <th>MSPO Logo Usage</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>N/A</th>
                                <th>Comment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[
                                {
                                  field: "Was the comformance uses of MSPO Logo been verified and stated in the audit report?"
                                },
                                ].map((item, i) => 
                                <tr key={i}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>{item.field}
                                  
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 16}`}
                                        value="Y"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 16}`, 'Y')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 16}`].item == item.field && values.audit_confirmations.items[`${i + 16}`].status ? values.audit_confirmations.items[`${i + 16}`].status === 'Y' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 16}`}
                                        value="N"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 16}`, 'N')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 16}`].item == item.field && values.audit_confirmations.items[`${i + 16}`].status ? values.audit_confirmations.items[`${i + 16}`].status === 'N' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 16}`}
                                        value=""
                                        onClick={() => {
                                          setFieldValue(`value.${i + 16}`, 'NA')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 16}`].item == item.field && values.audit_confirmations.items[`${i + 16}`].status ? values.audit_confirmations.items[`${i + 16}`].status === 'NA' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <Input
                                      readOnly={readOnly}
                                      type="textarea"
                                      name={`comment.${i + 16}`}
                                      onChange={handleChange}
                                      value={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 16}`].comment ? values.audit_confirmations.items[`${i + 16}`].comment : null}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            <thead className="thead-dark">
                            <tr>
                                <th>Acknowledgement</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>N/A</th>
                                <th>Comment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[
                                {
                                  field: "I hereby confirmed and received the audit report of the company as mentioned above."
                                },
                                {
                                  field: "I hereby agreed that all the details stated in the audit report are correct with no amendment."
                                },
                                {
                                    field: "I hereby acknowledged that upon submitting this form any changes made after will be under our responsibility and NIOSH Certification has the right to change if any reissuance of certificate requires."
                                },
                                ].map((item, i) => 
                                <tr key={i}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>{item.field}
                                  
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 17}`}
                                        value="Y"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 17}`, 'Y')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 17}`].item == item.field && values.audit_confirmations.items[`${i + 17}`].status ? values.audit_confirmations.items[`${i + 17}`].status === 'Y' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 17}`}
                                        value="N"
                                        onClick={() => {
                                          setFieldValue(`value.${i + 17}`, 'N')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 17}`].item == item.field && values.audit_confirmations.items[`${i + 17}`].status ? values.audit_confirmations.items[`${i + 17}`].status === 'N' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={readOnly}
                                        type="radio"
                                        name={`value.${i + 17}`}
                                        value=""
                                        onClick={() => {
                                          setFieldValue(`value.${i + 17}`, 'NA')
                                        }}
                                        checked={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 17}`].item == item.field && values.audit_confirmations.items[`${i + 17}`].status ? values.audit_confirmations.items[`${i + 17}`].status === 'NA' : null}
                                        required={true}
                                      />
                                      <span>
                                        <i></i>
                                      </span>
                                    </label>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <Input
                                      readOnly={readOnly}
                                      type="textarea"
                                      name={`comment.${i + 17}`}
                                      onChange={handleChange}
                                      value={values.audit_confirmations && values.audit_confirmations.items && values.audit_confirmations.items[`${i + 17}`].comment ? values.audit_confirmations.items[`${i + 17}`].comment : null}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                    </FormGroup>

                    <FormGroup>
                    <Label for="">Comments/Remarks: Refers to the reviewed audit report attached for the amendment.</Label>
                    <Editor 
                      // key={note.id}
                      apiKey={process.env.REACT_APP_TINYMCE_KEY}
                      value={values.audit_confirmations && values.audit_confirmations.comments ? values.audit_confirmations.comments : null}
                      init={{
                        height: 200,
                        menubar: 'file edit view insert format tools table tc help',
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                        paste_data_images: true,
                        content_style: "body { font-family: Arial; font-size: 10pt; }",
                      }}
                      onEditorChange={(content) => {
                        setFieldValue('comments', content);
                      }}
                    />
                    </FormGroup>
                    <div className="row clearfix">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label>Name</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="full_name" 
                          id="AuditorName"
                          // onChange={handleChange}
                          value={values.audit_plan && values.audit_plan.client ? values.audit_plan.client.contact_person_name :  null}
                          // required
                        />
                        </FormGroup>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label>Date</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="date" 
                          id="date"
                          // onChange={handleChange}
                          value={values.send_for_draft_audit_report_date ? values.send_for_draft_audit_report_date : date}
                          // required
                        />
                        </FormGroup>
                        </div>
                    </div>
                    <div className="row clearfix">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      Note : Client to respond the report confirmation & acknoledgement form within 3 working days upon received the report.
                    </div>
                    </div>
                    <br></br>
                    <div className="row clearfix">
                    <div className="col-sm-4">
                    {values.send_for_draft_audit_report_date == null ?
                    <button 
                          disabled={isSubmitting}
                          type="submit" 
                          className="btn btn-primary"
                        >
                          {isSubmitting ? 'Submiting...' : 'Submit'}
                        </button>
                        : null}
                      &nbsp;
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}
                      >
                        Cancel
                      </Button>
                    </div>
                    </div>

                    </div>
                  </Card>
                </div>
              </div>
            </FormikForm>
          </div>
        )}
      </Formik>
  )
}

export default FormMspo;