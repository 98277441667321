import React, { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import {
  ButtonGroup,
  Button,
  Card,
} from "reactstrap";

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import paginationFactory from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

const Listing = (props) => {

  const { addToast } = useToasts();

  let { path } = useRouteMatch();

  let history = useHistory();

  const [data, setData] = useState([]);

  const [query, setQuery] = useState(INITIAL_QUERY);

  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const [load, reload] = useState(true);

  const columns = [
    {
      dataField: "client.name",
      text: "Client Name"
    },
    {
      dataField: "id",
      text: "",
      formatter: (cell, row, rowIndex) => {
        // let type;
    
        // switch (parseInt(row.type)) {
        //   default:
        //   case 1:
        //     type = 'iso';
        //     break;
    
        //   case 2:
        //     type = 'mspo';
        //     break;
    
        //   case 3:
        //     type = 'mspo-sccs';
        //     break;
        // }
        
        return (
          <ButtonGroup size="sm">
            <Button outline tag={Link} to={`${path}/view/${cell}`} >
              <i className="icon-eye"></i>
            </Button>
            <Button outline tag={Link} to={`${path}/edit/${cell}`}>
              <i className="icon-pencil"></i>
            </Button>
            <Button outline color="danger" onClick={() => deleteHandler(cell)}>
              <i className="icon-trash"></i>
            </Button>
          </ButtonGroup>
        )
      },
      style: { textAlign: 'right' }
    }
  ];

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/client-revenues', {
        params: query,
      })
      .then(function (response) {
        // console.log(response);

        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));

        reload(false);
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }

        reload(false);
      });
    }

    getData();
  }, [props, query, load]);

  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete('/client-revenues/' + id)
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        addToast('Revenue successfully deleted', { appearance: 'success' });

        history.push(`${path}`);

        reload(true);
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <React.Fragment>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <Card>
            <div className="header">
              <h2>REVENUE LIST</h2>
              <ul className="header-dropdown">
                <li>
                  <Button 
                    className="m-t-10 m-l-20"
                    color="primary" 
                    tag={Link} 
                    to={`${props.path}/create`}
                  >
                    Add New
                  </Button>
                </li>
              </ul>
            </div>
            <div className="body">
              <div className="body project_report">
                <div className="table">
                  <BootstrapTable 
                    bootstrap4
                    remote={true}
                    bordered={false}
                    classes="table-hover js-basic-example dataTable table-custom m-b-0"
                    headerClasses="thead-dark"
                    keyField='id' 
                    data={ data } 
                    columns={ columns }
                    pagination={ 
                      paginationFactory(pagingInfo)
                    }
                    onTableChange={handleTableChange}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Listing;