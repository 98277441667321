import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

// import {
//   InputGroup,
// } from 'react-bootstrap';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import Select from 'react-select'

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import api from '../../../utils/api';

import moment from 'moment';

import DatePicker from 'reactstrap-date-picker';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import SidebarToggler from '../../../components/SidebarToggler';

import { objectToFormData } from 'object-to-formdata';

import 'bootstrap-daterangepicker/daterangepicker.css';

const INITIAL_VALUES = {
  // date_of_audit_start: "",
  // date_of_audit_end: "",
  // audit_team_members: [],
  sampled_units: [],
  competency_matrix: [],
  auditor_date: [],
//  mandays: [],
  auditor_role: [],
};
const OTHERMEMBERS = {
  fullname: '',
  email: '',
  role: '',
}

const AuditObjectives = {
  "iso":{
    "Stage 1": "1. To audit ORGANISATION’s management systems documentation;\n2. To evaluate ORGANISATION location and site-specific conditions and to undertake discussions with the client’s personnel to determine the preparedness for the stage 2 audit;\n3. To review ORGANISATION status and understanding regarding requirements of the standard, in particular with respect to the identification of key performance or significant aspects, processes, objectives and operation of the management system;\n4. To collect necessary information regarding the scope of the management system, processes and location(s) of ORGANISATION, and related statutory and regulatory aspects and compliance;\n5. To review the allocation of resources for stage 2 audit and agree with ORGANISATION on the details of the stage 2 audit;\n6. To provide a focus for planning the stage 2 audit by gaining a sufficient understanding ORGANISATION management system and site operations in the context of possible significant aspects; and\n7. To evaluate if the internal audits and management review are being planned and performed, and that the level of implementation of the management system substantiates that ORGANISATION is ready for the stage 2 audit.",
    "Stage 2": "To evaluate the implementation, including effectiveness, of the ORGANISATION’s Management System. It shall include the auditing of at least the following:\na. Information and evidence about conformity to all requirements of the applicable management system standard or other normative document;\nb. Performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable management system standard or other normative document);\nc. The ORGANISATION management system ability and its performance regarding meeting of applicable statutory, regulatory and contractual requirements;\nd. Operational control of the ORGANISATION processes;\ne. Internal auditing and management review;\nf. Management responsibility for the ORGANISATION policies.",
    "Surveillance 1": "The objectives of the Surveillance Audit are:\na. To determine the continued conformance of ORGANISATION management system to the standards requirements applied;\nb. To review & evaluate internal audits and management review;\nc. To assess changes that has been made to the ORGANISATION of its management system;\nd. To evaluate the compliance of ORGANISATION activities with its own policies, objectives and procedures in achieving performance improvement and ensuring regulatory compliance;\ne. To verify action taken on nonconformities identified during the previous audit;\nf. To assess the treatment of complaints related to management system; and\ng. To verify the use of marks and/or any other reference to certification.",
    "Surveillance 2": "The objectives of the Surveillance Audit are:\na. To determine the continued conformance of ORGANISATION management system to the standards requirements applied;\nb. To review & evaluate internal audits and management review;\nc. To assess changes that has been made to the ORGANISATION of its management system;\nd. To evaluate the compliance of ORGANISATION activities with its own policies, objectives and procedures in achieving performance improvement and ensuring regulatory compliance;\ne. To verify action taken on nonconformities identified during the previous audit;\nf. To assess the treatment of complaints related to management system; and\ng. To verify the use of marks and/or any other reference to certification.",
    "Surveillance 3": "The objectives of the Surveillance Audit are:\na. To determine the continued conformance of ORGANISATION management system to the standards requirements applied;\nb. To review & evaluate internal audits and management review;\nc. To assess changes that has been made to the ORGANISATION of its management system;\nd. To evaluate the compliance of ORGANISATION activities with its own policies, objectives and procedures in achieving performance improvement and ensuring regulatory compliance;\ne. To verify action taken on nonconformities identified during the previous audit;\nf. To assess the treatment of complaints related to management system; and\ng. To verify the use of marks and/or any other reference to certification."
  },
  "part-2": {
    "Stage 1": "a.	To verify the preparedness of applicant to undergo stage 2 audit by verifying availability of documents such as procedures, policies, management and monitoring plans as specified by the respective MSPO standard indicators;\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard indicators or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the sampled smallholder’s performance with regards to legal compliance;\ne.	To evaluate and verify the group manager’s responsibility towards the endorsed policies;\nf.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures and performance data.",
    "Stage 2": "a.	To verify the effectiveness of implementation for all areas of concerns raised during the stage 1 audit (if any);\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify all sampled smallholders performance with regards to legal compliance;\ne.	To verify and evaluate the sampled smallholder’s operational control of processes;\nf.	To evaluate and verify the group manager and sampled smallholder’s responsibility towards the endorsed policies;\ng.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;",
    "Surveillance 1": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify all sampled smallholders performance with regards to legal compliance;\ne.	To verify and evaluate the sampled smallholder’s operational control of processes;\nf.	To evaluate and verify the group manager and sampled smallholder’s responsibility towards the endorsed policies;\ng.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\nh.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 2": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify all sampled smallholders performance with regards to legal compliance;\ne.	To verify and evaluate the sampled smallholder’s operational control of processes;\nf.	To evaluate and verify the group manager and sampled smallholder’s responsibility towards the endorsed policies;\ng.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\nh.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 3": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify all sampled smallholders performance with regards to legal compliance;\ne.	To verify and evaluate the sampled smallholder’s operational control of processes;\nf.	To evaluate and verify the group manager and sampled smallholder’s responsibility towards the endorsed policies;\ng.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\nh.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 4": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019."
  },
  "part-3": {
    "Follow Up Audit": "\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n\n*applicable for remote audit",
    "Re-Certification Cum Migration":"\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n\n*applicable for remote audit",
    "Re-Certification":"\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n\n*applicable for remote audit",
    "Initial Audit":"\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n\n*applicable for remote audit",
    "Stage 1": "a.	To verify the preparedness of applicant to undergo stage 2 audit by verifying availability of documents such as procedures, policies, management and monitoring plans as specified by the respective MSPO standard indicators;\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard indicators or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents); \nd.	To verify the applicant’s performance with regards to legal compliance; \ne.	To evaluate and verify the management’s responsibility towards the endorsed policies; \nf.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal  requirements, responsibilities, competence of personnel, operations, procedures and performance data. \n\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n*applicable for remote audit",
    "Stage 2": "a.	To verify the effectiveness of implementation for all areas of concerns raised during the stage 1 audit (if any);\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the applicant’s performance with regards to legal compliance;\ne.	To verify and evaluate the applicant’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\n\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n*applicable for remote audit",
    "Surveillance 1": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.\n\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n*applicable for remote audit",
    "Surveillance 2": "a. To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.\n\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n*applicable for remote audit",
    "Surveillance 3": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.\n\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n*applicable for remote audit",
    "Surveillance 4": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019."
  },
  "part-4": {
    "Stage 1": "a.	To verify the preparedness of applicant to undergo stage 2 audit by verifying availability of documents such as procedures, policies, management and monitoring plans as specified by the respective MSPO standard indicators;\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard indicators or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents); \nd.	To verify the applicant’s performance with regards to legal compliance; \ne.	To evaluate and verify the management’s responsibility towards the endorsed policies; \nf.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal  requirements, responsibilities, competence of personnel, operations, procedures and performance data. \n\n*To conduct an audit by using electronic means to remotely obtain audit evidence and evaluate it objectively in order to determine the extend of conformity to the audit criteria. \n*applicable for remote audit",
    "Stage 2": "a.	To verify the effectiveness of implementation for all areas of concerns raised during the stage 1 audit (if any);\nb.	To verify and collate Information and evidence of conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the applicant’s performance with regards to legal compliance;\ne.	To verify and evaluate the applicant’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.",
    "Surveillance 1": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 2": "a. To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 3": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 4": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019."
  },
  "mspo-sccs": {
    "Initial Audit": "a.	To evaluate the implementation, including effectiveness of the applicant’s MSPO SCCS documentation in the processing of MSPO certified palm oil products;\nb.	To verify and evaluate performance monitoring, measuring, reporting, reviewing against key performance and evidence about conformity to all requirements of the applicable MSPO SCCS standard;\nc.	To verify and evaluate the adequacy of Internal auditing and management review done;\nd.	To evaluate and verify the management’s responsibility towards the endorsed MSPO SCCS policy;\ne.	To evaluate management’s actions and plans on resource management;\nTo review any information on complaints and grievances and evaluate the action (s) taken by the applicant in addressing the complaints; \ng.	Verification of the applicant’s traceability procedures;\nh.	Verification and confirmation on the type of Supply Chain Model used by the applicant;\ni.	Verification of outsourced activities and compliance towards the MSPO SCCS standard;",
    "Surveillance 1": "a.	To evaluate the effectiveness of correction and corrective action (s) for non-conformance (s) raised from the previous audit.\nb.	To evaluate the implementation, including effectiveness of the certificate holder’s MSPO SCCS documentation in the processing of MSPO certified palm oil products;\nc.	To verify and evaluate performance monitoring, measuring, reporting, reviewing against key performance and evidence about conformity to all requirements of the applicable MSPO SCCS standard or other normative documents;\nd.	To verify and evaluate the adequacy of Internal auditing and management review done;\ne.	To evaluate and verify the management’s responsibility towards the endorsed MSPO SCCS policy;\nf.	To review any information on complaints and grievances and evaluate the action (s) taken by the applicant in addressing the complaints;\ng.	To review any information on complaints and grievances and evaluate the action (s) taken by the applicant in addressing the complaints;\nh.	Verification of the applicant’s traceability procedures;\ni.	Verification and confirmation on the type of Supply Chain Model used by the certificate holder;\nj.	Verification of outsourced activities and compliance towards the MSPO SCCS standard;\nk.	To verify claims made by the certificate holder to be in accordance with the MSPO SCCS standard and system requirement.\nl.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 2": "a.	To evaluate the effectiveness of correction and corrective action (s) for non-conformance (s) raised from the previous audit.\nb.	To evaluate the implementation, including effectiveness of the certificate holder’s MSPO SCCS documentation in the processing of MSPO certified palm oil products;\nc.	To verify and evaluate performance monitoring, measuring, reporting, reviewing against key performance and evidence about conformity to all requirements of the applicable MSPO SCCS standard or other normative documents;\nd.	To verify and evaluate the adequacy of Internal auditing and management review done;\ne.	To evaluate and verify the management’s responsibility towards the endorsed MSPO SCCS policy;\nf.	To review any information on complaints and grievances and evaluate the action (s) taken by the applicant in addressing the complaints;\ng.	To review any information on complaints and grievances and evaluate the action (s) taken by the applicant in addressing the complaints;\nh.	Verification of the applicant’s traceability procedures;\ni.	Verification and confirmation on the type of Supply Chain Model used by the certificate holder;\nj.	Verification of outsourced activities and compliance towards the MSPO SCCS standard;\nk.	To verify claims made by the certificate holder to be in accordance with the MSPO SCCS standard and system requirement.\nl.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 3": "a.	To evaluate the effectiveness of correction and corrective action (s) for non-conformance (s) raised from the previous audit.\nb.	To evaluate the implementation, including effectiveness of the certificate holder’s MSPO SCCS documentation in the processing of MSPO certified palm oil products;\nc.	To verify and evaluate performance monitoring, measuring, reporting, reviewing against key performance and evidence about conformity to all requirements of the applicable MSPO SCCS standard or other normative documents;\nd.	To verify and evaluate the adequacy of Internal auditing and management review done;\ne.	To evaluate and verify the management’s responsibility towards the endorsed MSPO SCCS policy;\nf.	To review any information on complaints and grievances and evaluate the action (s) taken by the applicant in addressing the complaints;\ng.	To review any information on complaints and grievances and evaluate the action (s) taken by the applicant in addressing the complaints;\nh.	Verification of the applicant’s traceability procedures;\ni.	Verification and confirmation on the type of Supply Chain Model used by the certificate holder;\nj.	Verification of outsourced activities and compliance towards the MSPO SCCS standard;\nk.	To verify claims made by the certificate holder to be in accordance with the MSPO SCCS standard and system requirement.\nl.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019.",
    "Surveillance 4": "a.	To verify effectiveness and implementation of all correction and corrective actions for non-conformances raised in the previous audit (if any);\nb.	To verify and collate Information and evidence about conformity to all requirements of the applicable MSPO standard or relevant normative documents;\nc.	To evaluate performance monitoring, measuring, reporting and reviewing against key performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents);\nd.	To verify the certificate holder’s performance with regards to legal compliance;\ne.	To verify and evaluate the certificate holder’s operational control of processes;\nf.	To verify and evaluate the adequacy of Internal auditing and management review done;\ng.	To evaluate and verify the management’s responsibility towards the endorsed policies;\nh.	To identify and evaluate links between the normative requirements, policies, procedures, performance objectives and targets (consistent with the expectations in the applicable MSPO standard or relevant normative documents), any applicable legal requirements, responsibilities, competence of personnel, operations, procedures, performance data and internal audit findings and conclusions;\ni.	To identify and verify external service providers such as contractors whom are operating  in the concession of the certificate holder are complying with the respective MSPO standard and relevant normative documents.\nj.	To verify and ensure that if there is MSPO logo usage, then it shall be in accordance with MSPOLGP04 1 Oct 2019."
  }
};

const Form = (props) => {

  let { id, type, subtype } = useParams();

  // let { path } = useRouteMatch();
  let history = useHistory();
  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client" } };

  const { addToast } = useToasts();

  let [profile, setProfile] = useState({});
  const [data, setData] = useState(INITIAL_VALUES);
  const [clients, setClient] = useState([]);
  const [auditors, setAuditor] = useState([]);
  const [CERTIFICATIONS, setCertifications] = useState({});
  const [PRINCIPLES, setPrinciples] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
 
  useEffect(() => {

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
        console.log(response.data.roles);

          if (response.data.roles && response.data.roles[0].name.toLowerCase() === 'auditor')  {
            console.log('masuk sini');
            setReadOnly(true);
          }
         setProfile(response.data);

// {profile && profile.roles[0].name.toLowerCase() === 'ncsb admin' ?    : ''}
//{profile && profile.roles.find(r=>r.name.toLowerCase() === 'ncsb admin')?    : ''}
        if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') === "undefined") {
          history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype).replace(/:id/, ''));
        }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {

        setCertifications(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    const getClients = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      // if (type.toUpperCase() === 'ISO') {
      //   url = '/clients?fields[]=id&fields[]=name&type=' + type + '&limit=-1';
      //   url = '/clients?fields[]=id&fields[]=name&type[]=MSPO&type[]=MSPO-SCCS&limit=-1';
      // }
      //HIDE BY KIM AT 28 SEP 2022 DUE TO  NOT ALL CLIENT SET THE TYPE ACCORDINGLY
     /*  let url = '';
      switch (type.toUpperCase()) {
        case 'ISO':
          url = '/clients?fields[]=id&fields[]=name&type=ISO&limit=-1';
          break;

        case 'MSPO':
          url = '/clients?fields[]=id&fields[]=name&type=MSPO&subtype=' + subtype + '&limit=-1';
          break;

        case 'MSPO-SCCS':
          url = '/clients?fields[]=id&fields[]=name&type=MSPO-SCCS&limit=-1';
          break;

        default:
          url = '';
          break;
      } */

      api.get('/clients?fields[]=id&fields[]=name&limit=-1')
      .then(function (response) {
         console.log(response.data);

        setClient(response.data.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getClients();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=business_email&fields[]=private_email&fields[]=private_mobile&fields[]=profile&limit=-1')
      .then(function (response) {
        // console.log(response.data);

        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.full_name,
          value: auditor.id,
          contact_no: auditor.contact_no,
          profile: auditor.profile,
          business_email: auditor.business_email ? auditor.business_email : auditor.private_email
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audit-plans/' + id)
        .then(function (response) {
          console.log(response);
          /* if (response.data.data.competency_matrix) {
            let selectedCompetencyMatrix = [];
            response.data.data.competency_matrix.map((matrix, key) => {
              return selectedCompetencyMatrix.push(matrix.id);
            });

          } */
          /* if(response.data.data && response.data.data.mandays){
            
            let displayMandays = [];
            response.data.data.mandays.map((manday, key) => {
              return displayMandays.push({
                id :  manday.auditor_id,
                mandays: manday.mandays,
              });
            });
            response.data.data.mandays = displayMandays;  
          }
          else{
            
            response.data.data.mandays =null;
          } */
           if (response.data.data.client.certifications) {
            let selectedCertifications = [];
            response.data.data.client.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.data.client.certifications = selectedCertifications;
          }

          /*if (response.data.data.client) {
            let selectedClient = [];
           // response.data.data.client.map((client, key) => {
              return selectedClient.push(response.data.data.client.id);
          //  });

            response.data.data.client = selectedClient;
          } */


          if (response.data.data.lead_auditors && response.data.data.lead_auditors.length) {
            let letSelectedAuditors = [];
            response.data.data.lead_auditors.map((auditor, i) => {
              return letSelectedAuditors.push({
                label: auditor.full_name,
                value: auditor.id,
                contact_no: auditor.contact_no,
                profile: auditor.profile,
                business_email: auditor.business_email
              });
            })

            response.data.data.lead_auditors = letSelectedAuditors;
          }


          if (response.data.data.audit_team_members && response.data.data.audit_team_members.length) {
            let letSelectedAuditors2 = [];
            response.data.data.audit_team_members.map((auditor, i) => {
              return letSelectedAuditors2.push({
                label: auditor.full_name,
                value: auditor.id,
                contact_no: auditor.contact_no,
                profile: auditor.profile,
                business_email: auditor.business_email
              });
            })

            response.data.data.audit_team_members = letSelectedAuditors2;
          }
          let principles = [];
          if(type.toUpperCase() !== 'MSPO-SCCS'){

            principles = 
            [
              'Principle 1: Management, Commitment and Responsibility',
              'Principle 2: Transparency',
              'Principle 3: Compliance to Legal Requirements',
              'Principle 4: Social Responsibility, Health, Safety and Employment Conditions',
              'Principle 5: Environment, Natural Resources, Biodiversity and Ecosystem Services',
              'Principle 6: Best Practices',
              'Principle 7: Development of  New Plantings'
            ];
            setPrinciples(principles);
          }else{
            principles =  [
              'Clause 5: Requirements of supply chain management system',
              'Clause 6: Resources Management',
              'Clause 7: Traceability',
              'Clause 8: Supply chain models',
              'Clasuse 9: Outsourced activities',
              'Clause 10: Claim',
              'Clause 11: Complaints and grievances',
              'Clause 12: IT Platform (MSPO Trace)'
            ];
            setPrinciples(principles);

          }
     /*     console.log(response.data.data.lead_auditors[0].value);
           var auditdate='';
           var test = response.data.data.lead_auditors[0].value;
          response.data.data.auditor_date.map((a_date,l)=>{ 
             if(a_date.test.indexOf(test) > -1){
             // auditdate = a_date.response.data.data.lead_auditors[0].id;
             console.log('ini audit date :');
            }  
            
          }); */
          
          delete response.data.data.id;

          // if (response.data.audit_team_members) {
          //   let selectedAuditor = [];
          //   response.data.audit_team_members.map((audit_team_member, key) => {
          //     selectedAuditor.push(audit_team_member.id);
          //   });

          //   response.data.audit_team_members = selectedAuditor;
          // }

          setData(response.data.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ').replace(subtype.charAt(0), subtype.charAt(0).toUpperCase())}` : ""} Audit Plan
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                Audit Plan
              </li>
              <li className="breadcrumb-item">
                <Link to={props.path.replace(/:type/, type).replace(/:subtype/, subtype).replace(/:id/, '')}>
                  {type.toUpperCase()}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

         // values.len = values.mandays;
          const dataList = [];
          console.log(values.mandays);
         /*  for (i=0; i < values.len; i++){
            dataList.push({: values.question[i]['title'],'question':values.question[i]['question'],'answer':values.value[i] ? values.value[i]:null,'comment':values.comment && values.comment[i] ? values.comment[i]:null});
          } 
          values.mandays = dataList ; */
          values.type = type;

          values.subtype = subtype;

          values.working_language = "English and Bahasa Malaysia";

          values.others = "a.	Times are approximate and will be confirmed at the opening meeting prior to commencement of the audit. Auditors reserve the right to change or add to the elements listed before or during the audit depending on the results of on-site investigation.\nb.	Policy, awareness, communication, documented information, objective and program shall be covered at the respective areas and departmental functional activities where appropriate. \nc.	*If an incident/interruption occurs during the remote access, the audit team leader should review the situation with the auditee and, if necessary, with the audit client and reach agreement on whether the audit should be interrupted, rescheduled or continued; \nd.	*Maintain respect for privacy during audit breaks; and \ne.	*The client shall ensure that full cooperation is provided (e.g. contactable and able to provide information to the extent necessary, when needed.\n\n*applicable for remote audit.";

 /*         const competency_matrix = [];
          [
            'Principle 1: Management, Commitment and Responsibility',
            'Principle 2: Transparency',
            'Principle 3: Compliance to Legal Requirements',
            'Principle 4: Social Responsibility, Health, Safety and Employment Conditions',
            'Principle 5: Environment, Natural Resources, Biodiversity and Ecosystem Services',
            'Principle 6: Best Practices',
            'Principle 7: Development of  New Plantings'
          ].map((item, i) =>{
            competency_matrix.push()
          }
           values.len = values.competency_matrix.length;
          let leadAuditors = [];
          let teamMembers = [];
          for (i=0; i < values.len; i++){
            reviewerComments.push(values.tr_comments && values.tr_comments[i] ? values.tr_comments[i]:null);
            leadAuditorComments.push(values.lead_auditor_comments && values.lead_auditor_comments[i] ? values.lead_auditor_comments[i]:null);
          } 
        
          values.tr_comments = reviewerComments ;
          values.lead_auditor_comments = leadAuditorComments ;
      */     
        //   if (id) {
        //     let obj1 = {'_method': 'PUT'}
        //  //   values = {...obj1, ...values}
        //   }
 
          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
          
          if (!id) {
            // api.post('/audit-plans', JSON.stringify(values), {
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            // })
            await api({
              method: 'POST',
              url: '/audit-plans',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            })
            .then(function (response) {
              // console.log(response);

              addToast('Audit plan successfully saved', { appearance: 'success' });

              history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));

              // window.location = '/client-application/iso';
            })
            .catch(function (error) {
              addToast(error.message, { appearance: 'error' });
            });
          } else {
            // data.prepend('_method', 'PUT');

            // api.put('/audit-plans/' + id, JSON.stringify(values), {
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            // })
           // console.log(values.competency_matrix);
            data.append('_method', 'PUT');
            await api({
              method: 'POST',
              url: '/audit-plans/' + id,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            })
            .then(function (response) {
              // console.log(response);

              addToast('Audit plan successfully updated', { appearance: 'success' });

              history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));

              // window.location = '/client-application/iso';
            })
            .catch(function (error) {
              addToast(error.message, { appearance: 'error' });
            });
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <Card>
                  <div className="header">
                    <h2>AUDIT PLAN</h2>
                  </div>
                  <div className="body">
                    
                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <FormGroup>
                          <Label for="AuditNo">
                            {type.toUpperCase() === 'ISO' ? 'Audit No.' : 'Ref. No.'}
                          </Label>
                          <Input 
                            type="text" 
                            name="audit_no" 
                            id="AuditNo"
                            onChange={handleChange}
                            value={values.audit_no}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-sm-4">
                        <FormGroup>
                          <Label for="Issue">Issue No.</Label>
                          <Input 
                            type="text" 
                            name="issue" 
                            id="Issue"
                            onChange={handleChange}
                            value={values.issue}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-sm-4">
                        <FormGroup>
                          <Label for="Date">Date</Label>
                          <DatePicker
                            autoComplete="off"
                            name="date" 
                            id="Date"
                            value={values.date}
                            onChange={date => setFieldValue('date', moment(date).format('YYYY-MM-DD'))}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="OtherAudit">Other Audit</Label>
                          <Input 
                            type="select" 
                            name="other_audit" 
                            id="OtherAudit"
                            onChange={handleChange}
                            value={values.other_audit}
                          >
                            <option value=""></option>
                            <option value="Transfer">Transfer</option>
                            <option value="Special Audit">Special Audit</option>
                            <option value="Onsite NC Verification">Onsite NC Verification</option>
                            <option value="Scope Extension Audit">Scope Extension Audit</option>
                          </Input>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="Issue">Type</Label>
                          <Input 
                            type="select" 
                            name="stage" 
                            id="Issue"
                            onChange={(e) => {
                              // console.log(e.currentTarget.options[e.currentTarget.selectedIndex].value);
                              
                              let stage = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                              
                              setFieldValue('stage', stage);
                              
                              // if (AuditObjectives[type]) {
                                // console.log(type);
                                if (AuditObjectives[subtype] && AuditObjectives[subtype][stage]) {
                                  // console.log(AuditObjectives[subtype][stage]);
                                  
                                  // console.log(typeof values.audit_objective == 'undefined');
                                  
                                  if (typeof values.audit_objective == 'undefined' || values.audit_objective == null) {
                                    setFieldValue('audit_objective', AuditObjectives[subtype][stage]);
                                  } else {
                                    if (window.confirm('Replace audit objective?')) {
                                      setFieldValue('audit_objective', AuditObjectives[subtype][stage]);
                                    }
                                  }
                                }
                              // }
                            }}
                            value={values.stage}
                          >
                            <option></option>
                            <option value="Follow Up Audit">Migration</option>
                            <option value="Re-Certification Cum Migration">Re-Certification Cum Migration</option>
                            <option value="Re-Certification">Re-Certification</option>
                            {/* <option value="Special Audit">Special Audit</option> */}
                            <option value="Initial Audit">Initial Audit</option>
                            <option value="Stage 1">Stage 1</option>
                            <option value="Stage 2">Stage 2</option>
                            
{/*                             <option value="Surveillance 1">Surveillance 1</option>
                            <option value="Surveillance 2">Surveillance 2</option>
                            <option value="Surveillance 3">Surveillance 3</option>
                            <option value="Surveillance 4">Surveillance 4</option>
 */}
                            <option value="Surveillance 1">1st Surveillance</option>
                            <option value="Surveillance 2">2nd Surveillance</option>
                            <option value="Surveillance 3">3rd Surveillance</option>
                            <option value="Surveillance 4">4th Surveillance</option>
                            {/* <option value="Transfer Surveillance 1">Transfer Surveillance 1</option> */}
                            {/* <option value="Surveillance 4 Cum Extention">Surveillance 4 Cum Extention</option> */}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Organization">Organization</Label>
                          <Input 
                            disabled={readOnly}
                            type="select" 
                            className="custom-select" 
                            name="client_id" 
                            id="Organization"
                            required
                            // onChange={handleChange}
                            onChange={async (e) => {
                              let { value } = e.currentTarget;

                              // console.log(value);

                              setFieldValue('client_id', value);

                              let token = await localStorage.getItem('auth-token');

                              token = JSON.parse(token);

                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                              api.get('/clients/' + value)
                              .then((response) => {
                                // console.log(response.data.certifications);
                               // console.log(response.data);
                                if(response.data && response.data.sampled_units){
                                  let displaySampledUnits = [];
                                  response.data.sampled_units.map((sampledUnits, key) => {
                                    return displaySampledUnits.push({
                                      management_units: sampledUnits.management_units,
                                      area: sampledUnits.area,
                                      initial_certification: sampledUnits.initial_certification,
                                      annual_surveillance_1: sampledUnits.annual_surveillance_1,
                                      annual_surveillance_2: sampledUnits.annual_surveillance_2,
                                      annual_surveillance_3: sampledUnits.annual_surveillance_3,
                                      annual_surveillance_4: sampledUnits.annual_surveillance_4,
                                      re_certification: sampledUnits.re_certification

                                    });
                                  });
                                 setFieldValue('client.sampled_units', displaySampledUnits);
                                }
                                else{
                                  setFieldValue('client.sampled_units', null);
                                }
                                
                              if (response.data && response.data.certifications) {
                                  let selectedCertifications = [];
                                  response.data.certifications.map((certification, key) => {
                                    return selectedCertifications.push(certification.id);
                                  });

                                  // console.log(selectedCertifications);

                                  setFieldValue('client.certifications', selectedCertifications);
                              }
                              /* else{
                                setFieldValue('client.certifications', '');
                              } */

                                if (response.data && response.data.scope_of_certification) {
                                  setFieldValue('client.scope_of_certification', response.data.scope_of_certification);
                                } else {
                                  setFieldValue('client.scope_of_certification', '');
                                }

                                if (response.data && response.data.contact_person_name) {
                                  setFieldValue('client.contact_person_name', response.data.contact_person_name);
                                } else {
                                  setFieldValue('client.contact_person_name', '');
                                }

                                if (response.data && response.data.sector_code) {
                                  setFieldValue('client.sector_code', response.data.sector_code);
                                } else {
                                  setFieldValue('client.sector_code', '');
                                }

                                // if (response.data && response.data.contract_review && response.data.contract_review.lead_auditor.full_name) {
                                //   setFieldValue('client.contract_review.lead_auditor.full_name', response.data.contract_review.lead_auditor.full_name);
                                // } else {
                                //   setFieldValue('client.contract_review.lead_auditor.full_name', '');
                                // }
                              })
                              .catch((error) => {

                              });
                            }}
                            value={values.client_id}
                          >
                            <option></option>
                            {clients && clients.map((client, i) => 
                              <option key={i} value={client.id}>{client.name}</option>
                            )}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="AuditObjective">Audit Objective</Label>
                          <Input 
                            type="textarea" 
                            name="audit_objective" 
                            id="AuditObjective"
                            onChange={handleChange}
                            value={values.audit_objective}
                            rows={12}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ScopeOfCertification">Scope Of Certification</Label>
                          <Input 
                            readOnly={true}
                            type="textarea" 
                            name="scope_of_certification" 
                            id="ScopeOfCertification"
                            // onChange={handleChange}
                            value={values.client && values.client.scope_of_certification ? values.client.scope_of_certification : ''}
                            rows={5}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    {subtype.toLowerCase() === 'part-2' || subtype.toLowerCase() === 'part-3' ? null :
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ScopeNo">Scope No.</Label>
                          <Input 
                            readOnly={true}
                            type="text"
                            name="scope_no" 
                            id="ScopeNo"
                            onChange={handleChange}
                            value={values.client && values.client.sector_code ? values.client.sector_code : ''}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    }

                    <div className="row clearfix">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="DateOfAudit">Audit Date</Label>
                          <DateRangePicker 
                            containerStyles={{ display: "block" }}
                            startDate={values.date_of_audit_start && values.date_of_audit_start.length ? moment(values.date_of_audit_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            onApply={(event, picker) => {
                              setFieldValue('date_of_audit_start', picker.startDate.format('YYYY-MM-DD'));
                              setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));
                            }}
                          >
                            <Input 
                              autoComplete="off"
                              type="text" 
                              // name="date_of_audit" 
                              id="DateOfAudit"
                              // onChange={handleChange}
                              value={values.date_of_audit_start && values.date_of_audit_end ? moment(values.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(values.date_of_audit_end).format('DD/MM/YYYY') : ""}
                            />
                          </DateRangePicker>
                        </FormGroup>
                      </div>

                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="LastAudit">Previous Audit Date</Label>
                          <DateRangePicker 
                            containerStyles={{ display: "block" }}
                            startDate={values.last_audit_date_start && values.last_audit_date_start.length ? moment(values.last_audit_date_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            endDate={values.last_audit_date_end && values.last_audit_date_end.length ? moment(values.last_audit_date_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            onApply={(event, picker) => {
                              console.log(picker.startDate.format('YYYY-MM-DD'));
                              console.log(picker.endDate.format('YYYY-MM-DD'));

                              // setFieldValue('last_audit', picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                              
                              setFieldValue('last_audit_date_start', picker.startDate.format('YYYY-MM-DD'));
                              setFieldValue('last_audit_date_end', picker.endDate.format('YYYY-MM-DD'));
                              
                            }}
                          >
                            <Input 
                              autoComplete="off"
                              type="text" 
                              // name="last_audit" 
                              id="LastAudit"
                              // onChange={handleChange}
                              value={values.last_audit_date_start && values.last_audit_date_end ? moment(values.last_audit_date_start).format('DD/MM/YYYY') + ' - ' + moment(values.last_audit_date_end).format('DD/MM/YYYY') : ""}
                            />
                          </DateRangePicker>
                        </FormGroup>
                      </div>
                    </div>
                    
                    <div className="row clearfix">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="audit_method">
                            Audit Method
                          </Label>
                          <div>
                            <label className="fancy-radio">
                              <Input
                                type="radio"
                                name="audit_method"
                                value="Full Remote Audit"
                                onClick={handleChange}
                                checked={values.audit_method && values.audit_method.toLowerCase() === 'full remote audit'}
                              />
                              <span>
                                <i></i> Full Remote Audit
                              </span>
                            </label>
                            
                            <label className="fancy-radio">
                              <Input
                                type="radio"
                                name="audit_method"
                                value="Partial Remote Audit"
                                onClick={handleChange}
                                checked={values.audit_method && values.audit_method.toLowerCase() === 'partial remote audit'}
                              />
                              <span>
                                <i></i> Partial Remote Audit
                              </span>
                            </label>

                            <label className="fancy-radio">
                              <Input
                                type="radio"
                                name="audit_method"
                                value="On-Site Audit"
                                onClick={handleChange}
                                checked={values.audit_method && values.audit_method.toLowerCase() === 'on-site audit'}
                              />
                              <span>
                                <i></i> On-Site Audit
                              </span>
                            </label>
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                    {values.audit_method && (values.audit_method.toLowerCase() === 'full remote audit' || values.audit_method.toLowerCase() === 'partial remote audit') ?
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="MethodOfICTTools">Method of ICT Tools</Label>
                          <Input 
                              autoComplete="off"
                              // readOnly={readOnly}
                              type="text" 
                              name="ict_tools" 
                              // id="LastAudit"
                              onChange={handleChange}
                              value={values.ict_tools}
                            />
                        </FormGroup>        
                      </div>      
                    </div>
                    :null}

                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <FormGroup>
                          <Label for="AuditRefStandards">Audit Reference Standards</Label>
                          {/* <Input 
                            readOnly={readOnly}
                            type="text" 
                            name="audit_ref_standards" 
                            id="AuditRefStandards"
                            onChange={handleChange}
                            value={values.audit_ref_standards}
                          /> */}
                         {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                            values.client && values.client.certifications && values.client.certifications.indexOf(CERTIFICATION.id) !== -1 ?
                              <React.Fragment key={key}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      // readOnly={readOnly}
                                      disabled={true}
                                      type="checkbox" 
                                      // name={`certifications`}
                                      // onChange={handleChange}
                                      // onChange={() => {
                                      //   let { certifications } = values;

                                      //   if (certifications && certifications.length) {
                                      //     var index = certifications.indexOf(CERTIFICATION.id);
                                      //     if (index !== -1) {
                                      //       certifications.splice(index, 1);
                                      //     } else {
                                      //       certifications.push(CERTIFICATION.id);
                                      //     }
                                      //   } else {
                                      //     certifications = [];
                                      //     certifications.push(CERTIFICATION.id);
                                      //   }

                                      //   setFieldValue('certifications', certifications);
                                      // }}
                                      /// value={CERTIFICATION.id}
                                      checked={values.client && values.client.certifications && values.client.certifications.length && values.client.certifications.indexOf(CERTIFICATION.id) !== -1} 
                                    />
                                    <span>
                                      {CERTIFICATION.standard} {CERTIFICATION.standard === 'MSPO' ? <span className="text-muted">{subtype === 'part-2' ? 'MS 2530-2:2013' : ''}{subtype === 'part-3' ? 'MS 2530-3:2013' : ''}{subtype === 'part-4' ? 'MS 2530-4:2013' : ''}{subtype === 'mspo-sccs' ? 'MSPO-SCCS-01' : ''}</span> : ""}
                                    </span>
                                  </label>
                                </div>
                              </React.Fragment>
                              : null
                            ) : null}
                              </FormGroup>
                      </div>
                      {subtype.toLowerCase() !== 'iso' ? 
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <FormGroup>
                            <Label for="no_of_smallholders_total">Total Unit(s)</Label>
                            <Input 
                              autoComplete="off"
                              // readOnly={readOnly}
                              type="text" 
                              name="no_of_smallholders_total" 
                              // id="LastAudit"
                              onChange={handleChange}
                              value={values.no_of_smallholders_total}
                            />
                          </FormGroup>
                        </div>
                      : null}
                    </div>

                    {type.toUpperCase().includes('MSPO') ?
                    <React.Fragment>
                      <div className="row clearfix">
                        <div className="col-sm-6">
                          <FormGroup>
                            <Label for="">
                              Sample Units Risk Category
                            </Label>
                            <div>
                              <label className="fancy-radio">
                                <Input
                                  type="radio"
                                  name="sample_unit_risk_category"
                                  value="1"
                                  onClick={handleChange}
                                  checked={parseInt(values.sample_unit_risk_category) === 1}
                                />
                                <span>
                                  <i></i> Low
                                </span>
                              </label>

                              <label className="fancy-radio">
                                <Input
                                  type="radio"
                                  name="sample_unit_risk_category"
                                  value="2"
                                  onClick={handleChange}
                                  checked={parseInt(values.sample_unit_risk_category) === 2}
                                />
                                <span>
                                  <i></i> Medium
                                </span>
                              </label>

                              <label className="fancy-radio">
                                <Input
                                  type="radio"
                                  name="sample_unit_risk_category"
                                  value="3"
                                  onClick={handleChange}
                                  checked={parseInt(values.sample_unit_risk_category) === 3}
                                />
                                <span>
                                  <i></i> High
                                </span>
                              </label>
                              
                              <label className="fancy-radio">
                                <Input
                                  type="radio"
                                  name="sample_unit_risk_category"
                                  value="0"
                                  onClick={handleChange}
                                  checked={parseInt(values.sample_unit_risk_category) === 0}
                                />
                                <span>
                                  <i></i> N/A
                                </span>
                              </label>
                            </div>
                          </FormGroup>
                        </div>
                        
                        {subtype.toLowerCase() !== 'mspo-sccs' ?
                        <div className="col-sm-6">
                          <FormGroup>
                            <Label for="no_of_smallholders_sampled">
                            {subtype.toLowerCase() === 'part-2' ? 'Total Sampled Unit' : 'Sampled Unit(s)'}
                            </Label>
                            <Input 
                              autoComplete="off"
                              // readOnly={readOnly}
                              type="text" 
                              name="no_of_smallholders_sampled" 
                              // id="LastAudit"
                              onChange={handleChange}
                              value={values.no_of_smallholders_sampled}
                            />
                          </FormGroup>
                        </div>
                        : null}
                        
                        {subtype.toLowerCase() !== 'mspo-sccs' ?
                        <div className="col-sm-12">
                          <FormGroup>
                            <Label for="no_of_smallholders_sampled">
                              Sampled Units 
                            </Label>
                            <div>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Management Units</th>
                                    <th>Area Certified (Ha) / Mill Capacity (MT/Hr)</th>
                                    <th>Initial Certification</th>
                                    <th>Annual Surveillance 1</th>
                                    <th>Annual Surveillance 2</th>
                                    <th>Annual Surveillance 3</th>
                                    <th>Annual Surveillance 4</th>
                                    <th>Re-Certification</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {values.client && values.client.sampled_units ? values.client.sampled_units.map((sampled_unit, i) =>
                                  /* {values.sampled_units ? values.sampled_units.map((sampled_unit, i) => */
                                   <tr key={i}>
                                    <td>{sampled_unit.management_units}</td>
                                    <td>{sampled_unit.area}</td>
                                    <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={true}
                                      readOnly={true}
                                      type="checkbox" 
                                      // onChange={(event) => {
                                      //   setFieldValue(`sampled_units.${key}.initial_certification`, event.currentTarget.checked === true ? 'true' : 'false')
                                      // }}
                                      checked={sampled_unit.initial_certification === 'true' ? 1 : 0} 
                                    //  checked={sampled_unit[i].initial_certification} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={true}
                                      readOnly={true}
                                      type="checkbox" 
                                      // onChange={(event) => {
                                      //   setFieldValue(`sampled_units.${key}.annual_surveillance_1`, event.currentTarget.checked === true ? 'true' : 'false')
                                      // }}
                                      checked={sampled_unit.annual_surveillance_1 === 'true' ? 1 : 0} 
                                      //checked={sampled_unit.annual_surveillance_1} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={true}
                                      readOnly={true}
                                      type="checkbox" 
                                      // onChange={(event) => {
                                      //  setFieldValue(`sampled_units.${key}.annual_surveillance_2`, event.currentTarget.checked === true ? 'true' : 'false')
                                      // }}
                                      checked={sampled_unit.annual_surveillance_2 === 'true' ? 1 : 0} 
                                      //checked={sampled_unit.annual_surveillance_2} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={true}
                                      readOnly={true}
                                      type="checkbox" 
                                      // onChange={(event) => {
                                      //  setFieldValue(`sampled_units.${key}.annual_surveillance_3`, event.currentTarget.checked === true ? 'true' : 'false')
                                      // }}
                                      checked={sampled_unit.annual_surveillance_3 === 'true' ? 1 : 0} 
                                      //checked={sampled_unit.annual_surveillance_3} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={true}
                                      readOnly={true}
                                      type="checkbox" 
                                      // onChange={(event) => {
                                      //  setFieldValue(`sampled_units.${key}.annual_surveillance_4`, event.currentTarget.checked === true ? 'true' : 'false')
                                      // }}
                                      checked={sampled_unit.annual_surveillance_4 === 'true' ? 1 : 0} 
                                      //checked={sampled_unit.annual_surveillance_4} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled={true}
                                      readOnly={true}
                                      type="checkbox" 
                                      checked={sampled_unit.re_certification === 'true' ? 1 : 0} 
                                    />
                                    <span>

                                    </span>
                                  </label>
                                </div>                                          
                              </td>
                                   </tr>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </FormGroup>
                        </div>
                        : null}
                        
                        {subtype.toLowerCase() === 'mspo-sccs' ?
                        <div className="col-sm-6">
                          <FormGroup>
                            <Label for="supply_chain_model">
                              Supply Chain Model
                            </Label>
                            <div>
                              <label className="fancy-radio">
                                <Input
                                  type="radio"
                                  name="supply_chain_model"
                                  value="1"
                                  onClick={handleChange}
                                  checked={parseInt(values.supply_chain_model) === 1}
                                />
                                <span>
                                  <i></i> Mass Balance
                                </span>
                              </label>

                              <label className="fancy-radio">
                                <Input
                                  type="radio"
                                  name="supply_chain_model"
                                  value="2"
                                  onClick={handleChange}
                                  checked={parseInt(values.supply_chain_model) === 2}
                                />
                                <span>
                                  <i></i> Segregation
                                </span>
                              </label>
                              
                              <label className="fancy-radio">
                                <Input
                                  type="radio"
                                  name="supply_chain_model"
                                  value="3"
                                  onClick={handleChange}
                                  checked={parseInt(values.supply_chain_model) === 3}
                                />
                                <span>
                                  <i></i> Mass Balance &amp; Segregation
                                </span>
                              </label>
                            </div>
                          </FormGroup>
                        </div>
                        : null}
                      </div>
                    </React.Fragment>
                    : null}

                    {subtype.toLowerCase() === 'part-3' ? null :
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ClientDocToBeAudit">Client Documentation To Be Audit</Label>
                          <Input 
                            type="textarea" 
                            name="client_doc_to_be_audit" 
                            id="ClientDocToBeAudit"
                            onChange={handleChange}
                            value={values.client_doc_to_be_audit || 'Company\'s Manual, Procedure (latest issue), previous audit report and relevant records. '}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    }

                    {type !== 'mspo' ?
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="AuditVenue">Audit Venue</Label>
                          <Input 
                            type="textarea" 
                            name="audit_venue" 
                            id="AuditVenue"
                            onChange={handleChange}
                            value={values.audit_venue}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    : null}

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ContactPerson">Contact Person</Label>
                          <Input 
                            readOnly={true}
                            type="text" 
                            name="contact_person" 
                            id="ContactPerson"
                            onChange={handleChange}
                            value={values.client && values.client.contact_person_name ? values.client.contact_person_name : ''}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="LeadAuditor">Lead Auditor</Label>
                          {/* <Input 
                            readOnly={true}
                            type="text" 
                            // name="contact_person" 
                            // id="ContactPerson"
                            // onChange={handleChange}
                            value={values.client && values.client.contract_review && values.client.contract_review.lead_auditor ? values.client.contract_review.lead_auditor.full_name : ''}
                          /> */}
                          {/* <Input 
                            readOnly={readOnly}
                            type="select" 
                            className="custom-select" 
                            name="lead_auditor_id" 
                            id="LeadAuditor"
                            onChange={handleChange}
                            value={values.lead_auditor_id}
                          >
                            <option></option>
                            {auditors && auditors.map((auditor, i) => 
                              <option key={i} value={auditor.id}>{auditor.full_name}</option>
                            )}
                          </Input> */}
                          <Select
                            inputId="LeadAuditor"
                            name="lead_auditors"
                            isMulti
                            placeholder=""
                            openMenuOnFocus={true}
                            options={auditors} 
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                color: '#495057',
                                backgroundColor: 'transparent', 
                                borderColor: '#ced4da !important', 
                                boxShadow: 'none',
                                outline: 'none',
                              })
                            }}
                            value={values.lead_auditors}
                            onChange={(selectedAuditors) => {
                              // console.log(auditors);

                              // let id = [];

                              // selectedAuditors.map((auditor, i) =>
                              //   id.push(auditor.value)
                              // );

                              setFieldValue('lead_auditors', selectedAuditors);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="AuditTeamMembers">{type.toUpperCase() !== 'ISO' ? 'Audit Team Members' : 'Auditors'}</Label>
                          {/* {values.client && values.client.contract_review && values.client.contract_review.auditors 
                          ? values.client.contract_review.auditors.map((auditor, key) =>
                            <Input 
                              key={key}
                              readOnly={true}
                              type="text" 
                              value={auditor.full_name}
                            />
                          )
                          : null} */}
                          <Select
                            // className="custom-select"
                            // classNamePrefix=""
                            inputId="AuditTeamMembers"
                            name="auditors"
                            isMulti 
                            placeholder=""
                            openMenuOnFocus={true}
                            options={auditors} 
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                color: '#495057',
                                backgroundColor: 'transparent', 
                                borderColor: '#ced4da !important', 
                                boxShadow: 'none',
                                outline: 'none',
                              })
                            }}
                            // theme={theme => ({
                            //   ...theme,
                            //   borderColor: "#ced4da"
                            // })}
                            value={values.audit_team_members}
                            onChange={(selectedAuditors) => {
                              // console.log(auditors);

                              // let id = [];

                              // selectedAuditors.map((auditor, i) =>
                              //   id.push(auditor.value)
                              // );

                              setFieldValue('audit_team_members', selectedAuditors);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    {type.toUpperCase() === 'ISO' ?
                    <React.Fragment>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Auditors</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                              <th className="text-center">{lead_auditor.label}</th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th className="text-center">{member.label}</th>
                            ) : null}
                          </tr>
                          <tr>
                            <th style={{ verticalAlign: "top" }}>Role</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                              <th> 
                                 <Input 
                                  type="select" 
                                  onChange={handleChange}
                                  name={`auditor_role.${i}.role`}
                                  value={values.auditor_role && values.auditor_role[`${i}`] ? values.auditor_role[`${i}`][`role`] : null}
                              //    value={values.auditor_role}
                                >
                                  <option value="">Please select</option>                          
                                  <option value="Lead Auditor">Lead Auditor</option>                          
                                </Input>
                              </th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th>
                              <Input 
                                  type="select" 
                                  onChange={handleChange}
                                  name={`auditor_role.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0 }.role`}
                                  value={values.auditor_role && values.auditor_role[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`] ? values.auditor_role[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`][`role`] : null}
                              //    value={values.auditor_role}
                                >
                                  <option value="">Please select</option>                          
                                  <option value="Lead Auditor In Training">Lead Auditor In Training</option>                          
                                  <option value="Auditor">Auditor</option>
                                  <option value="Auditor In Training">Auditor In Training</option>                          
                                </Input>
                                
                              </th>
                             ) : null} 
                          </tr>
                          <tr>
                            <th>Mandays</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                            
                          
                              <th className="text-center"  key={i}> 

                              <Input 
                                  type="text" 
                                
                                  onChange={handleChange}
                                  name={`mandays.${i}.mandays`}
                                  value={values.mandays && values.mandays[`${i}`] ? values.mandays[`${i}`][`mandays`] : null}
                                  rows={5}
                                    />
                                  
                              </th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th className="text-center"  key={i}>
                                <Input 
                                  type="text" 
                                  onChange={handleChange}
                                  name={`mandays.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}.mandays`}
                                  value={values.mandays && values.mandays[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`] ? values.mandays[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`][`mandays`] : ''}
                                  rows={5}
                                />
                              </th>
                            ) : null}
                          </tr>                         
                          <tr>
                          </tr>
                         
                          <tr>
                            <th>Email</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                              <th className="text-center">{lead_auditor.business_email}</th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th className="text-center">{member.business_email ? member.business_email : '-' }</th>
                            ) : null}
                          </tr>
                        </thead>
                      </table>
                    </React.Fragment>
                    : null}        
                    {type.toUpperCase() === 'MSPO' || type.toUpperCase() === 'MSPO-SCCS' ?
                    <React.Fragment>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Audit Team Member</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                              <th className="text-center">{lead_auditor.label}</th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th className="text-center">{member.label}</th>
                            ) : null}
                          </tr>
                          <tr>
                            <th style={{ verticalAlign: "top" }}>Role</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                              <th> 
                                 <Input 
                                  type="select" 
                                  onChange={handleChange}
                                  name={`auditor_role.${i}.role`}
                                  value={values.auditor_role && values.auditor_role[`${i}`] ? values.auditor_role[`${i}`][`role`] : null}
                              //    value={values.auditor_role}
                                >
                                  <option value="">Please select</option>                          
                                  <option value="Lead Auditor">Lead Auditor</option>                          
                                </Input>
                              </th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th>
                              <Input 
                                  type="select" 
                                  onChange={handleChange}
                                  name={`auditor_role.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0 }.role`}
                                  value={values.auditor_role && values.auditor_role[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`] ? values.auditor_role[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`][`role`] : null}
                              //    value={values.auditor_role}
                                >
                                  <option value="">Please select</option>                          
                                  <option value="Lead Auditor In Training">Lead Auditor In Training</option>                          
                                  <option value="Auditor">Auditor</option>
                                  <option value="Auditor In Training">Auditor In Training</option>                          
                                </Input>
                                
                              </th>
                             ) : null} 
                          </tr>
                          <tr>
                            <th>Audit Date</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>

                              <th className="text-center">
                                <Input 
                                  type="text" 
                                  // className="custom-select" 
                                  // name="auditor_profile" 
                                  // id="PreferredContactPoint"
                                 /*  onChange={(event) => {
                                    setFieldValue(`auditor_date.${i}.${lead_auditor.value}`, event.target.value);
                                  }}
                                  name={`auditor_date.${i}.${lead_auditor.value}`} */
                                 /*  value={values.auditor_date && values.auditor_date[i] ? values.auditor_date[`${i}`][`${lead_auditor.value}`]  : ''} */
                                
                                 /*  value={values.auditor_date && values.auditor_date.length ?
                                    values.auditor_date.map((lead_auditor_date, j) =>
                                    lead_auditor_date[i].indexOf(lead_auditor.value) > -1 ?
                                    lead_auditor_date[`${i}`][`${lead_auditor.value}`] : null
                                    ).join(''):null} */
                                  onChange={handleChange}
                                  name={`auditor_date.${i}.date`}
                                  value={values.auditor_date && values.auditor_date[`${i}`] ? values.auditor_date[`${i}`][`date`] : null}
                                  rows={5}
                                />
                              </th>
                            
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th className="text-center">
                                <Input 
                                  type="text" 
                                  // className="custom-select" 
                                  // name="auditor_profile" 
                                  // id="PreferredContactPoint"
                                  /* onChange={(event) => {
                                    setFieldValue(`auditor_date.${i}.${member.value}`, event.target.value);
                                  }}
                                  name={`auditor_date.${i}.${member.value}`}
                                  value={values.auditor_date && values.auditor_date[i] ? values.auditor_date[`${i}`][`${values.audit_team_members[i].id}`] : ''} */
                                  onChange={handleChange}
                                  name={`auditor_date.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}.date`}
                                  value={values.auditor_date && values.auditor_date[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`] ? values.auditor_date[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`][`date`] : null}
                                 // value={values.auditor_date[i][values.audit_team_members[i].id]}
                                  
                                  rows={5}
                                />
                              </th>
                            ) : null}
                          </tr>
                          <tr>
                            <th>Mandays</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                            
                          
                              <th className="text-center"  key={i}> 
                             {/*  <Input 
                                  type="text" 
                                   onChange={(event) => {
                                    setFieldValue(`mandays.${i}.mandays`, event.currentTarget.value);
                                  }}
                                  name={`mandays.${i}.mandays`}
                                //  value={values.mandays && values.mandays[`${i}`] ? values.mandays[`${i}`][`${lead_auditor.value}`]  : ''}
                                  value={values.mandays && values.mandays.length ? 
                                    values.mandays.map((lead_auditor_manday,l)=>( 
                                    lead_auditor_manday.auditor_id ? 
                                      lead_auditor_manday.auditor_id.indexOf(lead_auditor.value) > -1 ?
                                        lead_auditor_manday.mandays 
                                      : 
                                      null
                                      :
                                     lead_auditor_manday[i][l]   ? lead_auditor_manday[i][l] :  null
                                    )).join(''):null }
                                  rows={5}
                                /> */}

                              <Input 
                                  type="text" 
                                 // name={`mandays.${i}.mandays`}
                                 // id={`Mandays${i}`}
                                 
                                 /* onChange={(event) => {
                                    setFieldValue(`mandays.${i}.mandays`, event.currentTarget.value);  
                                  }} */
                                  //value={values.mandays && values.mandays.length ? values.mandays[i].mandays : null}
                                  //value={values.mandays && values.mandays.length ?  values.mandays[i].mandays : null }
                                 /*  onChange={(event) => {
                                    setFieldValue(`mandays.${i}.${lead_auditor.value}`, event.currentTarget.value);
                                  }} */
                                  onChange={handleChange}
                                  name={`mandays.${i}.mandays`}
                                  value={values.mandays && values.mandays[`${i}`] ? values.mandays[`${i}`][`mandays`] : null}
                                //  value={values.mandays && values.mandays[`${i}`] ? values.mandays.map((lead_auditor_manday, j) =>lead_auditor_manday.mandays ).join(''):null} //values.mandays[`${i}`][`mandays`][`${lead_auditor.value}`]  : ''}

                                   /* value={values.mandays && values.mandays.length ?
                                    values.mandays.map((lead_auditor_manday, j) =>(
                                      lead_auditor_manday.auditor_id ?
                                        lead_auditor_manday.auditor_id.indexOf(lead_auditor.value) > -1 ?
                                          lead_auditor_manday.mandays 
                                          : null
                                          : lead_auditor_manday[i][j]   ? lead_auditor_manday[i][j] 
                                          :  null
                                    )).join(''):null}  */
                                    rows={5}
                                    />
                                  
                              </th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th className="text-center"  key={i}>
                                <Input 
                                  type="text" 
                                  /* onChange={(event) => {
                                    setFieldValue(`mandays.${i+values.lead_auditors.length}.${member.value}`, event.currentTarget.value);
                                  }} */
                                  onChange={handleChange}
                                  /* onChange={(event) => {
                                    alert(event.currentTarget.value);
                                   
                                  }} */
                                  name={`mandays.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}.mandays`}
                                  value={values.mandays && values.mandays[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`] ? values.mandays[`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}`][`mandays`] : ''}

                                  //  id={`Mandays${i+values.lead_auditors.length}`}
                                  /* onChange={(event) => {
                                    setFieldValue(`mandays.${i}.mandays`, event.currentTarget.value);
                                  }}
                                  name={`mandays.${i+values.lead_auditors.length}.mandays`}
                                  
                                  value={values.mandays && values.mandays.length ?
                                    values.mandays.map((member_manday, j) => (
                                      member_manday.auditor_id ? 
                                        member_manday.auditor_id.indexOf(member.value) > -1 ?
                                          member_manday.mandays 
                                          : null 
                                          : member_manday[i][j] ? member_manday[i][j] 
                                          : null
                                    )).join(''):null}  */
                                  rows={5}
                                />
{/*                                 <Input 
                                  type="text" 
                                   onChange={(event) => {
                                    setFieldValue(`mandays.${i}.mandays`, event.currentTarget.value);
                                  }} 
                                  name={`mandays.${i+values.lead_auditors.length}.mandays`}
                                 value={values.mandays && values.mandays.length ? values.mandays.map((member_manday,m)=>(  member_manday.auditor_id ? 
                                  member_manday.auditor_id.indexOf(member.value) > -1 ?
                                  member_manday.mandays 
                                    : 
                                  null
                                :
                                member_manday[i][m] ? member_manday[i][m] : null
                                 )):null }
                                  rows={5}
                                /> */}
                              
                              </th>
                            ) : null}
                          </tr>
                          <tr>
                            <th>Contact No.</th>
                            {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, i) =>
                              <th className="text-center">{lead_auditor.contact_no}</th>
                            ) : null}
                            {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, i) =>
                              <th className="text-center">{member.contact_no ? member.contact_no : '-' }</th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {PRINCIPLES.map((item, i) => 
                          
                            <tr>
                              <td>{item}</td>
                              {values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.map((lead_auditor, m) =>

                                
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      // readOnly={readOnly}
                                      disabled={false}
                                      type="checkbox" 
                                      /* onChange={handleChange}
                                      name={`competency_matrix.${i}.matrix`}
                                      checked={values.competency_matrix && values.competency_matrix[`${i}`] ? values.competency_matrix[`${i}`][`matrix`] : null} */

                                      name={`competency_matrix.${i}.${lead_auditor.value}`} 
                                      onChange={(event) => {
                                        if (values.competency_matrix && values.competency_matrix[i] && values.competency_matrix[i][m]) {
                                          setFieldValue(`competency_matrix.${i}.${m}`, 0/* !values.competency_matrix[i][m] */);
                                        } else {
                                          setFieldValue(`competency_matrix.${i}.${m}`, 1);
                                        } 
                                      }}
                                      checked={values.competency_matrix && values.competency_matrix[`${i}`] ? values.competency_matrix[`${i}`][`${m}`]==1  :null}
                                    />
                                    <span></span>
                                  </label>
                                 {/* {values.competency_matrix[`${i}`][`${m}`]} */}
                                </div>
                              </td>
                              ) : null}

                              {values.audit_team_members && values.audit_team_members.length ? values.audit_team_members.map((member, n) =>
                              <td style={{ textAlign: "center" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      /* onChange={handleChange}
                                      name={`competency_matrix.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+i : 0}.matrix`}
                                      checked={values.competency_matrix && values.competency_matrix[`${values.lead_auditors ? values.lead_auditors.length ? values.lead_auditors.length+i : 0 : 0}`] ? values.competency_matrix[`${values.lead_auditors ? values.lead_auditors.length ? values.lead_auditors.length+i : 0 : 0}`][`matrix`] : null} */

                                      // readOnly={readOnly}
                                      disabled={false}
                                      type="checkbox" 
                                      name={`competency_matrix.${i}.${member.value}`}
                                       onChange={(event) => {
                                        if (values.competency_matrix && values.competency_matrix[i] && values.competency_matrix[i][values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+n : 0]) {
                                          setFieldValue(`competency_matrix.${i}.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+n : 0}`, 0/* !values.competency_matrix[i][values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+n : 0] */);
                                        } else {
                                          setFieldValue(`competency_matrix.${i}.${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+n : 0}`, 1);
                                        }
                                      }} 
                                      checked={values.competency_matrix && values.competency_matrix[`${i}`] && values.competency_matrix[`${i}`][values.lead_auditors ? values.lead_auditors.length ? values.lead_auditors.length+n : 0 : 0]  ? values.competency_matrix[`${i}`][values.lead_auditors ? values.lead_auditors.length ? values.lead_auditors.length+n : 0 : 0]  == 1 : 0}
                                      
                                    />
                                  <span> </span>
                                 </label>
                                 {/* {console.log(values.competency_matrix[`${i}`][`${values.lead_auditors && values.lead_auditors.length ? values.lead_auditors.length+n : 0}`])}
                                 {values.competency_matrix[`${i}`][`${values.lead_auditors.length+n}`]} */}
                                </div>
                              </td>
                              ) : null}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </React.Fragment>
                    : null}

                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ConflictOfInterest">Conflict Of Interest</Label>
                          <Input 
                            readOnly={readOnly}
                            type="textarea" 
                            name="conflict_of_interest" 
                            id="ConflictOfInterest"
                            onChange={handleChange}
                            value={values.conflict_of_interest}
                          />
                        </FormGroup>
                      </div>
                    </div> */}

                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ConfidentialityRequirements">Confidentiality Requirements</Label>
                          <Input 
                            readOnly={readOnly}
                            type="textarea" 
                            name="confidentiality_requirements" 
                            id="ConfidentialityRequirements"
                            onChange={handleChange}
                            value={values.confidentiality_requirements}
                          />
                        </FormGroup>
                      </div>
                    </div> */}

                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="FacilitiesAndAssistanceNeeded">Facilities And Assistance Needed</Label>
                          <Input 
                            readOnly={readOnly}
                            type="textarea" 
                            name="facilities_and_assistance_needed" 
                            id="FacilitiesAndAssistanceNeeded"
                            onChange={handleChange}
                            value={values.facilities_and_assistance_needed}
                            rows={5}
                          />
                        </FormGroup>
                      </div>
                    </div> */}
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="WorkingLanguage">Other Members Information</Label>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Position / Role</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.other_members && values.other_members.length && values.other_members.map((OTHERMEMBERS, key) => 
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`other_members.${key}.fullname`}
                                      onChange={(event) => {
                                        setFieldValue(`other_members.${key}.fullname`, event.currentTarget.value);
                                      }}
                                      value={values.other_members[key].fullname}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`other_members.${key}.email`}
                                      onChange={(event) => {
                                        setFieldValue(`other_members.${key}.email`, event.currentTarget.value);
                                      }}
                                      value={values.other_members[key].email}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`other_members.${key}.role`}
                                      onChange={(event) => {
                                        setFieldValue(`other_members.${key}.role`, event.currentTarget.value);
                                      }}
                                      value={values.other_members[key].role}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      type="button"
                                      outline
                                      color="danger"
                                      onClick={() => {
                                        if (window.confirm('Delete this row?')) {
                                          let { other_members } = values;

                                          delete other_members[key];

                                          setFieldValue('other_members', other_members);
                                        }
                                      }}
                                    >
                                      <i className="icon-trash"></i>
                                    </Button>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td colSpan={subtype !== 'mspo-sccs' ? 7 : 8}>
                                  <Button 
                                    block 
                                    outline
                                    onClick={(e) => {
                                      e.preventDefault();

                                      let { other_members } = values;
                                      if (!other_members) {
                                        other_members = [];
                                      }
                                      other_members.push(OTHERMEMBERS);

                                      // setData(data => {
                                      //   return {...data, branches: branches}
                                      // })

                                      // console.log(branches);

                                      setFieldValue('other_members', other_members);
                                    }}
                                  >
                                    Add Row
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="WorkingLanguage">Working Language</Label>
                          <Input 
                            readOnly={true}
                            type="text" 
                            name="working_language" 
                            id="WorkingLanguage"
                            onChange={handleChange}
                            value={values.working_language || 'English & Bahasa Malaysia'}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="AuditPlan">Audit Plan</Label>
                          <Editor 
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            value={values.audit_plan}
                            init={{
                              height: 500,
                              menubar: 'file edit view insert format tools table tc help',
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                              paste_data_images: true,
                              content_style: "body { font-family: Arial; font-size: 10pt; }",
                            }}
                            onEditorChange={(content) => {
                              setFieldValue('audit_plan', content);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div> */}

                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="NCReporting">Non-Conformance (NC) &amp; Reporting</Label>
                          <Input 
                            readOnly={readOnly}
                            type="textarea" 
                            name="nc_reporting" 
                            id="NCReporting"
                            onChange={handleChange}
                            value={values.nc_reporting}
                          />
                        </FormGroup>
                      </div>
                    </div> */}

                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>a.</td>
                              <td>Major NC</td>
                              <td>

                              </td>
                            </tr>
                            <tr>
                              <td>b.</td>
                              <td>Minor NC</td>
                              <td>
                                
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Others">Others</Label>
                          <Input 
                            readOnly={true}
                            type="textarea" 
                            name="others" 
                            id="Others"
                            onChange={handleChange}
                            value={values.others  || "a.	Times are approximate and will be confirmed at the opening meeting prior to commencement of the audit. Auditors reserve the right to change or add to the elements listed before or during the audit depending on the results of on-site investigation.\nb.	Policy, awareness, communication, documented information, objective and program shall be covered at the respective areas and departmental functional activities where appropriate. \nc.	*If an incident/interruption occurs during the remote access, the audit team leader should review the situation with the auditee and, if necessary, with the audit client and reach agreement on whether the audit should be interrupted, rescheduled or continued; \nd.	*Maintain respect for privacy during audit breaks; and \ne.	*The client shall ensure that full cooperation is provided (e.g. contactable and able to provide information to the extent necessary, when needed.\n\n*applicable for remote audit."}
                            rows={10}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="AppendixA">Appendix A</Label>
                          <div className="input-group">
                            <div className="custom-file">
                              <Input
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                className="custom-file-input"
                                type="file" 
                                name="appendix_a" 
                                // id="ROCCrtificate"
                                onChange={(event) => {
                                  setFieldValue(`appendix_a`, event.currentTarget.files[0])
                                }}
                              />
                              <Label className="custom-file-label">
                                
                                {values.appendix_a && values.appendix_a.name ? values.appendix_a.name : 'No file chosen'}
                              </Label>
                            </div>
                          </div>
                          {/* <Input 
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            disabled={readOnly}
                            type="file" 
                            name="appendix_a" 
                            // id="ROCCrtificate"
                            onChange={(event) => {
                                // console.log(event.currentTarget.files[0]);
                              
                                setFieldValue(`appendix_a`, event.currentTarget.files[0])
                              }}
                          /> */}
                          {values.appendix_a && values.appendix_a.url ? <div><a className="btn btn-link" href={values.appendix_a.url}>{values.appendix_a.originalName}</a></div> : null}
                          <br />
                          &mdash;&mdash; OR &mdash;&mdash;
                          <br />
                          <br />
                          <Editor 
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            value={values.appendix_a}
                            init={{
                              height: 500,
                              menubar: 'file edit view insert format tools table tc help',
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                              paste_data_images: true,
                              content_style: "body { font-family: Arial; font-size: 10pt; }",
                            }}
                            onEditorChange={(content) => {
                              setFieldValue('appendix_a', content);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Card>
                {profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin' ?          
                <Card>
              <div className="header">
                <h2>FOR ADMIN USAGE : </h2>
              </div>
              <div className="body">
                <React.Fragment>
                  <div className="row clearfix">
                    <div className="col-sm-4">
                      <FormGroup>
                        <Label for="transportationDate">Auditor transportation arrangement date</Label>
                        <DatePicker
                          autoComplete="off"
                          name="transportation_arrangement_date" 
                          id="transportation_arrangement_date"
                          value={values.transportation_arrangement_date && values.transportation_arrangement_date ? values.transportation_arrangement_date : ""}
                          onChange={date => setFieldValue('transportation_arrangement_date', moment(date).format('YYYY-MM-DD'))}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </Card>
             : ''}      
            <div className="row clearfix">
                      <div className="col-sm-4">
                        {/* {readOnly === false ? 
                        <React.Fragment> */}
                        <button type="submit" className="btn btn-primary">
                          {id ? 'Update' : 'Create'}
                        </button>&nbsp;
                        {/* </React.Fragment> : null} */}
                        <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>              
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export default Form;