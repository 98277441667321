import React, { useEffect, useState, useRef } from 'react';

import {
  Link,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  // Card,
  // FormGroup,
  // Input,
  // Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import SidebarToggler from '../../components/SidebarToggler';

import { 
  Formik,
  Form
} from 'formik';

import { Input } from "reactstrap";

import moment from 'moment';

import api from "../../utils/api";
import { db } from "../../utils/firebase";

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const Notification = () => {
  let { path } = useRouteMatch();
  
  let [profile, setProfile] = useState({});
  
  let [notifications, setNotifications] = useState([]);
  
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  
  const [body, setBody] = useState('');
  
  useEffect(() => {
    let userId = null;

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // console.log(response.data);
        
        setProfile(response.data);
        
        db.ref("notifications/" + window.btoa(response.data.email)).limitToLast(100).orderByChild("created").on("value", snapshot => {
          let notifications = [];

          snapshot.forEach((snap) => {
            let n = snap.val();
            n.key = snap.key;
            
            notifications.push(n);
          });
          
          setNotifications(notifications.reverse());
          
          // console.log(notifications);
        });

      })
      .catch(function (error) {

      });
    }

    getMe();
    
  }, []);
  
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Notification
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  Notification
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <div className="mail-inbox">
              <div className="mail-right" style={{ width: "100%" }}>
                <div className="mail-list">
                  <ul className="list-unstyled">
                    {notifications.map((notification, i) => 
                      <li className={notification.isNew ? "clearfix unread" : "clearfix"}>
                        <div className="mail-detail-right">
                          <h6 className="sub">
                            <a 
                              href="#" 
                              className="mail-detail-expand"
                              onClick={(e) => {
                                e.preventDefault();
                                
                                let reactElement = htmlToReactParser.parse(notification.body);
                                
                                setBody(reactElement);
                                
                                toggleModal();
                                
                                db.ref("notifications/" + window.btoa(profile.email) + "/" + notification.key).update({"isNew": 0});
                              }}
                            >
                              {notification.subject}
                            </a>
                          </h6>
                          <span className="time">{moment.unix(notification.created).fromNow()}</span>
                          <button
                            type="button" 
                            className="btn"
                            style={{ position: "absolute", top: 0, right: "-50px" }}
                            onClick={(e) => {
                              if (window.confirm('Delete notification?')) {
                                db.ref("notifications/" + window.btoa(profile.email) + "/" + notification.key).remove();
                              }
                            }}
                          >
                            <i class="icon-trash"></i>
                          </button>
                        </div>          
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Notification;