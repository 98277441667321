import React, { useEffect, useState } from "react";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

const MspoLogoUsage = (props) => {
  let { id, type, subtype } = useParams();

  let history = useHistory();

  const { addToast } = useToasts();
  
  const [data, setData] = useState({});
  
  useEffect(() => {
    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          setData(response.data.data);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);
  
  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          await api.put('/audits/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            addToast('MSPO logo usage successfully updated', { appearance: 'success' });
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card>
              <div className="header">
                <h2>MSPO Logo Usage</h2>
              </div>
              <div className="body">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th colSpan={2}>General</th>
                      <th style={{ width: "25%" }}>Findings</th>
                      <th>Conformance/Non-conformance/Not applicable</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <ol>
                          <li>Does the logo user have an uptodate agreement with MPOCC?</li>
                        </ol>
                        Reference
                        <br />
                        <br />
                        <b>7. Role of Accredited Certification Body</b>
                        <ol type="i">
                          <li>The Logo User make available the up-to-date agreement between Logo User and MPOCC to the ACBs during the audit for verification or as and when needed;</li>
                        </ol>
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          name={`mspo_logo_usage.0.findings`}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[0] ? values.mspo_logo_usage[0].findings : ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          name={`mspo_logo_usage.0.status`}
                          onChange={handleChange}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[0] ? values.mspo_logo_usage[0].status : ""}
                        >
                          <option></option>
                          <option>Conformance</option>
                          <option>Non-Conformance</option>
                          <option>Not Applicable</option>
                        </Input>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <ol start={2}>
                          <li>Please state the license number of the logo user.</li>
                        </ol>
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          name={`mspo_logo_usage.1.findings`}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[1] ? values.mspo_logo_usage[1].findings : ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          name={`mspo_logo_usage.1.status`}
                          onChange={handleChange}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[1] ? values.mspo_logo_usage[1].status : ""}
                        >
                          <option></option>
                          <option>Conformance</option>
                          <option>Non-Conformance</option>
                          <option>Not Applicable</option>
                        </Input>
                      </td>
                    </tr>
                  </tbody>
                  <thead className="thead-dark">
                    <tr>
                      <th colSpan={2}>Requirements</th>
                      <th>Findings</th>
                      <th>Conformance/Non-conformance/Not applicable</th>
                    </tr>
                    <tr>
                      <th colSpan={4}>8.1 User Groups</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>8.1.5</td>
                      <td>Logo usage by parent company/HQ 
                          for any certified entities under 
                          company on their limitation,    
                          whereby the parent company/HQ can produce the materials for the 
                          subsidiaries however if there’s any 
                          document related to the subsidiaries 
                          only, they need to have their own 
                          logo license.
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          name={`mspo_logo_usage.2.findings`}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[2] ? values.mspo_logo_usage[2].findings : ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          name={`mspo_logo_usage.2.status`}
                          onChange={handleChange}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[2] ? values.mspo_logo_usage[2].status : ""}
                        >
                          <option></option>
                          <option>Conformance</option>
                          <option>Non-Conformance</option>
                          <option>Not Applicable</option>
                        </Input>
                      </td>
                    </tr>
                  </tbody>
                  <thead className="thead-dark">
                    <tr>
                      <th colSpan={4}>8.2 Usage Alternatives</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>8.2</td>
                      <td>
                        When the MSPO Logo is used, the following essential elements must be fulfilled:
                        <ul>
                          <li>The MSPO Logo shall be reproduced according to the measurements, colors, trademark claims and other specifications detailed in the Logo Reproduction Tool Kit, which MPOCC makes available to the approved logo users.</li>
                          <li>A logo license registration number provided by MPOCC as per Appendix B shall be included. </li>
                        </ul>
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          name={`mspo_logo_usage.3.findings`}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[3] ? values.mspo_logo_usage[3].findings : ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          name={`mspo_logo_usage.3.status`}
                          onChange={handleChange}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[3] ? values.mspo_logo_usage[3].status : ""}
                        >
                          <option></option>
                          <option>Conformance</option>
                          <option>Non-Conformance</option>
                          <option>Not Applicable</option>
                        </Input>
                      </td>
                    </tr>
                    <tr>
                      <td>8.2.1</td>
                      <td>
                        ii) Off-product usage includes:
                        <ul>
                          <li>Claim in the traceability documentation (e.g. invoice, packaging list and bill of lading)</li>
                          <li>Claim in general documentation (e.g. company or product brochure)</li>
                          <li>Claim in signage (e.g. at an organization’s premises or at boundary of oil palm planted area</li>
                        </ul>
                        
                          The MSPO Logo can also be used in off-product applications to promote the usage of MSPO labelled products, or to increase the general awareness about the MPOCC’s goals and purposes towards the achievement of sustainable oil palm management.
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          name={`mspo_logo_usage.4.findings`}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[4] ? values.mspo_logo_usage[4].findings : ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          name={`mspo_logo_usage.4.status`}
                          onChange={handleChange}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[4] ? values.mspo_logo_usage[4].status : ""}
                        >
                          <option></option>
                          <option>Conformance</option>
                          <option>Non-Conformance</option>
                          <option>Not Applicable</option>
                        </Input>
                      </td>
                    </tr>
                  </tbody>
                  <thead className="thead-dark">
                    <tr>
                      <th colSpan={4}>8.3 Off Product Use</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>8.3.2</td>
                      <td>
                        For off-product use, Logo Users must use the logo with the logo license number that is issued (To refer to section 8.3.2 page 10 of MSPOLGP04, 1 October 2019). 
<br />
<br />
The logo for off-product use shall adhere to MSPO Logo Reproduction Tool Kit with the exception of the size of the logo, which shall remain flexible and legible.  
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          name={`mspo_logo_usage.5.findings`}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[5] ? values.mspo_logo_usage[5].findings : ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          name={`mspo_logo_usage.5.status`}
                          onChange={handleChange}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[5] ? values.mspo_logo_usage[5].status : ""}
                        >
                          <option></option>
                          <option>Conformance</option>
                          <option>Non-Conformance</option>
                          <option>Not Applicable</option>
                        </Input>
                      </td>
                    </tr>
                  </tbody>
                  <thead className="thead-dark">
                    <tr>
                      <th colSpan={4}>9.1.3 License agreement</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>9.1.3</td>
                      <td>
                        The Logo User shall advise MPOCC and ACB immediately on any changes concerning: 
                        <ol type="i">
                          <li>Organization’s contact and location details; </li>
                          <li>Organization’s certification status;</li>
                          <li>MSPO Logo Intended Use</li>
                        </ol>
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          name={`mspo_logo_usage.6.findings`}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[6] ? values.mspo_logo_usage[6].findings : ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          name={`mspo_logo_usage.6.status`}
                          onChange={handleChange}
                          value={values.mspo_logo_usage && values.mspo_logo_usage[6] ? values.mspo_logo_usage[6].status : ""}
                        >
                          <option></option>
                          <option>Conformance</option>
                          <option>Non-Conformance</option>
                          <option>Not Applicable</option>
                        </Input>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
                <div className="row clearfix">
                  <div className="col-sm-4">
                    {/* {readOnly === false ? 
                    <React.Fragment> */}
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Updating...' : 'Update'}
                    </button>&nbsp;
                    {/* </React.Fragment> : null} */}
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default MspoLogoUsage;