import React, { useEffect, useState } from "react";

import {
  Link,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

const Introduction = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  useEffect(() => {
    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          
          // console.log(response.data);
          
          delete response.data.id;

          setData(response.data.data);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          api.put('/audits/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            addToast('Audit successfully updated', { appearance: 'success' });
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card>
              <div className="header">
                <h2>Title</h2>
              </div>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="title"
                        onChange={handleChange}
                        value={values.title}
                        rows={3}
                      />
                      {/* <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={data.introduction}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('introduction', content);
                        }}
                      /> */}
                    </FormGroup>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>&nbsp;
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path.replace(/:type/, props.type)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default Introduction;