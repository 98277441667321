import React, { useEffect, useState } from "react";

import {
  Link,
  // Route,
  // Switch,
  useParams,
  // useHistory,
  // useLocation,
  // useRouteMatch,
} from "react-router-dom";

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  Button,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from "reactstrap";

import { objectToFormData } from 'object-to-formdata';

import { useToasts } from 'react-toast-notifications';

import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

import classnames from 'classnames';

import moment from 'moment';

import './style.css';

//const HtmlToReactParser = require('html-to-react').Parser;
//const htmlToReactParser = new HtmlToReactParser();

const View = (props) => {
  let { type, subtype, id } = useParams();

  let [profile, setProfile] = useState({});
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const { addToast } = useToasts();
  const toggleModal = () => setModal(!modal);
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
          


/*         if(response.data.roles[0].name.toLowerCase() === 'client' && (response.data.client_feedbacks  === false || response.data.auditor_evaluation  === false)){
          window.location.replace('/audit-module/audit-report/'+type+'/'+subtype);
          addToast('Please submit your Client Feedback & Auditor Evaluation', { appearance: 'error',placement: 'top-center' });
        } */
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
        setProfile(response.data);
       // console.log(response.data.roles[0].name)
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
      
      if (token) {
       let config = {
          type: 'desktop',
          height: window.innerHeight - 110,
            document: {
                fileType: 'docx',
                key: `${id}-${Date.now()}`,
                title: 'Audit Report',
                url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=report&token=${token.access_token}&time=${Date.now()}`,
                permissions: {
                    download: true,
                    edit: false,
                    print: true,
                    review: false,
                }
            },
            documentType: 'text',
            editorConfig: {
                callbackUrl: '',
                mode: 'view',
                customization: {
                    zoom: 100,
                    customer: {
                        address: '',
                        info: '',
                        logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                        mail: 'iskandar@greentagpro.com',
                        name: 'Greentagpro Solution',
                        www: 'greentagpro.com',
                    },
                    chat: false,
                    forcesave: true,
                    comments: false,
                }
            },
        }

        new window.DocsAPI.DocEditor("placeholder", config);
      }
    }

    getToken();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          setData(response.data.data);
          
          let count_feedback=0;
          let count_evaluation=0;

          if(response.data.data.audit_plan ){
            response.data.data.audit_plan.auditor_evaluation.map((ae, key) => {       
                if(ae.date === null){
                  count_evaluation++;
                }
              })
            if(response.data.data.audit_plan.client_feedback){
                if(response.data.data.audit_plan.client_feedback.date === null){
                  count_feedback++;
                }
            }
          }

          if(response.data.meta.roles[0].name.toLowerCase() === 'client' && count_evaluation  >= 1){
            window.location.replace('/audit-module/audit-report/'+type+'/'+subtype);
            addToast('Please submit your Auditor Evaluation', { appearance: 'error',placement: 'top-center' });
          }     
         
          if(response.data.meta.roles[0].name.toLowerCase() === 'client' && count_feedback >= 1){
            window.location.replace('/audit-module/audit-report/'+type+'/'+subtype);
            addToast('Please submit your Client Feedback', { appearance: 'error',placement: 'top-center' });
          }     

          if (response.data.data.audit_note && response.data.data.audit_note.url) {
            let config = {
              type: response.data.data.audit_note && response.data.data.audit_note.originalExtension ? response.data.data.audit_note.originalExtension : 'pdf',
              height: window.innerHeight - 110,
                document: {
                    fileType: 'pdf',
                    key: `${id}-${Date.now()}`,
                    title: 'Audit Notes',
                    url: response.data.data.audit_note.url,
                    permissions: {
                        download: false,
                        edit: false,
                        print: true,
                        review: false,
                    }
                },
                documentType: 'text',
                editorConfig: {
                    callbackUrl: '',
                    mode: 'view',
                    customization: {
                        zoom: 100,
                        customer: {
                            address: '',
                            info: '',
                            logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                            mail: 'iskandar@greentagpro.com',
                            name: 'Greentagpro Solution',
                            www: 'greentagpro.com',
                        },
                        chat: false,
                        forcesave: true,
                        comments: false,
                    }
                },
            }
    
            new window.DocsAPI.DocEditor("placeholder_audit_note", config);
          }

          
          // if (response.data.data.audit_plan && response.data.data.audit_plan.appendix_a && response.data.data.audit_plan.appendix_a.url) {
            // console.log(response.data.data.audit_plan.appendix_a.url);
          if (response.data.data.audit_plan && response.data.data.audit_plan.appendix_a && response.data.data.audit_plan.appendix_a.url) {  
            let config = {
              type: response.data.data.audit_plan && response.data.data.audit_plan.appendix_a.originalExtension ? response.data.data.audit_plan.appendix_a.originalExtension : 'pdf',
              height: window.innerHeight - 110,
                document: {
                    fileType: 'pdf',
                    key: `${id}-${Date.now()}`,
                    title: 'Audit Programme',
                    url: response.data.data.audit_plan && response.data.data.audit_plan.appendix_a && response.data.data.audit_plan.appendix_a.url ? response.data.data.audit_plan.appendix_a.url : `${process.env.REACT_APP_API_URL}/audit-plans/${id}?export=appendix&token=${token.access_token}&time=${Date.now()}`,
                    
                    permissions: {
                        download: false,
                        edit: false,
                        print: true,
                        review: false,
                    }
                },
                documentType: 'text',
                editorConfig: {
                    callbackUrl: '',
                    mode: 'view',
                    customization: {
                        zoom: 100,
                        customer: {
                            address: '',
                            info: '',
                            logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                            mail: 'iskandar@greentagpro.com',
                            name: 'Greentagpro Solution',
                            www: 'greentagpro.com',
                        },
                        chat: false,
                        forcesave: true,
                        comments: false,
                    }
                },
            }
          
            new window.DocsAPI.DocEditor("placeholder_audit_programme", config);
          }
          if(response.data.meta && response.data.meta.roles[0] && response.data.meta.roles[0].name.toLowerCase() !== 'client' ){
            if (response.data.data.final_report && response.data.data.final_report.url) {  
              let config_final = {
                type: response.data.data.final_report && response.data.data.final_report.originalExtension ? response.data.data.final_report.originalExtension : 'pdf',
                height: window.innerHeight - 110,
                  document: {
                      fileType: 'pdf',
                      key: `${id}-${Date.now()}`,
                      title: 'Final Audit Report',
                      url: response.data.data.final_report.url,
                      permissions: {
                          download: false,
                          edit: false,
                          print: true,
                          review: false,
                      }
                  },
                  documentType: 'text',
                  editorConfig: {
                      callbackUrl: '',
                      mode: 'view',
                      customization: {
                          zoom: 100,
                          customer: {
                              address: '',
                              info: '',
                              logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                              mail: 'iskandar@greentagpro.com',
                              name: 'Greentagpro Solution',
                              www: 'greentagpro.com',
                          },
                          chat: false,
                          forcesave: true,
                          comments: false,
                      }
                  },
              }
            
              new window.DocsAPI.DocEditor("placeholder_final_report", config_final);
            }
          }
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
             addToast(error.response.data.message, { appearance: 'error' });
          } else {
            // addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, []);

  // if (type.toUpperCase() === 'MSPO') {
  //   return <ViewMspo {...props} />
  // }
  
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""} Audit Report
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                Audit Report
              </li>
              <li className="breadcrumb-item">
                <Link to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}>
                  {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card invoice1">
            <div className="body">
            <div className="row">
            <div className="col-md-10">

              <Nav pills>
                <NavItem>
                  <NavLink 
                  className={classnames({ active: activeTab === 1 })} 
                  onClick={() => { 
                    toggle(1);
                  }}>
                    Audit Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink 
                  className={classnames({ active: activeTab === 2 })}
                  disabled={data.audit_note && data.audit_note.url ? false : true}
                  onClick={() => { 
                    toggle(2);
                  }}>
                    Audit Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink 
                  className={classnames({ active: activeTab === 3 })} 
                  // disabled={data.audit_plan && data.audit_plan.appendix_a && data.audit_plan.appendix_a.url ? false : true}
                  onClick={() => { 
                    toggle(3);
                  }}>
                    Audit Programme
                  </NavLink>
                </NavItem>
                {profile && profile.roles && profile.roles[0].name.toLowerCase() !== 'client' && data && data.final_report ?
                    <NavItem>
                      <NavLink 
                      className={classnames({ active: activeTab === 4 })} 
                      onClick={() => { 
                        toggle(4);
                      }}>
                        Final Report
                      </NavLink>
                    </NavItem>
                  : 
                  null
                }
              </Nav>
              </div>   
              
              {profile && profile.roles && profile.roles[0].name != 'Client' ? 
              <div className="col-md-2 text-right">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-1`}>
                      Upload Final Report
                    </Tooltip>
                  }
                >
                <Button outline
                color="primary"
                onClick={toggleModal}>
                  <i className="icon-cloud-upload"></i>
                </Button>
                </OverlayTrigger>
                </div>
              
              : null}
              </div>
                          
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <div id="placeholder"></div>
                </TabPane>
                <TabPane tabId={2}>
                  <div id="placeholder_audit_note"></div>
                </TabPane>
                <TabPane tabId={3}>
                  <div id="placeholder_audit_programme"></div>
                </TabPane>
                {profile && profile.roles && profile.roles[0].name.toLowerCase() !== 'client' ?
                <TabPane tabId={4}>
                  <div id="placeholder_final_report"></div>
                </TabPane>
                :null}
              </TabContent>
              <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
                <Formik
                  //enableReinitialize={true}
                  initialValues={data}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let token = await localStorage.getItem('auth-token');

                    token = JSON.parse(token);

                    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                    values._method = 'PUT';

                    //setSubmitting(true);

                    var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                    if (id) {
                      //data.append('_method', 'PUT');
                      await api({
                        method: 'POST',
                        url: '/audits/' + id,
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: data,
                      })
                      .then(function (response) {
                        addToast('Final Audit Report successfully uploaded', { appearance: 'success' });
                        
                        //setSubmitting(false);

                        window.location.reload();
                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });
                      });
                    }
                  }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      
                    </FormGroup>
                    <ModalHeader toggle={toggleModal}>Upload Final Audit Report</ModalHeader>
                    <ModalBody className="p-0">
                      <div className="m-2 row clearfix">
                        <div className="col-sm-12">
                        <div className="input-group">
                        <div className="custom-file">
                          <Input
                            disabled={isSubmitting}
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            className="custom-file-input"
                            type="file" 
                            name="final_report" 
                            id="final_report"
                            onInput={(event) => {
                              setFieldValue('final_report', event.target.files[0])
                            }}
                          />
                          <Label className="custom-file-label">
                            {values && values.final_report && values.final_report.name ? values.final_report.name : 'No file chosen'}
                          </Label>
                        </div>
                        <div className="input-group-append">
                          <button 
                            disabled={isSubmitting}
                            className="btn btn-outline-secondary" 
                            type="submit"
                            >
                              {isSubmitting ? 'Uploading...' : 'Upload'}
                            </button>
                        </div>
                      </div>           
                          </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button 
                          type="submit"
                          disabled={isSubmitting}
                          color="primary" 
                          // onClick={sendAuditConfirmationHandler}
                        >
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </Button>{' '}
                      <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>

                  </FormikForm>
                )}
                </Formik>
                </Modal>
              <div className="col-md-12 text-right">
                <hr />
                {type.toUpperCase() === 'MSPO' ? 
                <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audits/view/${id}?token=${token}&publicSummaryReport=true`);
                  }}
                >
                  <i className="icon-printer"></i> Public Summary Report
                </button>
                : null}
                
                &nbsp;
                
                <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audits/view/${id}?token=${token}`);
                  }}
                >
                  <i className="icon-printer"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default View;
